import { getLocalStorage } from "../localStorage";
import { zitadelConfig } from "./zitadelConfig";
import { TokenResponse } from "./types";

export const fetchTokens = async ({ code }: { code: string }) => {
  const codeVerifier = getLocalStorage("codeVerifier");

  if (!code) {
    throw Error(
      "Parameter 'code' not found in URL. \nHas authentication taken place?"
    );
  }

  if (!codeVerifier) {
    throw Error(
      "Can't get tokens without the CodeVerifier. \nHas authentication taken place?"
    );
  }

  const response = await fetch(zitadelConfig.tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "authorization_code",
      code: code,
      client_id: zitadelConfig.clientId,
      redirect_uri: zitadelConfig.redirectUri,
      code_verifier: codeVerifier,
    }).toString(),
  });

  if (response.status !== 200) {
    throw Error(
      `Failed to fetch tokens. Status: ${response.status} ${response.statusText}`
    );
  } else {
    return (await response.json()) as TokenResponse;
  }
};
