import React from "react";
import { useQuery } from "@apollo/client";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import SettingsIcon from "../components/icons/SettingsIcon";
import IconButton from "@material-ui/core/IconButton";
import AssignmentIndIcon from "@material-ui/icons/AssignmentIndOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ME } from "../graphql/queries.gql";
import clsx from "clsx";
import { useDrawerContext } from "../contexts/DrawerContext";
import { Me } from "../graphql/types/Me";
import { useMediaQuery } from "@material-ui/core";
import { CompanyThumbnail } from "../routes/Admin/Assign/CompanyThumbnail";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },

  toolbarContent: {
    display: "flex",
    flex: 1,
  },

  toolbarLeftContent: {
    display: "flex",
  },

  toolbarRightContent: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },

  linkIcon: {
    marginRight: theme.spacing(1),
  },
  logoRouteLink: {
    display: "flex",
  },
  logo: {
    maxHeight: "40px",
  },
  dividerRight: {
    display: "flex",
    borderRight: `1px solid ${theme.palette.divider}`,
    alignItems: "center",
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  companyText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  planButton: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  currentPlan: {
    background: theme.palette.background.paper,
    fontWeight: "bold",
  },
  hide: {
    display: "none",
  },
}));

const MenuBar: React.FC = () => {
  const drawerContext = useDrawerContext();
  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const matchXsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const matchMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const { data: companyData } = useQuery<Me>(ME);
  return (
    <AppBar position="static" className={classes.root} color="default">
      <Toolbar className={classes.toolbar}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={drawerContext.toggleOpen}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <div className={classes.toolbarContent}>
          <div className={classes.toolbarLeftContent}>
            <div
              className={clsx({
                [classes.hide]: matchXsDown,
              })}
            >
              <Typography variant="h6" className={classes.title}>
                <Link
                  component={RouterLink}
                  to="/"
                  className={classes.logoRouteLink}
                >
                  <img
                    src={
                      location.pathname === "/survey"
                        ? "/img/again_logo_solid_480.png"
                        : "/img/again_logo_qwq8vl_c_scale,w_480.png"
                    }
                    alt="Again logo"
                    className={classes.logo}
                  />
                </Link>
              </Typography>
            </div>
          </div>

          <div className={classes.toolbarRightContent}>
            <div
              className={clsx(classes.dividerRight, {
                [classes.hide]: matchMdDown,
              })}
            >
              <Button
                component={RouterLink}
                to="/survey"
                disableRipple
                startIcon={<AssignmentIndIcon />}
              >
                Surveys
              </Button>
            </div>
            <div
              className={clsx(classes.dividerRight, {
                [classes.hide]: matchXsDown,
              })}
            >
              <Button
                component={RouterLink}
                to="/settings"
                disableRipple
                startIcon={<SettingsIcon />}
              >
                Settings
              </Button>
            </div>
            <div className={classes.dividerRight}>
              {companyData?.me?.companyConnection && (
                <>
                  <CompanyThumbnail
                    logoUrl={companyData.me.companyConnection.logoThumbnailUrl}
                    onClick={() => history.push("/settings/logo")}
                    size={30}
                  />
                  <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    className={classes.companyText}
                  >
                    {companyData.me.companyConnection.companyName}
                  </Typography>
                </>
              )}
            </div>
            <Button component={RouterLink} to="/auth/logout" color="secondary">
              Sign out
            </Button>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
