import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGA4React } from "ga-4-react";

const useImplementation = () => {
  const ga = useGA4React("G-ZS3WXV31BP");
  const location = useLocation();
  useEffect(() => {
    ga && ga.pageview(location.pathname + location.search);
  }, [ga, location]);
};

const isLocal =
  window.location.host.startsWith("localhost") ||
  window.location.host.startsWith("192.168") ||
  window.location.host.startsWith("0.0.0.0");

const useMock = () => {};

const useGoogleAnalytics = isLocal ? useMock : useImplementation;

export default useGoogleAnalytics;
