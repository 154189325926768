import { SupportedLanguageCode } from "../graphql/types/global";

export const documentLanguageCodes = Object.values(
  SupportedLanguageCode
).sort();

export const emptyLanguagesRecord: Readonly<
  Record<SupportedLanguageCode, string>
> = documentLanguageCodes.reduce(
  (acc, language) => ({ ...acc, [language]: "" }),
  {} as Record<SupportedLanguageCode, string>
);
