import {
  getLocalStorage,
  setLocalStorage,
  removeLocalStorage,
  StorageKey,
} from "../../utils/localStorage";

type TokenKey = Extract<
  StorageKey,
  "accessToken" | "refreshToken" | "expiresAt"
>;

export type TokenStorage = Record<TokenKey, string>;

export const setEphemeralSignInStorage = (
  input: Record<
    Extract<StorageKey, "postLoginRedirect" | "codeVerifier">,
    string
  >
) => {
  setLocalStorage("postLoginRedirect", input.postLoginRedirect);
  setLocalStorage("codeVerifier", input.codeVerifier);
};

export const clearEphemeralSignInStorage = () => {
  removeLocalStorage("postLoginRedirect");
  removeLocalStorage("codeVerifier");
};

export const clearLocalStorage = () => {
  removeLocalStorage("accessToken");
  removeLocalStorage("refreshToken");
  removeLocalStorage("expiresAt");
  removeLocalStorage("codeVerifier");
  removeLocalStorage("postLoginRedirect");
  removeLocalStorage("refreshExpiresAt" as any); // obsolete
};

export const setStorage = (input: TokenStorage) => {
  setLocalStorage("accessToken", input.accessToken);
  setLocalStorage("refreshToken", input.refreshToken);
  setLocalStorage("expiresAt", input.expiresAt);
};

export const getStorage = (key: TokenKey) => getLocalStorage(key);
