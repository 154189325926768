import React from "react";
import { allMarkets_allMarkets_markets } from "../graphql/types/allMarkets";

type Props = {
  market: Pick<allMarkets_allMarkets_markets, "iconUrl" | "marketName">;
  width?: number;
};

const makeHeight = (width: number) => Math.floor(width * (3 / 4));

const WIDTH = 20;
const HEIGHT = makeHeight(WIDTH);

export const MarketIcon = ({ market, width }: Props) => {
  const [w, h] = width ? [width, makeHeight(width)] : [WIDTH, HEIGHT];
  return (
    <img src={market.iconUrl} alt={market.marketName} width={w} height={h} />
  );
};
