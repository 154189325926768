import React, { memo, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import WarningIcon from "@material-ui/icons/Warning";
import { Box, Chip, Collapse, IconButton, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SystemUserDetails from "./SystemUserDetails";
import { asDirection } from "../../../utils/tableUtils";
import { SystemUser } from "../../../graphql/helperTypes";
import {
  DBSortOrder,
  DisbursementType,
  EntityDeleteState,
  InputListSort,
} from "../../../graphql/types/global";
import { AllSystemUsers_allSystemUsers } from "../../../graphql/types/AllSystemUsers";
import { OmitType } from "../../../utils/omitType";
import { NotificationButton } from "../Shared/NotificationButton";
import { OnRequestAddDisbursementDialog } from "../Shared/AddDisbursementDialog/types";
import { OnRequestPushUserNotificationDialog } from "../Shared/PushUserNotificationDialog/types";
import { OnRequestPushUserInvitationDialog } from "../Shared/PushUserInvitationDialog/types";
import { OnRequestDisbursementStatusDialog } from "../Shared/DisbursementStatusDialog/types";
import { DisbursementStatusAddCell } from "../Shared/DisbursementStatusCell";
import { truncateText } from "../../../utils/textUtils";
import { copyToClipboard } from "../../../utils/clipboard";
import { TimestampDisplay } from "../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof SystemUser;
  label: string;
  type:
    | "string"
    | "date"
    | "email"
    | "onboardingDisbursement"
    | "referralDisbursement"
    | "bankConnectionCount"
    | "verifiedBrandsCount";
  copy?: keyof SystemUser;
}

const headCells: HeadCell[] = [
  { id: "displayName", type: "string", label: "Name", copy: "id" },
  { id: "createdAt", type: "date", label: "Created", copy: "createdAt" },
  { id: "email", type: "email", label: "Email", copy: "email" },
  { id: "bankConnectionCount", type: "bankConnectionCount", label: "Bank" },
  { id: "verifiedBrandsCount", type: "verifiedBrandsCount", label: "Brands" },
  {
    id: "onboardingDisbursementConnection",
    type: "onboardingDisbursement",
    label: "Onboarding",
  },
  {
    id: "userInvitationConnection2" as any,
    type: "referralDisbursement",
    label: "Referral",
  },
];

const alignment = (type: HeadCell["type"]) => {
  switch (type) {
    case "verifiedBrandsCount":
    case "bankConnectionCount":
      return "center";
    case "onboardingDisbursement":
    case "referralDisbursement":
      return "right";
    default:
      return "left";
  }
};

export const supportedSortFields = new Set<keyof SystemUser>([
  "createdAt",
  "displayName",
  "email",
]);

export type OnRequestSort = (property: keyof SystemUser) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

const OnboardingDisbursementCell: React.FC<{
  user: SystemUser;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
  onRequestAddDisbursementDialog: OnRequestAddDisbursementDialog;
  onRequestUserNotificationDialog: OnRequestPushUserNotificationDialog;
}> = ({
  user,
  onRequestDisbursementStatusDialog,
  onRequestAddDisbursementDialog,
  onRequestUserNotificationDialog,
}) => {
  const disbursement = user.onboardingDisbursementConnection;
  const onNotificationClicked = () => {
    onRequestUserNotificationDialog({ userId: user.id });
  };

  const handlRequestAdd = async () => {
    onRequestAddDisbursementDialog({
      disbursementType: DisbursementType.onboarding,
      userId: user.id,
    });
  };

  const handleRequestStatus = () => {
    if (disbursement) {
      onRequestDisbursementStatusDialog({ disbursement });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <DisbursementStatusAddCell
        disbursement={disbursement}
        onRequestDisbursementStatusDialog={handleRequestStatus}
        onRequestAddDisbursementDialog={handlRequestAdd}
      />
      <NotificationButton onClick={onNotificationClicked} />
    </div>
  );
};

const ReferralDisbursementCell: React.FC<{
  user: SystemUser;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
  onRequestAddDisbursementDialog: OnRequestAddDisbursementDialog;
  onRequestPushUserInvitationDialog: OnRequestPushUserInvitationDialog;
}> = ({
  user,
  onRequestDisbursementStatusDialog,
  onRequestPushUserInvitationDialog,
  onRequestAddDisbursementDialog,
}) => {
  const invitation = user.userInvitationConnection;

  if (!invitation) {
    return null;
  }

  const disbursement = invitation?.disbursementConnection;

  const onNotificationClicked = () => {
    onRequestPushUserInvitationDialog({ invitationId: invitation.id });
  };

  const handlRequestAdd = async () => {
    onRequestAddDisbursementDialog({
      disbursementType: DisbursementType.referral,
      userInvitationId: invitation.id,
    });
  };

  const handleRequestStatus = () => {
    if (disbursement) {
      onRequestDisbursementStatusDialog({ disbursement });
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <DisbursementStatusAddCell
        disbursement={disbursement}
        onRequestDisbursementStatusDialog={handleRequestStatus}
        onRequestAddDisbursementDialog={handlRequestAdd}
      />
      <NotificationButton onClick={onNotificationClicked} />
    </div>
  );
};

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps extends OmitType<AllSystemUsers_allSystemUsers> {
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  onRequestSort: OnRequestSort;
  onRequestAddDisbursementDialog: OnRequestAddDisbursementDialog;
  onRequestUserNotificationDialog: OnRequestPushUserNotificationDialog;
  onRequestPushUserInvitationDialog: OnRequestPushUserInvitationDialog;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const rowStyles = useRowStyles();
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof SystemUser) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={rowStyles.defaultCell}
            align={alignment(headCell.type)}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            {supportedSortFields.has(headCell.id) ? (
              <TableSortLabel
                active={sorting.sortField === headCell.id}
                direction={
                  sorting.sortField === headCell.id
                    ? asDirection(sorting.sort)
                    : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sorting.sortField === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {sorting.sort === DBSortOrder.DESC
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    paper: {
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
      width: "100%",
    },
    table: {
      // tableLayout: "fixed",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const useRowStyles = makeStyles((theme) => ({
  withCredentials: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  expandButtonCell: {
    width: "44px",
    paddingRight: 0,
    paddingLeft: "8px",
  },
  defaultCell: {
    width: "auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 4,
    paddingLeft: 2,
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  noWrapCell: {
    whiteSpace: "nowrap",
  },
  copyableCell: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
  isDisabled: {
    opacity: 0.2,
  },
  iconWarning: {
    marginRight: theme.spacing(0.5),
  },
  disabledMessage: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  userEmailCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  userInstancesChip: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    cursor: "default",
  },
}));

const VerifiedBrandsCell: React.FC<{
  user: SystemUser;
}> = ({ user: { verifiedBrandsCount, onboardingDisbursementConnection } }) => {
  const isOnboarded = onboardingDisbursementConnection?.status != null;
  if (!isOnboarded && verifiedBrandsCount === 0) {
    return null;
  }

  const threshold = 10;
  const color = verifiedBrandsCount >= threshold ? "textPrimary" : "error";

  return (
    <Typography variant="body2" color={color}>
      {verifiedBrandsCount}
    </Typography>
  );
};

const BankCountCell: React.FC<{
  user: SystemUser;
}> = ({ user: { bankConnectionCount } }) => {
  const color = bankConnectionCount >= 1 ? "textPrimary" : "textSecondary";
  return (
    <Typography variant="body2" color={color}>
      {bankConnectionCount}
    </Typography>
  );
};

const UserEmailCell: React.FC<{ user: SystemUser }> = ({ user }) => {
  const classes = useRowStyles();
  const { allInstances, slugInstances } = useMemo(() => {
    const allInstances = user.mappedCampaignInstancesConnection.instances.map(
      (mapping) => mapping.campaignInstanceConnection
    );
    const slugInstances = allInstances
      .filter((i) => i.slug !== null && i.slug !== undefined && i.slug !== "")
      .map((i) => ({ ...i, label: truncateText(i.slug, 8) }));
    return { allInstances, slugInstances };
  }, [user.mappedCampaignInstancesConnection.instances]);

  const countAdditional = allInstances.length - slugInstances.length;
  const additionalLabel =
    slugInstances.length === 0 ? `${countAdditional}` : `+${countAdditional}`;

  return (
    <>
      <div className={classes.userEmailCell}>
        <span>{user.email}</span>
        <span>
          {slugInstances.map(({ label, slug }, i) => (
            <Chip
              key={i}
              label={label}
              title={slug ?? undefined}
              size="small"
              color="primary"
              className={classes.userInstancesChip}
            />
          ))}
          {countAdditional > 0 ? (
            <Chip
              label={additionalLabel}
              size="small"
              color="default"
              className={classes.userInstancesChip}
            />
          ) : null}
        </span>
      </div>
    </>
  );
};

const Row: React.FC<{
  user: SystemUser;
  onRequestAddDisbursementDialog: OnRequestAddDisbursementDialog;
  onRequestUserNotificationDialog: OnRequestPushUserNotificationDialog;
  onRequestPushUserInvitationDialog: OnRequestPushUserInvitationDialog;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
}> = ({
  user,
  onRequestAddDisbursementDialog,
  onRequestUserNotificationDialog,
  onRequestPushUserInvitationDialog,
  onRequestDisbursementStatusDialog,
}) => {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);

  const renderCell = (cell: HeadCell) => {
    switch (cell.type) {
      case "date":
        return <TimestampDisplay timestamp={user[cell.id] as string} />;

      case "email":
        return <UserEmailCell user={user} />;

      case "verifiedBrandsCount":
        return <VerifiedBrandsCell user={user} />;

      case "bankConnectionCount":
        return <BankCountCell user={user} />;

      case "onboardingDisbursement":
        return (
          <OnboardingDisbursementCell
            user={user}
            onRequestAddDisbursementDialog={onRequestAddDisbursementDialog}
            onRequestUserNotificationDialog={onRequestUserNotificationDialog}
            onRequestDisbursementStatusDialog={
              onRequestDisbursementStatusDialog
            }
          />
        );

      case "referralDisbursement":
        return (
          <ReferralDisbursementCell
            user={user}
            onRequestAddDisbursementDialog={onRequestAddDisbursementDialog}
            onRequestPushUserInvitationDialog={
              onRequestPushUserInvitationDialog
            }
            onRequestDisbursementStatusDialog={
              onRequestDisbursementStatusDialog
            }
          />
        );

      default:
        return user[cell.id];
    }
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        className={clsx(classes.withCredentials, {
          [classes.isDisabled]: !user.isEnabled,
        })}
      >
        <TableCell className={classes.expandButtonCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((cell) => (
          <TableCell
            key={`${cell.id}-${user.id}`}
            className={clsx(classes.defaultCell, {
              [classes.noWrapCell]: cell.type === "date",
              [classes.copyableCell]: cell.copy != null,
            })}
            onClick={() =>
              cell.copy != null && copyToClipboard(String(user[cell.copy]))
            }
            title={cell.copy ? `Kopiera ${user[cell.copy]}` : undefined}
            align={alignment(cell.type)}
          >
            {renderCell(cell)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {user.isEnabled === false && (
                <div className={classes.disabledMessage}>
                  <WarningIcon
                    color="action"
                    fontSize="small"
                    className={classes.iconWarning}
                  />
                  <div>
                    <Typography variant="caption">
                      <strong>User account has been disabled</strong>
                    </Typography>

                    {user.deleteStatus === EntityDeleteState.requested && (
                      <Typography variant="body2">
                        The user has requested to delete their account.
                      </Typography>
                    )}
                    {user.deleteStatus === EntityDeleteState.deleted && (
                      <Typography variant="body2">
                        This account has been deleted.
                      </Typography>
                    )}
                  </div>
                </div>
              )}
              <SystemUserDetails user={user} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SystemUsersTable = memo<TableProps>(
  ({
    onRequestSort,
    onRequestPage,
    onRequestPageSize,
    onRequestAddDisbursementDialog,
    onRequestUserNotificationDialog,
    onRequestPushUserInvitationDialog,
    onRequestDisbursementStatusDialog,
    pageInfo,
    sorting,
    users,
  }) => {
    const classes = useStyles();
    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sorting={sorting}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {users.map((user) => (
                  <Row
                    key={user.id}
                    user={user}
                    onRequestAddDisbursementDialog={
                      onRequestAddDisbursementDialog
                    }
                    onRequestUserNotificationDialog={
                      onRequestUserNotificationDialog
                    }
                    onRequestPushUserInvitationDialog={
                      onRequestPushUserInvitationDialog
                    }
                    onRequestDisbursementStatusDialog={
                      onRequestDisbursementStatusDialog
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
);

export default SystemUsersTable;
