import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  AssignableBatchUser,
  DemoSurveyBatch,
} from "../../../graphql/helperTypes";
import { OmitType } from "../../../utils/omitType";
import {
  CircularProgress,
  IconButton,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { allAssignableSurveyBatchUsers_allAssignableSurveyBatchUsers } from "../../../graphql/types/allAssignableSurveyBatchUsers";
import { Refresh } from "@material-ui/icons";
import { TimestampDisplay } from "../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof AssignableBatchUser;
  label: string;
  type: "string" | "date" | "userConnection" | "daysAgo";
}

const headCells: HeadCell[] = [
  // { id: "activityDaysAgo", type: "daysAgo", label: "Activity" },
  { id: "createdDaysAgo", type: "daysAgo", label: "Created" },
  { id: "ageYears", type: "string", label: "Age (years)" },
  { id: "genderCode", type: "string", label: "Gender" },
];

export type OnRequestSort = (property: keyof AssignableBatchUser) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

type EnhancedTableProps = Pick<
  TableProps,
  "isFetching" | "onRequestRefetch"
> & {
  classes: ReturnType<typeof useStyles>;
};

interface TableProps
  extends OmitType<allAssignableSurveyBatchUsers_allAssignableSurveyBatchUsers> {
  batch: DemoSurveyBatch;
  companyDemoId: string;
  isFetching: boolean;
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  onRequestRefetch: () => any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, isFetching } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={cellAlign(headCell)}
            sortDirection={headCell.id === headCells[0].id ? "asc" : undefined}
          >
            {i === 0 && (
              <IconButton
                type="button"
                color="primary"
                disabled={isFetching}
                onClick={props.onRequestRefetch}
                size="small"
                className={classes.refreshButton}
              >
                {isFetching ? <CircularProgress size="24px" /> : <Refresh />}
              </IconButton>
            )}
            <TableSortLabel
              active={headCell.id === headCells[0].id}
              direction={"asc"}
            >
              {headCell.label}
              {headCell.id === headCells[0].id ? (
                <span className={classes.visuallyHidden}>sorted ascending</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) => "left" as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: theme.spacing(2),
    },
    table: {
      marginTop: theme.spacing(1),
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    refreshButton: {
      marginRight: theme.spacing(1),
    },
  })
);

const AssignableBatchUsersTable = memo<TableProps>(
  ({
    pageInfo,
    users,
    onRequestPage,
    onRequestPageSize,
    onRequestRefetch,
    isFetching,
  }) => {
    const classes = useStyles();

    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    const renderCell = (cell: HeadCell, row: AssignableBatchUser) => {
      switch (cell.type) {
        case "date":
          return <TimestampDisplay timestamp={row[cell.id] as string} />;
        case "daysAgo":
          return row[cell.id] == null ? "unknown" : `${row[cell.id]} days ago`;
        default:
          return row[cell.id];
      }
    };

    return (
      <div className={classes.root}>
        <Typography variant="h6">Assignable real users (preview)</Typography>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              onRequestRefetch={onRequestRefetch}
              isFetching={isFetching}
            />
            <TableBody>
              {users.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {headCells.map((cell) => (
                      <TableCell
                        align={cellAlign(cell)}
                        key={`${cell.id}-${row.id}`}
                      >
                        {renderCell(cell, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    );
  }
);

export default AssignableBatchUsersTable;
