import React, { memo, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import {
  DBSortOrder,
  EntityDeleteState,
  InputListSort,
} from "../../../../graphql/types/global";
import { asDirection } from "../../../../utils/tableUtils";
import { OmitType } from "../../../../utils/omitType";
import { DeletesSystemUser } from "../../../../graphql/helperTypes";
import { SystemUsersDeletes_allSystemUsers } from "../../../../graphql/types/SystemUsersDeletes";
import { IconButton } from "@material-ui/core";
import { TimestampDisplay } from "../../../../components/TimestampDisplay";

export const supportedSortFields = new Set<keyof DeletesSystemUser>([
  "createdAt",
  "deletedAt",
  "fullName",
  "email",
]);

interface HeadCell {
  id: keyof DeletesSystemUser;
  label: string;
  type: "string" | "date" | "credentials" | "id";
}

const headCells: HeadCell[] = [
  { id: "fullName", type: "string", label: "Full name" },
  { id: "personalNumber", type: "string", label: "Personal number" },
  { id: "createdAt", type: "date", label: "Created" },
  { id: "deletedAt", type: "date", label: "Deleted" },
  { id: "id", type: "id", label: "User ID" },
  {
    id: "cachedTinkCredentialsConnection",
    type: "credentials",
    label: "Tink ID",
  },
  { id: "email", type: "string", label: "Email" },
  { id: "mobilePhoneNumber", type: "string", label: "Phone number" },
];

export type OnRequestConfirmDelete = (user: DeletesSystemUser) => Promise<void>;
export type OnRequestSort = (property: keyof DeletesSystemUser) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps extends OmitType<SystemUsersDeletes_allSystemUsers> {
  onRequestConfirmDelete: OnRequestConfirmDelete;
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  onRequestSort: OnRequestSort;
  onRequestRefetch: () => any;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DeletesSystemUser) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            {supportedSortFields.has(headCell.id) ? (
              <TableSortLabel
                active={sorting.sortField === headCell.id}
                direction={
                  sorting.sortField === headCell.id
                    ? asDirection(sorting.sort)
                    : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sorting.sortField === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {sorting.sort === DBSortOrder.DESC
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    hardWrapCell: {
      overflowWrap: "anywhere",
    },
  })
);

const ConfirmDeleteCell = (
  props: Pick<TableProps, "onRequestConfirmDelete"> & {
    user: DeletesSystemUser;
  }
) => {
  const [loading, setLoading] = useState(false);
  return (
    <TableCell>
      {props.user.deleteStatus !== EntityDeleteState.deleted && (
        <IconButton
          disabled={loading}
          onClick={() => {
            setLoading(true);
            props
              .onRequestConfirmDelete(props.user)
              .finally(() => setLoading(false));
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </TableCell>
  );
};

const UserDeletesTable = memo<TableProps>(
  ({
    onRequestPage,
    onRequestPageSize,
    onRequestConfirmDelete,
    onRequestSort,
    pageInfo,
    sorting,
    users,
  }) => {
    const classes = useStyles();
    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    const renderCell = (cell: HeadCell, row: DeletesSystemUser) => {
      switch (cell.type) {
        case "date":
          return <TimestampDisplay timestamp={row[cell.id] as string} />;
        case "credentials":
          return row.cachedTinkCredentialsConnection[0]?.tinkUserId;
        default:
          return row[cell.id];
      }
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sorting={sorting}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {users.map((user: DeletesSystemUser) => {
                  return (
                    <TableRow hover tabIndex={-1} key={user.id}>
                      {headCells.map((cell) => (
                        <TableCell
                          key={`${cell.id}-${user.id}`}
                          className={clsx({
                            [classes.noWrapCell]: cell.type === "date",
                            [classes.hardWrapCell]:
                              cell.type === "id" || cell.type === "credentials",
                          })}
                        >
                          {renderCell(cell, user)}
                        </TableCell>
                      ))}
                      <ConfirmDeleteCell
                        onRequestConfirmDelete={onRequestConfirmDelete}
                        user={user}
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
);

export default UserDeletesTable;
