import React from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ADD_COMPANY, MERGE_COMPANIES } from "../../../graphql/queries.gql";
import Typography from "@material-ui/core/Typography";
import { AllCompanies_allCompanies_companies } from "../../../graphql/types/AllCompanies";
import {
  MergeCompanies,
  MergeCompaniesVariables,
} from "../../../graphql/types/MergeCompanies";
import {
  AddCompany,
  AddCompanyVariables,
} from "../../../graphql/types/AddCompany";
import NullDataError from "../../../graphql/nullDataError";

interface MergeCompaniesProps {
  sourceCompanies: Array<string>;
  allCompanies: Array<AllCompanies_allCompanies_companies>;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  borderRight: {
    borderRight: `3px solid ${theme.palette.divider}`,
    width: "50%",
  },
  rightCell: {
    width: "50%",
    paddingLeft: theme.spacing(4),
  },
}));

const companyNameFromId = (
  id: string,
  companies: Array<AllCompanies_allCompanies_companies>
): string | undefined => companies.find((c) => c.id === id)?.companyName;

const MergeCompaniesDialog: React.FC<MergeCompaniesProps> = ({
  open,
  handleClose,
  allCompanies,
  sourceCompanies,
}) => {
  const classes = useStyles();
  const [company, setCompany] = React.useState<string>();
  const [mergeCompanies, { loading: mergeLoading }] = useMutation<
    MergeCompanies,
    MergeCompaniesVariables
  >(MERGE_COMPANIES);

  const [addCompany, { loading: addCompanyLoading }] = useMutation<
    AddCompany,
    AddCompanyVariables
  >(ADD_COMPANY);

  const loading = mergeLoading || addCompanyLoading;

  const saveMergeDisabled = loading || company === undefined;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCompany(event.target.value as string);
  };

  const { handleSubmit, register, errors } = useForm();
  const onCreateCompany = async (input: any) => {
    return addCompany({ variables: { input } })
      .then((res) => {
        if (!res.data) {
          throw new NullDataError("addCompany");
        }
        return mergeCompanies({
          variables: {
            input: {
              targetCompany: res.data.addCompany.companyId,
              sourceCompanies,
            },
          },
        });
      })
      .then(() => handleClose());
  };

  const handleMerge = () => {
    if (!company) {
      return;
    }
    return mergeCompanies({
      variables: {
        input: {
          targetCompany: company,
          sourceCompanies,
        },
      },
    }).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Koppla företag</DialogTitle>
      <DialogContent>
        <DialogContentText>Välj företag att koppla</DialogContentText>
        <div className={classes.flex}>
          <div className={classes.borderRight}>
            <Typography variant="h6">Existerande företag</Typography>
            <Select onChange={handleChange} value={company}>
              {allCompanies
                .filter((ac) => sourceCompanies.indexOf(ac.id) === -1)
                .sort((a, b) =>
                  a.companyName === b.companyName
                    ? 0
                    : a.companyName.toLowerCase() < b.companyName.toLowerCase()
                    ? -1
                    : 0
                )
                .map((c) => (
                  <MenuItem value={c.id} key={`menuItem-${c.id}`}>
                    {c.companyName}
                  </MenuItem>
                ))}
            </Select>

            <div>Företag som kopplas:</div>
            <ul>
              {sourceCompanies.map((c) => (
                <li key={c}>{companyNameFromId(c, allCompanies)}</li>
              ))}
            </ul>

            <Button
              onClick={handleMerge}
              variant="contained"
              color="primary"
              disabled={saveMergeDisabled}
            >
              Koppla
            </Button>
          </div>
          <div className={classes.rightCell}>
            <Typography variant="h6">Skapa nytt företag</Typography>
            <form onSubmit={handleSubmit(onCreateCompany)}>
              <TextField
                placeholder="Företagsnamn"
                id="companyName"
                name="companyName"
                label="Företagsnamn"
                error={errors.companyName !== undefined}
                helperText={
                  errors.companyName
                    ? "Please enter a valid company name"
                    : undefined
                }
                inputRef={register({
                  required: true,
                })}
              />
              <div>Företag som kopplas:</div>
              <ul>
                {sourceCompanies.map((c) => (
                  <li key={c}>{companyNameFromId(c, allCompanies)}</li>
                ))}
              </ul>
              <Button type="submit" variant="contained" color="primary">
                Skapa företag och koppla
              </Button>
            </form>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MergeCompaniesDialog;
