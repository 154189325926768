import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MergeCompaniesDialog from "./MergeCompaniesDialog";
import SetVisibleDialog from "./SetVisibleDialog";
import {
  ALL_COMPANIES,
  ASSIGN_ME_TO_COMPANY,
  SET_COMPANY_IS_VERIFIED,
} from "../../../graphql/queries.gql";
import {
  AllCompanies,
  AllCompaniesVariables,
  AllCompanies_allCompanies_companies,
} from "../../../graphql/types/AllCompanies";
import { DBSortOrder, InputListSort } from "../../../graphql/types/global";
import { CompanyThumbnail } from "./CompanyThumbnail";
import {
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Toolbar,
} from "@material-ui/core";
import { asDirection } from "../../../utils/tableUtils";
import UploadCompanyLogoDialog from "./UploadCompanyLogoDialog";
import {
  assignMeToCompany,
  assignMeToCompanyVariables,
} from "../../../graphql/types/assignMeToCompany";
import { Link } from "react-router-dom";
import {
  setCompanyIsVerified,
  setCompanyIsVerifiedVariables,
} from "../../../graphql/types/setCompanyIsVerified";

type ValidSortFields = {
  companyName: true;
  consumerCount: true;
  transactionCount: true;
  firstTransactionCreatedAt: true;
  latestTransactionCreatedAt: true;
  firstTransactionTimestamp: true;
  latestTransactionTimestamp: true;
};

const defaultSortOrder = (field: keyof ValidSortFields): DBSortOrder =>
  field === "transactionCount" || field === "consumerCount"
    ? DBSortOrder.DESC
    : DBSortOrder.ASC;

enum VisibleFilter {
  all = "all",
  onlyVisible = "onlyVisible",
  onlyInvisible = "onlyInvisible",
}

enum LogoFilter {
  all = "all",
  hasLogo = "hasLogo",
  noLogo = "noLogo",
}

enum VerifiedFilter {
  all = "all",
  isVerified = "isVerified",
  notVerified = "notVerified",
}

const useStyles = makeStyles((theme) => ({
  paper: {
    flex: 1,
  },
  select: {
    minWidth: 140,
    marginRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 140,
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  search: {
    position: "relative",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  grow: {
    flexGrow: 1,
  },
  verified: {
    display: "inline-flex",
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    marginLeft: "0.5rem",
    color: "#178017",
  },
  verifiedIcon: {
    width: "0.75rem",
    height: "0.75rem",
    marginRight: "0.25rem",
    color: "#178017",
  },
}));

interface Data {
  companyId: string;
  companyName: string;
  consumerCount: number;
  transactionCount: number;
  isVisible: boolean;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ValidSortFields
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sorting: InputListSort;
  checkStatus: CheckAllStatus;
}

interface HeadCell {
  id: keyof Data;
  numeric: boolean;
  label: string;
  sort?: keyof ValidSortFields;
}

const headCells: HeadCell[] = [
  {
    id: "companyName",
    numeric: false,
    label: "Name",
    sort: "companyName",
  },
  {
    id: "transactionCount",
    numeric: true,
    label: "#Transactions",
    sort: "transactionCount",
  },
  {
    id: "consumerCount",
    numeric: true,
    label: "#Customers",
    sort: "consumerCount",
  },
];

type CheckAllStatus = "on" | "off" | "indeterminate";

const SortableTableHead: React.FC<EnhancedTableProps> = ({
  classes,
  onSelectAllClick,
  sorting,
  checkStatus,
  onRequestSort,
}) => {
  const createSortHandler =
    (cell: HeadCell) => (event: React.MouseEvent<unknown>) => {
      if (cell.sort) {
        onRequestSort(event, cell.sort);
      }
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Checkbox
            indeterminate={checkStatus === "indeterminate"}
            checked={checkStatus === "on"}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                headCell.sort
                  ? sorting.sortField === headCell.sort
                    ? asDirection(sorting.sort)
                    : asDirection(defaultSortOrder(headCell.sort))
                  : undefined
              }
              onClick={createSortHandler(headCell)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Visible?</TableCell>
        <TableCell>ID</TableCell>
        <TableCell>Logo</TableCell>
        {/* <TableCell>Invitation code</TableCell> */}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

const CompanyActionsCell: React.FC<{
  company: AllCompanies_allCompanies_companies;
}> = ({ company: { companyName, id: companyId, isVerified } }) => {
  const [assignMutation, { loading }] = useMutation<
    assignMeToCompany,
    assignMeToCompanyVariables
  >(ASSIGN_ME_TO_COMPANY);

  const [setVerifiedMutation] = useMutation<
    setCompanyIsVerified,
    setCompanyIsVerifiedVariables
  >(SET_COMPANY_IS_VERIFIED);

  // const [reindexMutation, { loading: reIndexMutation }] = useMutation<
  //   reIndexCompanyMerchants,
  //   reIndexCompanyMerchantsVariables
  // >(REINDEX_COMPANY_MERCHANTS);

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignMeToCompany: React.MouseEventHandler<
    HTMLLIElement
  > = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    handleClose();
    try {
      await assignMutation({ variables: { companyId } });
      setSnackbarOpen(true);
    } catch (error: any) {
      console.error(error);
      alert(error?.message);
    } finally {
      handleClose();
    }
  };

  const setVerified = async (newIsVerified: boolean) => {
    try {
      await setVerifiedMutation({
        variables: { companyId, isVerified: newIsVerified },
      });
    } catch (error: any) {
      console.error(error);
      alert(error?.message);
    } finally {
      handleClose();
    }
  };

  const handleSetVerified: React.MouseEventHandler<HTMLLIElement> = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setVerified(true);
  };

  const handleSetNotVerified: React.MouseEventHandler<HTMLLIElement> = (
    evt
  ) => {
    evt.preventDefault();
    evt.stopPropagation();
    setVerified(false);
  };

  // const handleAssignReIndexCompany: React.MouseEventHandler<
  //   HTMLLIElement
  // > = async (evt) => {
  //   // eslint-disable-next-line no-restricted-globals
  //   if (confirm(`Reindex all merchants for ${companyName}?`)) {
  //     evt.preventDefault();
  //     evt.stopPropagation();
  //     handleClose();
  //     try {
  //       await reindexMutation({ variables: { input: { companyId } } });
  //       setSnackbarOpen(true);
  //     } catch (error: any) {
  //       console.error(error);
  //       alert(error?.message);
  //     } finally {
  //       handleClose();
  //     }
  //   }
  // };

  const isDisabled = loading;

  return (
    <div>
      <IconButton
        aria-label="actions"
        aria-controls={`menu-${companyId}`}
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isDisabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`menu-${companyId}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleAssignMeToCompany} disabled={isDisabled}>
          Assign me to {companyName}
        </MenuItem>
        <MenuItem
          onClick={isVerified ? handleSetNotVerified : handleSetVerified}
          disabled={isDisabled}
        >
          {isVerified ? "Set not verified" : "Set verified"}
        </MenuItem>
        {/* <MenuItem onClick={handleAssignReIndexCompany} disabled={isDisabled}>
          Reindex {companyName}
        </MenuItem> */}
      </Menu>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={`You are now assigned to company "${companyName}"!`}
        autoHideDuration={4000}
        action={
          <Button
            color="secondary"
            size="small"
            onClick={() => setSnackbarOpen(false)}
          >
            Stäng
          </Button>
        }
      />
    </div>
  );
};

const AssignCompany = () => {
  const classes = useStyles();

  const [filter, setFilter] = React.useState<string>();
  const [debouncedFilter, setDebouncedFilter] = React.useState(filter);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(50);
  const [sorting, setSorting] = React.useState<InputListSort>({
    sortField: "transactionCount",
    sort: DBSortOrder.DESC,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [visibleFilter, setVisibleFilter] = React.useState<VisibleFilter>(
    VisibleFilter.onlyVisible
  );
  const [logoFilter, setLogoFilter] = React.useState<LogoFilter>(
    LogoFilter.all
  );
  const [verifiedFilter, setVerifiedFilter] = React.useState<VerifiedFilter>(
    VerifiedFilter.all
  );

  const { data: companiesData, refetch } = useQuery<
    AllCompanies,
    AllCompaniesVariables
  >(ALL_COMPANIES, {
    variables: {
      input: {
        companyName: debouncedFilter,
        isVisible:
          visibleFilter === VisibleFilter.onlyVisible
            ? true
            : visibleFilter === VisibleFilter.onlyInvisible
            ? false
            : undefined,
        hasLogo:
          logoFilter === LogoFilter.hasLogo
            ? true
            : logoFilter === LogoFilter.noLogo
            ? false
            : undefined,
        isVerified:
          verifiedFilter === VerifiedFilter.isVerified
            ? true
            : verifiedFilter === VerifiedFilter.notVerified
            ? false
            : undefined,
        paging: { page, size: pageSize },
        sort: sorting,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const companiesResult = companiesData?.allCompanies.companies || [];
  const pageInfo = companiesData?.allCompanies.pageInfo;
  const rows = companiesResult;
  const numSelected = selected.length;

  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
  });

  const [mergeDialogOpen, setMergeDialogOpen] = React.useState(false);

  const checkStatus = React.useMemo<CheckAllStatus>(() => {
    const selSet = new Set(selected);
    const checkedOnPage = rows.filter((row) => selSet.has(row.id));
    const fullSelect = pageInfo?.count ?? pageSize;
    if (checkedOnPage.length === fullSelect) {
      return "on";
    } else if (checkedOnPage.length > 0) {
      return "indeterminate";
    }
    return "off";
  }, [rows, selected, pageInfo, pageSize]);

  const [toggleVisibleDialogOpen, setSetVisibleDialogOpen] =
    React.useState(false);

  const [uploadLogoCompanyId, setUploadLogoCompanyId] = React.useState<
    string | null
  >(null);

  // const handleClickReindexDialogOpen = () => {};

  const handleClickToggleVisibleDialogOpen = () => {
    setSetVisibleDialogOpen(true);
  };

  const handleClickMergeCompaniesOpen = () => {
    setMergeDialogOpen(true);
  };

  const handleMergeCompaniesClose = () => {
    setMergeDialogOpen(false);
    setSelected([]);
    refetch();
  };

  const handleSetCompaniesVisibleClose = () => {
    setSetVisibleDialogOpen(false);
    setSelected([]);
    refetch();
  };

  const handleUploadCompanyLogoClose = () => {
    setUploadLogoCompanyId(null);
    refetch();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage + 1);
  };

  React.useEffect(() => {
    const handler = setTimeout(() => setDebouncedFilter(filter), 500);
    return () => clearTimeout(handler);
  }, [filter]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageIds = rows.map((n) => n.id);

    if (event.target.checked) {
      const newSet = new Set<string>([...pageIds, ...selected]);
      setSelected([...Array.from(newSet)]);
    } else {
      const newSet = new Set<string>(selected);
      pageIds.forEach((id) => newSet.delete(id));
      setSelected([...Array.from(newSet)]);
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ValidSortFields
  ) => {
    const isCurrent = sorting.sortField === property;

    setSorting({
      sort: isCurrent
        ? sorting.sort === DBSortOrder.ASC
          ? DBSortOrder.DESC
          : DBSortOrder.ASC
        : defaultSortOrder(property),
      sortField: property,
    });
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <>
      <Toolbar disableGutters>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search by name…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={filter}
            onChange={(evt) => setFilter(evt.target.value)}
          />
        </div>

        <FormControl
          variant="outlined"
          size="small"
          className={classes.select}
          margin="dense"
        >
          <InputLabel>Visibility filter</InputLabel>
          <Select
            value={visibleFilter}
            onChange={(evt) =>
              setVisibleFilter(evt.target.value as VisibleFilter)
            }
            label="Visibility filter"
          >
            <MenuItem value={VisibleFilter.all}>All</MenuItem>
            <MenuItem value={VisibleFilter.onlyVisible}>Only visible</MenuItem>
            <MenuItem value={VisibleFilter.onlyInvisible}>
              Only invisible
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          size="small"
          className={classes.select}
          margin="dense"
        >
          <InputLabel>Logo filter</InputLabel>
          <Select
            value={logoFilter}
            onChange={(evt) => setLogoFilter(evt.target.value as LogoFilter)}
            label="Logo filter"
          >
            <MenuItem value={LogoFilter.all}>All</MenuItem>
            <MenuItem value={LogoFilter.hasLogo}>Has logo</MenuItem>
            <MenuItem value={LogoFilter.noLogo}>No logo</MenuItem>
          </Select>
        </FormControl>

        <FormControl
          variant="outlined"
          size="small"
          className={classes.select}
          margin="dense"
        >
          <InputLabel>Verified filter</InputLabel>
          <Select
            value={verifiedFilter}
            onChange={(evt) =>
              setVerifiedFilter(evt.target.value as VerifiedFilter)
            }
            label="Verified filter"
          >
            <MenuItem value={VerifiedFilter.all}>All</MenuItem>
            <MenuItem value={VerifiedFilter.isVerified}>Is verified</MenuItem>
            <MenuItem value={VerifiedFilter.notVerified}>Not verified</MenuItem>
          </Select>
        </FormControl>

        <div className={classes.grow} />
        <div className={classes.buttons}>
          {numSelected > 0 ? (
            <>
              <Button
                variant="text"
                color="default"
                endIcon={<ClearIcon />}
                onClick={() => setSelected([])}
                title="Clear selection"
              >
                {numSelected} selected
              </Button>
              <Button
                onClick={handleClickMergeCompaniesOpen}
                disabled={numSelected === 0}
                variant="contained"
                color="primary"
                className={classes.marginLeft}
              >
                Merge companies
              </Button>
            </>
          ) : null}
          <Button
            onClick={handleClickToggleVisibleDialogOpen}
            disabled={numSelected === 0}
            variant="contained"
            color="primary"
            className={classes.marginLeft}
          >
            Set visible
          </Button>
        </div>
      </Toolbar>

      <TableContainer component={Paper}>
        <TableContainer>
          <Table size="small">
            <SortableTableHead
              classes={classes}
              checkStatus={checkStatus}
              sorting={sorting}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((r: AllCompanies_allCompanies_companies) => {
                const isItemSelected = selected.indexOf(r.id) > -1;

                return (
                  <TableRow
                    onClick={(ev) => handleClick(ev, r.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    key={r.id}
                    hover
                  >
                    <TableCell>
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell>
                      <Link to={`/admin/company/${r.id}`}>{r.companyName}</Link>
                      {r.isVerified ? (
                        <span className={classes.verified}>
                          <CheckIcon className={classes.verifiedIcon} />{" "}
                          verifierad
                        </span>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      {r.statisticsConnection.transactionCount}
                    </TableCell>
                    <TableCell align="right">
                      {r.statisticsConnection.consumerCount}
                    </TableCell>
                    <TableCell>{r.isVisible ? "Yes" : "No"}</TableCell>
                    <TableCell>{r.id}</TableCell>
                    <TableCell>
                      <CompanyThumbnail
                        logoUrl={r.logoThumbnailUrl}
                        onClick={() => setUploadLogoCompanyId(r.id)}
                        size={40}
                      />
                    </TableCell>
                    <TableCell>
                      <CompanyActionsCell company={r} />
                      {/* <InvitationCodeCell
                        code={
                          r.companyInvitationConnection?.companyInvitationId
                        }
                        companyId={r.id}
                        companyName={r.companyName}
                      /> */}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10, 25, 50, 100, 250, 500, 1000]}
          component="div"
          count={pageInfo?.totalCount ?? 0}
          rowsPerPage={pageInfo?.size ?? pageSize}
          page={(pageInfo?.page ?? page) - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <MergeCompaniesDialog
        handleClose={handleMergeCompaniesClose}
        open={mergeDialogOpen}
        sourceCompanies={selected}
        allCompanies={rows}
      />
      <SetVisibleDialog
        handleClose={handleSetCompaniesVisibleClose}
        open={toggleVisibleDialogOpen}
        sourceCompanies={selected}
      />
      <UploadCompanyLogoDialog
        handleClose={handleUploadCompanyLogoClose}
        open={uploadLogoCompanyId != null}
        companyId={uploadLogoCompanyId}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={snackbarState.message}
        autoHideDuration={4000}
      />
    </>
  );
};

export default AssignCompany;
