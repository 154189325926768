import React, { useEffect, useRef } from "react";
import { Layout, useStyles } from "../StartPage/Layout";
import { CircularProgress } from "@material-ui/core";

export const LoggedOut = () => {
  const didRedirect = useRef(false);
  const classes = useStyles();

  useEffect(() => {
    if (didRedirect.current) return;
    didRedirect.current = true;

    localStorage.clear();
    sessionStorage.clear();
    window.location.assign("/");
  }, []);

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.form}>
          <h2>Signing out...</h2>
          <CircularProgress />
        </div>
      </div>
    </Layout>
  );
};
