import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { DBSortOrder, InputListSort } from "../../../graphql/types/global";
import { asDirection } from "../../../utils/tableUtils";
import { MappedDemoUser } from "../../../graphql/helperTypes";
import { OmitType } from "../../../utils/omitType";
import { IconButton, Paper } from "@material-ui/core";
import { companyDemoDetails_companyDemo_mappedUsersConnection } from "../../../graphql/types/companyDemoDetails";
import { useMutation } from "@apollo/client";
import {
  unmapCompanyDemoUser,
  unmapCompanyDemoUserVariables,
} from "../../../graphql/types/unmapCompanyDemoUser";
import { UNMAP_COMPANY_DEMO_USER } from "../../../graphql/queries.gql";
import { TimestampDisplay } from "../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof MappedDemoUser;
  label: string;
  type: "string" | "date" | "userConnection";
}

const headCells: HeadCell[] = [
  { id: "displayName", type: "string", label: "Name" },
  { id: "email", type: "string", label: "Email" },
  { id: "mobilePhoneNumber", type: "string", label: "Phone" },
  { id: "createdAt", type: "date", label: "Added to demo" },
];

export type OnRequestSort = (property: keyof MappedDemoUser) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps
  extends OmitType<companyDemoDetails_companyDemo_mappedUsersConnection> {
  companyDemoId: string;
  onRequestRefetch: () => any;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof MappedDemoUser) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={cellAlign(headCell)}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) => "left" as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    paper: {
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
      width: "100%",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    companyRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: `${theme.spacing(2)}px`,
    },
  })
);

const DemoMappedUsersTable = memo<TableProps>(
  ({
    onRequestSort,
    sorting,
    pageInfo,
    users,
    companyDemoId,
    onRequestRefetch,
  }) => {
    const classes = useStyles();

    const [unmapMutation, { loading }] = useMutation<
      unmapCompanyDemoUser,
      unmapCompanyDemoUserVariables
    >(UNMAP_COMPANY_DEMO_USER);

    const onUnmap = async (userId: string) => {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm("Remove this user from this demo?")) {
        return;
      }
      try {
        await unmapMutation({ variables: { companyDemoId, userId } });
      } finally {
        onRequestRefetch();
      }
    };

    const renderCell = (cell: HeadCell, row: MappedDemoUser) => {
      switch (cell.type) {
        case "date":
          return <TimestampDisplay timestamp={row[cell.id] as string} />;
        default:
          return row[cell.id];
      }
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sorting={sorting}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {users.map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.userConnection.id}>
                      {headCells.map((cell) => (
                        <TableCell
                          align={cellAlign(cell)}
                          key={`${cell.id}-${row.userConnection.id}`}
                        >
                          {renderCell(cell, row.userConnection)}
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton
                          onClick={() => onUnmap(row.userConnection.id)}
                          disabled={loading}
                        >
                          <BackspaceIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
);

export default DemoMappedUsersTable;
