import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import httpLink from "./httpLink";
import apiClientMiddleware from "./apiClientMiddleware";
import errorLink from "./errorLink";
import authLink from "./authLink";

export default new ApolloClient({
  link: from([apiClientMiddleware, authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
