import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  TableSortLabel,
  TableContainer,
  TablePagination,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { dateAndTime } from "../../../utils/formatDate";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import { asDirection } from "../../../utils/tableUtils";
import { TINK_CREDENTIAL_LOG_OPERATIONS } from "../../../graphql/queries.gql";
import { TinkCredentialOperationLog } from "../../../graphql/helperTypes";
import {
  DBSortOrder,
  InputListSort,
  TinkCredentialOperationCategory,
} from "../../../graphql/types/global";
import {
  TinkCredentialLogOperations,
  TinkCredentialLogOperationsVariables,
} from "../../../graphql/types/TinkCredentialLogOperations";
import { copyToClipboard } from "../../../utils/clipboard";

const supportedSortFields: (keyof TinkCredentialOperationLog)[] = [
  "createdAt",
  "category",
  "credentialId",
  "message",
  "operationId",
  "sessionId",
  "userId",
];

const allCategories = Object.values(TinkCredentialOperationCategory).sort();

interface HeadCell {
  id: keyof TinkCredentialOperationLog;
  label: string;
  type: "string" | "date" | "uuid";
}

const headCells: HeadCell[] = [
  { id: "createdAt", type: "date", label: "Created" },
  { id: "category", type: "string", label: "Category" },
  { id: "message", type: "string", label: "Message" },
  { id: "sessionId", type: "uuid", label: "Session" },
];

const useRowStyles = makeStyles((theme) => ({
  copyableCell: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
  noWrapCell: {
    whiteSpace: "nowrap",
  },
}));

const useStyles = makeStyles((theme) => ({
  copyableCell: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
  noWrapCell: {
    whiteSpace: "nowrap",
  },
  createdAtSortLabel: {
    cursor: "text",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  categories: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  categoriesFormGroup: {
    flexDirection: "row",
  },
}));

const Row: React.FC<{ log: TinkCredentialOperationLog }> = ({ log }) => {
  const classes = useRowStyles();
  return (
    <TableRow hover tabIndex={-1}>
      {headCells.map((cell, index) => (
        <TableCell
          key={cell.id}
          className={clsx(classes.copyableCell, {
            [classes.noWrapCell]: cell.type === "date",
          })}
          onClick={() => copyToClipboard(cellCopyValue(cell, log))}
          title={`Kopiera ${cellCopyValue(cell, log)}`}
        >
          {cell.type === "date"
            ? dateAndTime(log[cell.id] as any)
            : log[cell.id]}
        </TableCell>
      ))}
    </TableRow>
  );
};

const cellCopyValue = (cell: HeadCell, log: TinkCredentialOperationLog) =>
  String(log[cell.id]);

const CredentialOperationLogTable: React.FC<{ credentialId: string }> = ({
  credentialId,
}) => {
  const [categories, setCategories] = useState<
    TinkCredentialOperationCategory[]
  >([]);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data } = useQuery<
    TinkCredentialLogOperations,
    TinkCredentialLogOperationsVariables
  >(TINK_CREDENTIAL_LOG_OPERATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        sort: sorting,
        filter: { credentialId, categories },
        paging: { page, size: pageSize },
      },
    },
  });

  const classes = useStyles();
  const logs = data?.allTinkCredentialOperationLogs.logs;
  const pageInfo = data?.allTinkCredentialOperationLogs.pageInfo;

  const handleRequestSort = (property: keyof TinkCredentialOperationLog) => {
    if (!supportedSortFields.includes(property)) {
      return;
    }
    const isAsc =
      sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
    setSorting({
      sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
      sortField: property,
    });
  };

  const createSortHandler =
    (property: keyof TinkCredentialOperationLog) =>
    (_: React.MouseEvent<unknown>) => {
      handleRequestSort(property);
    };

  const handleRequestPageSize = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(1);
  };

  const handleChangePage = (_: any, page: number) => setPage(page + 1);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleRequestPageSize(parseInt(event.target.value, 10));
  };

  const isChecked = (category: TinkCredentialOperationCategory) =>
    categories.length === 0 || categories.includes(category);

  const handleCategoriesChange =
    (category: TinkCredentialOperationCategory) => (_: any) => {
      const currentCategories =
        categories.length === 0 ? allCategories : categories;
      const newCategories = isChecked(category)
        ? currentCategories.filter((c) => c !== category)
        : currentCategories.concat([category]);
      setCategories(newCategories);
    };

  return (
    <>
      <div className={classes.categories}>
        <FormControl component="fieldset">
          <FormGroup className={classes.categoriesFormGroup}>
            {allCategories.map((category) => (
              <FormControlLabel
                key={category}
                control={
                  <Checkbox
                    checked={isChecked(category)}
                    onChange={handleCategoriesChange(category)}
                    name={category}
                  />
                }
                label={category}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
      {logs && pageInfo && (
        <>
          <TableContainer>
            <Table size="small" aria-label="credential operations log">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="left"
                      sortDirection={
                        sorting.sortField === headCell.id
                          ? asDirection(sorting.sort)
                          : false
                      }
                    >
                      {supportedSortFields.includes(headCell.id) ? (
                        <TableSortLabel
                          active={sorting.sortField === headCell.id}
                          direction={
                            sorting.sortField === headCell.id
                              ? asDirection(sorting.sort)
                              : "asc"
                          }
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {sorting.sortField === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {sorting.sort === DBSortOrder.DESC
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log) => (
                  <Row log={log} key={log.operationId} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default CredentialOperationLogTable;
