import { TokenStorage } from "../../graphql/client/tokenStorage";
import { TokenResponse } from "./types";

export const toTokenStorage = (input: TokenResponse): TokenStorage => {
  return {
    accessToken: input.access_token,
    refreshToken: input.refresh_token ?? "",
    expiresAt: new Date(
      Date.now() + (input.expires_in ?? 100) * 1000
    ).toISOString(),
  };
};
