import React from "react";
import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { Disbursement, ListDisbursement } from "../../../graphql/helperTypes";
import { DisbursementStatus } from "../../../graphql/types/global";
import DisbursementButton from "./DisbursementButton";

const useStatusStyles = makeStyles((theme) =>
  createStyles({
    textPaid: {
      color: theme.palette.success.main,
    },
    textCreated: {
      color: theme.palette.success.light,
    },
    textError: {
      color: theme.palette.error.main,
    },
    textDefault: {
      color: theme.palette.text.secondary,
    },
  })
);

export const DisbursementStatusText = (props: {
  status: DisbursementStatus | null | undefined;
}) => {
  const classes = useStatusStyles();
  const textStyle = ((): keyof typeof classes => {
    switch (props.status) {
      case DisbursementStatus.paid:
        return "textPaid";
      case DisbursementStatus.created:
        return "textCreated";
      case DisbursementStatus.error:
        return "textError";
      default:
        return "textDefault";
    }
  })();

  return (
    <Typography variant="subtitle1" className={classes[textStyle]}>
      {props.status || "waiting"}
    </Typography>
  );
};

const useStatusCellStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end",
    },
  })
);

export function DisbursementStatusAddCell({
  disbursement,
  onRequestDisbursementStatusDialog,
  onRequestAddDisbursementDialog,
}: {
  disbursement: Disbursement | ListDisbursement | null;
  onRequestDisbursementStatusDialog: () => void;
  onRequestAddDisbursementDialog: () => void;
}) {
  const classes = useStatusCellStyles();
  return (
    <div className={classes.root}>
      <DisbursementStatusText status={disbursement?.status} />
      <DisbursementButton
        showAddButton={disbursement == null}
        onClick={
          disbursement == null
            ? onRequestAddDisbursementDialog
            : onRequestDisbursementStatusDialog
        }
      />
    </div>
  );
}

export function DisbursementStatusCell({
  disbursement,
  onRequestDisbursementStatusDialog,
}: {
  disbursement: Disbursement | ListDisbursement;
  onRequestDisbursementStatusDialog: () => void;
}) {
  const classes = useStatusCellStyles();
  return (
    <div className={classes.root}>
      <DisbursementStatusText status={disbursement?.status} />
      <DisbursementButton
        showAddButton={false}
        onClick={onRequestDisbursementStatusDialog}
      />
    </div>
  );
}
