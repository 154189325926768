import React, { memo, useState } from "react";
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FilterList, Refresh } from "@material-ui/icons";
import { SystemActivityLogCategory } from "../../../graphql/types/global";

const excludedCategories = new Set<SystemActivityLogCategory>([
  SystemActivityLogCategory.company_apply_for_premium,
  SystemActivityLogCategory.company_improvement_added,
  SystemActivityLogCategory.company_improvement_removed,
  SystemActivityLogCategory.company_improvement_updated,
  SystemActivityLogCategory.company_invitation_added,
  SystemActivityLogCategory.company_location_added,
  SystemActivityLogCategory.company_location_removed,
  SystemActivityLogCategory.company_replied_on_idea_for_change,
  SystemActivityLogCategory.company_replied_on_review,
  SystemActivityLogCategory.company_supporter_added,
  SystemActivityLogCategory.company_supporter_removed,
  SystemActivityLogCategory.idea_for_change_comment_added,
  SystemActivityLogCategory.idea_for_change_created,
  SystemActivityLogCategory.idea_for_change_endorsement_added,
  SystemActivityLogCategory.review_comment_added,
  SystemActivityLogCategory.review_endorsement_added,
  SystemActivityLogCategory.user_invitation_added,
]);

const allCategories = Object.values(SystemActivityLogCategory)
  .filter((category) => !excludedCategories.has(category))
  .sort();

export const defaultCategories: SystemActivityLogCategory[] = [
  SystemActivityLogCategory.company_user_signed_up,
  SystemActivityLogCategory.user_account_delete_confirmed,
  SystemActivityLogCategory.user_account_delete_requested,
  SystemActivityLogCategory.user_account_undeleted,
  SystemActivityLogCategory.user_invitation_used,
  SystemActivityLogCategory.user_signed_up,
  SystemActivityLogCategory.user_synced_first_transaction,
];

interface FilterProps {
  body: string;
  categories: SystemActivityLogCategory[];
  loading: boolean;
  onRequestRefetch: () => any;
  onRequestSetBody: (body: string) => any;
  onRequestSetCategories: (categories: SystemActivityLogCategory[]) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    categories: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  })
);

const SystemActivityLogFilter = memo<FilterProps>(
  ({
    body,
    categories,
    loading,
    onRequestRefetch,
    onRequestSetBody,
    onRequestSetCategories,
  }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const isChecked = (category: SystemActivityLogCategory) =>
      categories.includes(category);

    const handleChange = (category: SystemActivityLogCategory) => (_: any) => {
      const currentCategories = categories;
      const newCategories = isChecked(category)
        ? currentCategories.filter((c) => c !== category)
        : currentCategories.concat([category]);
      onRequestSetCategories(newCategories);
    };

    const handleSet = (categories: SystemActivityLogCategory[]) => (_: any) => {
      onRequestSetCategories(categories);
    };

    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>

          <IconButton
            aria-label="filter"
            onClick={() => setOpen(!open)}
            className={classes.icon}
          >
            <Badge
              color="primary"
              invisible={categories.length === allCategories.length}
              badgeContent={categories.length}
            >
              <FilterList />
            </Badge>
          </IconButton>

          <FormControl component="fieldset" fullWidth>
            <TextField
              label="Filter on message text"
              value={body}
              onChange={(evt) => onRequestSetBody(evt.target.value)}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </div>
        <Collapse in={open}>
          <div className={classes.categories}>
            <Button onClick={handleSet(allCategories)}>Select all</Button>
            <Button onClick={handleSet(defaultCategories)}>
              Select defaults
            </Button>
            <Button onClick={handleSet([])}>Select none</Button>
            <FormControl component="fieldset">
              <FormGroup>
                <Grid container spacing={1}>
                  {allCategories.map((category) => (
                    <Grid item xs={6} md={4} lg={3} xl={2} key={category}>
                      <FormControlLabel
                        className={classes.label}
                        control={
                          <Checkbox
                            checked={isChecked(category)}
                            onChange={handleChange(category)}
                            name={category}
                          />
                        }
                        label={category}
                      />
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
            </FormControl>
          </div>
        </Collapse>
      </div>
    );
  }
);

export default SystemActivityLogFilter;
