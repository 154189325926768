import { format, formatDuration } from "date-fns";
import { DbIntervalDateHoursMinutes } from "../graphql/types/DbIntervalDateHoursMinutes";

export const dateAndTime = (date: string | undefined | null, fallback = "") =>
  date ? format(new Date(date), "yyyy-MM-dd HH:mm") : fallback;

export const dateOnly = (date: string | undefined | null, fallback = "") =>
  date ? format(new Date(date), "yyyy-MM-dd") : fallback;

type NonNullDbInterval = {
  [P in keyof Omit<DbIntervalDateHoursMinutes, "__typename">]: NonNullable<
    DbIntervalDateHoursMinutes[P]
  >;
};

export const intervalAsDuration = (dbInterval: DbIntervalDateHoursMinutes) => {
  const nonNullInterval = Object.entries(dbInterval).reduce(
    (acc, [key, val]) => ({ ...acc, [key]: val || undefined }),
    {} as NonNullDbInterval
  );

  return formatDuration(nonNullInterval);
};
