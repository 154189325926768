import React, { memo } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";

interface FilterProps {
  loading: boolean;
  onRequestRefetch: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    textField: {
      margin: 0,
    },
    select: {
      minWidth: 140,
      marginRight: theme.spacing(1),
    },
    title: {
      margin: 0,
      padding: 0,
      lineHeight: 1,
    },
  })
);

const DemosFilter = memo<FilterProps>(({ loading, onRequestRefetch }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.firstRow}>
        <IconButton
          aria-label="refresh"
          onClick={onRequestRefetch}
          className={classes.icon}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="24px" />
          ) : (
            <Refresh fontSize="default" />
          )}
        </IconButton>
        <h3 className={classes.title}>Company demos</h3>
      </div>
    </div>
  );
});

export default DemosFilter;
