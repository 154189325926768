import { setStorage } from "../../graphql/client/tokenStorage";

export const migrateROCPTokens = () => {
  const storedToken = localStorage.getItem("ROCP_token");
  const storedRefreshToken = localStorage.getItem("ROCP_refreshToken");
  const storedTokenExpiresAtEpochSeconds =
    localStorage.getItem("ROCP_tokenExpire");

  if (storedToken && storedRefreshToken && storedTokenExpiresAtEpochSeconds) {
    console.info("migrating stored ROCP tokens");
    localStorage.clear();
    setStorage({
      accessToken: JSON.parse(storedToken),
      refreshToken: JSON.parse(storedRefreshToken),
      expiresAt: new Date(
        parseInt(storedTokenExpiresAtEpochSeconds, 10) * 1000
      ).toISOString(),
    });
  }
};
