import React from "react";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    disbursementCellRoot: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    swishIcon: {
      width: "0.75em",
      height: "0.75em",
    },
    notificationIcon: {
      width: "0.75em",
      height: "0.75em",
    },
    swishStatus: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    textPaid: {
      color: theme.palette.success.main,
    },
    textCreated: {
      color: theme.palette.success.light,
    },
    textDebited: {
      color: theme.palette.warning.light,
    },
    textError: {
      color: theme.palette.error.main,
    },
    textDefault: {
      color: theme.palette.text.secondary,
    },
    controlLabel: {
      marginRight: 0,
    },
  })
);

export const SwishStatusText = (props: {
  payoutStatus: string | null | undefined;
}) => {
  const styles = useStyles();
  const textStyle = ((status: string | null): keyof typeof styles => {
    switch (status) {
      case "PAID":
        return "textPaid";
      case "CREATED":
        return "textCreated";
      case "DEBITED":
        return "textDebited";
      case "ERROR":
        return "textError";
      default:
        return "textDefault";
    }
  })(props.payoutStatus ?? null);

  return (
    <Typography variant="subtitle1" className={styles[textStyle]}>
      {props.payoutStatus || "waiting"}
    </Typography>
  );
};
