import { onError } from "@apollo/client/link/error";
import { clearLocalStorage } from "./tokenStorage";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.log("[GraphQL]: Network error occurred", networkError);
  }
  if (graphQLErrors) {
    if (graphQLErrors[0].message.indexOf("User not Authenticated") > -1) {
      clearLocalStorage();
      window.location.reload();
    }
  }
});

export default errorLink;
