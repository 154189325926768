import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_COMPANY_LOCATION,
  ALL_COMPANY_LOCATIONS,
  ME,
  REMOVE_COMPANY_LOCATION,
} from "../../graphql/queries.gql";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {
  AllCompanyLocations,
  AllCompanyLocationsVariables,
} from "../../graphql/types/AllCompanyLocations";
import {
  AddCompanyLocation,
  AddCompanyLocationVariables,
} from "../../graphql/types/AddCompanyLocation";
import {
  RemoveCompanyLocation,
  RemoveCompanyLocationVariables,
} from "../../graphql/types/RemoveCompanyLocation";
import { Me } from "../../graphql/types/Me";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  list: {
    marginBottom: theme.spacing(2),
    maxWidth: 400,
  },
}));

const Locations: React.FC = () => {
  const { data: companyData, loading } = useQuery<Me>(ME);

  if (loading || !companyData?.me.companyId) {
    return <Loading />;
  }

  return <WithCompany companyId={companyData?.me.companyId} />;
};

const WithCompany: React.FC<{ companyId: string }> = ({ companyId }) => {
  const classes = useStyles();
  const { data, loading, refetch } = useQuery<
    AllCompanyLocations,
    AllCompanyLocationsVariables
  >(ALL_COMPANY_LOCATIONS, {
    variables: { companyId },
  });
  const [addCompanyLocation, { loading: addLoading }] = useMutation<
    AddCompanyLocation,
    AddCompanyLocationVariables
  >(ADD_COMPANY_LOCATION);
  const [removeCompanyLocation, { loading: removeLoading }] = useMutation<
    RemoveCompanyLocation,
    RemoveCompanyLocationVariables
  >(REMOVE_COMPANY_LOCATION);

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (input: any) => {
    return addCompanyLocation({
      variables: {
        input,
      },
    }).then(() => {
      refetch();
    });
  };

  const onRemove = (locationId: string) => {
    return removeCompanyLocation({
      variables: {
        id: locationId,
      },
    }).then(() => {
      refetch();
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      <Typography variant="h4">Locations</Typography>
      <Divider />
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.marginBottom}>
          <Grid item xs={12}>
            <Typography variant="h6">Lägg till plats</Typography>
            <p>
              Fyll i formuläret nedan och klicka på "Lägg till" för att lägga
              till en plats.
            </p>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                size="small"
                className={classes.textField}
                label="Adress"
                variant="outlined"
                id="companyAddress"
                name="companyAddress"
                autoFocus
                error={errors.companyAddress !== undefined}
                helperText={
                  errors.companyAddress ? "Du måste ange en adress" : undefined
                }
                inputRef={register({
                  required: true,
                })}
              />
              <TextField
                size="small"
                className={classes.textField}
                label="Telefonnummer"
                variant="outlined"
                id="companyPhoneNumber"
                name="companyPhoneNumber"
                error={errors.companyPhoneNumber !== undefined}
                helperText={
                  errors.companyPhoneNumber
                    ? "Du måste ange ett telefonnummer"
                    : undefined
                }
                inputRef={register({
                  required: true,
                })}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addLoading}
              >
                Lägg till plats
              </Button>
            </form>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {data?.allCompanyLocations.locations.length === 0 ? (
              <p>Inga platser tillagda. Lägg till en plats ovanför.</p>
            ) : (
              <>
                <p>
                  Nedan ser du ditt företags platser. De syns på
                  företagsprofilen i appen.
                </p>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableCell />
                      <TableCell>Address</TableCell>
                      <TableCell>Telefonnummer</TableCell>
                      <TableCell>Ta bort</TableCell>
                    </TableHead>
                    <TableBody>
                      {data?.allCompanyLocations.locations.map((location) => {
                        return (
                          <TableRow key={location.companyLocationId} hover>
                            <TableCell>
                              <HomeIcon />
                            </TableCell>
                            <TableCell>{location.companyAddress}</TableCell>
                            <TableCell>{location.companyPhoneNumber}</TableCell>
                            <TableCell>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                disabled={removeLoading}
                                onClick={() =>
                                  onRemove(location.companyLocationId)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Locations;
