import React from "react";
import { surveyMetricsFragment } from "../../../../graphql/types/surveyMetricsFragment";
import { OmitType } from "../../../../utils/omitType";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { formatPercentage } from "../../../../utils/formatDecimal";

type Props = {
  surveyMetrics: OmitType<surveyMetricsFragment>[];
};

export const SurveyMetrics = (props: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Batch nr</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Assigned</TableCell>
            <TableCell>Started</TableCell>
            <TableCell>Completed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.surveyMetrics.map((survey) => (
            <TableRow key={survey.id}>
              <TableCell>{survey.id}</TableCell>
              <TableCell>{survey.surveyBatchConnection.title}</TableCell>
              <TableCell>{survey.mappedUsersCount}</TableCell>
              <TableCell>
                {formatPercentage(survey.startedCount, survey.mappedUsersCount)}
              </TableCell>
              <TableCell>
                {formatPercentage(
                  survey.completedCount,
                  survey.mappedUsersCount
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
