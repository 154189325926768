import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppTextDocumentKind } from "../../../graphql/types/global";

const allKinds = Object.values(AppTextDocumentKind).sort();

interface CategoryFilterProps {
  kind: AppTextDocumentKind | null;
  onRequestSetKind: (kind: AppTextDocumentKind | null) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  })
);

const SystemActivityCategoryFilter: React.FC<CategoryFilterProps> = ({
  kind,
  onRequestSetKind,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl style={{ minWidth: 250 }}>
        <InputLabel id="document-kind-label">Document kind</InputLabel>
        <Select
          labelId="document-kind-label"
          id="document-kind"
          value={kind == null ? "" : kind}
          label="Document kind"
          onChange={(evt) => {
            const value = evt.target.value;
            onRequestSetKind(value ? (value as AppTextDocumentKind) : null);
          }}
        >
          <MenuItem value={""}>All kinds</MenuItem>
          {allKinds.map((kind) => (
            <MenuItem key={kind} value={kind}>
              {kind}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        component={RouterLink}
        to="/admin/documents/add"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
      >
        New
      </Button>
    </div>
  );
};

export default SystemActivityCategoryFilter;
