import { zitadelConfig } from "./zitadelConfig";
import { TokenResponse } from "./types";

export const refreshTokens = async ({
  refreshToken,
}: {
  refreshToken: string;
}) => {
  const response = await fetch(zitadelConfig.tokenEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: zitadelConfig.clientId,
      redirect_uri: zitadelConfig.redirectUri,
    }).toString(),
  });

  if (response.status !== 200) {
    throw Error(
      `Failed to refresh tokens. Status: ${response.status} ${response.statusText}`
    );
  } else {
    return (await response.json()) as TokenResponse;
  }
};
