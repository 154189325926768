import { useEffect, useState } from "react";
import { toggleInSet } from "./setUtils";

const setStorage = (key: string, set: Set<number>) => {
  try {
    localStorage.setItem(key, JSON.stringify(Array.from(set)));
  } catch (e: any) {
    console.error(e);
  }
};

const readStorage = (key: string) => {
  try {
    const json = localStorage.getItem(key);
    return new Set<number>(JSON.parse(json!));
  } catch (e) {
    return new Set<number>();
  }
};

export const useHiddenColIndex = (batchNumber: number) => {
  const storageKey = `hiddenColIndex_${batchNumber}`;

  const [hiddenColIndex, setHiddenColIndex] = useState<Set<number>>(() =>
    readStorage(storageKey)
  );

  const toggleHiddenColIndex = (index: number) => {
    setHiddenColIndex(new Set(toggleInSet(hiddenColIndex, index)));
  };

  useEffect(() => {
    setStorage(storageKey, hiddenColIndex);
  }, [hiddenColIndex, storageKey]);

  return [hiddenColIndex, toggleHiddenColIndex] as const;
};
