import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { TableFooter, TableHead, TableSortLabel } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { dateAndTime, intervalAsDuration } from "../../../utils/formatDate";
import { useQuery } from "@apollo/client";
import { USER_TRANSACTION_SYNC_SUMMARY } from "../../../graphql/queries.gql";
import { asDirection } from "../../../utils/tableUtils";
import withFilledPeriodGaps, { RowData } from "./withFilledPeriodGaps";
import { DbIntervalDateHoursMinutes } from "../../../graphql/types/DbIntervalDateHoursMinutes";
import {
  DBSortOrder,
  InputListSort,
  UserTransactionSyncSummaryOptions,
} from "../../../graphql/types/global";
import {
  UserTransactionSyncSummary,
  UserTransactionSyncSummaryVariables,
} from "../../../graphql/types/UserTransactionSyncSummary";

interface HeadCell {
  id: keyof RowData;
  label: string;
  type: "string" | "number" | "date" | "interval";
}

interface FooterCell {
  id: HeadCell["id"];
  type: "sum";
}

const headCells: HeadCell[] = [
  { id: "periodLabel", type: "string", label: "Period" },
  { id: "earliestTransaction", type: "date", label: "Earliest trans." },
  { id: "latestTransaction", type: "date", label: "Latest trans." },
  { id: "longestGap", type: "interval", label: "Longest gap" },
  { id: "averageGap", type: "interval", label: "Average gap" },
  { id: "transactionCount", type: "string", label: "Transactions" },
];

const footerCells: FooterCell[] = [{ id: "transactionCount", type: "sum" }];

const useStyles = makeStyles((theme) => ({
  noWrapCell: {
    whiteSpace: "nowrap",
  },
  sortLabel: {
    cursor: "text",
  },
  numberCell: {
    textAlign: "right",
  },
}));

const useRowStyles = makeStyles((theme) => ({
  noWrapCell: {
    whiteSpace: "nowrap",
  },
  numberCell: {
    textAlign: "right",
  },
}));

const Row: React.FC<{ row: RowData }> = ({ row }) => {
  const classes = useRowStyles();
  return (
    <TableRow hover tabIndex={-1}>
      {headCells.map((cell) => (
        <TableCell
          key={cell.id}
          className={clsx({
            [classes.noWrapCell]: cell.type === "date",
            [classes.numberCell]: cell.type === "number",
          })}
        >
          {cell.type === "date"
            ? dateAndTime(row[cell.id] as any)
            : cell.type === "interval"
            ? intervalAsDuration(row[cell.id] as DbIntervalDateHoursMinutes)
            : row[cell.id]}
        </TableCell>
      ))}
    </TableRow>
  );
};

const TransactionSyncSummaryTableContent: React.FC<{
  rows: RowData[];
}> = ({ rows }) => {
  const classes = useStyles();
  const sorting: InputListSort = {
    sort: DBSortOrder.ASC,
    sortField: "periodLabel",
  };
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {headCells.map((cell) => (
            <TableCell
              key={cell.id}
              className={clsx(classes.noWrapCell, {
                [classes.numberCell]: cell.type === "number",
              })}
              sortDirection={
                cell.id === sorting.sortField
                  ? asDirection(sorting.sort)
                  : undefined
              }
            >
              {cell.id === sorting.sortField ? (
                <TableSortLabel
                  key={cell.id}
                  active
                  direction={asDirection(sorting.sort)}
                  className={classes.sortLabel}
                >
                  {cell.label}
                </TableSortLabel>
              ) : (
                cell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <Row key={row.period} row={row} />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          {headCells.map((headCell) => {
            const footerCell = footerCells.find(({ id }) => id === headCell.id);
            if (!footerCell) {
              return <TableCell key={headCell.id} />;
            }
            const sum = rows.reduce(
              (acc, row) => acc + (row[footerCell.id] as number),
              0
            );
            return <TableCell key={headCell.id}>{sum} total</TableCell>;
          })}
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const TransactionSyncSummaryTable: React.FC<{
  options: UserTransactionSyncSummaryOptions;
}> = ({ options }) => {
  const { data } = useQuery<
    UserTransactionSyncSummary,
    UserTransactionSyncSummaryVariables
  >(USER_TRANSACTION_SYNC_SUMMARY, {
    fetchPolicy: "cache-and-network",
    variables: { input: options },
  });

  const values = data?.userTransactionSyncSummary.values;
  const rows = useMemo(() => withFilledPeriodGaps(values), [values]);

  return <TransactionSyncSummaryTableContent rows={rows} />;
};

export default TransactionSyncSummaryTable;
