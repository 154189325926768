import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import MenuBar from "../components/MenuBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core";
import { DrawerContextProvider } from "../contexts/DrawerContext";
import { CompanyMetadataContextProvider } from "../contexts/CompanyMetadataContext";
import { useAuthContext } from "../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}));

interface AuthRouteProps {
  container?: boolean;
}

const AuthenticatedRoute: React.FC<RouteProps & AuthRouteProps> = ({
  children,
  container = true,
  ...rest
}) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuthContext();

  if (!children) return null;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          <CompanyMetadataContextProvider>
            <DrawerContextProvider>
              <CssBaseline />
              <MenuBar />
              {container ? (
                <Container className={classes.container}>{children}</Container>
              ) : (
                children
              )}
            </DrawerContextProvider>
          </CompanyMetadataContextProvider>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        );
      }}
    ></Route>
  );
};

export const AuthenticatedStandaloneRoute: React.FC<
  RouteProps & AuthRouteProps
> = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuthContext();
  if (!children) return null;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          <CompanyMetadataContextProvider>
            <CssBaseline />
            {children}
          </CompanyMetadataContextProvider>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        );
      }}
    />
  );
};

export default AuthenticatedRoute;
