import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { SystemActivityLog } from "../../../graphql/helperTypes";
import { ALL_SYSTEM_ACTIVITY_LOGS } from "../../../graphql/queries.gql";
import {
  AllSystemActivityLogs,
  AllSystemActivityLogsVariables,
} from "../../../graphql/types/AllSystemActivityLogs";
import {
  SystemActivityLogCategory,
  InputListSort,
  DBSortOrder,
} from "../../../graphql/types/global";
import {
  OnRequestCloseAddDisbursementDialog,
  AddDisbursementDialogData,
  AddDisbursementDialogState,
} from "../Shared/AddDisbursementDialog/types";
import AddDisbursementDialog from "../Shared/AddDisbursementDialog";
import SystemActivityLogFilter, {
  defaultCategories,
} from "./SystemActivityLogFilter";
import SystemActivityLogTable from "./SystemActivityLogTable";
import {
  DisbursementStatusDialogState,
  DisbursementStatusDialogData,
} from "../Shared/DisbursementStatusDialog/types";
import {
  PushUserNotificationDialogState,
  PushUserNotificationDialogData,
} from "../Shared/PushUserNotificationDialog/types";
import DisbursementStatusDialog from "../Shared/DisbursementStatusDialog";
import PushUserNotificationDialog from "../Shared/PushUserNotificationDialog";
import { useParams } from "react-router-dom";

const SystemActitvityLogPage = () => {
  const params = useParams<{ marketId: string }>();
  const marketId = params.marketId ? parseInt(params.marketId) : null;

  const [disbursementStatusModalState, setDisbursementStatusModalState] =
    useState<DisbursementStatusDialogState>({ isOpen: false });
  const [pushUserNotificationModalState, setPushUserNotificationModalState] =
    useState<PushUserNotificationDialogState>({ isOpen: false });
  const [addDisbursementModalState, setAddDisbursementModalState] =
    useState<AddDisbursementDialogState>({ isOpen: false });
  const [body, setBody] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [categories, setCategories] =
    useState<SystemActivityLogCategory[]>(defaultCategories);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });
  const [debouncedBody, setDebouncedBody] = useState(body);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedBody(body), 500);
    return () => clearTimeout(handler);
  }, [body]);

  const { data, refetch, loading } = useQuery<
    AllSystemActivityLogs,
    AllSystemActivityLogsVariables
  >(ALL_SYSTEM_ACTIVITY_LOGS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: { body: debouncedBody, categories, marketId },
        paging: { page, size: pageSize },
        sort: sorting,
      },
    },
  });

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const handleRequestSort = useCallback(
    (property: keyof SystemActivityLog) => {
      const supportedProperties: (keyof SystemActivityLog)[] = [
        "createdAt",
        "body",
        "category",
      ];
      if (!supportedProperties.includes(property)) {
        return;
      }

      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleOpenDisbursementStatusModal = useCallback(
    (data: DisbursementStatusDialogData) =>
      setDisbursementStatusModalState({ isOpen: true, data }),
    [setDisbursementStatusModalState]
  );

  const handleCloseDisbursementStatusModal = useCallback(() => {
    setDisbursementStatusModalState({ isOpen: false });
    handleRefetch();
  }, [setDisbursementStatusModalState, handleRefetch]);

  const handleCloseAddDisbursementDialog =
    useCallback<OnRequestCloseAddDisbursementDialog>(
      (createdDisbursement) => {
        setAddDisbursementModalState({ isOpen: false });
        handleRefetch();
        if (createdDisbursement) {
          handleOpenDisbursementStatusModal({
            disbursement: createdDisbursement,
          });
        }
      },
      [
        setAddDisbursementModalState,
        handleRefetch,
        handleOpenDisbursementStatusModal,
      ]
    );

  const handleOpenAddDisbursementModal = useCallback(
    (data: AddDisbursementDialogData) => {
      console.log("handleOpenAddDisbursementModal", data);
      setAddDisbursementModalState({ isOpen: true, data });
    },
    [setAddDisbursementModalState]
  );

  const handleClosePushUserNotificationModal = useCallback(() => {
    setPushUserNotificationModalState({ isOpen: false });
  }, [setPushUserNotificationModalState]);

  const handleOpenUserNotificationModal = useCallback(
    (data: PushUserNotificationDialogData) =>
      setPushUserNotificationModalState({ isOpen: true, data }),
    [setPushUserNotificationModalState]
  );

  return (
    <div>
      <SystemActivityLogFilter
        body={body}
        onRequestSetBody={setBody}
        categories={categories}
        onRequestSetCategories={setCategories}
        onRequestRefetch={handleRefetch}
        loading={loading}
      />
      {data ? (
        <SystemActivityLogTable
          systemActivityLogs={data.allSystemActivityLogs.systemActivityLogs}
          pageInfo={data.allSystemActivityLogs.pageInfo}
          onRequestRefetch={handleRefetch}
          onRequestSort={handleRequestSort}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          onRequestAddDisbursementDialog={handleOpenAddDisbursementModal}
          onRequestDisbursementStatusDialog={handleOpenDisbursementStatusModal}
          sorting={sorting}
        />
      ) : null}
      <AddDisbursementDialog
        modalState={addDisbursementModalState}
        onRequestClose={handleCloseAddDisbursementDialog}
      />
      <DisbursementStatusDialog
        modalState={disbursementStatusModalState}
        onRequestClose={handleCloseDisbursementStatusModal}
        onRequestUserNotificationDialog={handleOpenUserNotificationModal}
      />
      <PushUserNotificationDialog
        modalState={pushUserNotificationModalState}
        onRequestClose={handleClosePushUserNotificationModal}
      />
    </div>
  );
};

export default SystemActitvityLogPage;
