import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import { ME, UPDATE_COMPANY } from "../../graphql/queries.gql";
import Loading from "../../components/Loading";
import { Me } from "../../graphql/types/Me";
import {
  UpdateCompany,
  UpdateCompanyVariables,
} from "../../graphql/types/UpdateCompany";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const Misc: React.FC = () => {
  const classes = useStyles();
  const { data: companyData, loading, refetch } = useQuery<Me>(ME);
  const [updateCompany, { loading: saveLoading }] = useMutation<
    UpdateCompany,
    UpdateCompanyVariables
  >(UPDATE_COMPANY);

  const { handleSubmit, register, errors } = useForm();

  if (loading || !companyData?.me.companyConnection) {
    return <Loading />;
  }

  const onSubmit = (input: any) => {
    return updateCompany({
      variables: {
        input,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {});
  };

  return (
    <>
      <Typography variant="h4">Miscellaneous</Typography>
      <Divider />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div>
                  Företagsnamn är det namn som syns utåt för era användare.
                </div>
                <TextField
                  size="small"
                  defaultValue={companyData.me.companyConnection.companyName}
                  className={classes.textField}
                  variant="outlined"
                  id="companyName"
                  label="Företagsnamn"
                  name="companyName"
                  error={errors.companyName !== undefined}
                  helperText={
                    errors.companyName
                      ? "Du måste ange ett företagsnamn."
                      : undefined
                  }
                  inputRef={register({
                    required: true,
                  })}
                />
              </div>
              <div>
                <div>
                  Beskrivning är en kort beskrivning av er som företag. Denna
                  syns i appen under ert företags profil.
                </div>
                <TextField
                  fullWidth
                  defaultValue={companyData.me.companyConnection.description}
                  className={classes.textField}
                  variant="outlined"
                  id="description"
                  label="Beskrivning"
                  name="description"
                  multiline
                  rows={4}
                  inputRef={register({
                    required: false,
                  })}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saveLoading}
              >
                Spara
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Misc;
