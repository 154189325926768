import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  USER_REFERRAL_BONUSES,
  USER_REWARDS,
} from "../../../../graphql/queries.gql";
import { SystemUser } from "../../../../graphql/helperTypes";
import {
  userRewards,
  userRewardsVariables,
} from "../../../../graphql/types/userRewards";
import {
  userReferralBonuses,
  userReferralBonusesVariables,
} from "../../../../graphql/types/userReferralBonuses";
import { dateAndTime } from "../../../../utils/formatDate";
import { RewardFragment } from "../../../../graphql/types/RewardFragment";
import { formatCurrency } from "../../../../utils/formatCurrency";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    whiteSpace: "nowrap",
  },
  newlines: {
    whiteSpace: "pre-wrap",
  },
}));

export const RewardsSection = ({ user }: { user: SystemUser }) => {
  return (
    <>
      <RewardsTable user={user} />
      <BonusTable user={user} />
    </>
  );
};

function RewardsTable({ user }: { user: SystemUser }) {
  const classes = useStyles();
  const { data } = useQuery<userRewards, userRewardsVariables>(USER_REWARDS, {
    fetchPolicy: "cache-and-network",
    variables: { userId: user.id, includeExpired: true, includeRedeemed: true },
  });

  const rewards = data?.userRewards.rewards || [];

  return (
    <TableContainer className={classes.tableContainer}>
      <Typography variant="h6">Reward badges</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>Title</TableCell>
            <TableCell className={classes.header}>
              Voucher description
            </TableCell>
            <TableCell className={classes.header}>Status description</TableCell>
            <TableCell className={classes.header}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards.map((reward) => (
            <TableRow key={reward.id}>
              <TableCell className={classes.newlines}>{reward.title}</TableCell>
              <TableCell className={classes.newlines}>
                {reward.voucherDefinitionConnection?.description}
              </TableCell>
              <TableCell className={classes.newlines}>
                {reward.earnStatusDescription}
              </TableCell>
              <TableCell>
                <RewardStatusDisplay reward={reward} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const RewardStatusDisplay = ({ reward }: { reward: RewardFragment }) => {
  const classes = useStyles();
  const swish = reward.swishRewardConnection;
  if (swish) {
    return (
      <Typography color="primary" variant="body2">
        Swish paid, {formatCurrency(swish.amount)} at{" "}
        {dateAndTime(swish.createdAt)}
      </Typography>
    );
  }

  const voucher = reward.userVoucherConnection;
  if (voucher) {
    if (voucher.isExpired) {
      return (
        <Typography color="error" variant="body2">
          Expired {dateAndTime(voucher.expiresAt)}
        </Typography>
      );
    } else if (voucher.redeemedAt) {
      return (
        <Typography color="primary" variant="body2">
          Redeemed {dateAndTime(voucher.redeemedAt)}
        </Typography>
      );
    } else {
      return (
        <Typography color="textPrimary" variant="body2">
          Expires {dateAndTime(voucher.expiresAt)}
        </Typography>
      );
    }
  }

  const unmet = reward.conditionsSummary.allUnmetConditions;
  if (unmet.length > 0) {
    return (
      <Typography
        color="textPrimary"
        variant="body2"
        className={classes.newlines}
      >
        <em>Unmet conditions: </em>
        {unmet
          .map(({ conditionDescription }) => conditionDescription)
          .join(", ")}
      </Typography>
    );
  }

  if (reward.earnedAt) {
    return (
      <Typography color="textPrimary" variant="body2">
        Earned {dateAndTime(reward.earnedAt)}
      </Typography>
    );
  }

  return (
    <Typography color="textPrimary" variant="body2">
      All conditions met
    </Typography>
  );
};

function BonusTable({ user }: { user: SystemUser }) {
  const classes = useStyles();
  const { data } = useQuery<userReferralBonuses, userReferralBonusesVariables>(
    USER_REFERRAL_BONUSES,
    { fetchPolicy: "cache-and-network", variables: { userId: user.id } }
  );

  const bonuses = data?.userReferralBonuses.bonuses || [];

  return (
    <TableContainer className={classes.tableContainer}>
      <Typography variant="h6">Referral bonus badges</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>Title</TableCell>
            <TableCell className={classes.header}>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bonuses.map((bonus) => (
            <TableRow key={bonus.id}>
              <TableCell className={classes.newlines}>{bonus.title}</TableCell>
              <TableCell className={classes.newlines}>
                {bonus.description}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
