import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const initSentry = () => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn:
        "https://71d256bce9a04b68a3600771802864c0@o552010.ingest.sentry.io/5677075",
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_ENVIRONMENT_NAME || "development",
      release: process.env.REACT_APP_RELEASE || "development",
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};

export default initSentry;
