import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import AgainThemeProvider from "./utils/AgainThemeProvider";
import initSentry from "./utils/initSentry";
import client from "./graphql/client";
import { AuthContextProvider } from "./contexts/AuthContext";
import { migrateROCPTokens } from "./utils/auth/migrateROCPTokens";

initSentry();
migrateROCPTokens();

const AuthenticatedContent = () => {
  return (
    <ApolloProvider client={client}>
      <AgainThemeProvider>
        <App />
      </AgainThemeProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <AuthenticatedContent />
      </AuthContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
