import React, {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Container,
  createStyles,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  lighten,
  makeStyles,
  Paper,
  Popover,
  PropTypes,
  Radio,
  RadioGroup,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
  withStyles,
} from "@material-ui/core";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Cell,
  LineChart,
  Line,
  ReferenceLine,
  Label,
  YAxis,
  XAxis,
  ComposedChart,
  ReferenceArea,
  PieChart,
  Pie,
} from "recharts";
import {
  addDays,
  addMonths,
  differenceInDays,
  format,
  formatDistanceToNow,
  isBefore,
  startOfMonth,
  subDays,
} from "date-fns";
import CountUp from "react-countup";
import { formatCurrency } from "../../utils/formatCurrency";
import logUnhandledSwitch from "../../utils/logUnhandledSwitch";
import { fontFamilies } from "../../utils/AgainThemeProvider";
import { SupportedCurrencyCode } from "../../graphql/types/global";

const segments = ["All", "20-29", "30-39", "40-49", "50-59", "60-69"].map(
  (s) => ({
    id: s,
    label: s,
  })
);

const views = ["CLV", "Loyalty", "Churn", "Reports", "Market share"] as const;
type View = (typeof views)[number];

const isLeftNav = (view: View) => {
  switch (view) {
    case "CLV":
    case "Churn":
    case "Loyalty":
      return true;
    case "Reports":
    case "Market share":
      return false;
    default:
      logUnhandledSwitch(view);
      return true;
  }
};

const isRightNav = (view: View) => !isLeftNav(view);

const DATA_SCALE_FACTOR = 8;
const REPORT_CHART_HEIGHT = 270;
const YAXIS_WIDTH = 55;
const AXIS_FONT_SIZE = 16;

const barChartMargin = { bottom: 0, left: 24, right: 24, top: 8 };
const barChartGap = 8;
const barChartRadius = 50;
const barChartBackground = "#434343";
const lineChartMargin = barChartMargin;

const GLOW_FILTER_DEF_ID = "glow-filter";

const surveyDateText = (d: Date) => {
  const now = new Date();
  if (isBefore(d, subDays(now, 20))) {
    return differenceInDays(now, d) + " days";
  }
  return formatDistanceToNow(d);
};

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      maxHeight: "30px",
      marginBottom: "5px",
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
      borderRight: `1px solid ${theme.palette.grey[800]}`,
      paddingRight: theme.spacing(2),
    },
    header: {
      display: "flex",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      alignItems: "center",
    },
    headerBeta: {
      paddingLeft: theme.spacing(2),
    },
    nav: {
      marginLeft: theme.spacing(2),
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    navButton: {
      padding: "0.25rem 0.5rem",
    },
    "@keyframes appear": {
      "0%": {
        opacity: 0,
        transform: "translateY(20px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0px)",
      },
    },
    "@keyframes opacityappear": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
    primaryCardRoot: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      animation: "$appear 350ms ease-in-out",
    },
    formControlRoot: {
      display: "flex",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    formControlLabelRoot: {
      backgroundColor: "#ffffff0a",
      padding: theme.spacing(1),
      paddingRight: theme.spacing(3),
      margin: 0,
      marginTop: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    heading: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    cardActions: {},
    surveyCard: {
      background: theme.palette.grey[100],
      marginTop: theme.spacing(2),
    },
    surveyCardStatusRow: {
      marginTop: theme.spacing(2),
    },
    tableContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(8),
    },
    tableStatusCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    statusChip: {
      outline: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: theme.spacing(2),
      padding: "4px 24px",
      fontSize: "0.8125rem",
      textTransform: "uppercase",
    },
    statusText: {
      padding: "4px 24px",
      fontSize: "0.8125rem",
      textTransform: "uppercase",
      color: theme.palette.primary.main,
    },
    tableStatusText: {
      paddingLeft: theme.spacing(1),
    },
    chartCard: {
      position: "relative",
      marginTop: theme.spacing(2),
    },
    chartCardContent: {
      padding: 0,
      userSelect: "none",
    },
    chartCardHeaderTitle: {
      fontSize: "1.2rem",
      fontWeight: "normal",
    },
    chartCardHeaderTitlePrimary: {
      color: theme.palette.primary.main,
    },
    chartCardHeaderTitleSecondary: {
      color: theme.palette.secondary.main,
    },
    chartCardHeaderTitleUnder: {
      lineHeight: 1,
      fontWeight: "normal",
      fontSize: "1.2rem",
      paddingLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    chartBar: {
      cursor: "pointer",
      fill: "#c6cdd6",
      transition: "fill 150ms ease-in-out",
    },
    chartBarPrimary: {
      fill: theme.palette.primary.main,
      "&.selected": {
        stroke: theme.palette.common.white,
        strokeWidth: "3px",
      },
      "&.not-selected": {
        fillOpacity: 0.5,
      },
      "&:hover": {
        stroke: theme.palette.common.white,
        strokeWidth: "3px",
        fill: theme.palette.primary.dark,
      },
    },
    chartBarSecondary: {
      fill: theme.palette.secondary.main,
      "&.selected": {
        stroke: theme.palette.common.white,
        strokeWidth: "3px",
        fill: theme.palette.secondary.light,
      },
      "&.not-selected": {
        fillOpacity: 0.5,
      },
      "&:hover": {
        stroke: theme.palette.common.white,
        strokeWidth: "3px",
        fill: theme.palette.secondary.dark,
      },
    },
    pageHeaderWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        "& > *": {
          marginTop: theme.spacing(1),
        },
      },
    },
    // chipConcluded: {
    //   background: theme.palette.primary.dark,
    // },
    // chipOngoing: {
    //   background: theme.palette.secondary.dark,
    // },
    questionsWrapper: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    textFieldWrapper: {
      marginBottom: theme.spacing(2),
    },
    circle: {
      maxWidth: "60vw",
      position: "relative",
      display: "block",
      margin: "2em auto",
      backgroundColor: "transparent",
      textAlign: "center",
    },
    circleAfter: {
      display: "block",
      paddingBottom: "100%",
      width: "100%",
      height: 0,
      borderRadius: "50%",
      backgroundColor: "#fafafa",
      content: '""',
      transition: "all 300ms ease-in-out",
      scale: 0.85,
      zIndex: 0,
      "&.is-active": {
        scale: 1,
        backgroundColor: "#fff",
      },
    },
    circle__inner: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      transition: "all 0.3s ease-in-out",
      opacity: 0,
      "&.is-active": {
        opacity: 1,
      },
    },
    circle__wrapper: {
      display: "table",
      width: "100%",
      height: "100%",
    },
    circle__content: {
      display: "table-cell",
      padding: "3rem",
      verticalAlign: "middle",
      zIndex: 1,
      color: theme.palette.background.paper,
    },
    circleAddButton: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: 0,
      right: 0,
      bottom: "0",
      paddingBottom: "1vw",
    },
    selectionConsumerCount: {
      fontFamily: fontFamilies.black,
      fontSize: "7rem",
      lineHeight: 1,
    },
    selectionHintText: {
      fontSize: "1.75rem",
      textTransform: "uppercase",
      letterSpacing: "-0.03em",
    },
    reportRow: {
      cursor: "pointer",
      "& > *": {
        borderBottom: "unset",
      },
      "&:hover th, &:hover td": {
        backgroundColor: "rgba(255,255,255,0.05)",
      },
      // "&.concluded th:first-child": {
      //   borderLeft: `${theme.spacing(2)}px solid ${theme.palette.primary.dark}`,
      // },
      // "&.ongoing th:first-child": {
      //   borderLeft: `${theme.spacing(2)}px solid ${
      //     theme.palette.secondary.dark
      //   }`,
      // },
    },
    reportMetricCellText: {
      color: theme.palette.text.primary,
    },
    reportMetricCellTextPositive: {
      color: theme.palette.primary.main,
    },
    reportDetailsTopGrid: {
      marginTop: theme.spacing(2),
    },
    reportChartGridItem: {
      // background: theme.palette.divider,
      // borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1),
      },
    },
    reportChartDescription: {
      marginLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    reportDataCell: {
      // background: theme.palette.divider,
      // borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginBottom: 0,
        marginRight: theme.spacing(2),
      },
      "&:last-of-type": {
        marginBottom: 0,
        marginRight: 0,
      },
    },
    reportDataCaption: {
      display: "block",
      color: theme.palette.text.primary,
      lineHeight: 1,
      textAlign: "center",
      marginBottom: theme.spacing(1),
      fontSize: "1.2rem",
    },
    reportDataDigits: {
      display: "block",
      fontSize: "6rem",
      fontFamily: fontFamilies.bold,
      lineHeight: 1,
      textAlign: "center",
    },
    reportDataDigitsLarge: {
      fontSize: "8rem",
      fontFamily: fontFamilies.extraBold,
    },
    reportDataDigitsSuffix: {
      fontSize: "3rem",
    },
    reportDataDigitsSuffixLarge: {
      fontSize: "5.5rem",
      fontFamily: fontFamilies.extraBold,
    },
    answerDisplay: {
      fontSize: "1.2rem",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(4),
      marginLeft: theme.spacing(3),
    },
    answerRow: {
      marginTop: theme.spacing(1),
    },
    answerChip: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(1),
    },
    automationDialogPaper: {
      borderRadius: "50%",
      height: "450px",
      width: "450px",
    },
    automationTitle: {
      fontSize: "1.75rem",
      fontFamily: fontFamilies.medium,
      color: theme.palette.primary.main,
      textTransform: "uppercase",
    },
    automationLargeIcon: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: "5rem",
      lineHeight: 1,
    },
    automationDialogContent: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    automationDialogParagraph: {
      fontSize: "1.2rem",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      maxWidth: "350px",
    },
    automationActionCancel: {
      marginTop: theme.spacing(1),
    },
    answerDisplayTitleRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    modalPaper: {
      minHeight: "55vh",
    },
    clvPaper: {
      marginTop: theme.spacing(2),
      position: "relative",
    },
    clvPaperContent: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: "flex",
    },
    clvHeader: {
      marginBottom: theme.spacing(2),
    },
    clvEntryRow: {
      display: "flex",
      height: "5rem",
      // cursor: "pointer",
      // "&:hover": {
      //   background: darken(theme.palette.common.white, 0.75),
      // },
    },
    clvEntryAmountPercent: {
      fontSize: "2rem",
      width: "18rem",
      textAlign: "center",
      lineHeight: "5rem",
    },
    clvEntryAmount: {
      width: "14rem",
      textAlign: "left",
      fontSize: "2rem",
      paddingLeft: "2rem",
      paddingRight: "2rem",
      lineHeight: "5rem",
    },
    clvEntryView: {
      flex: 1,
    },
    clvEntryViewGenderFilter: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(1),
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },
    clvEntryViewDetails: {
      flex: 1,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    clvEntryViewHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1),
      paddingLeft: "2rem",
    },
    clvEntryViewActionsRoot: {
      background: "transparent",
      pointerEvents: "none",
    },
    clvEntryViewActionsPaper: {
      background: "transparent",
      boxShadow: "none",
      pointerEvents: "all",
    },
    clvEntryViewActions: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(6),
      "& > *": {
        marginTop: theme.spacing(1),
      },
    },
    ageFilterWrapper: {},
    ageFilterButton: {},
    marketShareCards: {
      display: "flex",
      justifyContent: "space-between",
    },
    marketShareCard: {
      flex: 1,
      marginLeft: "3rem",
      marginTop: "1rem",
      padding: "2rem",
      textAlign: "center",
      "&:first-child": {
        marginLeft: "0rem",
      },
    },
    rightSideNavButtons: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
    },
    leftSideNavButtons: {
      marginTop: theme.spacing(1),
    },
    surveyBubbleHeader: {
      fontFamily: fontFamilies.black,
      fontSize: "3.5rem",
      textTransform: "uppercase",
      letterSpacing: "-0.03em",
    },
    clvSidebar: {
      background: theme.palette.common.black,
      borderRadius: "5rem",
      width: "5rem",
      height: "25rem",
      overflow: "hidden",
    },
    clvSidebarEntry: {
      ...theme.typography.button,
      background: theme.palette.common.black,
      color: theme.palette.common.white,
      border: 0,
      margin: 0,
      padding: 0,
      display: "block",
      height: "5rem",
      width: "5rem",
      lineHeight: "5rem",
      fontSize: "2rem",
      textAlign: "center",
      cursor: "pointer",
      transition: "all 300ms ease-in-out",
      "& div": {
        height: "5rem",
        width: "5rem",
        borderRadius: "5rem",
        lineHeight: "5rem",
        textAlign: "center",
      },
      "&:hover div": {
        background: darken(theme.palette.common.white, 0.75),
      },
      "&.selected div": {
        color: "black",
        fontFamily: fontFamilies.bold,
        background: theme.palette.primary.main,
      },
    },
    marketShareHeaderPaper: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    marketShareGenderFilter: {
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(2),
    },
    marketShareCardAgeGroup: {
      fontFamily: fontFamilies.light,
      fontSize: "2rem",
      lineHeight: "1em",
      marginBottom: "0.5rem",
      paddingBottom: "0.5rem",
      borderBottom: `0.5px solid ${theme.palette.divider}`,
    },
    marketShareCardValue: {
      fontFamily: fontFamilies.extraBold,
      fontSize: "5rem",
      lineHeight: "5rem",
      color: theme.palette.secondary.main,
    },
    marketShareCardSuffix: {
      fontSize: "3rem",
      fontFamily: fontFamilies.extraBold,
    },
    marketShareCardTrend: {
      fontSize: "1.5rem",
      fontFamily: fontFamilies.light,
      color: theme.palette.text.hint,
      lineHeight: "1em",
    },
    marketShareChartHeader: {
      fontFamily: fontFamilies.light,
      fontSize: "1.2rem",
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    reportDetailsWrapper: {
      backgroundColor: "#000000",
      padding: theme.spacing(2),
      borderLeft: `3px solid ${theme.palette.background.paper}`,
      borderRight: `3px solid ${theme.palette.background.paper}`,
    },
    reportDetailsWrapperLastRow: {
      borderBottomLeftRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
      borderBottom: `3px solid ${theme.palette.background.paper}`,
    },
    reportTableHeaderCell: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontSize: "1.2rem",
    },
    reportTableCell: {
      fontSize: "1.2rem",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    tableRowToggleButton: {
      marginRight: theme.spacing(2),
    },
    marketShareBadgeLabel: {
      fontFamily: fontFamilies.black,
      marginTop: "-0.5rem",
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
    navigationMarketShareBadgeLabel: {
      fontFamily: fontFamilies.black,
      marginTop: "-0.5rem",
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
      background: "#fff",
      color: "#000",
      animation: "$opacityappear 350ms ease-in-out",
    },
    reportDetailsDataCells: {
      marginTop: theme.spacing(2),
    },
  })
);

const AddButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: lighten(theme.palette.background.paper, 0.15),
    },
  },
}))(IconButton);

const ModalActionButton = withStyles((theme) => ({
  root: {
    fontFamily: fontFamilies.medium,
    fontSize: "1.2rem",
    margin: "0.5rem",
  },
  text: {
    fontFamily: fontFamilies.light,
    color: theme.palette.grey[500],
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
}))(Button);

const GreyActionButton = withStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: "100px",
    transition: "all 300ms ease-in-out",
    "&:hover": {
      backgroundColor: darken(theme.palette.background.paper, 0.3),
      outline: "2px solid #fff",
    },
    margin: "6px 3px",
  },
  sizeSmall: {
    fontSize: "0.8125rem",
    padding: "2px 0",
  },
  sizeLarge: {
    fontSize: "1.1rem",
    padding: "4px 20px",
  },
}))(Button);

const OutlinedButton = withStyles((theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: "transparent",
    borderRadius: "100px",
    marginLeft: theme.spacing(1),
    transition: "all 300ms ease-in-out",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  sizeSmall: {
    fontSize: "0.8125rem",
    padding: "2px 0",
    marginLeft: 0,
  },
  textPrimary: {
    color: theme.palette.common.white,
    "&:hover": {
      border: 0,
      backgroundColor: darken(theme.palette.primary.main, 0.75),
    },
  },
  textSecondary: {
    color: theme.palette.secondary.main,
    "&:hover": {
      border: 0,
      backgroundColor: darken(theme.palette.secondary.main, 0.65),
    },
  },
  outlined: {
    border: 0,
  },
  outlinedPrimary: {
    color: theme.palette.primary.main,
    outline: `3px solid ${theme.palette.primary.main}`,
    "&:hover": {
      border: 0,
      backgroundColor: darken(theme.palette.primary.main, 0.75),
    },
  },
  outlinedSecondary: {
    color: theme.palette.secondary.main,
    outline: `3px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      border: 0,
      backgroundColor: darken(theme.palette.secondary.main, 0.65),
    },
  },
  outlinedSizeSmall: {
    outlineWidth: "2px",
  },
}))(Button);

type TrendDataPoint = { x: string; y: number };

type TrendSegment = {
  id: string;
  label: string;
  dataPoints: TrendDataPoint[];
};

const makeTrendSegments = (totalTrend: TrendDataPoint[]): TrendSegment[] =>
  segments.map<TrendSegment>((segment, i) => ({
    ...segment,
    dataPoints: totalTrend.map((d, j) => ({
      x: d.x,
      y: Math.max(
        4,
        d.y + (i - 3) * (j - 3) + 0.5 * totalTrend[Math.max(0, i - (j - 2))].y
      ),
    })),
  }));

type BaseDataPoint = { id: string; x: Date; y: number };
type DataPoint = {
  id: string;
  selectionType: SelectionType;
  x: string;
  ys: { [key in SelectionSubType]?: number };
};
type Adjustment = { addition: number; modulus: number };

type OnBarClicked = (dataPoint: DataPoint, subType: SelectionSubType) => void;
type OnBarsSelected = (
  dataPoints: DataPoint[],
  subType: SelectionSubType
) => void;

const adjustData = (
  data: BaseDataPoint[],
  selectionType: SelectionType,
  transforms: {
    [key in SelectionSubType]?: Adjustment;
  }
): DataPoint[] => {
  const transform = (y: number, { addition, modulus }: Adjustment) => {
    return addition < 0
      ? Math.min(-8, -Math.abs((y + addition) % modulus))
      : Math.max(6, (y + addition) % modulus);
  };

  return data.map(({ x, y, id }, i) => ({
    id: `${id}-${selectionType}-${i}`,
    selectionType,
    x: formatXAxis(x),
    ys: Object.keys(transforms).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          transform(y, transforms[key as SelectionSubType] as Adjustment) *
          DATA_SCALE_FACTOR,
      }),
      {} as any
    ),
  }));
};

const trendData = (
  lowerP: number,
  upperP: number,
  ...dataSets: DataPoint[][]
): TrendDataPoint[] => {
  const points = dataSets[0].map((root, i) => ({
    x: root.x,
    y: dataSets
      .flatMap((ds) => Object.values(ds[i].ys))
      .reduce((acc, sum) => acc + sum, 0),
  }));

  const { min, max } = points.reduce(
    (acc, p) => ({ min: Math.min(acc.min, p.y), max: Math.max(acc.max, p.y) }),
    { min: 0, max: 0 }
  );

  return points.map((p) => ({
    x: p.x,
    y: lowerP + Math.ceil(((upperP - lowerP) * (p.y - min)) / (max - min)),
  }));
};

const baseDate = startOfMonth(addMonths(new Date(), -12));
const baseData: BaseDataPoint[] = [
  { id: "1", x: addMonths(baseDate, 1), y: 9 },
  { id: "2", x: addMonths(baseDate, 2), y: 8 },
  { id: "3", x: addMonths(baseDate, 3), y: 5 },
  { id: "4", x: addMonths(baseDate, 4), y: 7 },
  { id: "5", x: addMonths(baseDate, 5), y: 9 },
  { id: "6", x: addMonths(baseDate, 6), y: 11 },
  { id: "7", x: addMonths(baseDate, 7), y: 16 },
  { id: "8", x: addMonths(baseDate, 8), y: 17 },
  { id: "9", x: addMonths(baseDate, 9), y: 12 },
  { id: "10", x: addMonths(baseDate, 10), y: 8 },
  { id: "11", x: addMonths(baseDate, 11), y: 11 },
  { id: "12", x: addMonths(baseDate, 12), y: 17 },
];

type SelectionType = "Loyalty" | "Churn";
type SelectionSubType = "Increased" | "Decreased" | "Lost" | "Risk" | "Trend";
enum SurveyStatus {
  ongoing = "ongoing",
  finished = "finished",
}

type Survey = {
  id: string;
  createdAt: Date;
  customerCount: number;
  status: SurveyStatus;
  concludesAt: Date;
  template: Template;
  answers: SurveyAnswers;
  metric: {
    digits: number;
  };
};

type SurveyAnswers = Array<{
  question: string;
  answerDistribution: Array<{ option: string; ratio: number }>;
}>;

type TemplateId =
  | "churn-lost-1"
  | "churn-lost-2"
  | "churn-risk-1"
  | "churn-risk-2"
  | "loyalty-increased"
  | "loyalty-decreased"
  | "loyalty-program";

type Template = {
  id: TemplateId;
  selectionType: SelectionType;
  selectionSubType: SelectionSubType | undefined;
  label: string;
  questions: Array<{
    value: string;
    options: string[];
  }>;
};

const templates: Template[] = [
  {
    id: "churn-lost-1",
    selectionType: "Churn",
    selectionSubType: "Lost",
    label: "Template: Lost customer 1",
    questions: [
      { value: "Har vi tappat dig som kund?", options: ["Ja", "Nej"] },
      {
        value: "Vad är främsta anledningen att vi tappat dig?",
        options: ["Kundtjänst", "Priser", "Produktkvalité"],
      },
      {
        value: "Vad kan vi göra för att vinna tillbaka dig?",
        options: ["Fritext"],
      },
    ],
  },
  {
    id: "churn-lost-2",
    label: "Template: Lost customer 2",
    selectionType: "Churn",
    selectionSubType: "Lost",
    questions: [
      {
        value:
          "Vi saknar dig som kund! Vill du att vi kontaktar dig med ett personligt erbjudande?",
        options: ["Ja", "Nej"],
      },
      {
        value: "Vilket typ av erbjudande är mest värdefullt för dig?",
        options: ["Rabatter", "En utvald deal", "Annat"],
      },
      {
        value:
          "Vad är viktigast för att du ska vilja stanna kvar som kund hos oss i framtiden?",
        options: ["Fritext"],
      },
    ],
  },
  {
    id: "churn-risk-1",
    selectionType: "Churn",
    selectionSubType: "Risk",
    label: "Template: Churn RISK 1",
    questions: [
      { value: "Vad suger vi på?", options: ["Fritext"] },
      {
        value: "Vilken konkurrent tycker du gör ett bättre jobb än oss?",
        options: ["Fritext"],
      },
    ],
  },
  {
    id: "churn-risk-2",
    selectionType: "Churn",
    selectionSubType: "Risk",
    label: "Template: Churn RISK 2",
    questions: [
      {
        value: "Vad är viktigast för dig, för att du ska välja oss oftare?",
        options: ["Fritext"],
      },
      {
        value:
          "Vad kan vi göra för att du ska tycka att vi är bättre än våra konkurrenter?",
        options: ["Fritext"],
      },
    ],
  },
  {
    id: "loyalty-decreased",
    selectionType: "Loyalty",
    selectionSubType: "Decreased",
    label: "Template: Decreased LOYALTY",
    questions: [
      {
        value: "Varför handlar du mindre hos oss än vanligt?",
        options: ["Fritext"],
      },
      {
        value: "Vad kan vi göra för att du ska välja oss oftare?",
        options: ["Fritext"],
      },
      {
        value: "Vad är vi sämst på?",
        options: ["Fritext"],
      },
    ],
  },
  {
    id: "loyalty-increased",
    selectionType: "Loyalty",
    selectionSubType: "Increased",
    label: "Template: Increased loyalty",
    questions: [
      {
        value:
          "Vad är den främsta anledningen till att du har handlat mer hos oss än vanligt?",
        options: ["Fritext"],
      },
      {
        value: "Vad uppskattar du mest med oss?",
        options: ["Fritext"],
      },
      {
        value: "Vad är viktigast för oss att bli ännu bättre på, tycker du?",
        options: ["Fritext"],
      },
    ],
  },
  {
    id: "loyalty-program",
    selectionType: "Loyalty",
    selectionSubType: undefined,
    label: "Template: Loyalty program",
    questions: [
      {
        value: "Vad tycker du om vårt lojalitetsprogram?",
        options: ["5=Grymt!", "4", "3", "2", "1=Kasst"],
      },
      {
        value: "Vilka komponenter i lojalitetsprogrammet tycker du är bäst?",
        options: ["Cash back", "Rabatter", "Utvalda deals", "Annat"],
      },
      {
        value:
          "Om du vore en av våra topp 10% kunder, vad skulle du föredra för belöning?",
        options: ["Cash back", "Resa", "Riktigt bra rabatt", "Annat"],
      },
    ],
  },
];

const makeAnswers = (questions: Template["questions"], amplifier: number) =>
  questions.map((q, i) => ({
    question: q.value,
    answerDistribution: q.options.map((o, j, opts) => ({
      option: o,
      ratio: Math.floor(
        o === "Fritext"
          ? 100
          : opts.length === 3
          ? 33 - (j - 1) * (5 * amplifier)
          : opts.length === 2
          ? 50 + (j === 0 ? 15 : -15) * amplifier
          : 100
      ),
    })),
  }));

const LabelledChip: React.FC<{
  label: string;
  text: string;
  className?: string;
}> = ({ label, text, className }) => (
  <Chip
    className={className}
    label={
      <>
        <strong>{label}: </strong>
        {text ?? "..."}
      </>
    }
    variant="outlined"
  />
);

const AnswersDisplay = ({
  answers,
  consumerCount,
  selectionType,
  selectionSubType,
}: {
  answers: SurveyAnswers;
} & Pick<
  ModalContentData,
  "consumerCount" | "selectionType" | "selectionSubType"
>) => {
  const styles = useStyles();
  return (
    <div className={styles.answerDisplay}>
      <div className={styles.answerDisplayTitleRow}>
        <Typography variant="h6" color="secondary">
          Survey - Distribution
        </Typography>
      </div>
      {answers.map(({ question, answerDistribution }, i) => (
        <div key={i} className={styles.answerRow}>
          {question}
          {answerDistribution.map(({ option, ratio }) => (
            <LabelledChip
              key={option}
              label={option}
              text={ratio.toString() + "%"}
              className={styles.answerChip}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const GlowFilterDef = ({ variant }: { variant: "primary" | "secondary" }) => (
  <filter
    id={GLOW_FILTER_DEF_ID}
    filterUnits="userSpaceOnUse"
    x="0%"
    y="0%"
    width="100%"
    height="100%"
  >
    {/* <!-- blur the text at different levels--> */}
    <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur5" />
    <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur10" />
    <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur20" />
    <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur30" />
    <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur50" />
    {/* <!-- merge all the blurs except for the first one --> */}
    <feMerge result="blur-merged">
      <feMergeNode in="blur10" />
      <feMergeNode in="blur20" />
      <feMergeNode in="blur30" />
      <feMergeNode in="blur50" />
    </feMerge>
    {/* <!-- recolour the merged blurs red--> */}
    <feColorMatrix
      result="red-blur"
      in="blur-merged"
      type="matrix"
      values={
        variant === "secondary"
          ? `0.9 0 0 0 0
             0 1 0 0 0
             0 0 0.94 0 0
             0 0 0 1 0`
          : `0.2 0 0 0 0
             0 1 0 0 0
             0 0 0.44 0 0
             0 0 0 1 0`
      }
    />
    <feMerge>
      {/* <!-- largest blurs coloured red --> */}
      <feMergeNode in="red-blur" />
      {/* <!-- smallest blur left white --> */}
      <feMergeNode in="blur5" />
      {/* <!-- original white text --> */}
      <feMergeNode in="SourceGraphic" />
    </feMerge>
  </filter>
);

const dataKey = (subType: SelectionSubType) => `ys.${subType}`;

const LineChartContainer = ({
  def: { segments, label },
}: {
  def: TrendChartDefinition;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [{ view, segmentId }] = useSurveyPage();
  const segment = segments.find((s) => s.id === segmentId) || segments[0];
  const ticks =
    view === "Churn" ? [0, 10, 20, 30, 40] : [0, 10, 20, 30, 40, 50, 60];

  return (
    <Card className={classes.chartCard}>
      <ChartCardHeader title={label} />
      <CardContent className={classes.chartCardContent}>
        <ResponsiveContainer height={150}>
          <ComposedChart
            syncId="gridchart"
            margin={lineChartMargin}
            barCategoryGap={1}
            data={segment.dataPoints}
          >
            <YAxis
              width={YAXIS_WIDTH}
              fontSize={AXIS_FONT_SIZE}
              unit="%"
              tick={{ fill: theme.palette.grey[500] }}
              type="number"
              ticks={ticks}
              tickCount={ticks.length}
              domain={[ticks[0], ticks[ticks.length - 1]]}
              hide
            />
            <XAxis
              dataKey="x"
              fontSize={AXIS_FONT_SIZE}
              tick={<AxisTickDate />}
              tickLine={false}
              axisLine={false}
              interval={0}
              hide
            />
            <Bar dataKey="y">
              {segment.dataPoints.map((d, i) => {
                return <Cell style={{ display: "none" }} key={i} />;
              })}
            </Bar>
            <Line
              dataKey="y"
              stroke={theme.palette.primary.main}
              strokeWidth={2}
              type="monotone"
              fill={theme.palette.primary.main}
              dot={{ stroke: theme.palette.primary.main, r: 6 }}
              activeDot={{ stroke: theme.palette.primary.main, r: 8 }}
            />
            <Tooltip cursor={false} content={() => null} />
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

const formatXAxis = (value: Date) => format(value, "MMM");

const AxisTickDate = (props: any) => {
  const {
    x,
    y,
    payload: { value },
    index,
    highlightedIndices,
  } = props;
  const theme = useTheme();
  console.log(value);
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={AXIS_FONT_SIZE}
        dy={16}
        textAnchor="middle"
        fill={
          highlightedIndices?.has(index)
            ? theme.palette.common.white
            : theme.palette.grey[500]
        }
      >
        {value ?? ""}
      </text>
    </g>
  );
};

const AxisClvLabel = (props: any) => {
  const { x, y, index, selectedIndex } = props;
  const theme = useTheme();
  const selected = index === selectedIndex;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize={AXIS_FONT_SIZE * (selected ? 2 : 1.5)}
        dy={24}
        textAnchor="middle"
        fill={selected ? theme.palette.common.white : theme.palette.grey[500]}
        data-clv-index={index}
      >
        5%
      </text>
    </g>
  );
};

const getHighlightedIndices = (
  data: DataPoint[],
  subTypes: SelectionSubType[],
  highlightedIds: Set<string>
): Set<number> => {
  const indices = new Set<number>();
  data.forEach((d, i) => {
    subTypes.forEach((k) => {
      if (highlightedIds.has(highlightedKey(d, k))) {
        indices.add(i);
      }
    });
  });
  return indices;
};

const BarChartContainer = ({
  def: { data, label, barClasses },
  highlightedIds,
  onBarClicked,
  onBarsSelected,
}: {
  def: BarChartDefinition;
  highlightedIds: Set<string>;
  onBarClicked: OnBarClicked;
  onBarsSelected: OnBarsSelected;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const barClass = barClasses![0];
  const hasSelected = highlightedIds.size > 0;
  const subTypes = Object.keys(data[0].ys) as SelectionSubType[];

  const [selectStart, setSelectStart] = useState<ChartSelectStartPoint | null>(
    null
  );
  const [selectEnd, setSelectEnd] = useState<ChartSelectEndPoint | null>(null);
  const [selectX1, selectX2] = useMemo(() => {
    const startValue = selectStart?.xValue;
    const endValue = selectEnd?.xValue;
    if (startValue == null || endValue == null) {
      return [null, null] as const;
    }
    const idxStart = data.findIndex((d) => d.x === startValue);
    const idxEnd = data.findIndex((d) => d.x === endValue);
    return idxStart > idxEnd
      ? ([endValue, startValue] as const)
      : ([startValue, endValue] as const);
  }, [selectStart?.xValue, selectEnd?.xValue, data]);

  const highlightedIndices = useMemo(
    () => getHighlightedIndices(data, subTypes, highlightedIds),
    [data, subTypes, highlightedIds]
  );

  return (
    <Card className={classes.chartCard}>
      <ChartCardHeader title={label} />
      <CardContent className={classes.chartCardContent}>
        <ResponsiveContainer height={165}>
          <BarChart
            syncId="gridchart"
            margin={barChartMargin}
            barCategoryGap={barChartGap}
            data={data}
            onMouseDown={(e) => {
              if (e?.activeLabel != null && e.chartY != null) {
                setSelectStart({ xValue: e.activeLabel, subType: subTypes[0] });
              }
              setSelectEnd(null);
            }}
            onMouseMove={(e) => {
              if (
                selectStart !== null &&
                e?.activeLabel != null &&
                e.chartY != null &&
                e.activeLabel !== selectStart.xValue
              ) {
                setSelectEnd({ xValue: e.activeLabel });
              }
            }}
            onMouseUp={() => {
              if (
                selectStart &&
                selectEnd &&
                selectStart.xValue !== selectEnd.xValue
              ) {
                let idxStart = data.findIndex(
                  (d) => d.x === selectStart.xValue
                );
                let idxEnd = data.findIndex((d) => d.x === selectEnd.xValue);
                if (idxEnd < idxStart) {
                  [idxStart, idxEnd] = [idxEnd, idxStart];
                }
                onBarsSelected(
                  data.slice(idxStart, idxEnd + 1),
                  selectStart.subType
                );
              }
              setSelectStart(null);
              setSelectEnd(null);
            }}
          >
            <YAxis
              width={YAXIS_WIDTH}
              fontSize={AXIS_FONT_SIZE}
              tick={{ fill: theme.palette.grey[500] }}
              hide
            />
            <XAxis
              dataKey="x"
              fontSize={AXIS_FONT_SIZE}
              tick={<AxisTickDate highlightedIndices={highlightedIndices} />}
              tickLine={false}
              interval={0}
              axisLine={false}
            />
            {subTypes.map((subType) => (
              <Bar
                key={subType}
                dataKey={dataKey(subType)}
                background={{ fill: barChartBackground, cursor: "pointer" }}
                onClick={(d) => onBarClicked(d, subType)}
              >
                {data.map((d) => {
                  const selected = highlightedIds.has(
                    highlightedKey(d, subType)
                  );
                  return (
                    <Cell
                      className={clsx(classes.chartBar, classes[barClass], {
                        selected,
                        "not-selected": !selected && hasSelected,
                      })}
                      key={d.id}
                      radius={barChartRadius}
                    />
                  );
                })}
              </Bar>
            ))}
            {selectX1 !== null &&
            selectX2 !== null &&
            selectStart !== null &&
            selectEnd !== null ? (
              <ReferenceArea
                key="selection-ref"
                x1={selectX1}
                x2={selectX2}
                y1={selectStart.subType === subTypes[0] ? 0 : undefined}
                y2={selectStart.subType === subTypes[0] ? undefined : 0}
                stroke="none"
                fill="white"
                fillOpacity={0.3}
              />
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

const subTypeFromHighlightedId = (id: string) => {
  const [, subType] = id.split("|") || [];
  return subType as SelectionSubType | undefined;
};

const subTypesFromHighlightedIds = (ids: string[]) => {
  return ids.reduce((set, id) => {
    const subType = subTypeFromHighlightedId(id);
    subType && set.add(subType);
    return set;
  }, new Set<SelectionSubType>());
};

const ChartCardHeader = ({
  title,
  primary,
  secondary,
}: {
  title: string;
  primary?: boolean;
  secondary?: boolean;
}) => {
  const styles = useStyles();
  return (
    <CardHeader
      classes={{
        title: clsx(styles.chartCardHeaderTitle, {
          [styles.chartCardHeaderTitlePrimary]: primary === true,
          [styles.chartCardHeaderTitleSecondary]: secondary === true,
        }),
      }}
      title={title}
    />
  );
};

const ChartCardHeaderUnder = ({ title }: { title: string }) => {
  const styles = useStyles();
  return (
    <Typography className={styles.chartCardHeaderTitleUnder}>
      {title}
    </Typography>
  );
};

type ChartSelectStartPoint = { xValue: string; subType: SelectionSubType };
type ChartSelectEndPoint = { xValue: string };

const StackedChartContainer = ({
  def: { data, label, labelUnder, barClasses },
  highlightedIds,
  onBarClicked,
  onBarsSelected,
}: {
  def: StackedChartDefinition;
  highlightedIds: Set<string>;
  onBarClicked: OnBarClicked;
  onBarsSelected: OnBarsSelected;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const subTypes = Object.keys(data[0].ys) as SelectionSubType[];
  const [selectStart, setSelectStart] = useState<ChartSelectStartPoint | null>(
    null
  );
  const [selectEnd, setSelectEnd] = useState<ChartSelectEndPoint | null>(null);

  const [selectX1, selectX2] = useMemo(() => {
    const startValue = selectStart?.xValue;
    const endValue = selectEnd?.xValue;
    if (startValue == null || endValue == null) {
      return [null, null] as const;
    }
    const idxStart = data.findIndex((d) => d.x === startValue);
    const idxEnd = data.findIndex((d) => d.x === endValue);
    return idxStart > idxEnd
      ? ([endValue, startValue] as const)
      : ([startValue, endValue] as const);
  }, [selectStart?.xValue, selectEnd?.xValue, data]);

  const hasSelectedSubType = useMemo(
    () => subTypesFromHighlightedIds([...highlightedIds.values()]),
    [highlightedIds]
  );

  const highlightedIndices = useMemo(
    () => getHighlightedIndices(data, subTypes, highlightedIds),
    [data, subTypes, highlightedIds]
  );

  return (
    <Card className={classes.chartCard}>
      <ChartCardHeader title={label} />
      <CardContent className={classes.chartCardContent}>
        <ResponsiveContainer height={392}>
          <BarChart
            syncId="gridchart"
            stackOffset="sign"
            margin={barChartMargin}
            barCategoryGap={barChartGap}
            data={data}
            onMouseDown={(e) => {
              if (e?.activeLabel != null && e.chartY != null) {
                setSelectStart({
                  xValue: e.activeLabel,
                  subType: e.chartY < 185 ? subTypes[0] : subTypes[1],
                });
              }
              setSelectEnd(null);
            }}
            onMouseMove={(e) => {
              if (
                selectStart !== null &&
                e?.activeLabel != null &&
                e.chartY != null &&
                e.activeLabel !== selectStart.xValue
              ) {
                setSelectEnd({ xValue: e.activeLabel });
              }
            }}
            onMouseUp={() => {
              if (
                selectStart &&
                selectEnd &&
                selectStart.xValue !== selectEnd.xValue
              ) {
                let idxStart = data.findIndex(
                  (d) => d.x === selectStart.xValue
                );
                let idxEnd = data.findIndex((d) => d.x === selectEnd.xValue);
                if (idxEnd < idxStart) {
                  [idxStart, idxEnd] = [idxEnd, idxStart];
                }
                onBarsSelected(
                  data.slice(idxStart, idxEnd + 1),
                  selectStart.subType
                );
              }
              setSelectStart(null);
              setSelectEnd(null);
            }}
          >
            <YAxis
              width={YAXIS_WIDTH}
              fontSize={AXIS_FONT_SIZE}
              tick={{ fill: theme.palette.grey[500] }}
              type="number"
              hide
            />
            <XAxis
              dataKey="x"
              fontSize={AXIS_FONT_SIZE}
              tick={<AxisTickDate highlightedIndices={highlightedIndices} />}
              tickLine={false}
              axisLine={false}
              interval={0}
              type="category"
              allowDataOverflow
            />

            {subTypes.map((subType, i) => (
              <Bar
                key={subType}
                dataKey={dataKey(subType)}
                background={
                  i === 0
                    ? {
                        fill: barChartBackground,
                        pointerEvents: "none",
                        radius: barChartRadius,
                      }
                    : undefined
                }
                stackId="stack"
              >
                {data.map((d) => {
                  const selected = highlightedIds.has(
                    highlightedKey(d, subType)
                  );
                  return (
                    <Cell
                      onClick={() => onBarClicked(d, subType)}
                      className={clsx(
                        classes.chartBar,
                        classes[barClasses![i]],
                        {
                          selected: selected,
                          "not-selected":
                            !selected && hasSelectedSubType.size > 0, // hasSelectedSubType.has(subType),
                        }
                      )}
                      key={d.id}
                      radius={[barChartRadius, barChartRadius, 0, 0] as any}
                    />
                  );
                })}
              </Bar>
            ))}

            {/* <ReferenceLine
              y={0}
              strokeWidth={2}
              stroke={theme.palette.background.paper}
            /> */}

            {/* <Tooltip
              cursor={{ fill: "rgba(255, 255, 255, 0.05)" }}
              content={() => null}
            /> */}

            {selectX1 !== null &&
            selectX2 !== null &&
            selectStart !== null &&
            selectEnd !== null ? (
              <ReferenceArea
                key="selection-ref"
                x1={selectX1}
                x2={selectX2}
                y1={selectStart.subType === subTypes[0] ? 0 : undefined}
                y2={selectStart.subType === subTypes[0] ? undefined : 0}
                stroke="none"
                fill="white"
                fillOpacity={0.3}
              />
            ) : null}
          </BarChart>
        </ResponsiveContainer>

        <ChartCardHeaderUnder title={labelUnder} />
      </CardContent>
    </Card>
  );
};

type ChartDefinitionBase = {
  id: string;
  label: string;
};

type BarChartDefinitionBase = ChartDefinitionBase & {
  barClasses: Array<keyof ReturnType<typeof useStyles>>;
  data: DataPoint[];
};

type BarChartDefinition = BarChartDefinitionBase & {
  chartType: "bar";
};

type StackedChartDefinition = BarChartDefinitionBase & {
  chartType: "stacked";
  labelUnder: string;
};

type TrendChartDefinition = ChartDefinitionBase & {
  chartType: "line";
  segments: TrendSegment[];
};

type ChartDefinition =
  | BarChartDefinition
  | StackedChartDefinition
  | TrendChartDefinition;

const highlightedKey = (d: DataPoint, subType: SelectionSubType) =>
  [d.id, subType].join("|");

const isBarChart = (def: ChartDefinition): def is BarChartDefinition =>
  def.chartType === "bar" || def.chartType === "stacked";

const ChartGrid = ({
  charts,
  selectionType,
  ...props
}: {
  selectionType: SelectionType;
  charts: ChartDefinition[];
} & GlobalStateProps) => {
  const [highlightedIds, setHighlightedIds] = useState(new Set<string>());

  const onBarClicked: OnBarClicked = (d, subType) => {
    const newSet = new Set<string>(highlightedIds);
    const key = highlightedKey(d, subType);
    if (highlightedIds.has(key)) {
      newSet.delete(key);
    } else {
      newSet.add(key);
    }
    setHighlightedIds(newSet);
  };

  const onBarsSelected: OnBarsSelected = (ds, subType) => {
    const newSet = new Set<string>(ds.map((d) => highlightedKey(d, subType)));
    setHighlightedIds(newSet);
  };

  const consumerCount = useMemo(() => {
    return charts
      .filter(isBarChart)
      .flatMap((c) => c.data)
      .reduce((acc, d) => {
        let s = acc;
        (Object.keys(d.ys) as SelectionSubType[]).forEach((k) => {
          if (highlightedIds.has(highlightedKey(d, k))) {
            s += Math.abs(d.ys[k]!);
          }
        });
        return s;
      }, 0);
  }, [charts, highlightedIds]);

  const onRequestClearSelection = () => {
    setHighlightedIds(new Set());
  };

  const onRequestOpen = () => {
    const subTypes = subTypesFromHighlightedIds([...highlightedIds.values()]);
    props.onRequestNewSurvey({
      consumerCount,
      selectionType,
      isFollowup: false,
      selectionSubType:
        subTypes.size === 1 ? subTypes.values().next().value : undefined,
    });
  };

  useEffect(() => {
    if (!props.modalState.isOpen) {
      onRequestClearSelection();
    }
  }, [props.modalState.isOpen]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={7} md={8} xs={12}>
          {charts.map((def) =>
            def.chartType === "line" ? (
              <LineChartContainer key={def.id} def={def} />
            ) : def.chartType === "stacked" ? (
              <StackedChartContainer
                key={def.id}
                def={def}
                onBarClicked={onBarClicked}
                onBarsSelected={onBarsSelected}
                highlightedIds={highlightedIds}
              />
            ) : (
              <BarChartContainer
                key={def.id}
                def={def}
                onBarClicked={onBarClicked}
                onBarsSelected={onBarsSelected}
                highlightedIds={highlightedIds}
              />
            )
          )}
        </Grid>
        <Grid item sm={5} md={4} xs={12}>
          <SelectionArea
            consumerCount={consumerCount}
            onRequestOpen={onRequestOpen}
            onRequestClear={onRequestClearSelection}
          />
        </Grid>
      </Grid>
    </>
  );
};

const SelectionArea = ({
  consumerCount,
  onRequestOpen,
  onRequestClear,
}: {
  consumerCount: number;
  onRequestOpen: () => void;
  onRequestClear: () => void;
}) => {
  const styles = useStyles();
  const [afterStyle, setAfterStyle] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (consumerCount > 0) {
      setAfterStyle({
        transform: `translate(0px, 0px)`,
        transition: "all 300ms ease-in-out",
      });
      return;
    }

    const randomTime = () => Math.ceil(1000 + 300 * Math.random());

    let timer: any = null;
    const doAnimate = () => {
      const rnd = () => 10 + 20 * Math.random();
      const x = Math.round((Math.random() - 0.5) * rnd());
      const y = Math.round((Math.random() - 0.5) * rnd());
      const nextDelay = randomTime();
      setAfterStyle({
        transform: `translate(${x}px, ${y}px)`,
        transition: `all ${nextDelay}ms ease-in-out`,
      });
      timer = setTimeout(doAnimate, nextDelay);
    };
    timer = setTimeout(doAnimate, randomTime());

    return () => clearTimeout(timer);
  }, [consumerCount]);

  return (
    <div className={clsx(styles.circle, { "is-active": consumerCount > 0 })}>
      <div
        className={clsx(styles.circle__inner, {
          "is-active": consumerCount === 0,
        })}
      >
        <div className={styles.circle__wrapper}>
          <div className={styles.circle__content}>
            <Typography variant="h1" className={styles.surveyBubbleHeader}>
              Survey
            </Typography>
          </div>
        </div>
      </div>

      <div
        className={clsx(styles.circle__inner, {
          "is-active": consumerCount > 0,
        })}
      >
        <div className={styles.circle__wrapper}>
          <div className={styles.circle__content}>
            <CountUp
              end={consumerCount}
              delay={0}
              duration={0.8}
              useEasing
              separator="."
              preserveValue
            >
              {({ countUpRef }) => (
                <Typography
                  ref={countUpRef as any}
                  variant="h1"
                  className={styles.selectionConsumerCount}
                />
              )}
            </CountUp>
            <Typography className={styles.selectionHintText}>
              Customers
            </Typography>
            <div className={styles.circleAddButton}>
              <AddButton onClick={onRequestOpen} disabled={consumerCount === 0}>
                <AddIcon fontSize="large" />
              </AddButton>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(styles.circleAfter, {
          "is-active": consumerCount > 0,
        })}
        style={afterStyle}
      />
    </div>
  );
};

type ModalContentData = {
  consumerCount: number;
  selectionType: SelectionType;
  selectionSubType: SelectionSubType | undefined;
  isFollowup: boolean;
};

export type ModalContentState =
  | { isOpen: false }
  | { isOpen: true; data: ModalContentData };

type ModalProps = Pick<
  GlobalStateProps,
  "onRequestCloseSurvey" | "onRequestPublishSurvey" | "modalState"
>;

export type ModalContentProps = Pick<
  ModalProps,
  "onRequestCloseSurvey" | "onRequestPublishSurvey"
> & {
  data: ModalContentData;
};

const getApplicableTemplates = (data: ModalContentData): Template[] => {
  const result = templates.filter(
    (template) =>
      template.selectionType === data.selectionType &&
      (template.selectionSubType === undefined ||
        data.selectionSubType === undefined ||
        data.selectionSubType === template.selectionSubType)
  );
  return result;
};

const ModalContent = ({
  data,
  onRequestCloseSurvey,
  onRequestPublishSurvey,
}: ModalContentProps) => {
  const classes = useStyles();
  const selectionTemplates = getApplicableTemplates(data);
  const [value, setValue] = React.useState<TemplateId>(
    selectionTemplates[0].id
  );
  const selectedTemplate = useMemo(
    () => selectionTemplates.find((t) => t.id === value),
    [value, selectionTemplates]
  );

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const onPublish = () => {
    if (!selectedTemplate) {
      return;
    }
    const createdAt = new Date();
    const survey: Survey = {
      id: createdAt.toISOString(),
      createdAt,
      concludesAt: addDays(createdAt, 14),
      template: selectedTemplate,
      answers: makeAnswers(selectedTemplate.questions, 0.8),
      customerCount: data.consumerCount,
      status: SurveyStatus.ongoing,
      metric: { digits: 0 },
    };
    onRequestPublishSurvey(survey);
  };

  const modalTitle =
    data.selectionType === "Loyalty"
      ? data.selectionSubType == null
        ? "LOYALTY"
        : `${data.selectionSubType} LOYALTY`
      : data.selectionSubType === "Lost"
      ? "Lost CUSTOMERS"
      : "Churn RISK";

  return (
    <>
      <DialogTitle id="create-survey-dialog-title">
        {modalTitle} – New survey
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignContent="flex-start">
          <Grid item sm={7} md={7} xs={12}>
            <Typography variant="body1">
              You have selected{" "}
              <strong>{` ${data.consumerCount} customers`}</strong>
              {data.isFollowup ? (
                <>
                  {" "}
                  from <strong>a previous survey</strong>
                </>
              ) : null}
              . Choose a template to proceed.
            </Typography>

            <FormControl
              component="fieldset"
              className={classes.formControlRoot}
            >
              <RadioGroup
                aria-label="templateOption"
                name="templateOption"
                value={value}
                onChange={handleChange}
              >
                {selectionTemplates.map((opt) => (
                  <FormControlLabel
                    key={opt.id}
                    value={opt.id}
                    control={<Radio />}
                    label={opt.label}
                    className={classes.formControlLabelRoot}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item sm={5} md={5} xs={12}>
            <div className={classes.questionsWrapper}>
              {selectedTemplate?.questions.map((q, i) => (
                <div
                  key={`${selectedTemplate.id}-q${i}`}
                  className={classes.textFieldWrapper}
                >
                  <TextField
                    label={`Question ${i + 1}`}
                    defaultValue={q.value}
                    fullWidth
                    multiline
                    variant="outlined"
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ModalActionButton variant="text" onClick={onRequestCloseSurvey}>
          Cancel
        </ModalActionButton>
        <ModalActionButton
          variant="contained"
          color="primary"
          onClick={onPublish}
        >
          Publish
        </ModalActionButton>
      </DialogActions>
    </>
  );
};

const Modal = (props: ModalProps) => {
  const styles = useStyles();
  return (
    <Dialog
      open={props.modalState.isOpen}
      onClose={props.onRequestCloseSurvey}
      maxWidth="lg"
      aria-labelledby="create-survey-dialog-title"
      classes={{
        paper: styles.modalPaper,
      }}
    >
      {props.modalState.isOpen ? (
        <ModalContent
          data={props.modalState.data}
          onRequestCloseSurvey={props.onRequestCloseSurvey}
          onRequestPublishSurvey={props.onRequestPublishSurvey}
        />
      ) : null}
    </Dialog>
  );
};

const AutomationContent = ({
  onRequestClose,
  onRequestAutomationCreated,
}: {
  onRequestClose: () => void;
  onRequestAutomationCreated: () => void;
}) => {
  const styles = useStyles();
  return (
    <>
      <DialogContent className={styles.automationDialogContent}>
        <CheckCircleIcon
          color="primary"
          className={styles.automationLargeIcon}
        />
        <Typography variant="h1" className={styles.automationTitle}>
          Survey published
        </Typography>
        <Typography
          variant="body1"
          className={styles.automationDialogParagraph}
        >
          Automate survey, triggered by same change in consumption?
        </Typography>
        <ModalActionButton
          variant="contained"
          color="primary"
          onClick={onRequestAutomationCreated}
        >
          Automatic survey
        </ModalActionButton>
        <ModalActionButton
          variant="text"
          onClick={onRequestClose}
          className={styles.automationActionCancel}
        >
          Skip
        </ModalActionButton>
      </DialogContent>
    </>
  );
};

const Automation = (props: {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestAutomationCreated: () => void;
}) => {
  const styles = useStyles();
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onRequestClose}
      maxWidth="md"
      aria-labelledby="create-automation-dialog-title"
      classes={{ paper: styles.automationDialogPaper }}
    >
      {props.isOpen ? <AutomationContent {...props} /> : null}
    </Dialog>
  );
};

const loyaltyStacked = adjustData(baseData, "Loyalty", {
  Increased: { addition: 0, modulus: 22 },
  Decreased: { addition: -18, modulus: 22 },
});
const loyaltyTrend = makeTrendSegments(trendData(10, 35, loyaltyStacked));

const loyaltyCharts: ChartDefinition[] = [
  {
    id: "loyalty-trend",
    chartType: "line",
    segments: loyaltyTrend,
    label: "12 Month TREND",
  },
  {
    id: "loyalty-stacked",
    chartType: "stacked",
    data: loyaltyStacked,
    label: "Increased LOYALTY",
    labelUnder: "Decreased LOYALTY",
    barClasses: ["chartBarPrimary", "chartBarSecondary"],
  },
];

const churnRisk = adjustData(baseData, "Churn", {
  Risk: { addition: 5, modulus: 12 },
});
const churnLost = adjustData(baseData, "Churn", {
  Lost: { addition: 9, modulus: 11 },
});
const churnTrend = makeTrendSegments(trendData(5, 15, churnRisk, churnLost));

const churnCharts: ChartDefinition[] = [
  {
    id: "churn-trend",
    chartType: "line",
    segments: churnTrend,
    label: "12 Month TREND",
  },
  {
    id: "churn-risk",
    chartType: "bar",
    data: churnRisk,
    label: "Churn RISK",
    barClasses: ["chartBarPrimary"],
  },
  {
    id: "churn-lost",
    chartType: "bar",
    data: churnLost,
    label: "Lost CUSTOMERS",
    barClasses: ["chartBarSecondary"],
  },
];

const Loyalty = (props: GlobalStateProps) => (
  <ChartGrid selectionType="Loyalty" charts={loyaltyCharts} {...props} />
);

const Churn = (props: GlobalStateProps) => (
  <ChartGrid selectionType="Churn" charts={churnCharts} {...props} />
);

const SurveyStatusCell = ({ survey }: { survey: Survey }) => {
  const classes = useStyles();
  return (
    <div className={classes.tableStatusCell}>
      {survey.status === SurveyStatus.finished ? (
        <div className={classes.statusText}>{survey.status}</div>
      ) : (
        <div className={classes.statusChip}>{survey.status}</div>
      )}
    </div>
  );
};

const digitsWithSign = (digits: number) => {
  if (digits === 0) {
    return "0";
  } else if (digits > 0) {
    return `+${digits}`;
  } else {
    return `${digits}`;
  }
};

const SurveyMetricCell = ({ survey }: { survey: Survey }) => {
  const styles = useStyles();
  const { digits } = survey.metric;
  return (
    <Typography
      className={clsx(styles.reportMetricCellText, {
        [styles.reportMetricCellTextPositive]: digits > 0,
      })}
    >
      {digitsWithSign(digits)}%
    </Typography>
  );
};

const ReportChartGridItem: React.FC = ({ children }) => {
  const styles = useStyles();
  return (
    <Grid item md={9} sm={12} className={styles.reportChartGridItem}>
      {children}
    </Grid>
  );
};

const ReportDataCell = ({
  caption,
  digits,
  suffix,
  color,
  onRequestNewSurvey,
  survey,
  large,
}: {
  caption: string;
  digits: string | number;
  suffix?: string;
  large?: boolean;
  survey: Survey;
} & Pick<TypographyProps, "color"> &
  Partial<Pick<GlobalStateProps, "onRequestNewSurvey">>) => {
  const styles = useStyles();
  return (
    <div className={styles.reportDataCell}>
      <Typography className={styles.reportDataCaption} noWrap>
        {caption}
      </Typography>
      <Typography
        className={clsx(styles.reportDataDigits, {
          [styles.reportDataDigitsLarge]: large,
        })}
        color={color || (large ? "secondary" : "primary")}
        noWrap
      >
        {digits}
        {suffix ? (
          <span
            className={clsx(styles.reportDataDigitsSuffix, {
              [styles.reportDataDigitsSuffixLarge]: large,
            })}
          >
            {suffix}
          </span>
        ) : null}
      </Typography>
      {onRequestNewSurvey && (
        <GreyActionButton
          onClick={() =>
            onRequestNewSurvey({
              consumerCount:
                typeof digits === "number" ? digits : parseInt(digits, 10),
              isFollowup: true,
              selectionSubType: survey.template.selectionSubType,
              selectionType: survey.template.selectionType,
            })
          }
        >
          Survey
        </GreyActionButton>
      )}
    </div>
  );
};

const ReportDetailsTopGrid: React.FC = ({ children }) => {
  const styles = useStyles();
  return (
    <Grid
      container
      justify="space-between"
      className={styles.reportDetailsTopGrid}
    >
      {children}
    </Grid>
  );
};

const ReportChartDescription: React.FC = ({ children }) => {
  const styles = useStyles();
  return <div className={styles.reportChartDescription}>{children}</div>;
};

const ReportChartDescriptionTitle = ({
  children,
  color,
}: Pick<TypographyProps, "children" | "color">) => (
  <Typography variant="body1" color={color}>
    {children}
  </Typography>
);

const ReportDetailsOngoing = ({
  survey,
  onRequestNewSurvey,
}: { survey: Survey } & Pick<GlobalStateProps, "onRequestNewSurvey">) => {
  const theme = useTheme();
  const styles = useStyles();
  const data = [
    { id: "2", x: new Date("2022-10-02T12:00:00.000Z"), y: 10 },
    { id: "3", x: new Date("2022-10-03T12:00:00.000Z"), y: 10 },
    { id: "4", x: new Date("2022-10-04T12:00:00.000Z"), y: 11 },
    { id: "5", x: new Date("2022-10-05T12:00:00.000Z"), y: 12 },
    { id: "6", x: new Date("2022-10-06T12:00:00.000Z"), y: 12 },
    { id: "7", x: new Date("2022-10-07T12:00:00.000Z"), y: 12 },
    { id: "8", x: new Date("2022-10-08T12:00:00.000Z"), y: 14 },
    { id: "9", x: new Date("2022-10-09T12:00:00.000Z"), y: null },
    { id: "10", x: new Date("2022-10-10T12:00:00.000Z"), y: null },
    { id: "11", x: new Date("2022-10-11T12:00:00.000Z"), y: null },
    { id: "12", x: new Date("2022-10-12T12:00:00.000Z"), y: null },
  ];
  const maxValue = data.reduce((m, d) => Math.max(m, d.y ?? 0), 0);
  const answered = Math.floor(survey.customerCount / 1.6);
  const recovered = Math.ceil(0.23 * survey.customerCount);
  const notRecovered = survey.customerCount - recovered;

  return (
    <>
      <Grid container>
        <Grid item sm={7}>
          <AnswersDisplay
            answers={survey.answers}
            consumerCount={survey.customerCount}
            selectionType={survey.template.selectionType}
            selectionSubType={survey.template.selectionSubType}
          />
        </Grid>

        <Grid
          item
          sm={5}
          container
          alignItems="flex-start"
          className={styles.reportDetailsDataCells}
        >
          <ReportDataCell
            caption="Recovered CUSTOMERS"
            digits={recovered}
            survey={survey}
            onRequestNewSurvey={onRequestNewSurvey}
          />
          <ReportDataCell
            caption="Not RECOVERED"
            digits={notRecovered}
            onRequestNewSurvey={onRequestNewSurvey}
            survey={survey}
            color="error"
          />
        </Grid>
      </Grid>

      <ReportDetailsTopGrid>
        <ReportChartGridItem>
          <ResponsiveContainer height={REPORT_CHART_HEIGHT}>
            <LineChart
              margin={reportChartMargins(theme)}
              barCategoryGap={1}
              data={data}
            >
              <YAxis hide domain={[maxValue / 4, 1.25 * maxValue]} />
              <Line
                dataKey="y"
                stroke={theme.palette.secondary.main}
                fill={theme.palette.secondary.main}
                dot={{ stroke: theme.palette.secondary.main, r: 5 }}
                activeDot={{ stroke: theme.palette.secondary.main, r: 8 }}
              />
              <ReferenceLine
                x={0}
                stroke={"transparent"}
                strokeDasharray={"2 2"}
                label={
                  <Label
                    value={`${survey.customerCount} surveyed`}
                    offset={10}
                    position="insideTopLeft"
                    fill={theme.palette.secondary.main}
                    fontSize={AXIS_FONT_SIZE}
                  />
                }
              />
              <ReferenceLine
                x={6}
                stroke={theme.palette.divider}
                strokeDasharray={"2 2"}
                label={
                  <Label
                    value={`${answered} answers`}
                    offset={10}
                    position="insideBottomRight"
                    fill={theme.palette.text.hint}
                    fontSize={AXIS_FONT_SIZE}
                  />
                }
              />
              <ReferenceLine
                x={10}
                stroke={theme.palette.divider}
                strokeDasharray={"2 2"}
                label={
                  <Label
                    offset={10}
                    position="insideBottomRight"
                    fill={theme.palette.text.hint}
                    fontSize={AXIS_FONT_SIZE}
                  >
                    {SurveyStatus.finished.toLocaleUpperCase()}
                  </Label>
                }
              />
              <Tooltip cursor={false} content={() => null} />
            </LineChart>
          </ResponsiveContainer>
          <ReportChartDescription>
            <ReportChartDescriptionTitle color="secondary">
              Loyalty development per day
            </ReportChartDescriptionTitle>
          </ReportChartDescription>
        </ReportChartGridItem>

        <ReportDataCell
          caption={`Increased LOYALTY`}
          digits={survey.metric.digits}
          survey={survey}
          suffix="%"
          large
        />
      </ReportDetailsTopGrid>
    </>
  );
};

const reportChartMargins = (theme: Theme) => ({
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
  top: theme.spacing(3),
});

const ReportDetailsLoyalty = ({
  survey,
  onRequestNewSurvey,
}: {
  survey: Survey;
} & Pick<GlobalStateProps, "onRequestNewSurvey">) => {
  const theme = useTheme();
  const styles = useStyles();
  const data = [
    { id: "2", x: new Date("2022-10-02T12:00:00.000Z"), y: 10 },
    { id: "3", x: new Date("2022-10-03T12:00:00.000Z"), y: 10 },
    { id: "4", x: new Date("2022-10-04T12:00:00.000Z"), y: 11 },
    { id: "5", x: new Date("2022-10-05T12:00:00.000Z"), y: 12 },
    { id: "6", x: new Date("2022-10-06T12:00:00.000Z"), y: 12 },
    { id: "7", x: new Date("2022-10-07T12:00:00.000Z"), y: 12 },
    { id: "8", x: new Date("2022-10-08T12:00:00.000Z"), y: 16 },
    { id: "9", x: new Date("2022-10-09T12:00:00.000Z"), y: 17 },
    { id: "10", x: new Date("2022-10-10T12:00:00.000Z"), y: 17 },
    { id: "11", x: new Date("2022-10-11T12:00:00.000Z"), y: 17 },
    { id: "12", x: new Date("2022-10-12T12:00:00.000Z"), y: 17 },
  ];
  const recovered = Math.ceil(0.23 * survey.customerCount);
  const notRecovered = survey.customerCount - recovered;
  return (
    <>
      <Grid container>
        <Grid item sm={7}>
          <AnswersDisplay
            answers={survey.answers}
            consumerCount={survey.customerCount}
            selectionType={survey.template.selectionType}
            selectionSubType={survey.template.selectionSubType}
          />
        </Grid>

        <Grid
          item
          sm={5}
          container
          alignItems="flex-start"
          className={styles.reportDetailsDataCells}
        >
          <ReportDataCell
            caption="Recovered CUSTOMERS"
            digits={recovered}
            survey={survey}
            onRequestNewSurvey={onRequestNewSurvey}
          />
          <ReportDataCell
            caption="Not RECOVERED"
            digits={notRecovered}
            onRequestNewSurvey={onRequestNewSurvey}
            survey={survey}
            color="error"
          />
        </Grid>
      </Grid>

      <ReportDetailsTopGrid>
        <ReportChartGridItem>
          <ResponsiveContainer height={REPORT_CHART_HEIGHT}>
            <LineChart
              margin={reportChartMargins(theme)}
              barCategoryGap={1}
              data={data}
            >
              <Line
                dataKey="y"
                stroke={theme.palette.secondary.main}
                fill={theme.palette.secondary.main}
                dot={{ stroke: theme.palette.secondary.main, r: 5 }}
                activeDot={{ stroke: theme.palette.secondary.main, r: 8 }}
              />
              <ReferenceLine
                x={0}
                stroke={"transparent"}
                label={
                  <Label
                    value={`${survey.customerCount} surveyed`}
                    offset={10}
                    position="insideTopLeft"
                    fill={theme.palette.text.hint}
                    fontSize={AXIS_FONT_SIZE}
                  />
                }
              />
              <ReferenceLine
                x={10}
                stroke={theme.palette.divider}
                strokeDasharray={"2 2"}
                label={
                  <Label
                    value={`Avg. loyalty increased ${digitsWithSign(
                      survey.metric.digits
                    )}%`}
                    offset={10}
                    position="insideBottomRight"
                    fill={theme.palette.secondary.main}
                    fontSize={AXIS_FONT_SIZE}
                  />
                }
              />
              <Tooltip cursor={false} content={() => null} />
            </LineChart>
          </ResponsiveContainer>
          <ReportChartDescription>
            <ReportChartDescriptionTitle color="secondary">
              Loyalty development per day
            </ReportChartDescriptionTitle>
          </ReportChartDescription>
        </ReportChartGridItem>

        <ReportDataCell
          caption="Increased LOYALTY"
          digits={survey.metric.digits}
          survey={survey}
          suffix="%"
          large
        />
      </ReportDetailsTopGrid>
    </>
  );
};

const ReportDetailsChurn = ({
  survey,
  onRequestNewSurvey,
}: { survey: Survey } & Pick<GlobalStateProps, "onRequestNewSurvey">) => {
  const theme = useTheme();
  const styles = useStyles();
  const mod = (survey.customerCount % 20) - 3;
  const data = [
    { id: "2", x: new Date("2022-10-02T12:00:00.000Z"), y: 0 },
    { id: "3", x: new Date("2022-10-03T12:00:00.000Z"), y: 1 },
    { id: "4", x: new Date("2022-10-04T12:00:00.000Z"), y: 8 },
    { id: "5", x: new Date("2022-10-05T12:00:00.000Z"), y: 22 },
    { id: "6", x: new Date("2022-10-06T12:00:00.000Z"), y: 24 },
    { id: "7", x: new Date("2022-10-07T12:00:00.000Z"), y: 28 },
    { id: "8", x: new Date("2022-10-08T12:00:00.000Z"), y: 32 },
    { id: "9", x: new Date("2022-10-09T12:00:00.000Z"), y: 32 },
    { id: "10", x: new Date("2022-10-10T12:00:00.000Z"), y: 35 },
    { id: "11", x: new Date("2022-10-11T12:00:00.000Z"), y: 36 },
    { id: "12", x: new Date("2022-10-12T12:00:00.000Z"), y: 37 },
  ]
    .map((d) => ({ ...d, y: Math.max(1, Math.ceil(Math.sqrt(d.y + mod))) }))
    .map((d) => (Number.isNaN(d.y) ? { ...d, y: 1 } : d));

  const returned = Math.ceil(
    (survey.metric.digits / 100) * survey.customerCount
  );

  return (
    <>
      <Grid container>
        <Grid item sm={7}>
          <AnswersDisplay
            answers={survey.answers}
            consumerCount={survey.customerCount}
            selectionType={survey.template.selectionType}
            selectionSubType={survey.template.selectionSubType}
          />
        </Grid>

        <Grid
          item
          sm={5}
          container
          alignItems="flex-start"
          className={styles.reportDetailsDataCells}
        >
          <ReportDataCell
            caption="Recovered CUSTOMERS"
            digits={returned}
            survey={survey}
            onRequestNewSurvey={onRequestNewSurvey}
          />
          <ReportDataCell
            caption="Not RECOVERED"
            digits={survey.customerCount - returned}
            onRequestNewSurvey={onRequestNewSurvey}
            survey={survey}
            color="error"
          />
        </Grid>
      </Grid>

      <ReportDetailsTopGrid>
        <ReportChartGridItem>
          <ResponsiveContainer height={REPORT_CHART_HEIGHT}>
            <LineChart
              margin={reportChartMargins(theme)}
              barCategoryGap={1}
              data={data}
            >
              <Line
                dataKey="y"
                stroke={theme.palette.secondary.main}
                fill={theme.palette.secondary.main}
                dot={{ stroke: theme.palette.secondary.main, r: 5 }}
                activeDot={{ stroke: theme.palette.secondary.main, r: 8 }}
              />
              <ReferenceLine
                x={0}
                stroke="transparent"
                label={
                  <Label
                    value={`${survey.customerCount} surveyed`}
                    offset={10}
                    position="insideTopLeft"
                    fill={theme.palette.secondary.main}
                    fontSize={"1rem"}
                  />
                }
              />
              <ReferenceLine
                x={10}
                stroke={theme.palette.divider}
                strokeDasharray={"2 2"}
                label={
                  <Label
                    value={`${returned} returned`}
                    offset={10}
                    position="insideBottomRight"
                    fill={theme.palette.secondary.main}
                    fontSize={AXIS_FONT_SIZE}
                  />
                }
              />
              <Tooltip cursor={false} content={() => null} />
            </LineChart>
          </ResponsiveContainer>
          <ReportChartDescription>
            <ReportChartDescriptionTitle color="secondary">
              {survey.template.selectionSubType === "Risk"
                ? "Churn risk development per day"
                : "Customer return development per day"}
            </ReportChartDescriptionTitle>
          </ReportChartDescription>
        </ReportChartGridItem>

        <ReportDataCell
          caption="Decreased churn RISK"
          digits={survey.metric.digits}
          survey={survey}
          suffix="%"
          large
        />
      </ReportDetailsTopGrid>
    </>
  );
};

function SelectionCellText({ survey }: { survey: Survey }) {
  const theme = useTheme();
  const count = survey.customerCount;
  const ongoing = survey.status === SurveyStatus.ongoing;
  const type = survey.template.selectionType;
  const subType = survey.template.selectionSubType;
  if (type === "Churn" && subType === "Lost") {
    return ongoing ? (
      <>
        {count} -{" "}
        <span style={{ color: theme.palette.secondary.main }}>
          Lost CUSTOMERS
        </span>
      </>
    ) : (
      <span>{count} - Lost CUSTOMERS</span>
    );
  }
  const pre = `${count} customers - `;
  const post = type === "Churn" ? "Churn RISK" : `${subType} ${type}`;
  return ongoing ? (
    <>
      {pre}
      <span style={{ color: theme.palette.secondary.main }}>{post}</span>
    </>
  ) : (
    <span>
      {pre}
      {post}
    </span>
  );
}

const ReportRow = ({
  survey,
  onRequestNewSurvey,
  isLastRow,
}: { survey: Survey; isLastRow: boolean } & Pick<
  GlobalStateProps,
  "onRequestNewSurvey"
>) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <TableRow
        className={clsx(styles.reportRow, {
          ongoing: survey.status === SurveyStatus.ongoing,
          concluded: survey.status === SurveyStatus.finished,
        })}
        onClick={toggleOpen}
      >
        <TableCell
          component="th"
          scope="row"
          className={styles.reportTableCell}
        >
          {survey.status === SurveyStatus.finished ? (
            <Typography color="primary">
              {surveyDateText(survey.createdAt)}
            </Typography>
          ) : (
            <Typography color="secondary">NOW</Typography>
          )}
        </TableCell>
        <TableCell className={styles.reportTableCell}>
          <SelectionCellText survey={survey} />
        </TableCell>
        <TableCell className={styles.reportTableCell}>
          <SurveyStatusCell survey={survey} />
        </TableCell>
        <TableCell align="center" className={styles.reportTableCell}>
          <SurveyMetricCell survey={survey} />
        </TableCell>
        <TableCell align="right">
          <IconButton
            size="medium"
            onClick={toggleOpen}
            className={styles.tableRowToggleButton}
          >
            {isOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon color="primary" />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            style={{ overflow: "hidden" }}
          >
            <div
              className={clsx(styles.reportDetailsWrapper, {
                [styles.reportDetailsWrapperLastRow]: isLastRow,
              })}
            >
              {survey.status === SurveyStatus.ongoing ? (
                <ReportDetailsOngoing
                  survey={survey}
                  onRequestNewSurvey={onRequestNewSurvey}
                />
              ) : survey.template.selectionType === "Churn" ? (
                <ReportDetailsChurn
                  survey={survey}
                  onRequestNewSurvey={onRequestNewSurvey}
                />
              ) : (
                <ReportDetailsLoyalty
                  survey={survey}
                  onRequestNewSurvey={onRequestNewSurvey}
                />
              )}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

type CLVEntry = {
  id: string;
  percentile: { from: number; to: number };
  amount: number;
  ratio: number;
  subEntries?: CLVEntry[];
};

const CLV_AMOUNT_FACTOR = 10;

const CLVEntries: CLVEntry[] = [
  {
    id: "clv0",
    percentile: { from: 0, to: 20 },
    amount: 28195,
    ratio: 73,
    subEntries: [
      {
        id: "clv0-0",
        percentile: { from: 0, to: 5 },
        amount: 60072,
        ratio: 39,
      },
      {
        id: "clv0-1",
        percentile: { from: 5, to: 10 },
        amount: 24182,
        ratio: 16,
      },
      {
        id: "clv0-2",
        percentile: { from: 10, to: 15 },
        amount: 16428,
        ratio: 11,
      },
      {
        id: "clv0-3",
        percentile: { from: 15, to: 20 },
        amount: 12040,
        ratio: 8,
      },
    ],
  },
  { id: "clv1", percentile: { from: 20, to: 40 }, amount: 6696, ratio: 17 },
  { id: "clv2", percentile: { from: 40, to: 60 }, amount: 2684, ratio: 7 },
  { id: "clv3", percentile: { from: 60, to: 80 }, amount: 1039, ratio: 3 },
  { id: "clv4", percentile: { from: 80, to: 100 }, amount: 226, ratio: 1 },
];

const clvEntryOpacity = (i: number) => (i === 0 ? 1 : 1 - (0.2 + i * 0.12));

const range = (n: number) => Array.from({ length: n }, (value, key) => key);

const CLVSidebar = (props: {
  selectedEntry: CLVEntry | undefined;
  onEntryClicked: (entry: CLVEntry) => any;
}) => {
  const styles = useStyles();
  return (
    <div className={styles.clvSidebar}>
      {CLVEntries.map((entry, i) => {
        const selected = entry.id === props.selectedEntry?.id;
        return (
          <button
            key={entry.id}
            className={clsx(styles.clvSidebarEntry, { selected })}
            style={selected ? undefined : { opacity: clvEntryOpacity(i) }}
            onClick={() => props.onEntryClicked(entry)}
          >
            <div>{entry.percentile.to - entry.percentile.from}</div>
          </button>
        );
      })}
    </div>
  );
};

const CLVEntryView = (props: { entry: CLVEntry; previousEntry: CLVEntry }) => {
  const styles = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const amountsEntry = useMemo(() => {
    if (!props.entry.subEntries || typeof selectedIndex !== "number") {
      return props.entry;
    }
    const { subEntries } = props.entry;
    return subEntries[subEntries.length - (1 + selectedIndex)];
  }, [props.entry, selectedIndex]);

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useEffect(() => {
    closePopover();
    setSelectedIndex(null);
  }, [props.entry.id, setSelectedIndex, closePopover]);

  const baseData = [2, 3, 5, 13];
  const steps = 5;
  const rawPoints: TrendDataPoint[] = range(
    (props.entry.percentile.to - props.entry.percentile.from) / steps
  )
    .reverse()
    .map((n, i) => ({
      x: `${props.entry.percentile.from + n * steps}-${
        props.entry.percentile.from + (n + 1) * steps
      }%`,
      y: baseData[i],
    }));

  const { minY, maxY } = rawPoints.reduce(
    (m, d) => ({ maxY: Math.max(m.maxY, d.y), minY: Math.min(m.minY, d.y) }),
    { maxY: rawPoints[0].y, minY: rawPoints[0].y }
  );
  const dataPoints = rawPoints.map((d) => ({ ...d, minY, maxY: maxY + minY }));

  return (
    <div className={styles.clvEntryView}>
      <div className={styles.clvEntryViewGenderFilter}>
        {["All", "Men", "Women"].map((v) => (
          <OutlinedButton
            key={v}
            variant={v === "All" ? "outlined" : "text"}
            color="primary"
            size="small"
          >
            {v}
          </OutlinedButton>
        ))}
      </div>

      <div className={styles.clvEntryRow}>
        <CountUp
          start={props.previousEntry.ratio}
          end={amountsEntry.ratio}
          delay={0}
          duration={0.8}
          useEasing
          separator="."
          suffix="%"
          preserveValue
        >
          {({ countUpRef }) => (
            <Typography
              className={styles.clvEntryAmountPercent}
              ref={countUpRef as any}
            />
          )}
        </CountUp>

        <CountUp
          start={props.previousEntry.amount * CLV_AMOUNT_FACTOR}
          end={amountsEntry.amount * CLV_AMOUNT_FACTOR}
          delay={0}
          duration={0.8}
          useEasing
          separator=" "
          suffix=" kr"
          preserveValue
        >
          {({ countUpRef }) => (
            <Typography
              className={styles.clvEntryAmount}
              ref={countUpRef as any}
            />
          )}
        </CountUp>
      </div>

      <div className={styles.clvEntryViewDetails}>
        <ResponsiveContainer height={300} key={props.entry.id}>
          <ComposedChart
            margin={{ bottom: 8, left: 0, right: 0, top: 0 }}
            barCategoryGap={1}
            data={dataPoints}
            onMouseMove={(state) => {
              if (state.isTooltipActive) {
                setHoveredIndex(state.activeTooltipIndex ?? null);
              } else {
                setHoveredIndex(null);
              }
            }}
            onMouseDown={(state) => {
              const index = state.activeTooltipIndex;
              if (typeof index === "number" && index !== selectedIndex) {
                setSelectedIndex(index);
                const element = document.querySelector(
                  `[data-clv-index="${index}"]`
                );
                setAnchorEl((element as any) ?? null);
              } else {
                setSelectedIndex(null);
                closePopover();
              }
            }}
          >
            <defs>
              <GlowFilterDef variant="primary" />
            </defs>
            <YAxis
              width={YAXIS_WIDTH}
              fontSize={AXIS_FONT_SIZE}
              tick={{ fill: theme.palette.grey[500] }}
              type="number"
              hide
            />
            <XAxis
              dataKey="x"
              fontSize={AXIS_FONT_SIZE}
              tick={<AxisClvLabel selectedIndex={selectedIndex} />}
              interval={0}
              tickLine={false}
              axisLine={false}
            />
            <Bar dataKey="maxY">
              {dataPoints.map((d, i) => (
                <Cell
                  fill={
                    selectedIndex === i
                      ? "rgba(0,0,0,1)"
                      : hoveredIndex === i
                      ? "rgba(0,0,0,0.15)"
                      : "rgba(0,0,0,0)"
                  }
                  radius={20}
                  cursor="pointer"
                />
              ))}
            </Bar>
            <Line
              dataKey="y"
              stroke={theme.palette.primary.main}
              strokeWidth={1}
              fill={theme.palette.primary.main}
              dot={{ stroke: theme.palette.primary.main, r: 5 }}
              activeDot={{ stroke: theme.palette.primary.main, r: 8 }}
            />
            <Tooltip content={() => null} cursor={false} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <Popover
        anchorEl={anchorEl}
        disableEnforceFocus
        open={anchorEl !== null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={closePopover}
        classes={{
          root: styles.clvEntryViewActionsRoot,
          paper: styles.clvEntryViewActionsPaper,
        }}
      >
        <div className={styles.clvEntryViewActions}>
          <GreyActionButton
            variant="contained"
            size="large"
            onClick={closePopover}
          >
            Survey
          </GreyActionButton>
          <GreyActionButton
            variant="contained"
            size="large"
            onClick={closePopover}
          >
            Reward
          </GreyActionButton>
          <GreyActionButton
            variant="contained"
            size="large"
            onClick={closePopover}
          >
            Cashback
          </GreyActionButton>
        </div>
      </Popover>
    </div>
  );
};

type MarketShareGroup = {
  span: "20-29" | "30-39" | "40-49" | "50-59" | "60-69";
  share: number;
  trend: number;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  if (index === 0) {
    return null;
  }

  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN) + 20;

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontFamily={fontFamilies.bold}
      fontSize="2.2rem"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MARKET_SHARE_CHART_HEIGHT = 300;
const MARKET_SHARE_CHART_HEIGHT_FACTOR = 0.85;

const MarketSharePanel = () => {
  const [markerOpacity, setMarkerOpacity] = useState(0);
  const theme = useTheme();
  const styles = useStyles();
  const groups: MarketShareGroup[] = [
    { span: "20-29", share: 16, trend: -3 },
    { span: "30-39", share: 27, trend: 2 },
    { span: "40-49", share: 21, trend: -1 },
    { span: "50-59", share: 24, trend: -7 },
    { span: "60-69", share: 13, trend: -11 },
  ];
  const marketShareData: { x: number; y: number | null; y2: number | null }[] =
    [
      { x: 1, y: 30, y2: null },
      { x: 2, y: 25, y2: null },
      { x: 3, y: 35, y2: null },
      { x: 4, y: 32, y2: null },
      { x: 5, y: 50, y2: null },
      { x: 6, y: 44, y2: 44 },
      { x: 7, y: null, y2: null },
      { x: 8, y: null, y2: null },
      { x: 9, y: null, y2: null },
      { x: 10, y: null, y2: null },
      { x: 11, y: null, y2: 58 },
    ];

  const pieData = [
    { name: "Competitors", value: 79 },
    { name: "You", value: 21 },
  ];

  const onLine1AnimationStart = () => {
    setMarkerOpacity(0);
  };

  const onLine2AnimationEnd = () => {
    setMarkerOpacity(1);
  };

  return (
    <div>
      <Paper className={styles.marketShareHeaderPaper}>
        <ChartCardHeader title="MARKET SHARE" secondary />
        <div className={styles.marketShareGenderFilter}>
          {["All", "Men", "Women"].map((v) => (
            <OutlinedButton
              key={v}
              variant={v === "All" ? "outlined" : "text"}
              color="secondary"
              size="small"
            >
              <span style={{ color: "white" }}>{v}</span>
            </OutlinedButton>
          ))}
        </div>
      </Paper>

      <Grid container alignItems="center">
        <Grid item xs={7}>
          <Typography className={styles.marketShareChartHeader}>
            <span style={{ color: theme.palette.secondary.main }}>
              Forecast - based on
            </span>{" "}
            real time consumption
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={styles.marketShareChartHeader}>
            <Badge
              color="secondary"
              badgeContent="LIVE"
              classes={{
                badge: styles.marketShareBadgeLabel,
              }}
            >
              MARKET SHARE
            </Badge>
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={7}>
          <ResponsiveContainer
            width="100%"
            height={
              MARKET_SHARE_CHART_HEIGHT * MARKET_SHARE_CHART_HEIGHT_FACTOR
            }
          >
            <LineChart
              margin={{ bottom: 32, left: 64, right: 32, top: 32 }}
              data={marketShareData}
            >
              <defs>
                <marker
                  id="triangle"
                  viewBox="0 0 6 6"
                  refX="0"
                  refY="3"
                  markerUnits="strokeWidth"
                  markerWidth="6"
                  markerHeight="6"
                  orient="auto"
                  opacity={markerOpacity}
                >
                  <path
                    d="M 0 0 L 6 3 L 0 6 z"
                    fill={theme.palette.secondary.main}
                  />
                </marker>
              </defs>
              <YAxis
                width={YAXIS_WIDTH}
                fontSize={AXIS_FONT_SIZE}
                type="number"
                domain={[0, 100]}
                hide
              />
              <XAxis
                dataKey="x"
                fontSize={AXIS_FONT_SIZE}
                interval={0}
                tickLine={false}
                axisLine={false}
                tickFormatter={(v) =>
                  v === 1 || v === 11 ? "6m" : v === 6 ? "TODAY" : ""
                }
              />
              <Line
                dataKey="y"
                stroke={theme.palette.secondary.main}
                strokeWidth={1}
                fill={theme.palette.secondary.main}
                dot={{ stroke: theme.palette.secondary.main, r: 5 }}
                activeDot={{ stroke: theme.palette.secondary.main, r: 8 }}
                onAnimationStart={onLine1AnimationStart}
              />
              <Line
                animationBegin={1500}
                dataKey="y2"
                connectNulls
                stroke={theme.palette.secondary.main}
                strokeWidth={3}
                strokeDasharray="2 8"
                fill={theme.palette.secondary.main}
                dot={{ stroke: theme.palette.secondary.main, r: 0 }}
                activeDot={{ stroke: theme.palette.secondary.main, r: 0 }}
                markerEnd="url(#triangle)"
                onAnimationEnd={onLine2AnimationEnd}
              />
              <ReferenceLine
                x={6}
                stroke={theme.palette.divider}
                strokeDasharray={"1 3"}
                // label={
                //   <Label
                //     value={`${survey.customerCount} surveyed`}
                //     offset={10}
                //     position="insideTopLeft"
                //     fill={theme.palette.text.hint}
                //     fontSize={AXIS_FONT_SIZE}
                //   />
                // }
              />
              <Tooltip cursor={false} content={() => null} />
            </LineChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={5}>
          <ResponsiveContainer width="100%" height={MARKET_SHARE_CHART_HEIGHT}>
            <PieChart>
              <Pie
                dataKey="value"
                // isAnimationActive={false}
                data={pieData}
                cx="50%"
                cy="50%"
                // outerRadius={80}
                fill="#000000"
                label={renderCustomizedLabel}
                labelLine={false}
                stroke="none"
              >
                {pieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      entry.name === "Competitors"
                        ? theme.palette.background.paper
                        : theme.palette.secondary.main
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      <div className={styles.marketShareCards}>
        {groups.map((g) => (
          <Card key={g.span} className={styles.marketShareCard}>
            <Typography className={styles.marketShareCardAgeGroup}>
              {g.span}y
            </Typography>
            <Typography className={styles.marketShareCardValue}>
              {g.share}
              <span className={styles.marketShareCardSuffix}>%</span>
            </Typography>
            <Typography className={styles.marketShareCardTrend}>
              {digitsWithSign(g.trend)}%
            </Typography>
          </Card>
        ))}
      </div>
    </div>
  );
};

const CLVPanel = () => {
  const styles = useStyles();
  const [entryState, setEntryState] = useState<{
    current: CLVEntry;
    previous: CLVEntry;
  } | null>(null);

  return (
    <Paper className={styles.clvPaper}>
      <ChartCardHeader title="CUSTOMER LIFETIME VALUE" primary />
      <div className={styles.clvPaperContent}>
        <CLVSidebar
          selectedEntry={entryState?.current}
          onEntryClicked={(entry) =>
            setEntryState((prev) =>
              prev
                ? { current: entry, previous: prev.current }
                : { current: entry, previous: entry }
            )
          }
        />
        {entryState ? (
          <CLVEntryView
            entry={entryState.current}
            previousEntry={entryState.previous}
          />
        ) : (
          <div>
            {CLVEntries.map((entry, i) => (
              <div
                key={entry.id}
                className={styles.clvEntryRow}
                role="button"
                style={{ opacity: clvEntryOpacity(i) }}
              >
                <Typography className={styles.clvEntryAmountPercent}>
                  {entry.ratio}%
                </Typography>
                <Typography className={styles.clvEntryAmount}>
                  {formatCurrency({
                    cents: entry.amount * CLV_AMOUNT_FACTOR * 100,
                    currency: SupportedCurrencyCode.SEK,
                  })}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </div>
    </Paper>
  );
};

const Reports = (props: GlobalStateProps) => {
  return <ReportsTable {...props} />;
};

const ReportsTable = (props: GlobalStateProps) => {
  const classes = useStyles();
  const [{ surveys }] = useSurveyPage();
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell className={classes.reportTableHeaderCell}>
              Created
            </TableCell>
            <TableCell className={classes.reportTableHeaderCell}>
              Selection
            </TableCell>
            <TableCell className={classes.reportTableHeaderCell} align="center">
              Status
            </TableCell>
            <TableCell className={classes.reportTableHeaderCell} align="center">
              Performance
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {surveys.map((survey, i) => (
            <ReportRow
              key={survey.id}
              survey={survey}
              onRequestNewSurvey={props.onRequestNewSurvey}
              isLastRow={i === surveys.length - 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type GlobalStateProps = {
  modalState: ModalContentState;
  onRequestSnackbarMessage: (message: string) => void;
  onRequestNewSurvey: (data: ModalContentData) => void;
  onRequestCloseSurvey: () => void;
  onRequestPublishSurvey: (survey: Survey) => void;
};

const NavButton = ({
  view,
  color,
}: {
  view: View;
  color?: PropTypes.Color;
}) => {
  const styles = useStyles();
  const [state, dispatch] = useSurveyPage();
  const isSelected = view === state.view;
  return (
    <OutlinedButton
      color={color}
      onClick={() => dispatch({ type: "SET_VIEW", view })}
      className={styles.navButton}
      variant={isSelected ? "outlined" : "text"}
      aria-selected={isSelected ? "true" : "false"}
    >
      {isSelected && view === "Market share" ? (
        <Badge
          color="secondary"
          badgeContent="LIVE"
          classes={{
            badge: styles.navigationMarketShareBadgeLabel,
          }}
        >
          {view}
        </Badge>
      ) : (
        view
      )}
    </OutlinedButton>
  );
};

function Page() {
  const styles = useStyles();
  const [{ view, segmentId }, dispatch] = useSurveyPage();
  const [showAutomation, setShowAutomation] = useState(false);
  const [modalState, setModalState] = useState<ModalContentState>({
    isOpen: false,
  });
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
  });

  const handleSnackbarClose = useCallback(() => {
    setSnackbarState((current) => ({ ...current, open: false }));
  }, [setSnackbarState]);

  const onRequestSnackbarMessage = useCallback(
    (message: string) => setSnackbarState({ open: true, message }),
    [setSnackbarState]
  );

  const onRequestPublishSurvey = (survey: Survey) => {
    dispatch({ type: "ADD_SURVEY", survey });
    const doShowAutomation =
      modalState.isOpen && modalState.data.isFollowup !== true;
    onRequestCloseSurvey();
    onRequestSnackbarMessage(
      `Survey pushed to ${survey.customerCount} customers.`
    );
    if (doShowAutomation) {
      onRequestShowAutomation();
    }
  };

  const onRequestCloseSurvey = () => {
    setModalState({ isOpen: false });
  };

  const onRequestNewSurvey = (data: ModalContentData) => {
    setModalState({ isOpen: true, data });
  };

  const onRequestShowAutomation = () => {
    setShowAutomation(true);
  };

  const onRequestCloseAutomation = () => {
    setShowAutomation(false);
  };

  const onRequestAutomationCreated = () => {
    onRequestSnackbarMessage(`Automatic survey schedule created.`);
    onRequestCloseAutomation();
  };

  const globalProps: GlobalStateProps = {
    modalState,
    onRequestCloseSurvey,
    onRequestNewSurvey,
    onRequestPublishSurvey,
    onRequestSnackbarMessage,
  };

  const showAgeFilter = view !== "Market share" && view !== "Reports";
  const maxWidthAgeFilter = view === "CLV";

  return (
    <Container>
      <header className={styles.header}>
        <div className={styles.logoWrapper}>
          <img
            src="/img/again_logo_solid_480.png"
            alt="Again logo"
            className={styles.logo}
          />
        </div>
        <span className={styles.headerBeta}>BETA</span>

        <div className={styles.rightSideNavButtons}>
          {views.filter(isRightNav).map((v) => (
            <NavButton key={v} view={v} color="secondary" />
          ))}
        </div>
      </header>

      <Grid container>
        <Grid
          item
          sm={maxWidthAgeFilter ? 12 : 7}
          md={maxWidthAgeFilter ? 12 : 8}
          xs={12}
          justify="space-between"
          alignItems="flex-end"
          container
        >
          <div className={styles.leftSideNavButtons}>
            {views.filter(isLeftNav).map((v) => (
              <NavButton key={v} view={v} color="primary" />
            ))}
          </div>
          {showAgeFilter ? (
            <div className={styles.ageFilterWrapper}>
              {segments.map((s) => (
                <OutlinedButton
                  key={s.id}
                  onClick={() =>
                    dispatch({ type: "SET_SEGMENT", segmentId: s.id })
                  }
                  variant={s.id === segmentId ? "outlined" : "text"}
                  color="primary"
                  size="small"
                  className={styles.ageFilterButton}
                >
                  {s.label}
                </OutlinedButton>
              ))}
            </div>
          ) : (
            <div> </div>
          )}
        </Grid>
      </Grid>

      {view === "Loyalty" ? (
        <Loyalty {...globalProps} />
      ) : view === "Churn" ? (
        <Churn {...globalProps} />
      ) : view === "Reports" ? (
        <Reports {...globalProps} />
      ) : view === "CLV" ? (
        <CLVPanel />
      ) : view === "Market share" ? (
        <MarketSharePanel />
      ) : null}

      <Modal
        modalState={modalState}
        onRequestCloseSurvey={onRequestCloseSurvey}
        onRequestPublishSurvey={onRequestPublishSurvey}
      />
      <Automation
        isOpen={showAutomation}
        onRequestClose={onRequestCloseAutomation}
        onRequestAutomationCreated={onRequestAutomationCreated}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={snackbarState.message}
        autoHideDuration={4000}
      />
    </Container>
  );
}

interface State {
  view: View;
  segmentId: string;
  surveys: ReadonlyArray<Survey>;
}

type Action =
  | { type: "SET_VIEW"; view: View }
  | { type: "SET_SEGMENT"; segmentId: string }
  | { type: "ADD_SURVEY"; survey: Survey };

const initialState: State = {
  view: "Loyalty",
  segmentId: "All",
  surveys: [
    {
      id: "c98c5349-26c3-4681-8fb9-64d47d7df1f1",
      createdAt: subDays(new Date(), 8),
      customerCount: 680,
      status: SurveyStatus.finished,
      concludesAt: subDays(new Date(), 1),
      template: templates[3],
      answers: makeAnswers(templates[3].questions, 1.2),
      metric: { digits: 48 },
    },
    {
      id: "f10fa354-5665-443e-9a59-9216f21bc805",
      createdAt: subDays(new Date(), 14),
      customerCount: 560,
      status: SurveyStatus.finished,
      concludesAt: subDays(new Date(), 6),
      template: templates[4],
      answers: makeAnswers(templates[4].questions, 2),
      metric: { digits: 12 },
    },
    {
      id: "741db326-855e-4781-a991-9e73238b92af",
      createdAt: subDays(new Date(), 30),
      customerCount: 390,
      status: SurveyStatus.finished,
      concludesAt: subDays(new Date(), 8),
      template: templates[0],
      answers: makeAnswers(templates[0].questions, 1.5),
      metric: { digits: 44 },
    },
  ],
};

const reducer = (state: State, action: Action): State => {
  console.debug("[State]", action);
  const type = action.type;
  switch (type) {
    case "SET_VIEW":
      return { ...state, view: action.view };
    case "SET_SEGMENT":
      return { ...state, segmentId: action.segmentId };
    case "ADD_SURVEY":
      return { ...state, surveys: [action.survey, ...state.surveys] };
    default:
      return state;
  }
};

type SurveyPageContextValue = readonly [
  ReducerState<Reducer<State, Action>>,
  Dispatch<ReducerAction<Reducer<State, Action>>>
];

const SurveyPageContext = React.createContext<SurveyPageContextValue>([
  initialState,
  () => {},
] as any);

const SurveyPageContextProvider: React.FC = ({ children }) => {
  const value = useReducer(reducer, initialState);
  return (
    <SurveyPageContext.Provider value={value}>
      {children}
    </SurveyPageContext.Provider>
  );
};

const useSurveyPage = () => useContext(SurveyPageContext);

export default function SurveyDemoPage() {
  return (
    <SurveyPageContextProvider>
      <Page />
    </SurveyPageContextProvider>
  );
}
