import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import { SystemActivityLog } from "../../../graphql/helperTypes";
import {
  DBSortOrder,
  DisbursementType,
  InputListSort,
} from "../../../graphql/types/global";
import { AllSystemActivityLogs_allSystemActivityLogs } from "../../../graphql/types/AllSystemActivityLogs";
import { asDirection } from "../../../utils/tableUtils";
import { OmitType } from "../../../utils/omitType";
import { OnRequestAddDisbursementDialog } from "../Shared/AddDisbursementDialog/types";
import { DisbursementStatusAddCell } from "../Shared/DisbursementStatusCell";
import { OnRequestDisbursementStatusDialog } from "../Shared/DisbursementStatusDialog/types";
import { TimestampDisplay } from "../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof SystemActivityLog;
  label: string;
  type: "string" | "date" | "disbursement";
}

const headCells: HeadCell[] = [
  { id: "createdAt", type: "date", label: "Timestamp" },
  { id: "category", type: "string", label: "Category" },
  { id: "body", type: "string", label: "Message" },
  {
    id: "disbursementPossibleType",
    type: "disbursement",
    label: "Swish",
  },
];

export type OnRequestSort = (property: keyof SystemActivityLog) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps
  extends OmitType<AllSystemActivityLogs_allSystemActivityLogs> {
  onRequestRefetch: () => any;
  onRequestSort: OnRequestSort;
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  onRequestAddDisbursementDialog: OnRequestAddDisbursementDialog;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof SystemActivityLog) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "disbursementPossibleType" ? "right" : "left"
            }
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
  })
);

const DisbursementCell: React.FC<{
  log: SystemActivityLog;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
  onRequestAddDisbursementDialog: OnRequestAddDisbursementDialog;
}> = ({
  log,
  onRequestDisbursementStatusDialog,
  onRequestAddDisbursementDialog,
}) => {
  const disbursement = log.disbursementConnection;

  const handlRequestAdd = async () => {
    const disbursementType = log.disbursementPossibleType;
    switch (disbursementType) {
      case DisbursementType.onboarding:
        return onRequestAddDisbursementDialog({
          disbursementType,
          userId: log.primaryEntityId!,
        });
      case DisbursementType.referral:
        return onRequestAddDisbursementDialog({
          disbursementType,
          userInvitationId: log.primaryEntityId!,
        });
    }
  };

  const handleRequestStatus = () => {
    if (disbursement) {
      onRequestDisbursementStatusDialog({ disbursement });
    }
  };

  return (
    <DisbursementStatusAddCell
      disbursement={disbursement}
      onRequestDisbursementStatusDialog={handleRequestStatus}
      onRequestAddDisbursementDialog={handlRequestAdd}
    />
  );
};

const SystemActivityLogTable = memo<TableProps>(
  ({
    onRequestPage,
    onRequestPageSize,
    onRequestSort,
    onRequestAddDisbursementDialog,
    onRequestDisbursementStatusDialog,
    pageInfo,
    sorting,
    systemActivityLogs,
  }) => {
    const classes = useStyles();
    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    const renderCell = (cell: HeadCell, log: SystemActivityLog) => {
      switch (cell.type) {
        case "date":
          return <TimestampDisplay timestamp={log.createdAt} />;
        case "disbursement":
          return log.disbursementPossibleType ? (
            <DisbursementCell
              log={log}
              onRequestDisbursementStatusDialog={
                onRequestDisbursementStatusDialog
              }
              onRequestAddDisbursementDialog={onRequestAddDisbursementDialog}
            />
          ) : null;
        default:
          return log[cell.id];
      }
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sorting={sorting}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {systemActivityLogs.map((log: SystemActivityLog) => {
                  return (
                    <TableRow hover tabIndex={-1} key={log.systemActivityLogId}>
                      {headCells.map((cell) => (
                        <TableCell
                          key={`${cell.id}-${log.systemActivityLogId}`}
                          className={clsx({
                            [classes.noWrapCell]: cell.type === "date",
                          })}
                        >
                          {renderCell(cell, log)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
);

export default SystemActivityLogTable;
