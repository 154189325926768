import React from "react";
import { Box } from "@material-ui/core";
import { OmitType } from "../../../utils/omitType";
import { campaignMetrics_campaignMetrics } from "../../../graphql/types/campaignMetrics";
import { SurveyMetrics } from "./CampaignMetrics/SurveyMetrics";
import { AccountsCreatedByCampaignMetrics } from "./CampaignMetrics/AccountsCreatedByCampaignMetrics";
import { AccountsCreatedForCompanyMetrics } from "./CampaignMetrics/AccountsCreatedForCompanyMetrics";
import { companyDisplayFragment } from "../../../graphql/types/companyDisplayFragment";

type Props = {
  metrics: OmitType<campaignMetrics_campaignMetrics>;
  company: companyDisplayFragment | null | undefined;
};

export const CampaignMetricsPaper = ({ company, metrics }: Props) => {
  return (
    <Box>
      <AccountsCreatedForCompanyMetrics
        createdAccountsMetrics={metrics.accountsCreatedForCompany}
        company={company}
      />
      <AccountsCreatedByCampaignMetrics
        createdAccountsMetrics={metrics.accountsCreatedByCampaign}
      />
      <Box my={2}>
        <SurveyMetrics surveyMetrics={metrics.surveys} />
      </Box>
    </Box>
  );
};
