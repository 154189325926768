import React, { useCallback } from "react";
import { Dialog, Snackbar } from "@material-ui/core";
import { AddDisbursementDialogProps } from "./types";
import AddDisbursementDialogContent from "./AddDisbursementDialogContent";

export default function AddDisbursementDialog({
  modalState,
  onRequestClose,
}: AddDisbursementDialogProps) {
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
  });

  const handleSnackbarClose = useCallback(() => {
    setSnackbarState((current) => ({ ...current, open: false }));
  }, [setSnackbarState]);

  const onRequestSnackbarMessage = useCallback(
    (message: string) => setSnackbarState({ open: true, message }),
    [setSnackbarState]
  );

  const onClose = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose]
  );

  return (
    <>
      <Dialog
        open={modalState.isOpen}
        onClose={onClose}
        maxWidth="xs"
        aria-labelledby="add-disbursement-dialog-title"
      >
        {modalState.isOpen ? (
          <AddDisbursementDialogContent
            data={modalState.data}
            onRequestClose={onRequestClose}
            onRequestSnackbarMessage={onRequestSnackbarMessage}
          />
        ) : null}
      </Dialog>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={snackbarState.message}
        autoHideDuration={4000}
      />
    </>
  );
}
