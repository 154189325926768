import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import { DBSortOrder, InputListSort } from "../../../graphql/types/global";
import { asDirection } from "../../../utils/tableUtils";
import { OmitType } from "../../../utils/omitType";
import { Disbursement } from "../../../graphql/helperTypes";
import { OnRequestDisbursementStatusDialog } from "../Shared/DisbursementStatusDialog/types";
import { formatCurrency } from "../../../utils/formatCurrency";
import { DisbursementStatusCell } from "../Shared/DisbursementStatusCell";
import { TimestampDisplay } from "../../../components/TimestampDisplay";
import { AllDisbursements_allDisbursements } from "../../../graphql/types/AllDisbursements";

interface HeadCell {
  id: keyof Disbursement;
  label: string;
  type: "string" | "date" | "userConnection" | "status" | "amount";
}

const headCells: HeadCell[] = [
  { id: "createdAt", type: "date", label: "Created" },
  { id: "userConnection", type: "userConnection", label: "Recipient" },
  { id: "type", type: "string", label: "Type" },
  { id: "message", type: "string", label: "Message" },
  { id: "amount", type: "amount", label: "Amount" },
  { id: "status", type: "status", label: "Status" },
];

export type OnRequestSort = (property: keyof Disbursement) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps extends OmitType<AllDisbursements_allDisbursements> {
  onRequestRefetch: () => any;
  onRequestSort: OnRequestSort;
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  onRequestDisbursementStatusDialog: OnRequestDisbursementStatusDialog;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Disbursement) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={cellAlign(headCell)}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) =>
  cell.type === "amount" ? "right" : "left";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
  })
);

const DisbursementsTable = memo<TableProps>(
  ({
    onRequestPage,
    onRequestPageSize,
    onRequestDisbursementStatusDialog,
    onRequestSort,
    pageInfo,
    sorting,
    disbursements,
  }) => {
    const classes = useStyles();
    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    const renderCell = (cell: HeadCell, row: Disbursement) => {
      switch (cell.type) {
        case "date":
          return <TimestampDisplay timestamp={row[cell.id] as string} />;
        case "userConnection":
          return row.userConnection.profileConnection.displayName;
        case "amount":
          return formatCurrency(row.amount);
        case "status":
          return (
            <DisbursementStatusCell
              disbursement={row}
              onRequestDisbursementStatusDialog={() =>
                onRequestDisbursementStatusDialog({ disbursement: row })
              }
            />
          );
        default:
          return row[cell.id];
      }
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sorting={sorting}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {disbursements.map((disbursement: Disbursement) => {
                  return (
                    <TableRow hover tabIndex={-1} key={disbursement.id}>
                      {headCells.map((cell) => (
                        <TableCell
                          align={cellAlign(cell)}
                          key={`${cell.id}-${disbursement.id}`}
                          className={clsx({
                            [classes.noWrapCell]: cell.type === "date",
                          })}
                        >
                          {renderCell(cell, disbursement)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 50, 100, 500, 1000]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
);

export default DisbursementsTable;
