import React, { useEffect, useRef } from "react";
import { Layout, useFormStyles, useStyles } from "../StartPage/Layout";
import { Button, CircularProgress } from "@material-ui/core";
import { fetchTokens } from "../../utils/auth/fetchTokens";
import { TokenResponse } from "../../utils/auth/types";
import {
  clearEphemeralSignInStorage,
  setStorage,
} from "../../graphql/client/tokenStorage";
import { toTokenStorage } from "../../utils/auth/toTokenStorage";
import { redirectToLogin } from "../../utils/auth/redirectToLogin";

export const AuthCallback = () => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const didFetchTokens = useRef(false);

  // Runs once on page load
  useEffect(() => {
    // The client has been redirected back from the auth endpoint with an auth code
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (typeof code !== "string" || code.trim() === "") {
      setError(`Expected  to find a '?code=' parameter in the URL`);
      return;
    }

    // Make sure we only try to use the auth code once
    if (!didFetchTokens.current) {
      didFetchTokens.current = true;
      fetchTokens({ code })
        .then((tokens: TokenResponse) => {
          setStorage(toTokenStorage(tokens));
          const postLoginRedirect =
            localStorage.getItem("postLoginRedirect") || "/admin/activity";
          clearEphemeralSignInStorage();
          window.location.assign(postLoginRedirect); // TODO: redirect to stored location
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, []);

  const doLogin = async () => {
    setIsRedirecting(true);
    const postLoginRedirect = "/admin/activity";
    await redirectToLogin({ postLoginRedirect });
  };

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.form}>
          <h2>Signing in...</h2>
          {error ? (
            <div>
              <p className={formClasses.errorMessage}>Failed: {error}</p>
              <Button
                color="secondary"
                variant="contained"
                onClick={doLogin}
                disabled={isRedirecting}
              >
                Retry sign in
              </Button>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </Layout>
  );
};
