import React from "react";
import { IconButton, Theme, makeStyles } from "@material-ui/core";
import UploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles<
  Theme,
  { size: number; bgSize: number; bgPos: number }
>((theme) => ({
  logoWrapper: {
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",
    width: (props) => `${props.size}px`,
    height: (props) => `${props.size}px`,
  },
  checkeredBackground: {
    position: "absolute",
    borderRadius: "50%",
    top: 1,
    left: 1,
    right: 1,
    bottom: 1,
    backgroundImage: `linear-gradient(45deg, #aaa 25%, transparent 25%), linear-gradient(-45deg, #aaa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaa 75%), linear-gradient(-45deg, transparent 75%, #aaa 75%)`,
    backgroundSize: (props) => `${props.bgSize}px ${props.bgSize}px`,
    backgroundPosition: (props) =>
      `0 0, 0 ${props.bgPos}, ${props.bgPos} -${props.bgPos}, -${props.bgPos} 0px`,
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: (props) => `${props.size}px`,
    height: (props) => `${props.size}px`,
  },
}));

export const LogoDisplay = ({
  size,
  logoUrl,
}: {
  size: number;
  logoUrl: string;
}) => {
  const styles = useStyles({
    size,
    bgSize: Math.ceil(size / 2),
    bgPos: Math.ceil(size / 4),
  });

  return (
    <div className={styles.logoWrapper}>
      <div className={styles.checkeredBackground} />
      <img src={logoUrl} className={styles.image} alt="company logo" />
    </div>
  );
};

export function CompanyThumbnail({
  onClick,
  logoUrl,
  disabled,
  size = 40,
}: {
  size?: number;
  onClick?: () => any;
  logoUrl: string | null | undefined;
  disabled?: boolean;
}) {
  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return logoUrl ? (
    <IconButton onClick={handleClick} size="small" disabled={disabled}>
      <LogoDisplay size={size} logoUrl={logoUrl} />
    </IconButton>
  ) : (
    <IconButton
      aria-label="upload logo"
      size="small"
      onClick={handleClick}
      disabled={disabled}
    >
      <div
        style={{
          width: size,
          height: size,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <UploadIcon />
      </div>
    </IconButton>
  );
}
