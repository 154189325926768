import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UploadIcon from "@material-ui/icons/CloudUpload";
import { useMutation, useQuery } from "@apollo/client";
import {
  COMPANY_ITEM_DISPLAY,
  COMPLETE_MEDIA_UPLOAD,
  PREPARE_LOGO_MEDIA_UPLOAD,
} from "../../../graphql/queries.gql";
import {
  CompanyItemDisplay,
  CompanyItemDisplayVariables,
  CompanyItemDisplay_company,
} from "../../../graphql/types/CompanyItemDisplay";
import {
  Button,
  CircularProgress,
  DialogActions,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CompanyThumbnail } from "./CompanyThumbnail";
import {
  CompleteMediaUpload,
  CompleteMediaUploadVariables,
} from "../../../graphql/types/CompleteMediaUpload";
import {
  PrepareLogoMediaUpload,
  PrepareLogoMediaUploadVariables,
} from "../../../graphql/types/PrepareLogoMediaUpload";
import NullDataError from "../../../graphql/nullDataError";

interface DialogProps {
  companyId: string | null;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  cardMedia: {
    height: 150,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardMediaHovered: {
    height: 150,
    backgroundColor: theme.palette.grey[200],
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  imageContent: {
    padding: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
  nonHoveredWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

const CompanyItem: React.FC<{
  company: CompanyItemDisplay_company;
  onLogoChanged?: () => any;
}> = ({ company, onLogoChanged }) => {
  const ref = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attempt, setAttempt] = useState(0);

  const [prepareUpload] = useMutation<
    PrepareLogoMediaUpload,
    PrepareLogoMediaUploadVariables
  >(PREPARE_LOGO_MEDIA_UPLOAD);
  const [completeUpload] = useMutation<
    CompleteMediaUpload,
    CompleteMediaUploadVariables
  >(COMPLETE_MEDIA_UPLOAD);

  useEffect(() => {
    // if modal is opened for a company with no logo
    if (!company.logoThumbnailUrl) {
      ref.current?.click();
    }
  }, [company.id, company.logoThumbnailUrl]);

  const onDragEnter = () => setIsHovered(true);
  const onDragLeave = () => setIsHovered(false);

  const onUploadButtonClick = () => {
    if (ref?.current == null) return;
    ref.current.click();
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDropImage = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    startUpload(e.dataTransfer.files);
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    startUpload(e.currentTarget.files);
  };

  const startUpload = async (files: FileList | null) => {
    if (!files || !files[0]) return;

    try {
      setIsLoading(true);
      setIsHovered(false);
      const { size, type } = files[0];
      const result = await prepareUpload({
        variables: {
          input: { contentLength: size, contentMimeType: type },
          companyId: company.id,
        },
      });

      if (!result.data?.prepareLogoMediaUpload) {
        throw new NullDataError("prepareLogoMediaUpload");
      }

      const { uploadId, signedUrl } = result.data.prepareLogoMediaUpload;
      await fetch(signedUrl, {
        method: "PUT",
        body: files[0],
        headers: {
          "Content-Type": type,
          "Content-Length": size.toString(),
        },
      });

      await completeUpload({
        variables: {
          uploadId,
        },
      });
      setErrorMessage("");
      onLogoChanged && onLogoChanged();
    } catch (err: any) {
      console.error(err);
      setErrorMessage(err?.message || "An error occurred");
      // setting attempt will change the input key, which results in a re-render (clears the selected file)
      // so that we can click/drag-drop again to trigger a new upload
      setAttempt((prev) => prev + 1);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.imageContainer}>
      <div
        className={isHovered ? classes.cardMediaHovered : classes.cardMedia}
        onDragOver={onDragOver}
        onDrop={onDropImage}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        {isLoading ? (
          <CircularProgress />
        ) : isHovered ? (
          <>
            <UploadIcon style={{ fontSize: 60, pointerEvents: "none" }} />
            <Typography style={{ pointerEvents: "none" }} variant="subtitle2">
              Drop to upload
            </Typography>
          </>
        ) : (
          <div className={classes.nonHoveredWrapper}>
            <CompanyThumbnail
              key={company.logoUrl}
              logoUrl={company.logoUrl}
              size={120}
              onClick={onUploadButtonClick}
              disabled={isLoading}
            />
            <div>
              <Typography variant="caption" color="textSecondary">
                Click or drag/drop to upload new image
              </Typography>
            </div>
          </div>
        )}
      </div>
      <input
        key={`file-input-${attempt}`}
        type="file"
        id="fileElem"
        accept="image/*"
        onChange={onFileInputChange}
        className={classes.fileInput}
        ref={ref}
      />
      {errorMessage ? (
        <Typography color="error">{errorMessage}</Typography>
      ) : null}
    </div>
  );
};

const UploadCompanyLogoDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  companyId,
}) => {
  const { data, refetch } = useQuery<
    CompanyItemDisplay,
    CompanyItemDisplayVariables
  >(COMPANY_ITEM_DISPLAY, {
    variables: { companyId: companyId! },
    skip: !companyId,
  });

  const company = data?.company;

  const handleOnLogoChanged = () => {
    refetch();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-company-logo-title"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="upload-company-logo-title">
        {company ? `Upload logo: ${company.companyName}` : "Upload logo"}
      </DialogTitle>
      <DialogContent>
        {company ? (
          <CompanyItem company={company} onLogoChanged={handleOnLogoChanged} />
        ) : (
          <CircularProgress />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadCompanyLogoDialog;
