import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import AddIcon from "@material-ui/icons/Add";
import { SystemUser } from "../../../../graphql/helperTypes";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_COMPANY_DEMOS,
  MAP_COMPANY_DEMO_USER,
  UNMAP_COMPANY_DEMO_USER,
  USER_COMPANY_DEMOS,
  USER_FEATURE_FLAGS,
} from "../../../../graphql/queries.gql";
import { allCompanyDemos } from "../../../../graphql/types/allCompanyDemos";
import {
  unmapCompanyDemoUser,
  unmapCompanyDemoUserVariables,
} from "../../../../graphql/types/unmapCompanyDemoUser";
import {
  mapCompanyDemoUser,
  mapCompanyDemoUserVariables,
} from "../../../../graphql/types/mapCompanyDemoUser";
import { Link } from "react-router-dom";
import { SmallCompanyDisplay } from "../../../../components/CompanyDisplay";
import {
  userCompanyDemos,
  userCompanyDemosVariables,
} from "../../../../graphql/types/userCompanyDemos";

export const DemoSection: React.FC<{ user: SystemUser }> = ({ user }) => {
  const { data: userDemos } = useQuery<
    userCompanyDemos,
    userCompanyDemosVariables
  >(USER_COMPANY_DEMOS, {
    variables: { userId: user.id },
  });
  const { data: allDemos } = useQuery<allCompanyDemos>(ALL_COMPANY_DEMOS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  });
  const rows = allDemos?.allCompanyDemos.demos ?? [];

  const [mapMutation, { loading: isMapping }] = useMutation<
    mapCompanyDemoUser,
    mapCompanyDemoUserVariables
  >(MAP_COMPANY_DEMO_USER, {
    refetchQueries: [USER_COMPANY_DEMOS, USER_FEATURE_FLAGS],
  });

  const [unmapMutation, { loading: isUnmapping }] = useMutation<
    unmapCompanyDemoUser,
    unmapCompanyDemoUserVariables
  >(UNMAP_COMPANY_DEMO_USER, {
    refetchQueries: [USER_COMPANY_DEMOS, USER_FEATURE_FLAGS],
  });

  const loading = isMapping || isUnmapping;

  const onMap = async (companyDemoId: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Add this user to this demo?")) {
      await mapMutation({ variables: { companyDemoId, userId: user.id } });
    }
  };

  const onUnmap = async (companyDemoId: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Remove this user from this demo?")) {
      await unmapMutation({ variables: { companyDemoId, userId: user.id } });
    }
  };

  if (!userDemos) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Company</TableCell>
          <TableCell>Demo ID</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          const isMapped = userDemos.userCompanyDemos.some(
            (demo) => demo.id === row.id
          );
          return (
            <TableRow key={row.id}>
              <TableCell>
                <SmallCompanyDisplay company={row.companyConnection} />
              </TableCell>
              <TableCell>
                <Link to={`/admin/demos/${row.id}`}>{row.id}</Link>
              </TableCell>
              <TableCell>
                {isMapped ? "Mapped" : "Not mapped"}
                {isMapped ? (
                  <IconButton
                    onClick={() => onUnmap(row.id)}
                    disabled={loading}
                  >
                    <BackspaceIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => onMap(row.id)} disabled={loading}>
                    <AddIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
