import React from "react";
import {
  makeStyles,
  Box,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TransactionSyncSummaryTable from "./TransactionSyncSummaryTable";
import { SystemUser } from "../../../graphql/helperTypes";
import { TransactionSyncSummaryDateField } from "../../../graphql/types/global";
import { DemoSection } from "./Details/DemoSection";
import { TinkCredentialsTable } from "./Details/TinkCredentialsTable";
import { FeatureFlagSection } from "./Details/FeatureFlagSection";
import { KosmaSection } from "./Details/KosmaSection";
import { CampaignSection } from "./Details/CampaignSection";
import { BrandsSection } from "./Details/BrandsSection";
import { RewardsSection } from "./Details/RewardsSection";
import { EnableBankingSection } from "./Details/EnableBankingSection";
import { DisbursementsSection } from "./Details/DisbursementsSection";
import UserPhoneDisplay from "../Shared/UserPhoneDisplay";

const useToggledSectionStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleWrapper: {
    display: "flex",
    cursor: "pointer",
  },
}));

const ToggledSection: React.FC<{
  label: string;
  initialOpen?: boolean;
}> = ({ children, label, initialOpen }) => {
  const [open, setOpen] = React.useState(initialOpen ?? false);
  const styles = useToggledSectionStyles();
  const toggle = () => setOpen(!open);
  return (
    <section className={styles.root}>
      <div className={styles.titleWrapper} onClick={toggle}>
        <IconButton aria-label="expand" size="small">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Typography variant="h6">{label}</Typography>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box margin={1}>{children}</Box>
      </Collapse>
    </section>
  );
};

const detailStyles = makeStyles((theme) => ({
  userPhoneWrapper: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

const SystemUserDetails: React.FC<{ user: SystemUser }> = ({ user }) => {
  const styles = detailStyles();
  return (
    <>
      <div className={styles.userPhoneWrapper}>
        <Typography variant="subtitle2">Mobile phone number:</Typography>
        <UserPhoneDisplay user={user} />
      </div>
      <ToggledSection label="Disbursements">
        <DisbursementsSection user={user} />
      </ToggledSection>
      <ToggledSection label="Brands">
        <BrandsSection user={user} />
      </ToggledSection>
      <ToggledSection label="Campaigns">
        <CampaignSection user={user} />
      </ToggledSection>
      <ToggledSection label="Rewards">
        <RewardsSection user={user} />
      </ToggledSection>
      <ToggledSection label="Demo">
        <DemoSection user={user} />
      </ToggledSection>
      <ToggledSection label="Feature flags">
        <FeatureFlagSection user={user} />
      </ToggledSection>
      <ToggledSection label="Sync summary by transaction timestamp">
        <TransactionSyncSummaryTable
          options={{
            userId: user.id,
            dateField: TransactionSyncSummaryDateField.transactionTimestamp,
          }}
        />
      </ToggledSection>
      <ToggledSection label="Sync summary by created timestamp">
        <TransactionSyncSummaryTable
          options={{
            userId: user.id,
            dateField: TransactionSyncSummaryDateField.createdAt,
          }}
        />
      </ToggledSection>
      <ToggledSection label="Tink credentials">
        <TinkCredentialsTable user={user} />
      </ToggledSection>
      <ToggledSection label="Kosma">
        <KosmaSection user={user} />
      </ToggledSection>
      <ToggledSection label="Enable Banking">
        <EnableBankingSection user={user} />
      </ToggledSection>
    </>
  );
};

export default SystemUserDetails;
