import React, { useMemo } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const black = "airbnb_cereal_appblack";
const bold = "airbnb_cereal_appbold";
const extraBold = "airbnb_cereal_appextrabold";
const book = "airbnb_cereal_appbook";
const medium = "airbnb_cereal_appmedium";
const light = "airbnb_cereal_applight";

export const fontFamilies = {
  book,
  bold,
  extraBold,
  medium,
  light,
  black,
} as const;

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#FF063B",
    },
    grey: {
      "400": "#EAEAEA",
      "500": "#CFD8DC",
      "600": "#757575",
    },
    againGreen: {
      dark: "#5ced66",
      main: "#80FD89",
      light: "#a6ffac",
    },
  },
});

const surveyTheme = createMuiTheme({
  shape: {
    borderRadius: 24,
  },
  palette: {
    type: "dark",
    divider: "#7C7C7C",
    background: {
      default: "#000000",
      paper: "#292929",
    },
    primary: {
      main: "#80FD89",
    },
    secondary: {
      main: "#8B3ACD",
    },
    // grey: {
    //   "400": "#EAEAEA",
    //   "500": "#CFD8DC",
    //   "600": "#757575",
    // },
    againGreen: {
      main: "#80FD89",
    },
  },
  typography: {
    fontFamily: fontFamilies.book,
  },
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

const AgainThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const { pathname } = useLocation();
  const themeValue = useMemo(
    () => (pathname === "/survey" ? surveyTheme : theme),
    [pathname]
  );

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};

export default AgainThemeProvider;
