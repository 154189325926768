import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  PUSH_SYSTEM_ANNOUNCEMENT_TO_USER,
  SYSTEM_USER,
} from "../../../../graphql/queries.gql";
import { PushUserNotificationDialogContentProps } from "./types";
import UserPhoneDisplay from "../UserPhoneDisplay";
import { SystemUser } from "../../../../graphql/helperTypes";
import {
  systemUser,
  systemUserVariables,
} from "../../../../graphql/types/systemUser";
import { Controller, useForm } from "react-hook-form";
import { NotificationIcon } from "../NotificationButton";
import {
  pushSystemAnnouncementToUser,
  pushSystemAnnouncementToUserVariables,
} from "../../../../graphql/types/pushSystemAnnouncementToUser";

const iconSize = "1em";

const useStyles = makeStyles((theme) =>
  createStyles({
    actionsRow: {
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
    },
    row: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
  })
);

interface FormContentProps extends PushUserNotificationDialogContentProps {
  user: SystemUser;
}

interface FormValues {
  message: string;
}

function FormContent({
  data,
  onRequestClose,
  onRequestSnackbarMessage,
  user,
}: FormContentProps) {
  const styles = useStyles();
  const { handleSubmit, formState, errors, control } = useForm<FormValues>();

  const [pushMutation] = useMutation<
    pushSystemAnnouncementToUser,
    pushSystemAnnouncementToUserVariables
  >(PUSH_SYSTEM_ANNOUNCEMENT_TO_USER);

  const handleSubmitPayout = async (input: FormValues) => {
    await pushMutation({
      variables: { userId: data.userId, message: input.message },
    });
    onRequestSnackbarMessage(`Notification pushed to ${user.displayName}`);
    onRequestClose();
  };

  const isLocked = formState.isSubmitting;

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSubmitPayout)}>
        <DialogTitle id="user-notification-dialog-title">
          Send push-notification to user
        </DialogTitle>
        <DialogContent>
          <div className={styles.row}>
            <Typography
              variant="overline"
              className={styles.header}
              color="textSecondary"
            >
              Recipient
            </Typography>
            <Typography variant="body2">
              <strong>{user.displayName}</strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {user.email}
              <br />
              <UserPhoneDisplay user={user} />
            </Typography>
          </div>

          <Controller
            name="message"
            control={control}
            defaultValue={""}
            rules={{
              required: "Enter a message",
              maxLength: {
                value: 256,
                message: "The max length of a push message is 256 characters",
              },
            }}
            render={({ onBlur, onChange, value, name }) => (
              <TextField
                autoFocus
                label="Message"
                multiline
                fullWidth
                variant="outlined"
                disabled={isLocked}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={onRequestClose}
            color="secondary"
            disabled={isLocked}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="primary"
            startIcon={
              formState.isSubmitting ? (
                <CircularProgress size={iconSize} />
              ) : (
                <NotificationIcon className={styles.icon} />
              )
            }
            disabled={isLocked}
          >
            Push
          </Button>
        </DialogActions>
      </form>
    </Box>
  );
}

export default function PushUserNotificationDialogContent(
  props: PushUserNotificationDialogContentProps
) {
  const userResponse = useQuery<systemUser, systemUserVariables>(SYSTEM_USER, {
    variables: { userId: props.data.userId },
    fetchPolicy: "cache-and-network",
  });
  const user = userResponse?.data?.systemUser;
  return user ? <FormContent {...props} user={user} /> : null;
}
