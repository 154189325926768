import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from "@apollo/client";
import {
  ADD_COMPANY_IMPROVEMENT,
  ALL_COMPANY_IMPROVEMENTS,
  ME,
  REMOVE_COMPANY_IMPROVEMENT,
  UPDATE_COMPANY_IMPROVEMENT,
} from "../../graphql/queries.gql";
import Loading from "../../components/Loading";
import IconButton from "@material-ui/core/IconButton";
import {
  AllCompanyImprovements,
  AllCompanyImprovementsVariables,
  AllCompanyImprovements_allCompanyImprovements_improvements,
} from "../../graphql/types/AllCompanyImprovements";
import {
  UpdateCompanyImprovement,
  UpdateCompanyImprovementVariables,
} from "../../graphql/types/UpdateCompanyImprovement";
import {
  AddCompanyImprovement,
  AddCompanyImprovementVariables,
} from "../../graphql/types/AddCompanyImprovement";
import {
  RemoveCompanyImprovement,
  RemoveCompanyImprovementVariables,
} from "../../graphql/types/RemoveCompanyImprovement";
import { Me } from "../../graphql/types/Me";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(2),
  },
}));

const Improvements: React.FC = () => {
  const { data: companyData, loading } = useQuery<Me>(ME);

  if (loading || !companyData?.me.companyId) {
    return <Loading />;
  }

  return <WithCompany companyId={companyData?.me.companyId} />;
};

const WithCompany: React.FC<{ companyId: string }> = ({ companyId }) => {
  const classes = useStyles();

  const { data, loading, refetch } = useQuery<
    AllCompanyImprovements,
    AllCompanyImprovementsVariables
  >(ALL_COMPANY_IMPROVEMENTS, {
    variables: { companyId },
  });

  const [updateCompanyImprovement, { loading: updateLoading }] = useMutation<
    UpdateCompanyImprovement,
    UpdateCompanyImprovementVariables
  >(UPDATE_COMPANY_IMPROVEMENT);

  const [addCompanyImprovement, { loading: addLoading }] = useMutation<
    AddCompanyImprovement,
    AddCompanyImprovementVariables
  >(ADD_COMPANY_IMPROVEMENT);

  const [removeCompanyImprovement, { loading: removeLoading }] = useMutation<
    RemoveCompanyImprovement,
    RemoveCompanyImprovementVariables
  >(REMOVE_COMPANY_IMPROVEMENT);

  const { handleSubmit, register, errors } = useForm();
  const onSubmit = (input: any) => {
    return addCompanyImprovement({
      variables: {
        input,
      },
    }).then(() => {
      refetch();
    });
  };

  const onRemove = (improvementId: string) => {
    return removeCompanyImprovement({
      variables: {
        id: improvementId,
      },
    }).then(() => {
      refetch();
    });
  };

  const onUpdate = (
    improvement: AllCompanyImprovements_allCompanyImprovements_improvements,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    return updateCompanyImprovement({
      variables: {
        input: {
          companyImprovementId: improvement.companyImprovementId,
          completed: event.target.checked,
        },
      },
    }).then(() => {
      refetch();
    });
  };

  if (loading) return <Loading />;

  return (
    <>
      <Typography variant="h4">Improvements</Typography>
      <Divider />
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.marginBottom}>
          <Grid item xs={12}>
            <Typography variant="h6">Lägg till supporter</Typography>
            <p>
              Fyll i formuläret nedan och klicka på "Lägg till förbättring" för
              att lägga till en förbättring.
            </p>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                size="small"
                className={classes.textField}
                variant="outlined"
                id="improvementText"
                label="Beskriv din förbättring"
                name="improvementText"
                autoFocus
                error={errors.improvement !== undefined}
                helperText={
                  errors.improvement
                    ? "Du måste ange en beskrivning."
                    : undefined
                }
                inputRef={register({
                  required: true,
                })}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={addLoading}
              >
                Lägg till förbättring
              </Button>
            </form>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {data?.allCompanyImprovements.improvements.length === 0 ? (
              <p>
                Inga förbättringar tillagda. Lägg till en förbättring nedan.
              </p>
            ) : (
              <>
                <p>
                  Nedan ser du ditt företags förbättringar. De syns på
                  företagsprofilen i appen.
                </p>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableCell>Förbättring</TableCell>
                      <TableCell>Genomförd</TableCell>
                      <TableCell>Ta bort</TableCell>
                    </TableHead>
                    <TableBody>
                      {data?.allCompanyImprovements.improvements.map(
                        (improvement) => {
                          return (
                            <TableRow
                              key={improvement.companyImprovementId}
                              hover
                            >
                              <TableCell>
                                {improvement.improvementText}
                              </TableCell>
                              <TableCell>
                                <Switch
                                  checked={improvement.completed}
                                  onChange={(e) => onUpdate(improvement, e)}
                                  disabled={updateLoading}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  disabled={removeLoading}
                                  onClick={() =>
                                    onRemove(improvement.companyImprovementId)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Improvements;
