import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { campaignMetrics_campaignMetrics_accountsCreatedByCampaign } from "../../../../graphql/types/campaignMetrics";
import { formatPercentage } from "../../../../utils/formatDecimal";

export type MetricsRow = { title: string } & Pick<
  campaignMetrics_campaignMetrics_accountsCreatedByCampaign,
  "totalCount" | "createdBankConnectionCount" | "syncedTransactionsCount"
>;

type Props = {
  metrics: MetricsRow[];
};

export const AccountsCreatedMetrics = ({ metrics }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Total count</TableCell>
            <TableCell>Created bank connection</TableCell>
            <TableCell>Synced transactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((row, i) => (
            <TableRow key={i}>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.totalCount}</TableCell>
              <TableCell>
                {formatPercentage(
                  row.createdBankConnectionCount,
                  row.totalCount
                )}
              </TableCell>
              <TableCell>
                {formatPercentage(row.syncedTransactionsCount, row.totalCount)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
