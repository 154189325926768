import React from "react";
import {
  InternalRefetchQueriesInclude,
  ApolloError,
  useMutation,
} from "@apollo/client";
import { FETCH_SWISH_PAYOUT } from "../../../../graphql/queries.gql";
import {
  fetchSwishPayout,
  fetchSwishPayoutVariables,
} from "../../../../graphql/types/fetchSwishPayout";
import { SwishPayoutDetailsFragment } from "../../../../graphql/types/SwishPayoutDetailsFragment";
import { dateAndTime } from "../../../../utils/formatDate";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  makeStyles,
  createStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    noWrap: {
      whiteSpace: "nowrap",
    },
    refreshButton: {
      marginRight: theme.spacing(1),
    },
  })
);

interface LogTableProps {
  logs: SwishPayoutDetailsFragment["swishPayoutLogsConnection"];
  swishPayoutId: string;
  swishStatusRefetchQueries?: InternalRefetchQueriesInclude;
  onSwishStatusRefetchError?: (error: ApolloError) => any;
}

export default function SwishLogsTable({
  logs,
  swishStatusRefetchQueries,
  onSwishStatusRefetchError,
  swishPayoutId,
}: LogTableProps) {
  const styles = useStyles();

  const [fetchPayoutMutation, { loading: fetchLoading }] = useMutation<
    fetchSwishPayout,
    fetchSwishPayoutVariables
  >(FETCH_SWISH_PAYOUT, {
    refetchQueries: swishStatusRefetchQueries,
    onError: onSwishStatusRefetchError,
  });

  const onRequestFetch = () => {
    fetchPayoutMutation({ variables: { swishPayoutId } });
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <IconButton
              type="button"
              color="primary"
              disabled={fetchLoading}
              onClick={onRequestFetch}
              size="small"
              className={styles.refreshButton}
            >
              <RefreshIcon />
            </IconButton>
            Created
          </TableCell>
          <TableCell>Status</TableCell>
          <TableCell className={styles.noWrap}>Resp.</TableCell>
          <TableCell className={styles.noWrap}>Error code</TableCell>
          <TableCell className={styles.noWrap}>Error message</TableCell>
          <TableCell className={styles.noWrap}>
            Additional information
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((log) => (
          <TableRow key={log.id}>
            <TableCell component="th" scope="row" className={styles.noWrap}>
              {dateAndTime(log.createdAt)}
            </TableCell>
            <TableCell>{log.payoutStatus}</TableCell>
            <TableCell>{log.responseCode}</TableCell>
            <TableCell>{log.errorCode}</TableCell>
            <TableCell>{log.errorMessage}</TableCell>
            <TableCell>{log.additionalInformation}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
