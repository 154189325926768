import React, { useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import { useQuery, useMutation } from "@apollo/client";
import {
  COMPLETE_MEDIA_UPLOAD,
  ME,
  PREPARE_LOGO_MEDIA_UPLOAD,
} from "../../graphql/queries.gql";
import { Me } from "../../graphql/types/Me";
import {
  PrepareLogoMediaUpload,
  PrepareLogoMediaUploadVariables,
} from "../../graphql/types/PrepareLogoMediaUpload";
import {
  CompleteMediaUpload,
  CompleteMediaUploadVariables,
} from "../../graphql/types/CompleteMediaUpload";
import NullDataError from "../../graphql/nullDataError";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 345,
  },
  cardMedia: {
    height: 140,
    backgroundColor: theme.palette.grey[400],
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardMediaHovered: {
    height: 140,
    backgroundColor: theme.palette.grey[500],
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  imageContent: {
    padding: theme.spacing(2),
  },
  fileInput: {
    display: "none",
  },
}));

const FileUploader = () => {
  const ref = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { data: companyData, refetch: refetchCompanyData } = useQuery<Me>(ME);

  const [prepareUpload] = useMutation<
    PrepareLogoMediaUpload,
    PrepareLogoMediaUploadVariables
  >(PREPARE_LOGO_MEDIA_UPLOAD);
  const [completeUpload] = useMutation<
    CompleteMediaUpload,
    CompleteMediaUploadVariables
  >(COMPLETE_MEDIA_UPLOAD);

  const onDragEnter = () => setIsHovered(true);
  const onDragLeave = () => setIsHovered(false);

  const onUploadButtonClick = () => {
    if (ref?.current == null) return;

    ref.current.click();
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDropImage = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleFiles(e.dataTransfer.files);
  };

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleFiles(e.currentTarget.files);
  };

  const handleFiles = async (files: FileList | null) => {
    if (!files || !files[0]) return;

    try {
      setIsLoading(true);
      const { size, type } = files[0];
      const result = await prepareUpload({
        variables: {
          input: {
            contentLength: size,
            contentMimeType: type,
          },
        },
      });

      if (!result.data?.prepareLogoMediaUpload) {
        throw new NullDataError("prepareLogoMediaUpload");
      }

      const { uploadId, signedUrl } = result.data.prepareLogoMediaUpload;
      await fetch(signedUrl, {
        method: "PUT",
        body: files[0],
        headers: {
          "Content-Type": type,
          "Content-Length": size.toString(),
        },
      });

      await completeUpload({
        variables: {
          uploadId,
        },
      });
      await refetchCompanyData();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Typography variant="h4">Logo</Typography>
      <Divider />
      <div className={classes.imageContainer}>
        <div
          className={isHovered ? classes.cardMediaHovered : classes.cardMedia}
          onDragOver={onDragOver}
          onDrop={onDropImage}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          {isHovered ? (
            <>
              <CloudUploadIcon
                style={{ fontSize: 60, pointerEvents: "none" }}
              />
              <Typography variant="subtitle2" style={{ pointerEvents: "none" }}>
                Drop to upload
              </Typography>
            </>
          ) : (
            companyData?.me.companyConnection?.logoUrl && (
              <img
                src={companyData.me.companyConnection.logoUrl}
                alt="Preview of current company logo"
              />
            )
          )}
        </div>
        <input
          type="file"
          id="fileElem"
          accept="image/*"
          onChange={onFileInputChange}
          className={classes.fileInput}
          ref={ref}
        />
        <div className={classes.imageContent}>
          <Typography gutterBottom variant="h5">
            Logo
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            The logo of your company. Displayed in the mobile app as well as
            here in the admin interface. To upload a new logo, click the button
            or drag-and-drop an image to the surface above.
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={onUploadButtonClick}
              disabled={isLoading}
            >
              Upload new logo
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUploader;
