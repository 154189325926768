import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  makeStyles,
  IconButton,
  Badge,
  Box,
  Collapse,
  Button,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  CachedTinkCredential,
  SystemUser,
} from "../../../../graphql/helperTypes";
import { TinkCredentialStatus } from "../../../../graphql/types/global";
import clsx from "clsx";
import { dateAndTime } from "../../../../utils/formatDate";
import CredentialOperationLogTable from "../CredentialOperationLogTable";
import { useMutation, useQuery } from "@apollo/client";
import {
  FetchUserTinkTransactions,
  FetchUserTinkTransactionsVariables,
} from "../../../../graphql/types/FetchUserTinkTransactions";
import {
  FETCH_USER_TINK_TRANSACTIONS,
  USER_CACHED_TINK_CREDENTIALS,
} from "../../../../graphql/queries.gql";
import { format, subYears } from "date-fns";
import { copyToClipboard } from "../../../../utils/clipboard";
import {
  userCachedTinkCredentials,
  userCachedTinkCredentialsVariables,
} from "../../../../graphql/types/userCachedTinkCredentials";

interface HeadCell {
  id: keyof CachedTinkCredential;
  label: string;
  type: "string" | "date";
}

export const isBadCredential = ({
  lastKnownStatus,
  deletedAt,
}: CachedTinkCredential): boolean => {
  const isDeleted = deletedAt != null;
  switch (lastKnownStatus) {
    case TinkCredentialStatus.AUTHENTICATING:
    case TinkCredentialStatus.CREATED:
    case TinkCredentialStatus.UPDATED:
    case TinkCredentialStatus.UPDATING:
      return false;
    default:
      // if a credential is deleted, we don't consider it bad; otherwise, all
      // statuses except those listed above are considered "bad"
      return !isDeleted;
  }
};

const useRowStyles = makeStyles((theme) => ({
  copyableCell: {
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.grey["200"],
    },
  },
  noWrapCell: {
    whiteSpace: "nowrap",
  },
  deletedCell: {
    color: theme.palette.grey["500"],
  },
}));

const useStyles = makeStyles((theme) => ({
  noWrapCell: {
    whiteSpace: "nowrap",
  },
  deletedCell: {
    color: theme.palette.grey["500"],
  },
  createdAtSortLabel: {
    cursor: "text",
  },
  userIds: {
    marginLeft: theme.spacing(3),
  },
}));

const headCells: HeadCell[] = [
  { id: "providerName", type: "string", label: "Provider" },
  { id: "createdAt", type: "date", label: "Created" },
  { id: "transactionsSyncedAt", type: "date", label: "Last sync attempt" },
  {
    id: "lastTransactionTimestamp",
    type: "date",
    label: "Last transaction timestamp",
  },
  {
    id: "lastKnownStatus",
    type: "string",
    label: "Last known status",
  },
  {
    id: "lastKnownStatusUpdatedAt",
    type: "date",
    label: "Last known status updated",
  },
  { id: "deletedAt", type: "date", label: "Deleted" },
];

const cellCopyValue = (cell: HeadCell, credential: CachedTinkCredential) => {
  return cell.id === "providerName"
    ? credential.tinkCredentialId
    : String(credential[cell.id]);
};

const Row: React.FC<{ credential: CachedTinkCredential }> = ({
  credential,
}) => {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((cell, index) => (
          <TableCell
            key={cell.id}
            className={clsx(classes.copyableCell, {
              [classes.noWrapCell]: cell.type === "date",
              [classes.deletedCell]: !!credential.deletedAt,
            })}
            onClick={() => copyToClipboard(cellCopyValue(cell, credential))}
            title={`Kopiera ${cellCopyValue(cell, credential)}`}
          >
            <Badge
              color="error"
              variant="dot"
              invisible={index !== 0 || !isBadCredential(credential)}
            >
              {cell.id === "deletedAt"
                ? dateAndTime(credential.deletedAt, "No")
                : cell.type === "date"
                ? dateAndTime(credential[cell.id] as any)
                : credential[cell.id]}
            </Badge>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Operations log
              </Typography>
              <CredentialOperationLogTable
                credentialId={credential.tinkCredentialId}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const TinkCredentialsTable = ({ user }: { user: SystemUser }) => {
  const classes = useStyles();
  const [fetchTransactions, { loading: isFetchingTransactions }] = useMutation<
    FetchUserTinkTransactions,
    FetchUserTinkTransactionsVariables
  >(FETCH_USER_TINK_TRANSACTIONS);
  const { data } = useQuery<
    userCachedTinkCredentials,
    userCachedTinkCredentialsVariables
  >(USER_CACHED_TINK_CREDENTIALS, {
    variables: { userId: user.id },
  });
  const credentials = data?.userCachedTinkCredentials;

  if (!credentials) return null;

  const tinkUserId = credentials[0]?.tinkUserId;

  const onFetchTransactions = async () => {
    const defaultDate = format(subYears(new Date(), 1), "yyyy-MM-dd");
    const fromDate =
      prompt(`From (yyyy-MM-dd) or empty`, defaultDate) || undefined;
    try {
      return fetchTransactions({ variables: { userId: user.id, fromDate } });
    } catch (err: any) {
      console.error(err);
      alert(err?.message);
    }
  };

  return (
    <>
      <Typography variant="caption" component="p" className={classes.userIds}>
        <strong>Keycloak ID:</strong> <code>{user.id}</code>
        {tinkUserId && (
          <>
            <strong> Tink ID:</strong> <code>{tinkUserId}</code>
            <Button
              size="small"
              disabled={isFetchingTransactions}
              onClick={onFetchTransactions}
              style={{ marginLeft: 8 }}
            >
              Fetch transactions
            </Button>
          </>
        )}
      </Typography>
      <Table size="small" aria-label="credentials">
        <TableHead>
          <TableRow>
            <TableCell />
            {headCells.map((cell) => (
              <TableCell
                key={cell.id}
                className={classes.noWrapCell}
                sortDirection={cell.id === "createdAt" ? "desc" : undefined}
              >
                {cell.id === "createdAt" ? (
                  <TableSortLabel
                    key={cell.id}
                    className={classes.createdAtSortLabel}
                    active
                    direction="desc"
                  >
                    {cell.label}
                  </TableSortLabel>
                ) : (
                  cell.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {credentials.map((credential) => (
            <Row credential={credential} key={credential.tinkCredentialId} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
