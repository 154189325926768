import React, { useMemo } from "react";
import { getLocalStorage } from "../utils/localStorage";

interface AuthContextState {
  isAuthenticated: boolean;
}

interface AuthContextValue extends AuthContextState {}

const AuthContext = React.createContext<AuthContextValue | undefined>({
  isAuthenticated: false,
});

export const AuthContextProvider: React.FC = ({ children }) => {
  const [state] = React.useState<AuthContextState>({
    isAuthenticated:
      getLocalStorage("accessToken") != null &&
      getLocalStorage("refreshToken") != null,
  });

  const value = useMemo(() => {
    return { ...state };
  }, [state]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const value = React.useContext(AuthContext);
  if (value === undefined) throw new Error("Context value not defined");

  return value;
};
