import { useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { SystemUser } from "../../../graphql/helperTypes";
import { ALL_SYSTEM_USERS } from "../../../graphql/queries.gql";
import {
  AllSystemUsers,
  AllSystemUsersVariables,
} from "../../../graphql/types/AllSystemUsers";
import { DBSortOrder, InputListSort } from "../../../graphql/types/global";
import {
  OnRequestCloseAddDisbursementDialog,
  AddDisbursementDialogData,
  AddDisbursementDialogState,
} from "../Shared/AddDisbursementDialog/types";
import AddDisbursementDialog from "../Shared/AddDisbursementDialog";
import SystemUsersFilter from "./SystemUsersFilter";
import UsersTable, { supportedSortFields } from "./SystemUsersTable";
import {
  PushUserNotificationDialogData,
  PushUserNotificationDialogState,
} from "../Shared/PushUserNotificationDialog/types";
import PushUserNotificationDialog from "../Shared/PushUserNotificationDialog";
import {
  PushUserInvitationDialogData,
  PushUserInvitationDialogState,
} from "../Shared/PushUserInvitationDialog/types";
import PushUserInvitationDialog from "../Shared/PushUserInvitationDialog";
import DisbursementStatusDialog from "../Shared/DisbursementStatusDialog";
import {
  DisbursementStatusDialogData,
  DisbursementStatusDialogState,
} from "../Shared/DisbursementStatusDialog/types";
import { useParams } from "react-router-dom";

const SystemUsersPage: React.FC = () => {
  const params = useParams<{ marketId: string }>();
  const marketId = params.marketId ? parseInt(params.marketId) : null;

  const [addDisbursementModalState, setAddDisbursementModalState] =
    useState<AddDisbursementDialogState>({ isOpen: false });
  const [pushUserNotificationModalState, setPushUserNotificationModalState] =
    useState<PushUserNotificationDialogState>({ isOpen: false });
  const [pushUserInvitationModalState, setPushUserInvitationModalState] =
    useState<PushUserInvitationDialogState>({ isOpen: false });
  const [disbursementStatusModalState, setDisbursementStatusModalState] =
    useState<DisbursementStatusDialogState>({ isOpen: false });

  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearch(search), 500);
    return () => clearTimeout(handler);
  }, [search]);

  const { data, loading, refetch } = useQuery<
    AllSystemUsers,
    AllSystemUsersVariables
  >(ALL_SYSTEM_USERS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      input: {
        filter: { search: debouncedSearch, marketId },
        paging: { page, size: pageSize },
        sort: sorting,
      },
    },
  });

  const handleRequestSort = useCallback(
    (property: keyof SystemUser) => {
      if (!supportedSortFields.has(property)) {
        return;
      }

      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const handleOpenDisbursementStatusModal = useCallback(
    (data: DisbursementStatusDialogData) =>
      setDisbursementStatusModalState({ isOpen: true, data }),
    [setDisbursementStatusModalState]
  );

  const handleCloseDisbursementStatusModal = useCallback(() => {
    setDisbursementStatusModalState({ isOpen: false });
    handleRefetch();
  }, [setDisbursementStatusModalState, handleRefetch]);

  const handleCloseAddDisbursementDialog =
    useCallback<OnRequestCloseAddDisbursementDialog>(
      (createdDisbursement) => {
        setAddDisbursementModalState({ isOpen: false });
        handleRefetch();
        if (createdDisbursement) {
          handleOpenDisbursementStatusModal({
            disbursement: createdDisbursement,
          });
        }
      },
      [
        setAddDisbursementModalState,
        handleRefetch,
        handleOpenDisbursementStatusModal,
      ]
    );

  const handleOpenAddDisbursementModal = useCallback(
    (data: AddDisbursementDialogData) =>
      setAddDisbursementModalState({ isOpen: true, data }),
    [setAddDisbursementModalState]
  );

  const handleClosePushUserNotificationModal = useCallback(() => {
    setPushUserNotificationModalState({ isOpen: false });
  }, [setPushUserNotificationModalState]);

  const handleOpenUserNotificationModal = useCallback(
    (data: PushUserNotificationDialogData) =>
      setPushUserNotificationModalState({ isOpen: true, data }),
    [setPushUserNotificationModalState]
  );

  const handleClosePushUserInvitationModal = useCallback(() => {
    setPushUserInvitationModalState({ isOpen: false });
  }, [setPushUserInvitationModalState]);

  const handleOpenPushUserInvitationModal = useCallback(
    (data: PushUserInvitationDialogData) =>
      setPushUserInvitationModalState({ isOpen: true, data }),
    [setPushUserInvitationModalState]
  );

  const onRequestSetSearch = useCallback(
    (search: string) => {
      setSearch(search);
      setPage(1);
    },
    [setSearch]
  );

  return (
    <div>
      <SystemUsersFilter
        loading={loading}
        search={search}
        onRequestRefetch={handleRefetch}
        onRequestSetSearch={onRequestSetSearch}
      />
      {data ? (
        <UsersTable
          users={data.allSystemUsers.users}
          pageInfo={data.allSystemUsers.pageInfo}
          onRequestSort={handleRequestSort}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          onRequestAddDisbursementDialog={handleOpenAddDisbursementModal}
          onRequestUserNotificationDialog={handleOpenUserNotificationModal}
          onRequestPushUserInvitationDialog={handleOpenPushUserInvitationModal}
          onRequestDisbursementStatusDialog={handleOpenDisbursementStatusModal}
          sorting={sorting}
        />
      ) : null}
      <AddDisbursementDialog
        modalState={addDisbursementModalState}
        onRequestClose={handleCloseAddDisbursementDialog}
      />
      <PushUserNotificationDialog
        modalState={pushUserNotificationModalState}
        onRequestClose={handleClosePushUserNotificationModal}
      />
      <PushUserInvitationDialog
        modalState={pushUserInvitationModalState}
        onRequestClose={handleClosePushUserInvitationModal}
      />
      <DisbursementStatusDialog
        modalState={disbursementStatusModalState}
        onRequestClose={handleCloseDisbursementStatusModal}
        onRequestUserNotificationDialog={handleOpenUserNotificationModal}
      />
    </div>
  );
};

export default SystemUsersPage;
