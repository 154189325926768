import React from "react";
import { Chip, ChipProps } from "@material-ui/core";

export const LabelledChip: React.FC<
  {
    label: string;
    text: React.ReactNode | undefined | null;
  } & Pick<ChipProps, "className" | "color" | "onClick">
> = ({ label, text, className, color, onClick }) => (
  <Chip
    className={className}
    label={
      <>
        <strong>{label}: </strong>
        {text ?? "..."}
      </>
    }
    variant="outlined"
    color={color}
    onClick={onClick}
  />
);
