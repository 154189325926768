import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import { SystemUser } from "../../../../graphql/helperTypes";
import { SmallCompanyDisplay } from "../../../../components/CompanyDisplay";
import { noop } from "../../../../utils/noop";
import { useQuery } from "@apollo/client";
import {
  userVerifiedBrands,
  userVerifiedBrandsVariables,
} from "../../../../graphql/types/userVerifiedBrands";
import { USER_VERIFIED_BRANDS } from "../../../../graphql/queries.gql";

export const BrandsSection: React.FC<{ user: SystemUser }> = ({ user }) => {
  const { data } = useQuery<userVerifiedBrands, userVerifiedBrandsVariables>(
    USER_VERIFIED_BRANDS,
    { variables: { userId: user.id, forceRefresh: true } }
  );
  if (!data) return null;
  const { companies, pageInfo } = data.userVerifiedBrands.companies;
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>Transaction count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies.map((row, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  <SmallCompanyDisplay company={row} />
                </TableCell>
                <TableCell>
                  {row.myConsumptionConnection.transactionTotalCount}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={pageInfo.totalCount}
        rowsPerPage={pageInfo.size}
        page={pageInfo.page - 1}
        onChangePage={noop}
        ActionsComponent={Noop}
        // onChangeRowsPerPage={noop}
      />
    </TableContainer>
  );
};

const Noop: React.FC<any> = () => null;
