import { setContext } from "@apollo/client/link/context";
import { clearLocalStorage, getStorage, setStorage } from "./tokenStorage";
import { refreshTokens } from "../../utils/auth/refreshTokens";
import { toTokenStorage } from "../../utils/auth/toTokenStorage";

let accessTokenPromise: Promise<string> | null = null;

const refreshAccessToken = async (refreshToken: string) => {
  try {
    const tokens = await refreshTokens({ refreshToken });
    setStorage(toTokenStorage(tokens));
    return tokens.access_token;
  } catch (err: any) {
    console.error(err);
    clearLocalStorage();
    window.location.assign("/");
    throw err;
  }
};

const getOrSetRefreshPromise = (refreshToken: string) => {
  if (accessTokenPromise === null) {
    accessTokenPromise = refreshAccessToken(refreshToken);
  }

  return accessTokenPromise;
};

const authLink = setContext(async (_, { headers }) => {
  console.log("[Token]: Executing setContext authlink");

  // get the authentication token from local storage if it exists
  const accessToken = getStorage("accessToken");
  const tokenExpiresAt = getStorage("expiresAt");
  if (accessToken == null || tokenExpiresAt == null) {
    console.log("[Token]: accessToken or tokenExpiresAt was null", {
      accessToken,
      tokenExpiresAt,
    });
    clearLocalStorage();
    return;
  }

  if (new Date(tokenExpiresAt) > new Date()) {
    // happy path - we have a valid token that has not expired
    return { headers: { ...headers, authorization: `Bearer ${accessToken}` } };
  }

  const refreshToken = getStorage("refreshToken");
  if (refreshToken) {
    console.log("[Token]: accessToken expired, refreshing");
    const newToken = await getOrSetRefreshPromise(refreshToken);
    accessTokenPromise = null;

    return { headers: { ...headers, authorization: `Bearer ${newToken}` } };
  }

  // accessToken and refreshToken are expired, delete all tokens and force user to start over
  clearLocalStorage();
  return { headers };
});

export default authLink;
