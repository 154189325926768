import React from "react";
import { Dialog } from "@material-ui/core";
import { DisbursementStatusDialogProps } from "./types";
import DisbursementStatusDialogContent from "./DisbursementStatusDialogContent";

export default function DisbursementStatusDialog({
  modalState,
  onRequestClose,
  onRequestUserNotificationDialog,
}: DisbursementStatusDialogProps) {
  return (
    <Dialog
      open={modalState.isOpen}
      onClose={onRequestClose}
      maxWidth="xl"
      aria-labelledby="disbursement-status-dialog-title"
    >
      {modalState.isOpen ? (
        <DisbursementStatusDialogContent
          data={modalState.data}
          onRequestClose={onRequestClose}
          onRequestUserNotificationDialog={onRequestUserNotificationDialog}
        />
      ) : null}
    </Dialog>
  );
}
