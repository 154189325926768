export const addToSet = <S>(theSet: Set<S>, item: S): Set<S> => {
  theSet.add(item);
  return theSet;
};

export const removeFromSet = <S>(theSet: Set<S>, item: S): Set<S> => {
  theSet.delete(item);
  return theSet;
};

export const toggleInSet = <S>(theSet: Set<S>, item: S): Set<S> =>
  theSet.has(item) ? removeFromSet(theSet, item) : addToSet(theSet, item);

export const asSet = <T, S>(items: T[], mapper: (item: T) => S) =>
  items.reduce((acc, item) => addToSet(acc, mapper(item)), new Set<S>());

export const asMap = <K, V>(items: V[], keyMapper: (item: V) => K) =>
  items.reduce((acc, item) => {
    acc.set(keyMapper(item), item);
    return acc;
  }, new Map<K, V>());
