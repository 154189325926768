import { format, parseISO, isBefore, add } from "date-fns";
import { UserTransactionSyncSummaryPeriod } from "../../../graphql/helperTypes";

type Input = UserTransactionSyncSummaryPeriod;
type EmptyGap = Input["averageGap"];

export type RowData = Input & {
  isFilledPeriodGap: boolean;
};

const emptyGap: EmptyGap = {
  days: null,
  hours: null,
  minutes: null,
  months: null,
  years: null,
};

const withFilledPeriodGaps = (data: Input[] | null | undefined): RowData[] => {
  if (!data || data.length === 0) {
    return [];
  }

  const mapped: RowData[] = data.map((row) => ({
    ...row,
    isFilledPeriodGap: false,
  }));
  const existingPeriods = new Set(data.map((row) => row.period.substr(0, 10)));

  try {
    const firstPeriod = parseISO(data[0].period);
    const lastPeriod = parseISO(data[data.length - 1].period);
    let currentPeriod = firstPeriod;
    let didPush = false;

    while (isBefore(currentPeriod, lastPeriod)) {
      const datePart = format(currentPeriod, "yyyy-MM-dd");
      if (!existingPeriods.has(datePart)) {
        didPush = true;
        mapped.push({
          period: currentPeriod.toISOString(),
          periodLabel: format(currentPeriod, "yyyy LLLL"),
          earliestTransaction: "",
          latestTransaction: "",
          transactionCount: 0,
          isFilledPeriodGap: true,
          averageGap: emptyGap,
          longestGap: emptyGap,
        });
      }
      currentPeriod = add(currentPeriod, { months: 1 });
    }

    if (didPush) {
      return mapped.sort((a, b) =>
        a.period < b.period ? -1 : a.period === b.period ? 0 : 1
      );
    }

    return mapped;
  } catch (err) {
    return mapped;
  }
};

export default withFilledPeriodGaps;
