import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { useMutation, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  COMPANY_ITEM_DISPLAY,
  SET_COMPANIES_VISIBLE,
} from "../../../graphql/queries.gql";
import {
  CompanyItemDisplay,
  CompanyItemDisplayVariables,
} from "../../../graphql/types/CompanyItemDisplay";
import { Typography } from "@material-ui/core";
import { CompanyThumbnail } from "./CompanyThumbnail";
import {
  SetCompaniesVisible,
  SetCompaniesVisibleVariables,
} from "../../../graphql/types/SetCompaniesVisible";

interface DialogProps {
  sourceCompanies: Array<string>;
  open: boolean;
  handleClose: () => void;
}

const CompanyItem: React.FC<{ companyId: string }> = ({ companyId }) => {
  const { data } = useQuery<CompanyItemDisplay, CompanyItemDisplayVariables>(
    COMPANY_ITEM_DISPLAY,
    { variables: { companyId } }
  );

  if (!data || !data.company) {
    return <li>{companyId}</li>;
  }
  return (
    <li>
      <Typography variant="subtitle1">
        <CompanyThumbnail logoUrl={data.company.logoThumbnailUrl} />
        {data.company.companyName}
      </Typography>
    </li>
  );
};

const SetVisibleDialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  sourceCompanies,
}) => {
  const [setCompaniesVisible] = useMutation<
    SetCompaniesVisible,
    SetCompaniesVisibleVariables
  >(SET_COMPANIES_VISIBLE);
  const [visible, setVisible] = React.useState("1");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisible((event.target as HTMLInputElement).value);
  };

  const { handleSubmit } = useForm();

  const onCreateCompany = async (input: any) => {
    return setCompaniesVisible({
      variables: {
        input: {
          visible: visible === "1" ? true : false,
          companyIds: sourceCompanies,
        },
      },
    }).then(() => handleClose());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Visa eller dölj företag</DialogTitle>
      <DialogContent>
        <div>
          <form onSubmit={handleSubmit(onCreateCompany)}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Visible:</FormLabel>
              <RadioGroup
                aria-label="visible"
                name="visible"
                value={visible}
                onChange={handleChange}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <Typography variant="h6">
              {sourceCompanies.length} företag påverkas:
            </Typography>

            <ul>
              {sourceCompanies.map((c) => (
                <CompanyItem key={c} companyId={c} />
              ))}
            </ul>
            <Button type="submit" variant="contained" color="primary">
              Spara
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SetVisibleDialog;
