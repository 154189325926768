import { useLazyQuery } from "@apollo/client";
import {
  createStyles,
  FormLabel,
  makeStyles,
  Paper,
  TableContainer,
  TextareaAutosize,
  Theme,
} from "@material-ui/core";
import React from "react";
import { USER_LOOKUP } from "../../../graphql/queries.gql";
import {
  userLookup,
  userLookupVariables,
  userLookup_systemUser,
} from "../../../graphql/types/userLookup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
      width: "100%",
      padding: theme.spacing(2),
    },
    formLabel: {
      display: "block",
      marginBottom: theme.spacing(1),
    },
    textArea: {
      width: "100%",
    },
    tableHeadCell: {
      padding: theme.spacing(0.5),
      textAlign: "left",
    },
    tableCell: {
      padding: theme.spacing(0.5),
    },
  })
);

type LoadedUser =
  | { id: string; status: "fetching"; user: null }
  | { id: string; status: "failed"; user: null; error: string }
  | { id: string; status: "loaded"; user: userLookup_systemUser };

const UsersLookupPage: React.FC = () => {
  const styles = useStyles();
  const [text, setText] = React.useState("");
  const [parsedIds, setParsedIds] = React.useState<string[]>([]);
  const [loadedUsers, setLoadedUsers] = React.useState<LoadedUser[]>([]);
  const [fetchUser, x] = useLazyQuery<userLookup, userLookupVariables>(
    USER_LOOKUP,
    { fetchPolicy: "cache-first", nextFetchPolicy: "cache-first" }
  );

  React.useEffect(() => {
    const doLoad = async () => {
      setLoadedUsers(
        parsedIds.map((id) => ({
          id,
          status: "fetching",
          user: null,
        }))
      );

      let loadstate: LoadedUser[] = [];
      for (const userId of parsedIds) {
        try {
          const { data } = await fetchUser({ variables: { userId } });
          if (!data?.systemUser) {
            throw new Error("user not found");
          }
          loadstate.push({
            id: userId,
            status: "loaded",
            user: data.systemUser,
          });
        } catch (e: any) {
          loadstate.push({
            id: userId,
            status: "failed",
            user: null,
            error: e?.message || "error",
          });
          continue;
        }
      }
      setLoadedUsers(loadstate);
    };

    doLoad();
  }, [parsedIds, fetchUser]);

  const doParseIds = () => {
    const ids = text
      .split(/[\n,]/)
      .map((id) => id.trim())
      .filter((id) => id.length > 0);
    setParsedIds(ids);
  };

  return (
    <Paper className={styles.paper}>
      <form>
        <FormLabel className={styles.formLabel}>
          User ids (separate by newline or comma)
        </FormLabel>
        <TextareaAutosize
          rowsMin={5}
          className={styles.textArea}
          value={text}
          onChange={(e) => setText(e.target.value)}
          autoFocus
          onBlur={doParseIds}
        />
      </form>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th className={styles.tableHeadCell}>User id</th>
              <th className={styles.tableHeadCell}>Name</th>
              <th className={styles.tableHeadCell}>Email</th>
              <th className={styles.tableHeadCell}>Phone</th>
            </tr>
          </thead>
          <tbody>
            {loadedUsers.map((user, i) => (
              <tr key={user.id}>
                <td className={styles.tableCell}>{user.id}</td>
                {user.status === "fetching" && (
                  <td className={styles.tableCell} colSpan={3}>
                    Loading...
                  </td>
                )}
                {user.status === "failed" && (
                  <td className={styles.tableCell} colSpan={3}>
                    {user.error}
                  </td>
                )}
                {user.status === "loaded" && (
                  <>
                    <td className={styles.tableCell}>{user.user.fullName}</td>
                    <td className={styles.tableCell}>{user.user.email}</td>
                    <td className={styles.tableCell}>
                      {user.user.mobilePhoneNumber}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Paper>
  );
};

export default UsersLookupPage;
