import { setContext } from "@apollo/client/link/context";
import { logError } from "../../utils/logger";

const withApiClient = (headers: any) => {
  try {
    const platform = "frontend";
    const version =
      process.env.REACT_APP_RELEASE?.substring(0, 7) || "development";
    return {
      headers: {
        ...headers,
        "again-api-client-platform": platform,
        "again-api-client-version": version,
      },
    };
  } catch (err: any) {
    logError(err);
    return { headers };
  }
};

const apiClientMiddleware = setContext(async (operation, { headers }) => {
  return withApiClient(headers);
});

export default apiClientMiddleware;
