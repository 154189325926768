import React, { useCallback } from "react";
import { Dialog, Snackbar } from "@material-ui/core";
import { PushUserNotificationDialogProps } from "./types";
import PushUserNotificationDialogContent from "./PushUserNotificationDialogContent";

export default function PushUserNotificationDialog({
  modalState,
  onRequestClose,
}: PushUserNotificationDialogProps) {
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
  });

  const handleSnackbarClose = useCallback(() => {
    setSnackbarState((current) => ({ ...current, open: false }));
  }, [setSnackbarState]);

  const onRequestSnackbarMessage = useCallback(
    (message: string) => setSnackbarState({ open: true, message }),
    [setSnackbarState]
  );

  return (
    <>
      <Dialog
        open={modalState.isOpen}
        onClose={onRequestClose}
        maxWidth="xl"
        aria-labelledby="user-notification-dialog-title"
      >
        {modalState.isOpen ? (
          <PushUserNotificationDialogContent
            data={modalState.data}
            onRequestClose={onRequestClose}
            onRequestSnackbarMessage={onRequestSnackbarMessage}
          />
        ) : null}
      </Dialog>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={snackbarState.message}
        autoHideDuration={4000}
      />
    </>
  );
}
