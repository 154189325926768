import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  DBSortOrder,
  FeatureFlagType,
  InputListSort,
} from "../../../graphql/types/global";
import { asDirection } from "../../../utils/tableUtils";
import {
  AssignedBatchUser,
  DemoSurveyBatch,
} from "../../../graphql/helperTypes";
import { OmitType } from "../../../utils/omitType";
import { assignedSurveyBatchUsers_assignedSurveyBatchUsers } from "../../../graphql/types/assignedSurveyBatchUsers";
import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import {
  assignDemoUsersToDemoBatch,
  assignDemoUsersToDemoBatchVariables,
} from "../../../graphql/types/assignDemoUsersToDemoBatch";
import {
  ASSIGN_DEMO_USERS_TO_DEMOBATCH,
  ASSIGN_REAL_USERS_TO_DEMOBATCH,
  RESET_USER_SWIPE_BATCH_ANSWER_STATE,
} from "../../../graphql/queries.gql";
import {
  assignRealUsersToDemoBatch,
  assignRealUsersToDemoBatchVariables,
} from "../../../graphql/types/assignRealUsersToDemoBatch";
import {
  resetUserSwipeBatchAnswerState,
  resetUserSwipeBatchAnswerStateVariables,
} from "../../../graphql/types/resetUserSwipeBatchAnswerState";
import { TimestampDisplay } from "../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof AssignedBatchUser;
  label: string;
  type: "string" | "date" | "userConnection";
}

const headCells: HeadCell[] = [
  { id: "userConnection", type: "userConnection", label: "User" },
  { id: "createdAt", type: "date", label: "Assigned at" },
  { id: "answerStatus", type: "string", label: "Status" },
];

export type OnRequestSort = (property: keyof AssignedBatchUser) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

type EnhancedTableProps = Pick<
  TableProps,
  "onRequestRefetch" | "onRequestSort" | "sorting" | "isFetching"
> & {
  classes: ReturnType<typeof useStyles>;
};

interface TableProps
  extends OmitType<assignedSurveyBatchUsers_assignedSurveyBatchUsers> {
  batch: DemoSurveyBatch;
  companyDemoId: string;
  onRequestRefetch: () => any;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
  isFetching: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort, isFetching } = props;
  const createSortHandler =
    (property: keyof AssignedBatchUser) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={cellAlign(headCell)}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            {i === 0 && (
              <IconButton
                type="button"
                color="primary"
                disabled={isFetching}
                onClick={props.onRequestRefetch}
                size="small"
                className={classes.refreshButton}
              >
                {isFetching ? <CircularProgress size="24px" /> : <Refresh />}
              </IconButton>
            )}
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) => "left" as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: theme.spacing(2),
    },
    table: {
      marginTop: theme.spacing(1),
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    companyRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: `${theme.spacing(1)}px`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    userChip: {
      marginRight: theme.spacing(1),
    },
    refreshButton: {
      marginRight: theme.spacing(1),
    },
  })
);

const AssignedBatchUsersTable = memo<TableProps>(
  ({
    onRequestSort,
    sorting,
    pageInfo,
    users,
    batch,
    companyDemoId,
    onRequestRefetch,
    isFetching,
  }) => {
    const classes = useStyles();

    const [assignDemoUsersMutation, { loading: assignDemoUsersLoading }] =
      useMutation<
        assignDemoUsersToDemoBatch,
        assignDemoUsersToDemoBatchVariables
      >(ASSIGN_DEMO_USERS_TO_DEMOBATCH);

    const [assignRealUsersMutation, { loading: assignRealUsersLoading }] =
      useMutation<
        assignRealUsersToDemoBatch,
        assignRealUsersToDemoBatchVariables
      >(ASSIGN_REAL_USERS_TO_DEMOBATCH);

    const [resetAnswerStateMutation] = useMutation<
      resetUserSwipeBatchAnswerState,
      resetUserSwipeBatchAnswerStateVariables
    >(RESET_USER_SWIPE_BATCH_ANSWER_STATE);

    const renderCell = (cell: HeadCell, row: AssignedBatchUser) => {
      switch (cell.type) {
        case "userConnection":
          const hasDemoFlag = row.userConnection.featureFlagsConnection.some(
            (flag) =>
              flag.featureFlagType === FeatureFlagType.is_company_demo_app_user
          );
          const chip = hasDemoFlag ? (
            <Chip
              label="demo user"
              variant="outlined"
              color="primary"
              className={classes.userChip}
            />
          ) : (
            <Chip
              label="real user"
              variant="outlined"
              color="secondary"
              className={classes.userChip}
            />
          );

          return (
            <span>
              {chip}
              {row.userConnection.displayName}
            </span>
          );
        case "date":
          return <TimestampDisplay timestamp={row[cell.id] as string} />;
        default:
          return row[cell.id];
      }
    };

    const onAssignDemoUsers = async () => {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          `Assign all current demo users to batch ${batch.id} "${batch.title}"?`
        )
      ) {
        return;
      }

      try {
        await assignDemoUsersMutation({
          variables: { batchNumber: batch.id, companyDemoId },
        });
      } finally {
        onRequestRefetch();
      }
    };

    const onAssignRealUsers = async () => {
      const countInput = prompt(
        `How many real users should be assigned to batch ${batch.id} "${batch.title}"?`,
        "40"
      );

      if (!countInput) {
        return;
      }

      const userCountLimit = parseInt(countInput ?? "0", 10);
      if (
        Number.isNaN(userCountLimit) ||
        userCountLimit <= 0 ||
        userCountLimit > 100
      ) {
        alert("Invalid input, enter a number between 1 and 100");
        return;
      }

      try {
        await assignRealUsersMutation({
          variables: { batchNumber: batch.id, companyDemoId, userCountLimit },
        });
      } finally {
        onRequestRefetch();
      }
    };

    const onResetAnswerState = async (userId: string) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          `Reset the answer state for this user? They will be able to answer all cards from the beginning, but will not receive new notifications.`
        )
      ) {
        try {
          await resetAnswerStateMutation({
            variables: { batchNumber: batch.id, userId },
          });
        } catch (error: any) {
          console.error(error);
          alert(error?.message);
        }
      }
    };

    return (
      <div className={classes.root}>
        <Typography variant="h6">Assigned users</Typography>
        <div className={classes.actions}>
          <Button
            onClick={onAssignDemoUsers}
            disabled={assignDemoUsersLoading}
            type="button"
            color="primary"
            variant="outlined"
          >
            Assign all demo users
          </Button>
          <Button
            onClick={onAssignRealUsers}
            disabled={assignRealUsersLoading}
            type="button"
            color="secondary"
            variant="outlined"
          >
            Assign real users
          </Button>
        </div>

        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              sorting={sorting}
              isFetching={isFetching}
              onRequestSort={onRequestSort}
              onRequestRefetch={onRequestRefetch}
            />
            <TableBody>
              {users.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {headCells.map((cell) => (
                      <TableCell
                        align={cellAlign(cell)}
                        key={`${cell.id}-${row.id}`}
                      >
                        {renderCell(cell, row)}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        startIcon={<RotateLeftIcon />}
                        onClick={() => onResetAnswerState(row.id)}
                      >
                        Reset
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={[pageInfo.size]}
            count={pageInfo.totalCount}
            rowsPerPage={pageInfo.size}
            page={pageInfo.page - 1}
            onChangePage={() => {}}
            onChangeRowsPerPage={() => {}}
          />
        </TableContainer>
      </div>
    );
  }
);

export default AssignedBatchUsersTable;
