import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Layout, StyledButton, useStyles } from "./Layout";
import { redirectToLogin } from "../../utils/auth/redirectToLogin";
import { useAuthContext } from "../../contexts/AuthContext";

export const SignInPage: React.FC = () => {
  const auth = useAuthContext();
  const location = useLocation();
  const classes = useStyles();
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const doLogin = async () => {
    setIsRedirecting(true);
    let { from } = (location.state as any) || {
      from: { pathname: "/admin/activity" },
    };
    await redirectToLogin({ postLoginRedirect: from.pathname });
  };

  if (auth.isAuthenticated) {
    console.info("User is logged in, redirecting to home route");
    return <Redirect to="/admin/activity" />;
  }

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.form}>
          <StyledButton
            type="button"
            variant="contained"
            className={classes.submit}
            disabled={isRedirecting}
            onClick={doLogin}
          >
            Sign in
          </StyledButton>
        </div>
      </div>
    </Layout>
  );
};
