export const truncateText = (
  input: string | number | null | undefined,
  maxLength: number
) => {
  if (!input) {
    return "";
  }

  if (typeof input === "number") {
    return input.toString();
  }

  if (input.length > maxLength) {
    return input.substring(0, maxLength - 1) + "…";
  }

  return input;
};
