import {
  type DocumentNode,
  type OperationVariables,
  type QueryHookOptions,
  type TypedDocumentNode,
  useQuery,
} from "@apollo/client";

export default function useQueryWithPreviousData<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  path: keyof TData,
  options?: QueryHookOptions<TData, TVariables>
) {
  const defaultOptions: typeof options = {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
  };

  const result = useQuery<TData, TVariables>(
    query,
    options ? { ...defaultOptions, ...options } : defaultOptions
  );

  const data = result.data?.[path];
  const previousData = result.previousData?.[path];

  return [data ?? previousData, result] as const;
}
