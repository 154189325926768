import { AmountFragment } from "../graphql/types/AmountFragment";
import { SupportedCurrencyCode } from "../graphql/types/global";
import { OmitType } from "./omitType";

type InputValueType =
  | "negativeCents" // This is typically the type from the backend API, a sum on tink_transaction.amount_inc_vat where the transaction amount is expressed as -12345 when something cost 123.45 kr. Therefore it will be inverted, and divided by 100, to be displayed as kr.
  | "cents";

interface CurrencyValueOptions {
  valueType: InputValueType;
}

type CurrencyFormattingOptions = CurrencyValueOptions;
type Amount = OmitType<AmountFragment>;

export const formatCurrencyValue = (
  amount: Amount | null | undefined,
  options?: CurrencyValueOptions
): number => {
  let actualAmount = amount?.cents || 0;
  if (options?.valueType === "negativeCents") {
    actualAmount = -actualAmount / 100;
  } else {
    actualAmount = actualAmount / 100;
  }
  return actualAmount;
};

export const formatCurrency = (
  amount: Amount | null | undefined,
  options?: CurrencyFormattingOptions
): string =>
  formatCurrencyValue(amount, options).toLocaleString("sv-SE", {
    style: "currency",
    currency: amount?.currency || SupportedCurrencyCode.SEK,
    minimumFractionDigits: 0,
  });
