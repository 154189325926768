import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { InputListSort, DBSortOrder } from "../../../graphql/types/global";
import { ALL_COMPANY_DEMOS } from "../../../graphql/queries.gql";
import { CompanyDemo } from "../../../graphql/helperTypes";
import DemosTable from "./DemosTable";
import { allCompanyDemos } from "../../../graphql/types/allCompanyDemos";
import DemosFilter from "./DemosFilter";

const DemosPage: React.FC = () => {
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(100);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });

  const { data, refetch, loading } = useQuery<allCompanyDemos>(
    ALL_COMPANY_DEMOS,
    { fetchPolicy: "cache-and-network", notifyOnNetworkStatusChange: true }
  );

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const handleRequestSort = useCallback(
    (property: keyof CompanyDemo) => {
      const supportedProperties: (keyof CompanyDemo)[] = ["createdAt"];
      if (!supportedProperties.includes(property)) {
        return;
      }
      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const handleRequestPageSize = useCallback((pageSize: number) => {}, []);
  const handleRequestPage = useCallback((page: number) => {}, []);

  return (
    <div>
      <DemosFilter loading={loading} onRequestRefetch={handleRefetch} />
      {data ? (
        <DemosTable
          demos={data.allCompanyDemos.demos}
          pageInfo={data.allCompanyDemos.pageInfo}
          onRequestRefetch={handleRefetch}
          onRequestSort={handleRequestSort}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          sorting={sorting}
        />
      ) : null}
    </div>
  );
};

export default DemosPage;
