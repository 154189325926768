import React, { memo } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { campaign } from "../../../graphql/types/campaign";
import { campaignInstance } from "../../../graphql/types/campaignInstance";
import { Link } from "react-router-dom";
import { CompanyThumbnail } from "../Assign/CompanyThumbnail";

interface FilterProps {
  loading: boolean;
  onRequestRefetch: () => any;
  campaign: campaign["campaign"] | undefined | null;
  campaignInstance: campaignInstance["campaignInstance"] | undefined | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    titleWrapper: {
      marginRight: theme.spacing(2),
    },
    instanceWrapper: {
      marginLeft: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      borderLeft: "1px solid " + theme.palette.divider,
    },
    title: {
      margin: 0,
      padding: 0,
      lineHeight: 1,
    },
    campaignLogoWrapper: {
      marginRight: theme.spacing(0.5),
    },
  })
);

const CampaignInstanceDetailsFilter = memo<FilterProps>(
  ({ loading, onRequestRefetch, campaign, campaignInstance }) => {
    const classes = useStyles();

    const logo = campaign?.companyConnection?.logoThumbnailUrl;
    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>
          {campaign && (
            <div className={classes.titleWrapper}>
              <Link to={`/admin/campaign/${campaign.id}`}>
                {logo ? (
                  <span className={classes.campaignLogoWrapper}>
                    <CompanyThumbnail logoUrl={logo} size={20} />
                  </span>
                ) : null}
                {campaign.description}
              </Link>
            </div>
          )}
          <div className={classes.instanceWrapper}>
            <h3 className={classes.title}>{campaignInstance?.id}</h3>
            <span>{campaignInstance?.description}</span>
          </div>
        </div>
      </div>
    );
  }
);

export default CampaignInstanceDetailsFilter;
