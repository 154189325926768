import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { ALL_APP_TEXT_DOCUMENTS } from "../../../graphql/queries.gql";
import {
  allAppTextDocuments,
  allAppTextDocumentsVariables,
} from "../../../graphql/types/allAppTextDocuments";
import { AppTextDocumentKind } from "../../../graphql/types/global";
import DocumentsFilter from "./DocumentsFilter";
import DocumentsTable from "./DocumentsTable";

const DocumentsPage: React.FC = () => {
  const [kind, setKind] = useState<AppTextDocumentKind | null>(null);
  // const [body, setBody] = useState("");
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(100);
  // const [sorting, setSorting] = useState<InputListSort>({
  //   sortField: "createdAt",
  //   sort: DBSortOrder.DESC,
  // });

  const { data } = useQuery<allAppTextDocuments, allAppTextDocumentsVariables>(
    ALL_APP_TEXT_DOCUMENTS,
    {
      fetchPolicy: "cache-and-network",
      variables: kind ? { kind } : {},
    }
  );

  return (
    <div>
      <DocumentsFilter onRequestSetKind={setKind} kind={kind} />
      {data ? (
        <DocumentsTable
          {...data.allAppTextDocuments}
          // onRequestSort={handleRequestSort}
          // onRequestPage={setPage}
          // onRequestPageSize={handleRequestPageSize}
          // sorting={sorting}
        />
      ) : null}
    </div>
  );
};

export default DocumentsPage;
