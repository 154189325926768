import React, { memo, useMemo } from "react";
import deepEqual from "fast-deep-equal";
import {
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import {
  DisbursementStatus,
  DisbursementType,
} from "../../../graphql/types/global";

enum CompositeStatus {
  All = "All",
  AllNonPaid = "AllNonPaid",
}

export const compositeMapping: Record<CompositeStatus, DisbursementStatus[]> = {
  [CompositeStatus.All]: [],
  [CompositeStatus.AllNonPaid]: Object.values(DisbursementStatus).filter(
    (value) =>
      value !== DisbursementStatus.paid &&
      value !== DisbursementStatus.nullified
  ),
};

const compositeStatuses = Object.values(CompositeStatus).sort();
const allStatuses = Object.values(DisbursementStatus).sort();
const allTypes = Object.values(DisbursementType).sort();

interface FilterProps {
  message: string;
  statuses: DisbursementStatus[];
  types: DisbursementType[];
  loading: boolean;
  onRequestRefetch: () => any;
  onRequestSetMessage: (message: string) => any;
  onRequestSetStatuses: (types: DisbursementStatus[]) => any;
  onRequestSetTypes: (types: DisbursementType[]) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    textField: {
      margin: 0,
    },
    select: {
      minWidth: 140,
      marginRight: theme.spacing(1),
    },
  })
);

const DisbursementsFilter = memo<FilterProps>(
  ({
    message,
    statuses,
    types,
    loading,
    onRequestRefetch,
    onRequestSetMessage,
    onRequestSetStatuses,
    onRequestSetTypes,
  }) => {
    const classes = useStyles();

    const selectedValue = useMemo<string>(() => {
      if (statuses.length === 1) {
        return statuses[0];
      } else {
        const compositeEntry = Object.entries(compositeMapping).find(
          ([, values]) => {
            return deepEqual(values, statuses);
          }
        );
        return compositeEntry?.[0] || CompositeStatus.All;
      }
    }, [statuses]);

    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>

          <FormControl
            variant="outlined"
            size="small"
            className={classes.select}
            margin="dense"
          >
            <InputLabel>Type</InputLabel>
            <Select
              value={types[0] ?? ""}
              onChange={(evt) =>
                onRequestSetTypes(
                  evt.target.value === ""
                    ? []
                    : [evt.target.value as DisbursementType]
                )
              }
              label="Type"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {allTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            margin="dense"
            className={classes.select}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={selectedValue}
              onChange={(evt) => {
                const value: string = (evt.target.value as any) || "";
                if (value in compositeMapping) {
                  const values = compositeMapping[value as CompositeStatus];
                  onRequestSetStatuses(values);
                } else if (value) {
                  onRequestSetStatuses([value as DisbursementStatus]);
                } else {
                  onRequestSetStatuses([]);
                }
              }}
              label="Status"
            >
              {compositeStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <em>{status}</em>
                </MenuItem>
              ))}
              {allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className={classes.textField}>
            <TextField
              label="Filter on message text"
              value={message}
              onChange={(evt) => onRequestSetMessage(evt.target.value)}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </div>
      </div>
    );
  }
);

export default DisbursementsFilter;
