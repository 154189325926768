import {
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  Input,
  InputLabel,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import clsx from "clsx";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { BATCH_ANSWER_REPORT_PER_QUESTION } from "../../../graphql/queries.gql";
import {
  batchAnswerReportPerQuestion,
  batchAnswerReportPerQuestionVariables,
  batchAnswerReportPerQuestion_batchAnswerReportPerQuestion_rows,
} from "../../../graphql/types/batchAnswerReportPerQuestion";
import { dateAndTime } from "../../../utils/formatDate";
import { groupBy } from "../../../utils/groupBy";
import { useHiddenColIndex } from "../../../utils/useHiddenColIndex";
import useQueryWithPreviousData from "../../../utils/useQueryWithPreviousData";

const DEFAULT_PAGE_SIZE = 1000;

const pageSizeStorageKey = (batchNumber: number) =>
  `batch_report_pageSize_${batchNumber}`;

const tryReadPageSize = (batchNumber: number) => {
  try {
    const json = localStorage.getItem(pageSizeStorageKey(batchNumber));
    const parsed = parseInt(json ?? "0", 10);
    if (!parsed || isNaN(parsed)) {
      return DEFAULT_PAGE_SIZE;
    }
    return parsed;
  } catch (e) {
    console.error(e);
    return DEFAULT_PAGE_SIZE;
  }
};

const tryStorePageSize = (batchNumber: number, size: number) => {
  try {
    localStorage.setItem(pageSizeStorageKey(batchNumber), String(size));
  } catch (e) {
    console.error(e);
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    table: {
      fontSize: "0.8em",
      marginTop: theme.spacing(1),
    },
    headCell: {
      textAlign: "left",
    },
    cell: {
      textAlign: "left",
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    titleRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    filterForm: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(2),
      },
    },
    altRow: {
      backgroundColor: theme.palette.grey[100],
    },
  })
);

type Row = batchAnswerReportPerQuestion_batchAnswerReportPerQuestion_rows;
type User = Row["userConnection"];
type Question = Pick<Row, "questionMarkdown" | "sortOrder" | "swipeCardId">;
type IndexedQuestion = Question & { index: number };

const groupByUser = (rows: Row[] | null | undefined) => {
  if (!rows || rows.length === 0) {
    return {
      rows: [],
      questions: [],
    };
  }

  const questionKey = (r: Row) => r.questionMarkdown;

  const questions = new Map<string, Question>();

  const userLookup = rows.reduce((store, row) => {
    store.set(row.userId, row.userConnection);
    const k = questionKey(row);
    if (!questions.has(k)) {
      questions.set(k, row);
    }
    return store;
  }, new Map<string, User>());

  const getEarliest = (rows: Row[]) =>
    rows.reduce(
      (earliest, r) => (earliest < r.answeredAt ? earliest : r.answeredAt),
      rows[0].answeredAt
    );

  const grouped = groupBy(rows, (r) => r.userId);

  const sortedQuestions = Array.from(questions.entries()).sort(
    (a, b) => (a[1].sortOrder ?? 0) - (b[1].sortOrder ?? 0)
  );

  const indexedQuestions = sortedQuestions.reduce(
    (store, [questionMarkdown, question], index) => {
      store.set(questionMarkdown, { ...question, index });
      return store;
    },
    new Map<string, IndexedQuestion>()
  );

  return {
    questions: sortedQuestions.map(([_, q]) => q),
    rows: Array.from(grouped.entries())
      .map(([userId, rows]) => {
        const mappedRows = new Array(indexedQuestions.size).fill(null) as (
          | Row[]
          | null
        )[];

        for (const r of rows) {
          const question = indexedQuestions.get(questionKey(r))!;
          if (mappedRows[question.index] == null) {
            mappedRows[question.index] = [r];
          } else {
            mappedRows[question.index]!.push(r);
          }
        }

        mappedRows.forEach((mr) => {
          mr?.sort((a, b) => a.answerChoice.localeCompare(b.answerChoice));
        });

        return {
          user: userLookup.get(userId)!,
          earliestAnsweredAt: getEarliest(rows),
          rows: mappedRows,
        };
      })
      .sort((a, b) => b.earliestAnsweredAt.localeCompare(a.earliestAnsweredAt)),
  } as const;
};

export const BatchReportPage = () => {
  const classes = useStyles();
  const params = useParams<{ batchNumber: string }>();
  const batchNumber = parseInt(params.batchNumber, 10);

  const [commitedPageSize, setCommitedPageSize] = React.useState(
    () => tryReadPageSize(batchNumber) || DEFAULT_PAGE_SIZE
  );
  const [pageInputText, setPageInputText] = React.useState(() =>
    String(commitedPageSize)
  );
  const [commitedPageNumber, setCommitedPageNumber] = React.useState(1);
  const [pageInputNumberText, setPageInputNumberText] = React.useState(() =>
    String(commitedPageNumber)
  );

  const [data, { loading, refetch }] = useQueryWithPreviousData<
    batchAnswerReportPerQuestion,
    batchAnswerReportPerQuestionVariables
  >(BATCH_ANSWER_REPORT_PER_QUESTION, "batchAnswerReportPerQuestion", {
    variables: {
      batchNumber: parseInt(params.batchNumber, 10),
      paging: {
        page: commitedPageNumber,
        size: commitedPageSize,
      },
    },
  });

  const [hiddenColIndex, toggleHiddenColIndex] = useHiddenColIndex(batchNumber);

  const groupedByUser = useMemo(() => groupByUser(data?.rows), [data?.rows]);

  const onRequestRefetch = () => {
    refetch();
  };

  const onFilterCommit = (e: React.FormEvent) => {
    e.preventDefault();

    const newSize = parseInt(pageInputText, 10);
    if (!isNaN(newSize) && newSize !== commitedPageSize) {
      setCommitedPageSize(newSize);
      tryStorePageSize(batchNumber, newSize);
    }

    const newPage = parseInt(pageInputNumberText, 10);
    if (!isNaN(newPage) && newPage !== commitedPageNumber && newPage > 0) {
      setCommitedPageNumber(newPage);
    }
  };

  return (
    <>
      <Typography variant="h6">
        Batch answer report {params.batchNumber}
        <IconButton
          aria-label="refresh"
          onClick={onRequestRefetch}
          className={classes.icon}
          disabled={loading}
          size="small"
        >
          {loading ? (
            <CircularProgress size="16px" />
          ) : (
            <Refresh fontSize="small" />
          )}
        </IconButton>
      </Typography>
      <Paper className={classes.card}>
        <form onSubmit={onFilterCommit} className={classes.filterForm}>
          <div>
            <InputLabel htmlFor="pageSize">
              Max report rows (N latest)
            </InputLabel>
            <Input
              id="pageSize"
              name="pageSize"
              value={pageInputText}
              onChange={(e) => setPageInputText(e.target.value)}
            />
          </div>
          <div>
            <InputLabel htmlFor="pageNumber">Page number</InputLabel>
            <Input
              id="pageNumber"
              name="pageNumber"
              value={pageInputNumberText}
              onChange={(e) => setPageInputNumberText(e.target.value)}
            />
          </div>
          {pageInputText !== String(commitedPageSize) ||
          pageInputNumberText !== String(commitedPageNumber) ? (
            <Button type="submit" className={classes.icon}>
              OK
            </Button>
          ) : null}
        </form>
      </Paper>
      <Paper className={classes.card}>
        <div className={classes.titleRow}>
          <Typography variant="subtitle1">Grouped by user</Typography>
          <div>
            {groupedByUser.questions.map((q, i) => (
              <label key={i}>
                <input
                  type="checkbox"
                  key={i}
                  checked={hiddenColIndex.has(i) ? false : true}
                  onChange={() => toggleHiddenColIndex(i)}
                />
                {`q${i + 1}`}
              </label>
            ))}
          </div>
        </div>
        <table cellPadding={2} cellSpacing={1} className={classes.table}>
          <thead>
            <tr>
              <th className={classes.headCell}>Email</th>
              <th className={classes.headCell}>Mobile</th>
              <th className={classes.headCell}>Gender</th>
              <th className={classes.headCell}>Age</th>
              <th className={classes.headCell}>Postal</th>
              {groupedByUser.questions.map((q, i) =>
                hiddenColIndex.has(i) ? (
                  <td key={i} />
                ) : (
                  <th className={classes.headCell} key={i}>
                    {q.questionMarkdown}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {groupedByUser.rows.map(({ user, rows }, n) => (
              <tr
                key={user.id}
                className={n % 2 === 0 ? classes.altRow : undefined}
              >
                <td className={clsx(classes.noWrap, classes.cell)}>
                  {user.email}
                </td>
                <td className={clsx(classes.noWrap, classes.cell)}>
                  {user.mobilePhoneNumber}
                </td>
                <td className={clsx(classes.noWrap, classes.cell)}>
                  {user.gender}
                </td>
                <td className={clsx(classes.noWrap, classes.cell)}>
                  {user.ageYears}
                </td>
                <td className={clsx(classes.noWrap, classes.cell)}>
                  {user.postalCode}
                </td>
                {rows.map((answerRows, i) =>
                  answerRows && !hiddenColIndex.has(i) ? (
                    <td key={i} className={classes.cell}>
                      {answerRows.map((r, j) => (
                        <div key={j}>
                          {r.answerChoice === "freeText"
                            ? null
                            : r.answerChoice}
                          {r.answerFreeText && r.answerFreeText !== "-"
                            ? ` ${r.answerFreeText}`
                            : null}
                        </div>
                      ))}
                    </td>
                  ) : (
                    <td key={i} />
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>

      <Paper className={classes.card}>
        <Typography variant="subtitle1">All answers report</Typography>
        <table cellPadding={2} cellSpacing={2} className={classes.table}>
          <tbody>
            {data?.rows.map((r, i) => (
              <tr key={i} className={i % 2 === 0 ? classes.altRow : undefined}>
                <td className={classes.noWrap}>{r.userConnection.email}</td>
                <td className={classes.noWrap}>
                  {r.userConnection.mobilePhoneNumber}
                </td>
                <td className={classes.noWrap}>{r.userConnection.gender}</td>
                <td className={classes.noWrap}>{r.userConnection.ageYears}</td>
                <td className={classes.noWrap}>
                  {r.userConnection.postalCode}
                </td>
                <td className={classes.noWrap}>{dateAndTime(r.answeredAt)}</td>
                <td>{r.questionMarkdown}</td>
                <td>{r.answerChoice}</td>
                <td>{r.answerFreeText}</td>
                <td>{r.sortOrder}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Paper>
    </>
  );
};
