import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { InputListSort, DBSortOrder } from "../../../graphql/types/global";
import { ALL_CAMPAIGNS } from "../../../graphql/queries.gql";
import { Campaign } from "../../../graphql/helperTypes";
import CampaignsTable from "./CampaignsTable";
import CampaignsFilter from "./CampaignsFilter";
import {
  allCampaigns,
  allCampaignsVariables,
} from "../../../graphql/types/allCampaigns";

const CampaignsPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRequestSort = useCallback(
    (property: keyof Campaign) => {
      const supportedProperties: (keyof Campaign)[] = ["createdAt"];
      if (!supportedProperties.includes(property)) {
        return;
      }
      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const { data, refetch, loading } = useQuery<
    allCampaigns,
    allCampaignsVariables
  >(ALL_CAMPAIGNS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      paging: { page, size: pageSize },
      sort: sorting,
    },
  });

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  return (
    <div>
      <CampaignsFilter loading={loading} onRequestRefetch={handleRefetch} />
      {data ? (
        <CampaignsTable
          campaigns={data.allCampaigns.campaigns}
          pageInfo={data.allCampaigns.pageInfo}
          onRequestRefetch={handleRefetch}
          onRequestSort={handleRequestSort}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          sorting={sorting}
        />
      ) : null}
    </div>
  );
};

export default CampaignsPage;
