import React from "react";
import { Typography } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import {
  kosmaConsents,
  kosmaConsentsVariables,
} from "../../../../graphql/types/kosmaConsents";
import {
  KOSMA_ACCOUNTS,
  KOSMA_CONSENTS,
} from "../../../../graphql/queries.gql";
import { SystemUser } from "../../../../graphql/helperTypes";
import {
  kosmaAccounts,
  kosmaAccountsVariables,
} from "../../../../graphql/types/kosmaAccounts";
import KosmaConsentsTable from "./KosmaConsentsTable";
import { DBSortOrder } from "../../../../graphql/types/global";
import KosmaAccountsTable from "./KosmaAccountsTable";
import { noop } from "../../../../utils/noop";

export const KosmaSection = ({ user }: { user: SystemUser }) => {
  const { data: consentsData } = useQuery<
    kosmaConsents,
    kosmaConsentsVariables
  >(KOSMA_CONSENTS, {
    fetchPolicy: "cache-and-network",
    variables: { userId: user.id },
  });

  const { data: accountsData } = useQuery<
    kosmaAccounts,
    kosmaAccountsVariables
  >(KOSMA_ACCOUNTS, {
    fetchPolicy: "cache-and-network",
    variables: { userId: user.id },
  });

  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        Consents
      </Typography>
      {consentsData && (
        <KosmaConsentsTable
          consents={consentsData.kosmaConsents.consents}
          pageInfo={consentsData.kosmaConsents.pageInfo}
          onRequestPage={noop}
          onRequestPageSize={noop}
          onRequestRefetch={noop}
          onRequestSort={noop}
          sorting={{ sort: DBSortOrder.DESC, sortField: "createdAt" }}
        />
      )}

      <Typography variant="h6" gutterBottom component="div">
        Accounts
      </Typography>
      {accountsData && (
        <KosmaAccountsTable
          accounts={accountsData.kosmaAccounts.accounts}
          pageInfo={accountsData.kosmaAccounts.pageInfo}
          onRequestPage={noop}
          onRequestPageSize={noop}
          onRequestRefetch={noop}
          onRequestSort={noop}
          sorting={{ sort: DBSortOrder.DESC, sortField: "createdAt" }}
        />
      )}
    </>
  );
};
