import React from "react";
import { campaignInstanceUsers_campaignInstanceUsers } from "../../../graphql/types/campaignInstanceUsers";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { OmitType } from "../../../utils/omitType";
import { MappedCampaignUser } from "../../../graphql/helperTypes";
import { DBSortOrder } from "../../../graphql/types/global";
import { asDirection } from "../../../utils/tableUtils";
import { TimestampDisplay } from "../../../components/TimestampDisplay";
import clsx from "clsx";

export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface Props extends OmitType<campaignInstanceUsers_campaignInstanceUsers> {
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
}

interface HeadCell {
  id: keyof (MappedCampaignUser & MappedCampaignUser["userConnection"]);
  label: string;
  type: "name" | "date" | "string" | "invitation" | "disbursement";
}

const headCells: HeadCell[] = [
  { id: "displayName", type: "name", label: "Display name" },
  { id: "email", type: "string", label: "Email" },
  { id: "createdAt", type: "date", label: "Created" },
  { id: "assignedAt", type: "date", label: "Assigned" },
  {
    id: "onboardingDisbursementConnection",
    type: "disbursement",
    label: "Onboarding",
  },
  { id: "userInvitationConnection", type: "invitation", label: "Invited by" },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
  })
);

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;
  const sortField = "assignedAt";
  const direction = DBSortOrder.DESC;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              headCell.id === sortField ? asDirection(direction) : false
            }
          >
            <TableSortLabel
              active={sortField === headCell.id}
              direction={
                sortField === headCell.id ? asDirection(direction) : "asc"
              }
            >
              {headCell.label}
              {sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  sorted descending
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const CampaignInstanceUsersPaper = ({
  users,
  pageInfo,
  onRequestPage,
  onRequestPageSize,
}: Props) => {
  const classes = useStyles();
  const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onRequestPageSize(parseInt(event.target.value, 10));
  };

  const renderCell = (cell: HeadCell, row: MappedCampaignUser) => {
    const value =
      cell.id in row
        ? (row as any)[cell.id]
        : (row.userConnection as any)[cell.id];
    switch (cell.type) {
      case "disbursement":
        return row.userConnection.onboardingDisbursementConnection?.status;
      case "invitation":
        return (
          row.userConnection.userInvitationConnection?.createdBySystemConnection
            .email ?? ""
        );
      case "date":
        return <TimestampDisplay timestamp={value} />;
      default:
        return value;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {users.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {headCells.map((cell) => (
                      <TableCell
                        key={`${cell.id}-${row.id}`}
                        className={clsx({
                          [classes.noWrapCell]: cell.type === "date",
                        })}
                      >
                        {renderCell(cell, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 50, 100, 500, 1000]}
          count={pageInfo.totalCount}
          rowsPerPage={pageInfo.size}
          page={pageInfo.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
