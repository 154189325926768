import { generateRandomString } from "./generateRandomString";
import { generateCodeChallenge } from "./generateCodeChallenge";
import { setEphemeralSignInStorage } from "../../graphql/client/tokenStorage";

export const redirectToLogin = async ({
  postLoginRedirect,
}: {
  postLoginRedirect: string;
}) => {
  const authEndpoint = process.env.REACT_APP_ZITADEL_MANAGE_API_AUTH_ENDPOINT!;
  const clientId = process.env.REACT_APP_ZITADEL_MANAGE_API_CLIENT_ID!;
  const redirectUri = process.env.REACT_APP_ZITADEL_MANAGE_API_REDIRECT_URI!;

  const codeVerifier = generateRandomString(96);
  const codeChallenge = await generateCodeChallenge(codeVerifier);

  const params = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    redirect_uri: redirectUri,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    scope: "openid profile email offline_access",
  });

  setEphemeralSignInStorage({ codeVerifier, postLoginRedirect });
  window.location.assign(`${authEndpoint}?${params.toString()}`);
};
