import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RefreshIcon from "@material-ui/icons/Refresh";
import { SystemUser } from "../../../../graphql/helperTypes";
import {
  DISBURSEMENT_DETAILS,
  RETRY_DISBURSEMENT,
  SYSTEM_USER,
} from "../../../../graphql/queries.gql";
import {
  DisbursementDetails,
  DisbursementDetailsVariables,
} from "../../../../graphql/types/DisbursementDetails";
import {
  retryDisbursement,
  retryDisbursementVariables,
} from "../../../../graphql/types/retryDisbursement";
import UserPhoneDisplay from "../UserPhoneDisplay";
import { DisbursementStatusDialogContentProps } from "./types";
import { dateAndTime } from "../../../../utils/formatDate";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { DisbursementStatus } from "../../../../graphql/types/global";
import SwishAppIcon from "../../../../components/icons/SwishAppIcon";
import { DisbursementDetailsFragment } from "../../../../graphql/types/DisbursementDetailsFragment";
import {
  systemUser,
  systemUserVariables,
} from "../../../../graphql/types/systemUser";
import SwishStatusSummary from "../SwishStatus/SwishStatusSummary";
import { NotificationIcon } from "../NotificationButton";
import { SwishPayoutDetailsFragment } from "../../../../graphql/types/SwishPayoutDetailsFragment";
import { DisbursementStatusText } from "../DisbursementStatusCell";
import SwishLogsTable from "../SwishStatus/SwishLogsTable";
import logUnhandledSwitch from "../../../../utils/logUnhandledSwitch";

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    refreshButton: {
      marginRight: theme.spacing(1),
    },
    retryButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    swishIcon: {
      width: "0.75em",
      height: "0.75em",
    },
    swishDetailsIcon: {
      marginRight: "0.25em",
    },
    headerRow: {
      display: "flex",
      alignItems: "center",
    },
    headerIcon: {
      marginRight: "0.5em",
    },
    accordion: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    accordionDetails: {
      flexDirection: "column",
    },
  })
);

interface LogTableProps {
  logs: DisbursementDetailsFragment["disbursementLogsConnection"];
  onRequestRefresh: () => any;
  isFetching: boolean;
}

function LogsTable({ logs, onRequestRefresh, isFetching }: LogTableProps) {
  const styles = useStyles();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <IconButton
              type="button"
              color="primary"
              disabled={isFetching}
              onClick={onRequestRefresh}
              size="small"
              className={styles.refreshButton}
            >
              {isFetching ? <CircularProgress size="24px" /> : <RefreshIcon />}
            </IconButton>
            Created
          </TableCell>
          <TableCell>Status</TableCell>
          <TableCell className={styles.noWrap}>Error code</TableCell>
          <TableCell className={styles.noWrap}>Message</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((log) => (
          <TableRow key={log.id}>
            <TableCell component="th" scope="row" className={styles.noWrap}>
              {dateAndTime(log.createdAt)}
            </TableCell>
            <TableCell>{log.disbursementStatus}</TableCell>
            <TableCell>{log.errorCode}</TableCell>
            <TableCell>{log.message}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const swishPayoutDetailsHeaderText = (
  payoutDetails: SwishPayoutDetailsFragment
) => {
  switch (payoutDetails.payoutStatus) {
    case "PAID":
    case "CREATED":
    case "DEBITED":
      return payoutDetails.payoutStatus;
    case "ERROR":
      const mostRecentLog = payoutDetails.swishPayoutLogsConnection.find(
        (log) => log.errorCode != null
      );
      return mostRecentLog
        ? `${mostRecentLog.errorCode}: ${mostRecentLog.errorMessage}`
        : payoutDetails.payoutStatus;
    default:
      return "textDefault";
  }
};

interface FormContentProps extends DisbursementStatusDialogContentProps {
  user: SystemUser;
}

const getIsProcessing = (
  disbursementStatus: DisbursementStatus | null | undefined
) => {
  switch (disbursementStatus) {
    case DisbursementStatus.error:
    case DisbursementStatus.paid:
    case DisbursementStatus.nullified:
      return false;
    case DisbursementStatus.created:
    case DisbursementStatus.dispatched:
    case DisbursementStatus.processing:
    case DisbursementStatus.processed:
    case null:
    case undefined:
      return true;
    default:
      logUnhandledSwitch(disbursementStatus);
      return true;
  }
};

function FormContent({
  data,
  onRequestClose,
  user,
  onRequestUserNotificationDialog,
}: FormContentProps) {
  const styles = useStyles();
  const {
    data: detailsData,
    loading,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery<DisbursementDetails, DisbursementDetailsVariables>(
    DISBURSEMENT_DETAILS,
    {
      variables: { disbursementId: data.disbursement.id },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-and-network",
    }
  );

  const isPolling = getIsProcessing(detailsData?.disbursement.status);
  useEffect(() => {
    if (isPolling) {
      startPolling(1000);
    }
    return () => stopPolling();
  }, [isPolling, startPolling, stopPolling]);

  const [retryDisbursementMutation, { loading: retryLoading }] = useMutation<
    retryDisbursement,
    retryDisbursementVariables
  >(RETRY_DISBURSEMENT, {
    refetchQueries: [DISBURSEMENT_DETAILS],
    onError: (err) => {
      console.error(err);
      alert(err.message);
      refetch();
    },
  });

  const isFetching = loading || retryLoading;
  const actionsPossible = true;

  const paidAt = detailsData?.disbursement.paidOutAt;
  const disbursementStatus = detailsData?.disbursement.status ?? null;
  const payoutDetails = detailsData?.disbursement.swishPayoutConnection;

  const onRequestRetry = async () => {
    const defaultMessage = detailsData?.disbursement.message || "";
    const defaultAmountText = String(
      Math.floor((detailsData?.disbursement.amount.cents || 0) / 100)
    );
    const amountText = prompt("Amount in SEK", defaultAmountText);

    if (amountText == null) {
      return;
    }

    const message = prompt("Message", defaultMessage);

    if (message == null) {
      return;
    }

    try {
      await retryDisbursementMutation({
        variables: {
          input: {
            disbursementId: data.disbursement.id,
            amountInCents:
              amountText !== defaultAmountText
                ? parseInt(amountText, 10) * 100
                : null,
            message: message !== defaultMessage ? message : null,
          },
        },
      });
      setTimeout(() => refetch(), 1000);
    } catch (err: any) {
      console.error(err);
      alert(err?.message);
      refetch().catch(() => {});
    }
  };

  return (
    <Box>
      <DialogTitle id="swish-status-dialog-title">
        <div className={styles.headerRow}>
          <AccountBalanceIcon
            fontSize="default"
            className={styles.headerIcon}
          />
          <span>Disbursement status</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Disbursement type
          </Typography>
          <Typography variant="body2">
            <strong>{detailsData?.disbursement.type}</strong>
          </Typography>
        </div>

        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Disbursement ID
          </Typography>
          <Typography variant="body2">
            <strong>{data.disbursement.id}</strong>
          </Typography>
        </div>

        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Disbursement status
          </Typography>
          <Typography variant="body2">
            <strong>
              <DisbursementStatusText status={disbursementStatus} />
            </strong>
          </Typography>
          {disbursementStatus === DisbursementStatus.error && (
            <Button
              type="button"
              color="primary"
              startIcon={<SwishAppIcon className={styles.swishIcon} />}
              disabled={isFetching || !actionsPossible}
              onClick={onRequestRetry}
              className={styles.retryButton}
            >
              Retry payout
            </Button>
          )}
        </div>

        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Paid out at
          </Typography>
          <Typography variant="body2">
            <strong>{dateAndTime(paidAt, "Pending")}</strong>
          </Typography>
        </div>

        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Recipient
          </Typography>
          <Typography variant="body2">
            <strong>{user.displayName}</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user.email}
            <br />
            <UserPhoneDisplay user={user} />
          </Typography>

          <Button
            type="button"
            color="primary"
            startIcon={<NotificationIcon />}
            disabled={isFetching}
            onClick={() => onRequestUserNotificationDialog({ userId: user.id })}
          >
            Push notification
          </Button>
        </div>

        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Message
          </Typography>
          <Typography variant="body2">
            <strong>{detailsData?.disbursement.message}</strong>
          </Typography>
        </div>

        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Amount in SEK
          </Typography>
          <Typography variant="body2">
            <strong>{formatCurrency(detailsData?.disbursement.amount)}</strong>
          </Typography>
        </div>

        {payoutDetails && (
          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <SwishAppIcon className={styles.swishDetailsIcon} />
              <Typography>
                Swish Payout details (
                {swishPayoutDetailsHeaderText(payoutDetails)})
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              <SwishStatusSummary
                payoutDetails={payoutDetails}
                type={detailsData.disbursement.type ?? data.disbursement.type}
              />
              <SwishLogsTable
                logs={payoutDetails?.swishPayoutLogsConnection ?? []}
                swishPayoutId={payoutDetails.id}
                swishStatusRefetchQueries={[DISBURSEMENT_DETAILS]}
                onSwishStatusRefetchError={(err) => {
                  console.error(err);
                  alert(err.message);
                  refetch();
                }}
              />
            </AccordionDetails>
          </Accordion>
        )}

        <LogsTable
          logs={detailsData?.disbursement.disbursementLogsConnection ?? []}
          onRequestRefresh={() => refetch()}
          isFetching={isFetching || isPolling}
        />
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={onRequestClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Box>
  );
}

export default function SwishStatusDialogContent(
  props: DisbursementStatusDialogContentProps
) {
  const userResponse = useQuery<systemUser, systemUserVariables>(SYSTEM_USER, {
    variables: { userId: props.data.disbursement.userId },
    fetchPolicy: "cache-and-network",
  });
  const user = userResponse?.data?.systemUser;
  return user ? <FormContent {...props} user={user} /> : null;
}
