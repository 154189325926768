import React, { memo } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";

interface FilterProps {
  search: string;
  loading: boolean;
  onRequestRefetch: () => any;
  onRequestSetSearch: (search: string) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
  })
);

const SystemUsersFilter = memo<FilterProps>(
  ({ search, loading, onRequestRefetch, onRequestSetSearch }) => {
    const classes = useStyles();

    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>
          <FormControl component="fieldset" fullWidth>
            <TextField
              label="Search users on name/email"
              value={search}
              onChange={(evt) => onRequestSetSearch(evt.target.value)}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </div>
      </div>
    );
  }
);

export default SystemUsersFilter;
