import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createStyles,
  Theme,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { SystemUser } from "../../../../graphql/helperTypes";
import { format } from "date-fns";
import { CompanyThumbnail } from "../../Assign/CompanyThumbnail";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  userMappedCampaignInstances,
  userMappedCampaignInstancesVariables,
} from "../../../../graphql/types/userMappedCampaignInstances";
import { USER_MAPPED_CAMPAIGN_INSTANCES } from "../../../../graphql/queries.gql";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    campaignLogoWrapper: {
      marginRight: theme.spacing(0.5),
    },
  })
);

export const CampaignSection: React.FC<{ user: SystemUser }> = ({ user }) => {
  const classes = useStyles();
  const { data } = useQuery<
    userMappedCampaignInstances,
    userMappedCampaignInstancesVariables
  >(USER_MAPPED_CAMPAIGN_INSTANCES, {
    variables: { userId: user.id },
  });
  if (!data) return null;
  const rows = data.userMappedCampaignInstances.instances;
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Slug</TableCell>
          <TableCell>Instance</TableCell>
          <TableCell>Campaign</TableCell>
          <TableCell>User assigned</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, i) => {
          const {
            slug,
            description,
            campaignConnection: campaign,
          } = row.campaignInstanceConnection;
          const logo = campaign.companyConnection?.logoThumbnailUrl;

          return (
            <TableRow key={i}>
              <TableCell>
                {slug ? (
                  <Chip color="primary" label={slug} size="small" />
                ) : null}
              </TableCell>
              <TableCell>
                <Link
                  to={`/admin/campaign/${campaign.id}/instance/${row.campaignInstanceConnection.id}`}
                >
                  {description}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={`/admin/campaign/${campaign.id}`}>
                  {logo ? (
                    <span className={classes.campaignLogoWrapper}>
                      <CompanyThumbnail logoUrl={logo} size={20} />
                    </span>
                  ) : null}
                  {campaign.description}
                </Link>
              </TableCell>
              <TableCell>
                {format(new Date(row.createdAt), "yyyy-MM-dd HH:mm")}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
