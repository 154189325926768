import React from "react";
import { OmitType } from "../../../../utils/omitType";
import { accountsCreatedForCompanyMetricsFragment } from "../../../../graphql/types/accountsCreatedForCompanyMetricsFragment";
import { Box, Typography } from "@material-ui/core";
import { companyDisplayFragment } from "../../../../graphql/types/companyDisplayFragment";
import { campaignInstanceUsers_campaignInstanceUsers } from "../../../../graphql/types/campaignInstanceUsers";

type Props = {
  createdAccountsMetrics: OmitType<accountsCreatedForCompanyMetricsFragment>;
  company: companyDisplayFragment | null | undefined;
  users?: OmitType<campaignInstanceUsers_campaignInstanceUsers>;
};

export const AccountsCreatedForCompanyMetrics = (props: Props) => {
  return (
    <Box display="flex" m={2} p={1} gridGap={32} justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="caption">
          Antal skapade verifierade kunder till {props.company?.companyName}
        </Typography>
        <Typography variant="h3">
          {props.createdAccountsMetrics.totalCount} st
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="caption">Kundvärde snitt</Typography>
        <Typography variant="h3">
          {/* {formatCurrency(props.createdAccountsMetrics.averageAmountInCents, {
            valueType: "cents",
          })} */}
        </Typography>
      </Box>
      {props.users && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="caption">
            Antal kunder i instans-gruppen
          </Typography>
          <Typography variant="h3">
            {props.users.pageInfo.totalCount} st
          </Typography>
        </Box>
      )}
    </Box>
  );
};
