import React from "react";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  warning: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

export default function WarningPaper() {
  const classes = useStyles();
  return (
    <Paper variant="outlined" className={classes.warning}>
      <Typography variant="h6" color="error" className={classes.header}>
        <WarningIcon className={classes.icon} fontSize="small" />
        Danger Zone™
      </Typography>

      <Typography variant="body1">
        Right now, adding/updating a document may result in the document being
        published directly to the live production app. There are no drafts, no
        backups, no version control.
        <strong> Proceed only if you know what will happen.</strong>
      </Typography>
    </Paper>
  );
}
