import React from "react";
import { dateAndTime } from "../utils/formatDate";

type InputTimestamp = string | null | undefined;
type TimestampFormatter = (timestamp: InputTimestamp) => string;

export const TimestampDisplay = ({
  timestamp,
  format = dateAndTime,
}: {
  timestamp: InputTimestamp;
  format?: TimestampFormatter;
}) => {
  if (!timestamp) {
    return null;
  }

  return (
    <time dateTime={timestamp} title={timestamp}>
      {format(timestamp)}
    </time>
  );
};
