import React, { memo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { DBSortOrder } from "../../../../graphql/types/global";
import { OmitType } from "../../../../utils/omitType";
import { enableBankingSessions_enableBankingSessions } from "../../../../graphql/types/enableBankingSessions";
import EnableBankingAccountsTable from "./EnableBankingAccountsTable";
import { noop } from "../../../../utils/noop";
import { LabelledChip } from "../../../../components/LabelledChip";
import { dateAndTime, dateOnly } from "../../../../utils/formatDate";
import { truncateText } from "../../../../utils/textUtils";
import { copyToClipboard } from "../../../../utils/clipboard";

type TableProps = OmitType<enableBankingSessions_enableBankingSessions>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    logo: {
      height: 24,
    },
    logoWrapper: {
      height: 24,
    },
    infoRow: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > *": {
        marginRight: theme.spacing(2),
      },
    },
  })
);

const EnableBankingSessionsTable = memo<TableProps>(({ sessions }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {sessions.map((row) => {
        return (
          <Paper className={classes.paper} key={row.id}>
            <div className={classes.infoRow}>
              <div className={classes.logoWrapper}>
                <img
                  src={`${row.aspspConnection.logo}-/resize/500x/`}
                  alt={row.aspspConnection.name}
                  className={classes.logo}
                />
              </div>
              <LabelledChip label="Name" text={row.aspspConnection.name} />
              <LabelledChip label="Created" text={dateAndTime(row.createdAt)} />
              <LabelledChip
                label="Valid until"
                text={dateOnly(row.validUntil)}
              />
              <LabelledChip
                label="Id"
                text={truncateText(row.id, 10)}
                onClick={() => copyToClipboard(row.id)}
              />
            </div>
            {row.authorisedAt ? (
              <EnableBankingAccountsTable
                accounts={row.accountsConnection.accounts}
                pageInfo={row.accountsConnection.pageInfo}
                sorting={{ sortField: "createdAt", sort: DBSortOrder.DESC }}
                onRequestSort={noop}
              />
            ) : null}
          </Paper>
        );
      })}
    </div>
  );
});

export default EnableBankingSessionsTable;
