import React from "react";
import { useQuery } from "@apollo/client";
import { ENABLE_BANKING_SESSIONS } from "../../../../graphql/queries.gql";
import { SystemUser } from "../../../../graphql/helperTypes";
import {
  enableBankingSessions,
  enableBankingSessionsVariables,
} from "../../../../graphql/types/enableBankingSessions";
import EnableBankingSessionsTable from "./EnableBankingSessionsTable";

export const EnableBankingSection = ({ user }: { user: SystemUser }) => {
  const { data: sessionsData } = useQuery<
    enableBankingSessions,
    enableBankingSessionsVariables
  >(ENABLE_BANKING_SESSIONS, {
    fetchPolicy: "cache-and-network",
    variables: { userId: user.id },
    pollInterval: 5000,
  });

  return (
    <>
      {sessionsData && (
        <EnableBankingSessionsTable
          sessions={sessionsData.enableBankingSessions.sessions}
          pageInfo={sessionsData.enableBankingSessions.pageInfo}
        />
      )}
    </>
  );
};
