import React, { memo } from "react";
import { CircularProgress, IconButton } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import { campaign } from "../../../graphql/types/campaign";
import { CompanyThumbnail } from "../Assign/CompanyThumbnail";

interface FilterProps {
  loading: boolean;
  onRequestRefetch: () => any;
  campaign: campaign["campaign"] | undefined | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    titleWrapper: {
      marginRight: theme.spacing(2),
    },
    title: {
      margin: 0,
      padding: 0,
      lineHeight: 1,
    },
    campaignLogoWrapper: {
      marginRight: theme.spacing(0.5),
    },
  })
);

const CampaignDetailsFilter = memo<FilterProps>(
  ({ loading, onRequestRefetch, campaign }) => {
    const classes = useStyles();
    const logo = campaign?.companyConnection?.logoThumbnailUrl;
    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>
          <div className={classes.titleWrapper}>
            {logo ? (
              <span className={classes.campaignLogoWrapper}>
                <CompanyThumbnail logoUrl={logo} size={20} />
              </span>
            ) : null}
            {campaign?.description}
          </div>
        </div>
      </div>
    );
  }
);

export default CampaignDetailsFilter;
