import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AgainRoundedIcon: React.FC = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 360 360">
      <g>
        <path
          d="M121.62 0h116.761c35.688 0 57.102 0 71.378 5.98 20.58 7.516 36.791 23.784 44.281 44.434C360 64.74 360 86.226 360 122.04v115.922c0 35.813 0 57.3-5.96 71.625-7.49 20.65-23.701 36.917-44.281 44.434-14.276 5.98-35.69 5.98-71.378 5.98H121.62c-35.69 0-57.103 0-71.379-5.98-20.58-7.517-36.79-23.784-44.281-44.434C0 295.261 0 273.774 0 237.961V122.039c0-35.813 0-57.3 5.96-71.625 7.49-20.65 23.702-36.918 44.282-44.434C64.517 0 85.93 0 121.62 0z"
          fill="#80FD89"
        />
        <path
          d="M178.201 61.005c4.066-.134 7.668 2.266 10.139 5.531 3.638 4.807 4.648 10.487 5.733 16.248 1.79 9.5 3.991 19.256 7.392 28.317 1.65 4.394 3.613 8.655 6.223 12.545a43.835 43.835 0 009.857 10.454 39.945 39.945 0 0012.369 5.779c4.312 1.173 8.777 1.612 13.244 1.67 7.895.102 15.765-.984 23.558-2.516 7.546-1.484 15.387-3.441 22.964-1.121 4.299 1.316 8.173 4.1 9.427 8.374 1.21 4.126-.395 8.424-3.113 11.798-2.21 2.743-4.994 4.807-7.855 6.783a141.157 141.157 0 01-8.861 5.648c-3.693 2.163-7.489 4.154-11.062 6.511-2.81 1.852-5.467 3.921-8.124 5.986-2.722 2.114-5.45 4.229-7.955 6.598-2.481 2.346-4.729 4.93-6.661 7.747a44.527 44.527 0 00-4.74 8.944 66.436 66.436 0 00-.325 27.009c1.831 9.384 5.65 18.207 9.598 26.908 2.633 5.805 5.383 11.75 5.439 18.193.03 3.496-.837 7.071-3.475 9.337-2.18 1.872-5.129 2.422-8 2.194-8.783-.698-15.113-7.476-21.091-13.622-6.278-6.454-12.877-12.642-20.677-17.046a55.996 55.996 0 00-23.13-7.061 59.72 59.72 0 00-25.079 6.218c-8.621 4.283-16.07 10.579-22.926 17.478-4.557 4.587-9.028 9.577-15.173 11.855-3.628 1.345-7.671 1.586-11.065-.246a10.744 10.744 0 01-2.97-2.396 12.997 12.997 0 01-2.258-3.721c-1.21-3.004-1.38-6.327-.843-9.58 1.283-7.78 6.269-14.109 10.271-20.713 4.207-6.942 7.464-14.405 9.103-22.285a58.102 58.102 0 00.246-22.409 34.89 34.89 0 00-6.219-12.199c-2.787-3.524-6.214-6.462-9.757-9.221-7.273-5.665-15.086-10.631-23.255-15-4.595-2.458-9.367-4.757-13.273-8.305-4.089-3.716-6.96-8.916-5.484-14.128 1.334-4.715 5.784-7.615 10.62-8.754 6.784-1.6 13.694-.009 20.437 1.207 8.697 1.568 17.512 2.554 26.276 1.73 4.519-.425 8.983-1.33 13.288-2.767a55.6 55.6 0 0011.292-5.239 37.136 37.136 0 009.971-10.853c2.489-4.122 4.147-8.68 5.488-13.327 2.477-8.589 4.093-17.831 5.53-26.664.954-5.862 1.917-11.56 5.626-16.466 2.278-3.012 5.522-5.298 9.28-5.422z"
          fill="#fff"
          stroke="#000"
          stroke-width="15"
        />
      </g>
    </SvgIcon>
  );
};

export default AgainRoundedIcon;
