import React from "react";
import { Link } from "react-router-dom";
import { LIST_ANSWER_REPORT_BATCHES } from "../../../graphql/queries.gql";
import { listAnswerReportBatches } from "../../../graphql/types/listAnswerReportBatches";
import { dateAndTime } from "../../../utils/formatDate";
import useQueryWithPreviousData from "../../../utils/useQueryWithPreviousData";

export const BatchReportsPage = () => {
  const [data] = useQueryWithPreviousData<listAnswerReportBatches>(
    LIST_ANSWER_REPORT_BATCHES,
    "listAnswerReportBatches"
  );
  return (
    <table cellSpacing={4} cellPadding={4}>
      <tbody>
        {data?.batches?.map((batch) => (
          <tr key={batch.batchNumber}>
            <td>
              <Link to={`/admin/batchreport/${batch.batchNumber}`}>
                {batch.batchNumber}
              </Link>
            </td>
            <td>{batch.title}</td>
            <td>{dateAndTime(batch.createdAt)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
