export const formatDecimal = (
  number?: number | undefined,
  options?: Intl.NumberFormatOptions
) =>
  (number || 0).toLocaleString("sv-SE", {
    ...options,
    minimumFractionDigits: options?.minimumFractionDigits ?? 0,
    maximumFractionDigits: options?.maximumFractionDigits ?? 1,
  });

export const formatPercentage = (
  numerator: number,
  denominator: number,
  options?: Intl.NumberFormatOptions
) =>
  denominator === 0
    ? "0 %"
    : formatDecimal(numerator / denominator, {
        style: "percent",
        maximumFractionDigits: 0,
        ...options,
      });
