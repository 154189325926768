import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  PUSH_SYSTEM_ANNOUNCEMENT_TO_USER_INVITATION,
  USER_INVITATION,
} from "../../../../graphql/queries.gql";
import { PushUserInvitationDialogContentProps } from "./types";
import { UserInvitation } from "../../../../graphql/helperTypes";
import { Controller, useForm } from "react-hook-form";
import { NotificationIcon } from "../NotificationButton";
import {
  pushSystemAnnouncementToUserInvitaionParts,
  pushSystemAnnouncementToUserInvitaionPartsVariables,
} from "../../../../graphql/types/pushSystemAnnouncementToUserInvitaionParts";
import {
  userInvitation,
  userInvitationVariables,
} from "../../../../graphql/types/userInvitation";

const iconSize = "1em";

const useStyles = makeStyles((theme) =>
  createStyles({
    actionsRow: {
      marginBottom: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
    },
    row: {
      marginBottom: theme.spacing(1),
    },
    row2: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    icon: {
      width: iconSize,
      height: iconSize,
    },
  })
);

interface FormContentProps extends PushUserInvitationDialogContentProps {
  invitation: UserInvitation;
}

interface FormValues {
  messageToCreatedBy: string;
  messageToUsedBy: string;
}

function FormContent({
  data,
  onRequestClose,
  onRequestSnackbarMessage,
  invitation,
}: FormContentProps) {
  const styles = useStyles();
  const { handleSubmit, formState, errors, control } = useForm<FormValues>();

  const [pushMutation] = useMutation<
    pushSystemAnnouncementToUserInvitaionParts,
    pushSystemAnnouncementToUserInvitaionPartsVariables
  >(PUSH_SYSTEM_ANNOUNCEMENT_TO_USER_INVITATION);

  const handleSubmitPayout = async (input: FormValues) => {
    await pushMutation({
      variables: {
        invitationId: data.invitationId,
        messageToCreatedBy: input.messageToCreatedBy,
        messageToUsedBy: input.messageToUsedBy,
      },
    });
    const createdName =
      invitation.createdByConnection.profileConnection.displayName;
    const usedName = invitation.usedByConnection?.profileConnection.displayName;
    onRequestSnackbarMessage(
      `Notification pushed to ${createdName} and ${usedName}`
    );
    onRequestClose();
  };

  const isLocked = formState.isSubmitting;

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSubmitPayout)}>
        <DialogTitle id="user-notification-dialog-title">
          Send push-notification to invitation parts
        </DialogTitle>
        <DialogContent>
          <div className={styles.row}>
            <Typography
              variant="overline"
              className={styles.header}
              color="textSecondary"
            >
              Message to the invited
            </Typography>
            <Typography variant="body2">
              <strong>
                {invitation.usedByConnection?.profileConnection.displayName}
              </strong>
            </Typography>
          </div>

          <Controller
            name="messageToUsedBy"
            control={control}
            defaultValue={`Tack för att du gått med i Again! För att få ersättning behöver du lägga till fler bankkopplingar under ”Inställningar” i appen. `}
            rules={{
              required: "Enter a message",
              maxLength: {
                value: 256,
                message: "The max length of a push message is 256 characters",
              },
            }}
            render={({ onBlur, onChange, value, name }) => (
              <TextField
                autoFocus
                label="Message"
                multiline
                fullWidth
                variant="outlined"
                disabled={isLocked}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!errors.messageToUsedBy}
                helperText={errors.messageToUsedBy?.message}
              />
            )}
          />

          <div className={styles.row2}>
            <Typography
              variant="overline"
              className={styles.header}
              color="textSecondary"
            >
              Message to the inviter
            </Typography>
            <Typography variant="body2">
              <strong>
                {invitation.createdByConnection.profileConnection.displayName}
              </strong>
            </Typography>
          </div>

          <Controller
            name="messageToCreatedBy"
            control={control}
            defaultValue={`${
              invitation.usedByConnection?.profileConnection.displayName ||
              "Din vän"
            } har gått med i Again! Du har fått poäng i tävlingen 🤗 För att få bonus behöver personen lägga till fler bankkopplingar.`}
            rules={{
              required: "Enter a message",
              maxLength: {
                value: 256,
                message: "The max length of a push message is 256 characters",
              },
            }}
            render={({ onBlur, onChange, value, name }) => (
              <TextField
                label="Message"
                multiline
                fullWidth
                variant="outlined"
                disabled={isLocked}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!errors.messageToCreatedBy}
                helperText={errors.messageToCreatedBy?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={onRequestClose}
            color="secondary"
            disabled={isLocked}
          >
            Close
          </Button>
          <Button
            type="submit"
            color="primary"
            startIcon={
              formState.isSubmitting ? (
                <CircularProgress size={iconSize} />
              ) : (
                <NotificationIcon className={styles.icon} />
              )
            }
            disabled={isLocked}
          >
            Push
          </Button>
        </DialogActions>
      </form>
    </Box>
  );
}

export default function PushUserInvitationDialogContent(
  props: PushUserInvitationDialogContentProps
) {
  const invitationResponse = useQuery<userInvitation, userInvitationVariables>(
    USER_INVITATION,
    {
      variables: { userInvitationId: props.data.invitationId },
      fetchPolicy: "cache-and-network",
    }
  );
  const invitation = invitationResponse.data?.userInvitation;
  return invitation ? <FormContent {...props} invitation={invitation} /> : null;
}
