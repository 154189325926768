import React from "react";
import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { companyDisplayFragment } from "../graphql/types/companyDisplayFragment";
import { LogoDisplay } from "../routes/Admin/Assign/CompanyThumbnail";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    companyRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
    },
  })
);

export const SmallCompanyDisplay = ({
  company,
}: {
  company:
    | Pick<companyDisplayFragment, "companyName" | "logoThumbnailUrl">
    | undefined
    | null;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.companyRow}>
      {company?.logoThumbnailUrl && (
        <LogoDisplay logoUrl={company.logoThumbnailUrl} size={24} />
      )}
      <Typography variant="body2">{company?.companyName}</Typography>
    </div>
  );
};
