import React from "react";
import { truncateText } from "../utils/textUtils";

export const TruncatedText = ({
  value,
  maxLength = 10,
}: {
  value: string | null | undefined | number;
  maxLength?: number;
}) => {
  const text = value?.toString() || "";
  return <span title={text}>{truncateText(text, maxLength)}</span>;
};
