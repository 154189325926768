import { useMutation, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  APP_TEXT_DOCUMENT_BY_ID,
  SET_APP_TEXT_DOCUMENT_ARCHIVED,
  UPDATE_APP_TEXT_DOCUMENT,
} from "../../../graphql/queries.gql";
import {
  AppTextDocumentKind,
  SupportedLanguageCode,
} from "../../../graphql/types/global";
import {
  appTextDocumentById,
  appTextDocumentByIdVariables,
  appTextDocumentById_appTextDocumentById_document,
  appTextDocumentById_appTextDocumentById_document_campaignConnection,
} from "../../../graphql/types/appTextDocumentById";
import {
  UpdateAppTextDocument,
  UpdateAppTextDocumentVariables,
} from "../../../graphql/types/UpdateAppTextDocument";
import { format } from "date-fns";
import WarningPaper from "./WarningPaper";
import { CompanyThumbnail } from "../Assign/CompanyThumbnail";
import { LabelledChip } from "../../../components/LabelledChip";
import {
  documentLanguageCodes,
  emptyLanguagesRecord,
} from "../../../utils/getSupportedLanguageCodes";
import {
  setAppTextDocumentArchived,
  setAppTextDocumentArchivedVariables,
} from "../../../graphql/types/setAppTextDocumentArchived";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  chip: {},
  buttons: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  campaignLogoWrapper: {
    marginRight: theme.spacing(0.5),
  },
}));

interface DocumentMetadata {
  kind: AppTextDocumentKind;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  campaign: appTextDocumentById_appTextDocumentById_document_campaignConnection | null;
}

export default function EditDocument() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [document, setDocument] = useState<DocumentMetadata | null>(null);
  const [snapshot, setSnapshot] =
    useState<appTextDocumentById_appTextDocumentById_document | null>(null);
  const [markdownByLanguage, setMarkdownByLanguage] =
    useState(emptyLanguagesRecord);
  const [snackbarState, setSnackbarState] = React.useState({
    open: false,
    message: "",
  });
  const [setArchivedMutation, { loading: setArchivedLoading }] = useMutation<
    setAppTextDocumentArchived,
    setAppTextDocumentArchivedVariables
  >(SET_APP_TEXT_DOCUMENT_ARCHIVED);

  const setMarkdownFromDocument = ({
    allContentConnection,
  }: Pick<
    appTextDocumentById_appTextDocumentById_document,
    "allContentConnection"
  >) => {
    const allMarkdown = allContentConnection.reduce(
      (acc, { languageCode, markdown }) => ({
        ...acc,
        [languageCode]: markdown,
      }),
      emptyLanguagesRecord
    );
    setMarkdownByLanguage(allMarkdown);
  };

  const { loading: fetching } = useQuery<
    appTextDocumentById,
    appTextDocumentByIdVariables
  >(APP_TEXT_DOCUMENT_BY_ID, {
    fetchPolicy: "network-only",
    variables: { id },
    onCompleted: (response) => {
      const document = response.appTextDocumentById.document;
      if (document) {
        setDocument({
          kind: document.kind,
          createdBy: document.createdByConnection.displayName,
          updatedBy: document.updatedByConnection.displayName,
          createdAt: format(new Date(document.createdAt), "yyyy-MM-dd HH:mm"),
          updatedAt: format(new Date(document.updatedAt), "yyyy-MM-dd HH:mm"),
          campaign: document.campaignConnection,
        });
        setSnapshot(document);
        setMarkdownFromDocument(document);
      }
    },
  });

  const [updateMutation, { loading: updating }] = useMutation<
    UpdateAppTextDocument,
    UpdateAppTextDocumentVariables
  >(UPDATE_APP_TEXT_DOCUMENT);

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const loading = fetching || updating || setArchivedLoading;

  const changeHandler =
    (
      language: SupportedLanguageCode
    ): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    (evt) => {
      setMarkdownByLanguage({
        ...markdownByLanguage,
        [language]: evt.target.value,
      });
    };

  const onSubmitHandler = (languageCode: SupportedLanguageCode) => () => {
    if (
      !window.confirm(
        `WARNING: Did you read, understand, and accept the Danger Zone™ warning?`
      )
    ) {
      return;
    }

    updateMutation({
      variables: {
        input: {
          appDocumentId: id,
          markdown: markdownByLanguage[languageCode],
          languageCode,
        },
      },
    }).then((response) => {
      const updated = response.data?.updateAppTextDocument.document;
      if (document && updated) {
        setDocument({
          ...document,
          updatedBy: updated.updatedByConnection.displayName,
          updatedAt: format(new Date(updated.updatedAt), "yyyy-MM-dd HH:mm"),
        });
        setSnackbarState({
          open: true,
          message: `Document was updated!`,
        });
      }
    });
  };

  const toggleArchived = async () => {
    if (!snapshot) {
      return;
    }
    const response = await setArchivedMutation({
      variables: {
        appTextDocumentById: snapshot.id,
        archived: !snapshot.isArchived,
      },
    });
    const doc = response.data?.setAppTextDocumentArchived.document;
    if (doc) {
      setSnapshot({ ...snapshot, isArchived: doc.isArchived });
    }
  };

  return (
    <>
      <WarningPaper />
      <Paper className={classes.paper}>
        <div className={classes.chips}>
          <LabelledChip
            className={classes.chip}
            label="Kind"
            text={document?.kind}
          />
          <LabelledChip
            className={classes.chip}
            label="Created by"
            text={document?.createdBy}
          />
          <LabelledChip
            className={classes.chip}
            label="Created at"
            text={document?.createdAt}
          />
          <LabelledChip
            className={classes.chip}
            label="Updated by"
            text={document?.updatedBy}
          />
          <LabelledChip
            className={classes.chip}
            label="Updated at"
            text={document?.updatedAt}
          />
          {document?.campaign ? (
            <LabelledChip
              className={classes.chip}
              label="Campaign"
              color="primary"
              text={
                <span>
                  <Link to={`/admin/campaign/${document.campaign.id}`}>
                    {document.campaign.companyConnection?.logoThumbnailUrl ? (
                      <span className={classes.campaignLogoWrapper}>
                        <CompanyThumbnail
                          logoUrl={
                            document.campaign.companyConnection.logoThumbnailUrl
                          }
                          size={20}
                        />
                      </span>
                    ) : null}
                    {document.campaign.description}
                  </Link>{" "}
                  – <em>only applies to users in this campaign</em>
                </span>
              }
            />
          ) : null}
          <Button
            size="small"
            variant="outlined"
            onClick={toggleArchived}
            disabled={loading}
          >
            {snapshot?.isArchived ? "Unarchive" : "Archive"}
          </Button>
        </div>

        {documentLanguageCodes.map((language) => (
          <>
            <TextField
              key={language}
              id="outlined-multiline-flexible"
              label={`Markdown content: ${language}`}
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              value={markdownByLanguage[language]}
              onChange={changeHandler(language)}
              disabled={loading}
            />

            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitHandler(language)}
                disabled={loading}
              >
                Save ({language})
              </Button>
            </div>
          </>
        ))}

        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          open={snackbarState.open}
          onClose={handleSnackbarClose}
          message={snackbarState.message}
          autoHideDuration={4000}
        />
      </Paper>
    </>
  );
}
