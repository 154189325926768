import React, { useMemo } from "react";
import { OmitType } from "../../../../utils/omitType";
import { accountsCreatedByCampaignMetricsFragment } from "../../../../graphql/types/accountsCreatedByCampaignMetricsFragment";
import { AccountsCreatedMetrics, MetricsRow } from "./AccountsCreatedMetrics";

type Props = {
  createdAccountsMetrics: OmitType<accountsCreatedByCampaignMetricsFragment>[];
};

type ReduceState = Record<"1" | "2" | "*", MetricsRow>;

const useMappedMetrics = (
  metrics: Props["createdAccountsMetrics"]
): MetricsRow[] => {
  return useMemo(() => {
    const initialState: ReduceState = {
      "1": {
        totalCount: 0,
        createdBankConnectionCount: 0,
        syncedTransactionsCount: 0,
        title: "First degree invites",
      },
      "2": {
        totalCount: 0,
        createdBankConnectionCount: 0,
        syncedTransactionsCount: 0,
        title: "Second degree invites",
      },
      "*": {
        totalCount: 0,
        createdBankConnectionCount: 0,
        syncedTransactionsCount: 0,
        title: "Any degree invites",
      },
    };

    const increaseCounts = (
      key: keyof ReduceState,
      acc: ReduceState,
      row: OmitType<accountsCreatedByCampaignMetricsFragment>
    ) => {
      const entry = acc[key];
      entry.totalCount += row.totalCount;
      entry.createdBankConnectionCount += row.createdBankConnectionCount;
      entry.syncedTransactionsCount += row.syncedTransactionsCount;
    };

    return Object.values(
      metrics.reduce((acc, row) => {
        const key: keyof ReduceState | null =
          row.invitationDegree === 1
            ? "1"
            : row.invitationDegree === 2
            ? "2"
            : null;
        if (key !== null) {
          increaseCounts(key, acc, row);
        }
        if (row.invitationDegree > 0) {
          increaseCounts("*", acc, row);
        }
        return acc;
      }, initialState)
    );
  }, [metrics]);
};

export const AccountsCreatedByCampaignMetrics = (props: Props) => {
  const metrics = useMappedMetrics(props.createdAccountsMetrics);
  return <AccountsCreatedMetrics metrics={metrics} />;
};
