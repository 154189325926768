import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { InputListSort, DBSortOrder } from "../../../graphql/types/global";
import {
  CAMPAIGN,
  CAMPAIGN_INSTANCES,
  CAMPAIGN_METRICS,
} from "../../../graphql/queries.gql";
import { CampaignInstance } from "../../../graphql/helperTypes";
import CampaignInstancesTable from "./CampaignInstancesTable";
import CampaignDetailsFilter from "./CampaignDetailsFilter";
import {
  campaignInstances,
  campaignInstancesVariables,
} from "../../../graphql/types/campaignInstances";
import { useParams } from "react-router-dom";
import { campaign, campaignVariables } from "../../../graphql/types/campaign";
import {
  campaignMetrics,
  campaignMetricsVariables,
} from "../../../graphql/types/campaignMetrics";
import { CampaignMetricsPaper } from "./CampaignMetricsPaper";
import { Box, Typography } from "@material-ui/core";

const CampaignDetailsPage: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRequestSort = useCallback(
    (property: keyof CampaignInstance) => {
      const supportedProperties: (keyof CampaignInstance)[] = ["createdAt"];
      if (!supportedProperties.includes(property)) {
        return;
      }
      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const {
    data: instancesData,
    refetch: refetchInstances,
    loading,
  } = useQuery<campaignInstances, campaignInstancesVariables>(
    CAMPAIGN_INSTANCES,
    {
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        paging: { page, size: pageSize },
        sort: sorting,
        campaignId,
      },
    }
  );

  const { data: campaignData, refetch: refetchCampaign } = useQuery<
    campaign,
    campaignVariables
  >(CAMPAIGN, {
    variables: { campaignId },
    fetchPolicy: "cache-and-network",
  });

  const { data: metricsData, refetch: refetchMetrics } = useQuery<
    campaignMetrics,
    campaignMetricsVariables
  >(CAMPAIGN_METRICS, {
    variables: { campaignId },
    fetchPolicy: "cache-and-network",
  });

  const handleRefetch = useCallback(() => {
    refetchInstances();
    refetchCampaign();
    refetchMetrics();
  }, [refetchInstances, refetchCampaign, refetchMetrics]);

  return (
    <div>
      <CampaignDetailsFilter
        loading={loading}
        onRequestRefetch={handleRefetch}
        campaign={campaignData?.campaign}
      />
      {metricsData ? (
        <CampaignMetricsPaper
          metrics={metricsData.campaignMetrics}
          company={campaignData?.campaign.companyConnection}
        />
      ) : null}
      {instancesData ? (
        <Box my={2}>
          <Typography gutterBottom variant="subtitle2">
            Instances
          </Typography>

          <CampaignInstancesTable
            instances={instancesData.campaignInstances.instances}
            pageInfo={instancesData.campaignInstances.pageInfo}
            onRequestRefetch={handleRefetch}
            onRequestSort={handleRequestSort}
            onRequestPage={handleRequestPage}
            onRequestPageSize={handleRequestPageSize}
            sorting={sorting}
          />
        </Box>
      ) : null}
    </div>
  );
};

export default CampaignDetailsPage;
