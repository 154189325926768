import React, { memo, useCallback, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Paper from "@material-ui/core/Paper";
import { DBSortOrder, InputListSort } from "../../../graphql/types/global";
import { asDirection } from "../../../utils/tableUtils";
import { DemoSurveyBatch } from "../../../graphql/helperTypes";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  assignedSurveyBatchUsers,
  assignedSurveyBatchUsersVariables,
} from "../../../graphql/types/assignedSurveyBatchUsers";
import {
  ASSIGNABLE_SURVEY_BATCH_USERS,
  ASSIGNED_SURVEY_BATCH_USERS,
  COMPANY_DEMO_DETAILS,
  SET_ACTIVE_LIVE_STATS_BATCH,
  SET_REWARD_DEFINITION_ENABLED,
} from "../../../graphql/queries.gql";
import AssignedBatchUsersTable from "./AssignedBatchUsersTable";
import clsx from "clsx";
import AssignableBatchUsersTable from "./AssignableBatchUsersTable";
import {
  allAssignableSurveyBatchUsers,
  allAssignableSurveyBatchUsersVariables,
} from "../../../graphql/types/allAssignableSurveyBatchUsers";
import {
  setActiveLiveStatsBatch,
  setActiveLiveStatsBatchVariables,
} from "../../../graphql/types/setActiveLiveStatsBatch";
import {
  setRewardDefinitionEnabled,
  setRewardDefinitionEnabledVariables,
} from "../../../graphql/types/setRewardDefinitionEnabled";
import { noop } from "../../../utils/noop";
import { TimestampDisplay } from "../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof DemoSurveyBatch;
  label: string;
  type: "string" | "title" | "date" | "usersConnection";
}

const headCells: HeadCell[] = [
  { id: "id", type: "string", label: "ID" },
  { id: "title", type: "title", label: "Title" },
  { id: "createdAt", type: "date", label: "Created" },
  { id: "expiresAt", type: "date", label: "Expires" },
  {
    id: "assignedBatchUsersConnection",
    type: "usersConnection",
    label: "Assigned users",
  },
];

export type OnRequestSort = (property: keyof DemoSurveyBatch) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps {
  batches: DemoSurveyBatch[];
  companyDemoId: string;
  onRequestRefetch: () => any;
  onRequestSort: OnRequestSort;
  onRequestPage: OnRequestPage;
  onRequestPageSize: OnRequestPageSize;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DemoSurveyBatch) => (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell, i) => (
          <TableCell
            key={headCell.id}
            align={cellAlign(headCell)}
            className={classes.noWrapCell}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) => "left" as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    fullWidthCell: {
      width: "100%",
    },
    companyRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
    },
    expandButtonCell: {
      width: "44px",
      paddingRight: 0,
      paddingLeft: "8px",
    },
    actions: {
      display: "flex",
      flexDirection: "row",
      gap: `${theme.spacing(1)}px`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    liveStats: {
      display: "inline-flex",
      fontSize: "0.75rem",
      lineHeight: "0.85rem",
      marginLeft: "0.5rem",
      color: "#178017",
    },
    liveStatsIcon: {
      width: "0.75rem",
      height: "0.75rem",
      marginRight: "0.25rem",
      color: "#178017",
    },
  })
);

const Row = ({
  batch,
  companyDemoId,
  onRequestRefetch,
}: {
  batch: DemoSurveyBatch;
  companyDemoId: string;
  onRequestRefetch: () => any;
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fetchAssigned, assignedQuery] = useLazyQuery<
    assignedSurveyBatchUsers,
    assignedSurveyBatchUsersVariables
  >(ASSIGNED_SURVEY_BATCH_USERS);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: assignableUsersData,
    loading: assignableUsersDataLoading,
    refetch: assignableUsersDataRefetch,
  } = useQuery<
    allAssignableSurveyBatchUsers,
    allAssignableSurveyBatchUsersVariables
  >(ASSIGNABLE_SURVEY_BATCH_USERS, {
    skip: !open,
    fetchPolicy: "cache-and-network",
    variables: {
      batchNumber: batch.id,
      paging: { page, size: pageSize },
    },
  });

  const onRefresh = () => {
    assignedQuery.refetch();
    assignableUsersDataRefetch();
    onRequestRefetch();
  };

  useEffect(() => {
    if (open) {
      fetchAssigned({
        variables: { batchNumber: batch.id },
        fetchPolicy: "cache-and-network",
      });
    }
  }, [open, batch.id, fetchAssigned]);

  const isFetching = assignedQuery.loading || assignableUsersDataLoading;

  const renderCell = (cell: HeadCell, row: DemoSurveyBatch) => {
    switch (cell.type) {
      case "usersConnection":
        return row.assignedBatchUsersConnection.pageInfo.totalCount;
      case "date":
        return <TimestampDisplay timestamp={row[cell.id] as string} />;
      case "title":
        return (
          <span>
            {row.title}
            {row.isLiveStatsEnabled ? (
              <span className={classes.liveStats}>
                <RssFeedIcon className={classes.liveStatsIcon} /> Live stats
                active
              </span>
            ) : null}
          </span>
        );
      default:
        return row[cell.id];
    }
  };

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  return (
    <>
      <TableRow hover tabIndex={-1} key={batch.id}>
        <TableCell className={classes.expandButtonCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {headCells.map((cell, i) => (
          <TableCell
            align={cellAlign(cell)}
            key={`${cell.id}-${batch.id}`}
            className={clsx({
              [classes.fullWidthCell]: cell.id === "title",
              [classes.noWrapCell]: cell.type === "date",
            })}
          >
            {renderCell(cell, batch)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box paddingY={2}>
              <Box display="flex" flexDirection="row">
                <RewardEnabledSection batch={batch} />
                <LiveStatsSection batch={batch} />
              </Box>
              <UserSelectionStrategyDescription batch={batch} />
              {assignableUsersData ? (
                <AssignableBatchUsersTable
                  batch={batch}
                  companyDemoId={companyDemoId}
                  onRequestRefetch={onRefresh}
                  isFetching={isFetching}
                  pageInfo={
                    assignableUsersData.allAssignableSurveyBatchUsers.pageInfo
                  }
                  users={
                    assignableUsersData.allAssignableSurveyBatchUsers.users
                  }
                  onRequestPage={handleRequestPage}
                  onRequestPageSize={handleRequestPageSize}
                />
              ) : (
                <CircularProgress />
              )}
              {assignedQuery.data ? (
                <AssignedBatchUsersTable
                  batch={batch}
                  companyDemoId={companyDemoId}
                  isFetching={isFetching}
                  onRequestRefetch={onRefresh}
                  onRequestSort={noop}
                  pageInfo={
                    assignedQuery.data.assignedSurveyBatchUsers.pageInfo
                  }
                  users={assignedQuery.data.assignedSurveyBatchUsers.users}
                  sorting={{ sort: DBSortOrder.ASC, sortField: "createdAt" }}
                />
              ) : (
                <CircularProgress />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const RewardEnabledSection: React.FC<{
  batch: DemoSurveyBatch;
}> = ({ batch }) => {
  const [setEnabledMutation, { loading }] = useMutation<
    setRewardDefinitionEnabled,
    setRewardDefinitionEnabledVariables
  >(SET_REWARD_DEFINITION_ENABLED, {
    refetchQueries: [COMPANY_DEMO_DETAILS],
  });

  const onToggle = () => {
    const def = batch.rewardDefinitionConnection;
    if (!def) {
      return null;
    }
    setEnabledMutation({
      variables: { isEnabled: !def.isEnabled, rewardDefinitionId: def.id },
    });
  };

  if (!batch.rewardDefinitionConnection) {
    return null;
  }

  const { isEnabled } = batch.rewardDefinitionConnection;

  return (
    <Box display="flex" flexDirection="row" alignItems="center" px={1}>
      <Switch
        disabled={loading}
        color="primary"
        name="rewardIsEnabled"
        checked={isEnabled}
        onChange={onToggle}
        size="small"
      />
      <Typography variant="body2">
        Reward badge is{" "}
        <Typography
          variant="subtitle2"
          component="span"
          color={isEnabled ? "primary" : "textSecondary"}
        >
          {isEnabled ? "enabled" : "disabled"}
        </Typography>
      </Typography>
    </Box>
  );
};

const LiveStatsSection: React.FC<{
  batch: DemoSurveyBatch;
}> = ({ batch }) => {
  const classes = useStyles();
  const [setActiveMutation, { loading }] = useMutation<
    setActiveLiveStatsBatch,
    setActiveLiveStatsBatchVariables
  >(SET_ACTIVE_LIVE_STATS_BATCH, {
    refetchQueries: [COMPANY_DEMO_DETAILS],
  });

  const onSetActive = () => {
    setActiveMutation({ variables: { batchNumber: batch.id } });
  };

  return (
    <Box margin={2}>
      {batch.isLiveStatsEnabled ? (
        <span className={classes.liveStats}>
          <RssFeedIcon className={classes.liveStatsIcon} /> This is the active
          live stats batch
        </span>
      ) : (
        <Button
          onClick={onSetActive}
          disabled={loading}
          type="button"
          color="primary"
          variant="outlined"
          size="small"
          startIcon={<RssFeedIcon fontSize="small" />}
        >
          Make this the active live stats batch
        </Button>
      )}
    </Box>
  );
};

const UserSelectionStrategyDescription: React.FC<{
  batch: DemoSurveyBatch;
}> = ({ batch }) => {
  const strategy = batch.userSelectionStrategyConnection;
  if (!strategy) {
    return (
      <Box margin={2}>
        <Typography variant="h6">User selection strategy</Typography>
        <Typography>No strategy defined</Typography>
      </Box>
    );
  }

  const batchCompanies = strategy.mappedCompaniesConnection
    .filter((row) => row.includeInBatch)
    .map((row) => row.companyConnection.companyName)
    .sort((a, b) => a.localeCompare(b))
    .join(", ");

  const spendShareCompanies = strategy.mappedCompaniesConnection
    .filter((row) => row.includeInSpendShare)
    .map((row) => row.companyConnection.companyName)
    .sort((a, b) => a.localeCompare(b))
    .join(", ");

  return (
    <Box margin={2}>
      <Typography variant="h6">User selection strategy</Typography>
      <Typography>
        {batchCompanies.length === 0
          ? "Explicit user filter"
          : `${
              strategy.description ?? "All customers from mapped companies"
            } (where mapped companies = {${batchCompanies}})`}
      </Typography>
      {batchCompanies.length > 0 && (
        <Typography>Spend share companies = {spendShareCompanies}</Typography>
      )}
    </Box>
  );
};

const DemoSurveyBatchesTable = memo<TableProps>(
  ({
    onRequestPage,
    onRequestPageSize,
    onRequestSort,
    onRequestRefetch,
    sorting,
    batches,
    companyDemoId,
  }) => {
    const classes = useStyles();
    const handleChangePage = (_: any, page: number) => onRequestPage(page + 1);
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onRequestPageSize(parseInt(event.target.value, 10));
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                sorting={sorting}
                onRequestSort={onRequestSort}
              />
              <TableBody>
                {batches.map((batch) => (
                  <Row
                    key={batch.id}
                    batch={batch}
                    companyDemoId={companyDemoId}
                    onRequestRefetch={onRequestRefetch}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[1000]}
            count={batches.length}
            rowsPerPage={1000}
            page={0}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
);

export default DemoSurveyBatchesTable;
