const LOCAL_API = "http://localhost:3001/graphql";

export interface IWindowLocation {
  host: string;
}

export interface IWindowWithLocation {
  location: IWindowLocation;
}

export const graphQLEndpoint = (windowObject: IWindowWithLocation = window) => {
  const { host } = windowObject.location;
  try {
    const apiUrl = localStorage.getItem("againApiUrl");
    if (apiUrl && apiUrl?.length > 0) {
      console.warn("using againApiUrl from localStorage", { apiUrl });
      return apiUrl;
    }
  } catch {}

  if (/^localhost:(\d+)/i.test(host)) {
    return LOCAL_API;
  } else if (/^manage.again.app/i.test(host)) {
    return "https://api.again.app/graphql";
  } else {
    const reStackPrefix = /(.*)-manage\.again\.app$/;
    const matches = host.match(reStackPrefix);
    if (matches?.length === 2) {
      const stackPrefix = matches[1];
      return `https://${stackPrefix}-api.again.app/graphql`;
    } else {
      throw new Error("Unexpected host name, cannot infer stack name");
    }
  }
};
