import React, { useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { DisbursementType } from "../../../../graphql/types/global";
import SwishAppIcon from "../../../../components/icons/SwishAppIcon";
import logUnhandledSwitch from "../../../../utils/logUnhandledSwitch";
import { dateAndTime } from "../../../../utils/formatDate";
import { logError } from "../../../../utils/logger";
import {
  ADD_DISBURSEMENT_ONBOARDING,
  ADD_DISBURSEMENT_USER_REFERRAL,
  SYSTEM_USER,
  USER_INVITATION,
} from "../../../../graphql/queries.gql";
import {
  AddDisbursementDialogContentProps,
  AddDisbursementDialogData,
} from "./types";
import UserPhoneDisplay, { hasMobilePhoneNumber } from "../UserPhoneDisplay";
import {
  systemUser,
  systemUserVariables,
} from "../../../../graphql/types/systemUser";
import { SystemUser, UserInvitation } from "../../../../graphql/helperTypes";
import {
  userInvitation,
  userInvitationVariables,
} from "../../../../graphql/types/userInvitation";
import {
  addDisbursementOnboarding,
  addDisbursementOnboardingVariables,
} from "../../../../graphql/types/addDisbursementOnboarding";
import {
  addDisbursementUserReferral,
  addDisbursementUserReferralVariables,
} from "../../../../graphql/types/addDisbursementUserReferral";
import { DisbursementMinimalFragment } from "../../../../graphql/types/DisbursementMinimalFragment";

const iconSize = "1em";

const useStyles = makeStyles((theme) =>
  createStyles({
    amountField: {
      marginBottom: theme.spacing(2),
    },
    swishIcon: {
      width: iconSize,
      height: iconSize,
    },
  })
);

const useContentStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    row: {
      marginBottom: theme.spacing(3),
    },
  })
);

function OnboardingContent(props: { user: SystemUser }) {
  const styles = useContentStyles();
  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Swish payout type
        </Typography>
        <Typography variant="body2">
          <strong>Onboarding</strong>
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Recipient
        </Typography>
        <Typography variant="body2">
          <strong>{props.user.displayName}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {props.user.email}
          <br />
          <UserPhoneDisplay user={props.user} />
        </Typography>
      </div>
    </div>
  );
}

function ReferralContent({
  user,
  invitation,
}: {
  user: SystemUser;
  invitation: UserInvitation;
}) {
  const styles = useContentStyles();

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Swish payout type
        </Typography>
        <Typography variant="body2">
          <strong>Referral</strong>
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Recipient (the inviter)
        </Typography>
        <Typography variant="body2">
          <strong>{user.displayName}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {user.email}
          <br />
          <UserPhoneDisplay user={user} />
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          The invited user
        </Typography>
        <Typography variant="body2">
          <strong>
            {invitation.usedByConnection?.profileConnection.displayName}
          </strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Joined {dateAndTime(invitation.usedAt)}
          <br />
          First transaction synced{" "}
          {dateAndTime(invitation.firstTransactionCreatedAt)}
        </Typography>
      </div>
    </div>
  );
}

function makeDefaultMessage(
  data: AddDisbursementDialogData,
  invitation: UserInvitation | undefined
) {
  const type = data.disbursementType;
  switch (type) {
    case DisbursementType.onboarding:
      return `Nu kan dina varumärken belöna dig genom Again!`;
    case DisbursementType.referral:
      const invitedName =
        invitation?.usedByConnection?.profileConnection.displayName ||
        "Din kompis";
      return `Well done! ${invitedName} har gått med! :)`;
    default:
      logUnhandledSwitch(type);
      return "";
  }
}

type FormValues = {
  message: string;
  amountText: string;
};

interface FormContentProps extends AddDisbursementDialogContentProps {
  user: SystemUser;
  invitation: UserInvitation | undefined;
}

const defaultAmountText = "10";

function FormContent({
  data,
  onRequestClose,
  onRequestSnackbarMessage,
  user,
  invitation,
}: FormContentProps) {
  const styles = useStyles();
  const { handleSubmit, formState, errors, control } = useForm<FormValues>();

  const onClose = useCallback(
    () => onRequestClose(undefined),
    [onRequestClose]
  );

  const [addOnboardingMutation] = useMutation<
    addDisbursementOnboarding,
    addDisbursementOnboardingVariables
  >(ADD_DISBURSEMENT_ONBOARDING);

  const [addReferralMutation] = useMutation<
    addDisbursementUserReferral,
    addDisbursementUserReferralVariables
  >(ADD_DISBURSEMENT_USER_REFERRAL);

  const handleSubmitPayout = async (input: FormValues) => {
    if (!hasMobilePhoneNumber(user)) {
      return;
    }

    const onDisbursementCreated = (
      disbursement: DisbursementMinimalFragment | undefined | null
    ) => {
      onRequestSnackbarMessage(`Swish payout created!`);
      onRequestClose(disbursement);
    };

    try {
      const message = input.message;
      if (message.trim() === "") {
        throw new Error("Message must not be empty");
      }
      const amountInCents = parseInt(input.amountText, 10) * 100;
      if (Number.isNaN(amountInCents) || amountInCents <= 0) {
        throw new Error("Amount must be a number > 0");
      }
      const overrides = { message, amountInCents };

      if (data.disbursementType === DisbursementType.onboarding) {
        const userId = user.id;
        const disbursement = await addOnboardingMutation({
          variables: { input: { userId, overrides } },
        });
        onDisbursementCreated(disbursement.data?.addDisbursementOnboarding);
      } else {
        const invitationId = data.userInvitationId;
        const disbursement = await addReferralMutation({
          variables: { input: { invitationId, overrides } },
        });
        onDisbursementCreated(disbursement.data?.addDisbursementUserReferral);
      }
    } catch (err: any) {
      logError(err);
      alert(err.message);
    }
  };

  const canSubmit = hasMobilePhoneNumber(user);
  const isLocked = formState.isSubmitting;

  return (
    <Box>
      <form onSubmit={handleSubmit(handleSubmitPayout)}>
        <DialogTitle id="add-disbursement-dialog-title">
          Manual Swish payout
        </DialogTitle>
        <DialogContent>
          {data.disbursementType === DisbursementType.onboarding ? (
            <OnboardingContent user={user} />
          ) : (
            <ReferralContent user={user} invitation={invitation!} />
          )}

          <Controller
            name="amountText"
            control={control}
            defaultValue={defaultAmountText}
            rules={{
              required: "Enter the amount to pay out in SEK",
              min: { value: 1, message: "Amount must be at least 1 SEK" },
              pattern: { value: /^\d+$/, message: "Must be only numbers" },
            }}
            render={({ onBlur, onChange, value, name }) => (
              <TextField
                className={styles.amountField}
                label="Amount to pay out in SEK"
                variant="outlined"
                fullWidth
                autoFocus
                inputMode="numeric"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">kr</InputAdornment>
                  ),
                }}
                disabled={isLocked || !canSubmit}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!errors.amountText}
                helperText={errors.amountText?.message}
              />
            )}
          />

          <Controller
            name="message"
            control={control}
            defaultValue={makeDefaultMessage(data, invitation)}
            rules={{
              required: "Enter a message",
              maxLength: {
                value: 50,
                message: "The max length allowed by Swish is 50 characters",
              },
            }}
            render={({ onBlur, onChange, value, name }) => (
              <TextField
                label="Message"
                multiline
                fullWidth
                variant="outlined"
                disabled={isLocked || !canSubmit}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            onClick={onClose}
            color="secondary"
            disabled={isLocked}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            startIcon={
              formState.isSubmitting ? (
                <CircularProgress size={iconSize} />
              ) : (
                <SwishAppIcon className={styles.swishIcon} />
              )
            }
            disabled={isLocked || !canSubmit}
          >
            Pay now
          </Button>
        </DialogActions>
      </form>
    </Box>
  );
}

export default function AddDisbursementDialogContent(
  props: AddDisbursementDialogContentProps
) {
  const invitationResponse = useQuery<userInvitation, userInvitationVariables>(
    USER_INVITATION,
    {
      variables: {
        userInvitationId:
          props.data.disbursementType === DisbursementType.referral
            ? props.data.userInvitationId
            : "",
      },
      skip: props.data.disbursementType !== DisbursementType.referral,
      fetchPolicy: "cache-and-network",
    }
  );

  const userId =
    props.data.disbursementType === DisbursementType.onboarding
      ? props.data.userId
      : invitationResponse.data?.userInvitation.createdByConnection.id;

  const userResponse = useQuery<systemUser, systemUserVariables>(SYSTEM_USER, {
    variables: { userId: userId! },
    fetchPolicy: "cache-and-network",
    skip: !userId,
  });

  const user = userResponse.data?.systemUser;
  const invitation = invitationResponse.data?.userInvitation;

  const errMessage =
    userResponse.error?.message || invitationResponse.error?.message;

  return user != null &&
    (props.data.disbursementType === DisbursementType.onboarding ||
      invitation != null) ? (
    <FormContent {...props} user={user} invitation={invitation} />
  ) : errMessage ? (
    <div style={{ padding: "50px" }}>
      <Typography variant="subtitle1" color="error">
        Failed to load AddDisbursementDialog
      </Typography>
      <Typography variant="body1">{errMessage}</Typography>
    </div>
  ) : (
    <div style={{ padding: "50px" }}>
      <CircularProgress />
    </div>
  );
}
