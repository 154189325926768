import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { ADD_APP_TEXT_DOCUMENT_WITH_CONTENT } from "../../../graphql/queries.gql";
import {
  AppTextDocumentKind,
  SupportedLanguageCode,
} from "../../../graphql/types/global";
import WarningPaper from "./WarningPaper";
import {
  AddAppTextDocumentWithContent,
  AddAppTextDocumentWithContentVariables,
} from "../../../graphql/types/AddAppTextDocumentWithContent";
import {
  documentLanguageCodes,
  emptyLanguagesRecord,
} from "../../../utils/getSupportedLanguageCodes";

const allKinds = Object.values(AppTextDocumentKind).sort();

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  buttons: {
    paddingTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

export default function CreateDocument() {
  const classes = useStyles();
  const history = useHistory();
  const [markdownByLanguage, setMarkdownByLanguage] =
    useState(emptyLanguagesRecord);
  const [kind, setKind] = useState<AppTextDocumentKind | null>(null);

  const [addMutation, { loading }] = useMutation<
    AddAppTextDocumentWithContent,
    AddAppTextDocumentWithContentVariables
  >(ADD_APP_TEXT_DOCUMENT_WITH_CONTENT);

  const changeHandler =
    (
      language: SupportedLanguageCode
    ): React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    (evt) => {
      setMarkdownByLanguage({
        ...markdownByLanguage,
        [language]: evt.target.value,
      });
    };

  const onSubmit = () => {
    if (kind == null) {
      alert(`Pick document kind`);
      return;
    }

    if (kind !== AppTextDocumentKind.system_announcement) {
      if (
        !window.confirm(
          `WARNING: Did you read, understand, and accept the Danger Zone™ warning?`
        )
      ) {
        return;
      }
    }
    const content = Object.entries(markdownByLanguage)
      .filter(([, markdown]) => markdown !== "")
      .map(([language, markdown]) => ({
        languageCode: language as SupportedLanguageCode,
        markdown,
      }));
    addMutation({ variables: { input: { kind, content } } }).then(
      (response) => {
        const id = response.data?.addAppTextDocumentWithContent.document.id;
        if (id) {
          history.push(`/admin/documents/edit/${id}`);
        }
      }
    );
  };

  return (
    <>
      <WarningPaper />
      <Paper className={classes.paper}>
        <FormControl style={{ minWidth: 250, marginBottom: 20 }}>
          <InputLabel id="document-kind-label">Document kind</InputLabel>
          <Select
            labelId="document-kind-label"
            id="document-kind"
            value={kind}
            label="Document kind"
            onChange={(evt) => setKind(evt.target.value as AppTextDocumentKind)}
            disabled={loading}
          >
            <MenuItem disabled>Pick document kind</MenuItem>
            {allKinds.map((kind) => (
              <MenuItem key={kind} value={kind}>
                {kind}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {documentLanguageCodes.map((language) => (
          <TextField
            key={language}
            id="outlined-multiline-flexible"
            label={`Markdown content: ${language}`}
            variant="outlined"
            multiline
            rows={10}
            fullWidth
            value={markdownByLanguage[language]}
            onChange={changeHandler(language)}
            disabled={loading}
            className={classes.textField}
          />
        ))}

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            Add this document
          </Button>
        </div>
      </Paper>
    </>
  );
}
