import React, { useEffect, useRef } from "react";
import { zitadelConfig } from "../../utils/auth/zitadelConfig";
import { getLocalStorage } from "../../utils/localStorage";
import { CircularProgress } from "@material-ui/core";
import { Layout, useStyles } from "../StartPage/Layout";

const redirectToLogout = ({ token }: { token: string }) => {
  const params = new URLSearchParams({
    token,
    token_type_hint: "access_token",
    client_id: zitadelConfig.clientId,
    post_logout_redirect_uri: zitadelConfig.loggedOutUri,
  });
  window.location.assign(
    `${zitadelConfig.endSessionEndpoint}?${params.toString()}`
  );
};

export const LogOut = () => {
  const didRedirect = useRef(false);
  const classes = useStyles();
  const token = getLocalStorage("accessToken");

  useEffect(() => {
    if (didRedirect.current) return;
    didRedirect.current = true;

    if (token) {
      redirectToLogout({ token });
    } else {
      window.location.assign("/");
    }
  }, [token]);

  return (
    <Layout>
      <div className={classes.root}>
        <div className={classes.form}>
          <h2>Signing out...</h2>
          <CircularProgress />
          {/* {error && <p className={formClasses.errorMessage}>{error}</p>} */}
        </div>
      </div>
    </Layout>
  );
};
