import React from "react";
import { Box } from "@material-ui/core";
import { OmitType } from "../../../utils/omitType";
import { companyDisplayFragment } from "../../../graphql/types/companyDisplayFragment";
import { AccountsCreatedByCampaignMetrics } from "../CampaignDetails/CampaignMetrics/AccountsCreatedByCampaignMetrics";
import { AccountsCreatedForCompanyMetrics } from "../CampaignDetails/CampaignMetrics/AccountsCreatedForCompanyMetrics";
import { SurveyMetrics } from "../CampaignDetails/CampaignMetrics/SurveyMetrics";
import { campaignInstanceMetrics_campaignInstanceMetrics } from "../../../graphql/types/campaignInstanceMetrics";
import { campaignInstanceUsers_campaignInstanceUsers } from "../../../graphql/types/campaignInstanceUsers";

type Props = {
  metrics: OmitType<campaignInstanceMetrics_campaignInstanceMetrics>;
  company: companyDisplayFragment | null | undefined;
  users?: OmitType<campaignInstanceUsers_campaignInstanceUsers>;
};

export const CampaignInstanceMetricsPaper = ({
  company,
  metrics,
  users,
}: Props) => {
  return (
    <Box>
      <AccountsCreatedForCompanyMetrics
        createdAccountsMetrics={metrics.accountsCreatedForCompany}
        company={company}
        users={users}
      />
      <AccountsCreatedByCampaignMetrics
        createdAccountsMetrics={metrics.accountsCreatedByCampaignInstance}
      />
      <Box my={2}>
        <SurveyMetrics surveyMetrics={metrics.surveys} />
      </Box>
    </Box>
  );
};
