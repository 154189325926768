import React from "react";
import { Link, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import AmpStoriesIcon from "@material-ui/icons/AmpStories";
import AssignmentIcon from "@material-ui/icons/Assignment";
import RoomIcon from "@material-ui/icons/Room";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import AppsIcon from "@material-ui/icons/Apps";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { makeStyles } from "@material-ui/core/styles";
import { useDrawerContext } from "../contexts/DrawerContext";

interface WithDrawerProps {
  children?: React.ReactNode;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  withSettingsDrawerRoot: {
    display: "flex",
    flexGrow: 1,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: drawerWidth,
    },
  },
  list: {
    paddingTop: 0,
  },
}));

interface MenuListItemProps {
  path: string;
  icon: React.ReactNode;
  text: string;
}

const useListItemStyles = makeStyles((theme) => ({
  activeListItem: {
    borderLeft: `${theme.spacing(1)}px solid ${theme.palette.secondary.main}`,
  },
  listItem: {
    paddingLeft: `${theme.spacing(3)}px`,
  },
}));

const MenuListItem: React.FC<MenuListItemProps> = ({ path, icon, text }) => {
  const classes = useListItemStyles();
  const location = useLocation();

  return (
    <ListItem
      button
      component={Link}
      to={`${path}`}
      className={
        location.pathname === path ? classes.activeListItem : classes.listItem
      }
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

const WithSettingsDrawer: React.FC<WithDrawerProps> = ({ children }) => {
  const drawerContext = useDrawerContext();

  const classes = useStyles();
  if (!children) {
    return null;
  }
  const drawerContent = (
    <div>
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List className={classes.list}>
          <MenuListItem
            path="/settings/logo"
            icon={<AmpStoriesIcon />}
            text="Logo"
          />
          <MenuListItem
            path="/settings/improvements"
            icon={<AssignmentIcon />}
            text="Improvements"
          />
          <MenuListItem
            path="/settings/locations"
            icon={<RoomIcon />}
            text="Locations"
          />
          <MenuListItem
            path="/settings/supporters"
            icon={<AccessibilityNewIcon />}
            text="Supporters"
          />
          <MenuListItem path="/settings/misc" icon={<AppsIcon />} text="Misc" />
        </List>
      </div>
    </div>
  );

  return (
    <div className={classes.withSettingsDrawerRoot}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            open={drawerContext.isOpen}
            onClose={drawerContext.toggleOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            className={classes.drawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            anchor="left"
            open
          >
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default WithSettingsDrawer;
