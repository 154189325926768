import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { CampaignInstanceMetricsPaper } from "./CampaignInstanceMetricsPaper";
import {
  campaignInstanceMetrics,
  campaignInstanceMetricsVariables,
} from "../../../graphql/types/campaignInstanceMetrics";
import {
  CAMPAIGN,
  CAMPAIGN_INSTANCE,
  CAMPAIGN_INSTANCE_METRICS,
  CAMPAIGN_INSTANCE_USERS,
} from "../../../graphql/queries.gql";
import { useQuery } from "@apollo/client";
import { campaign, campaignVariables } from "../../../graphql/types/campaign";
import CampaignInstanceDetailsFilter from "./CampaignInstanceDetailsFilter";
import {
  campaignInstance,
  campaignInstanceVariables,
} from "../../../graphql/types/campaignInstance";
import {
  campaignInstanceUsers,
  campaignInstanceUsersVariables,
} from "../../../graphql/types/campaignInstanceUsers";
import { CampaignInstanceUsersPaper } from "./CampaignInstanceUsersPaper";

const CampaignInstanceDetailsPage: React.FC = () => {
  const { campaignId, campaignInstanceId } = useParams<{
    campaignId: string;
    campaignInstanceId: string;
  }>();

  const {
    data: campaignData,
    refetch: refetchCampaign,
    loading: campaignLoading,
  } = useQuery<campaign, campaignVariables>(CAMPAIGN, {
    variables: { campaignId },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: campaignInstanceData,
    refetch: refetchCampaignInstance,
    loading: campaignInstanceLoading,
  } = useQuery<campaignInstance, campaignInstanceVariables>(CAMPAIGN_INSTANCE, {
    variables: { campaignInstanceId },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: metricsData,
    refetch: refetchMetrics,
    loading: metricsLoading,
  } = useQuery<campaignInstanceMetrics, campaignInstanceMetricsVariables>(
    CAMPAIGN_INSTANCE_METRICS,
    {
      variables: { campaignInstanceId },
      fetchPolicy: "cache-and-network",
    }
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const {
    data: usersData,
    refetch: refetchUsers,
    loading: usersLoading,
  } = useQuery<campaignInstanceUsers, campaignInstanceUsersVariables>(
    CAMPAIGN_INSTANCE_USERS,
    {
      variables: {
        campaignInstanceId,
        paging: { page, size: pageSize },
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const handleRefetch = useCallback(() => {
    refetchCampaign();
    refetchCampaignInstance();
    refetchMetrics();
    refetchUsers();
  }, [refetchCampaign, refetchCampaignInstance, refetchMetrics, refetchUsers]);

  const loading =
    campaignLoading ||
    campaignInstanceLoading ||
    metricsLoading ||
    usersLoading;

  return (
    <div>
      <CampaignInstanceDetailsFilter
        loading={loading}
        onRequestRefetch={handleRefetch}
        campaign={campaignData?.campaign}
        campaignInstance={campaignInstanceData?.campaignInstance}
      />
      {metricsData && (
        <CampaignInstanceMetricsPaper
          metrics={metricsData.campaignInstanceMetrics}
          company={campaignData?.campaign.companyConnection}
          users={usersData?.campaignInstanceUsers}
        />
      )}
      {usersData && (
        <CampaignInstanceUsersPaper
          users={usersData.campaignInstanceUsers.users}
          pageInfo={usersData.campaignInstanceUsers.pageInfo}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
        />
      )}
    </div>
  );
};

export default CampaignInstanceDetailsPage;
