import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { SystemUser } from "../../../../graphql/helperTypes";
import { DisbursementType } from "../../../../graphql/types/global";
import { SwishPayoutDetailsFragment } from "../../../../graphql/types/SwishPayoutDetailsFragment";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { dateAndTime } from "../../../../utils/formatDate";
import { SwishStatusText } from "./SwishStatusText";
import UserPhoneDisplay from "../UserPhoneDisplay";

const useStyles = makeStyles((theme) =>
  createStyles({
    row: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(1),
      lineHeight: 1,
    },
    noWrap: {
      whiteSpace: "nowrap",
    },
    swishIcon: {
      width: "0.75em",
      height: "0.75em",
    },
  })
);

interface Props {
  payoutDetails:
    | Pick<
        SwishPayoutDetailsFragment,
        "id" | "payoutStatus" | "paidAt" | "message" | "amount"
      >
    | null
    | undefined;
  type: DisbursementType;
  user?: SystemUser;
}

export default function SwishStatusSummary({
  payoutDetails,
  type,
  user,
}: Props) {
  const styles = useStyles();

  return (
    <>
      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Payout type
        </Typography>
        <Typography variant="body2">
          <strong>{type}</strong>
        </Typography>
      </div>

      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Swish Payout ID
        </Typography>
        <Typography variant="body2">
          <strong>{payoutDetails?.id}</strong>
        </Typography>
      </div>

      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Swish status
        </Typography>
        <Typography variant="body2">
          <strong>
            <SwishStatusText payoutStatus={payoutDetails?.payoutStatus} />
          </strong>
        </Typography>
      </div>

      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Swish paid out at
        </Typography>
        <Typography variant="body2">
          <strong>{dateAndTime(payoutDetails?.paidAt, "Pending")}</strong>
        </Typography>
      </div>

      {user && (
        <div className={styles.row}>
          <Typography
            variant="overline"
            className={styles.header}
            color="textSecondary"
          >
            Recipient
          </Typography>
          <Typography variant="body2">
            <strong>{user.displayName}</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user.email}
            <br />
            <UserPhoneDisplay user={user} />
          </Typography>
        </div>
      )}

      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Message
        </Typography>
        <Typography variant="body2">
          <strong>{payoutDetails?.message}</strong>
        </Typography>
      </div>

      <div className={styles.row}>
        <Typography
          variant="overline"
          className={styles.header}
          color="textSecondary"
        >
          Amount in SEK
        </Typography>
        <Typography variant="body2">
          <strong>{formatCurrency(payoutDetails?.amount)}</strong>
        </Typography>
      </div>
    </>
  );
}
