import React from "react";
import SvgIcon, { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import { DefaultComponentProps } from "@material-ui/core/OverridableComponent";

const SwishAppIcon: React.FC<DefaultComponentProps<SvgIconTypeMap>> = (
  props
) => {
  return (
    <SvgIcon {...props} viewBox="0 0 2020 2020">
      <defs>
        <linearGradient
          x1="80.8151%"
          y1="59.6366%"
          x2="59.8678%"
          y2="8.1495%"
          id="c"
        >
          <stop stopColor="#EF2131" offset="0%" />
          <stop stopColor="#FECF2C" offset="100%" />
        </linearGradient>
        <linearGradient x1="58.8181%" y1="0%" x2="9.244%" y2="94.0713%" id="d">
          <stop stopColor="#FBC52C" offset="0%" />
          <stop stopColor="#F87130" offset="26.3921%" />
          <stop stopColor="#EF52E2" offset="56.0797%" />
          <stop stopColor="#661EEC" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="20.569%"
          y1="41.0176%"
          x2="40.5973%"
          y2="91.2765%"
          id="e"
        >
          <stop stopColor="#78F6D8" offset="0%" />
          <stop stopColor="#77D1F6" offset="26.5705%" />
          <stop stopColor="#70A4F3" offset="55.4471%" />
          <stop stopColor="#661EEC" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="42.4404%"
          y1="100%"
          x2="91.6137%"
          y2="6.2776%"
          id="f"
        >
          <stop stopColor="#536EED" offset="0%" />
          <stop stopColor="#54C3EC" offset="24.6658%" />
          <stop stopColor="#64D769" offset="56.42%" />
          <stop stopColor="#FECF2C" offset="100%" />
        </linearGradient>
        <path id="a" d="M0 0h2020v2020H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M576.8742 0h866.2516c200.5918 0 273.3311 20.8858 346.6645 60.1049 73.3333 39.219 130.8858 96.7715 170.1048 170.1048C1999.1142 303.543 2020 376.2825 2020 576.8742v866.2516c0 200.5918-20.8858 273.3311-60.1049 346.6645-39.219 73.3333-96.7715 130.8858-170.1048 170.1048C1716.457 1999.1142 1643.7176 2020 1443.1258 2020H576.8742c-200.5917 0-273.3311-20.8858-346.6645-60.1049-73.3333-39.219-130.8857-96.7715-170.1048-170.1048C20.8858 1716.457 0 1643.7176 0 1443.1258V576.8742C0 376.2825 20.8858 303.543 60.1049 230.2097 99.3239 156.8764 156.8764 99.324 230.2097 60.105 303.543 20.8858 376.2825 0 576.8742 0Z"
          fill="#FFF"
        />
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fillOpacity="0" fill="#FFF" xlinkHref="#a" />
        <g mask="url(#b)">
          <path
            d="M0 1210.0493c291.3722 139.3517 650.9655 70.3869 868.2085-188.4605C1125.7003 714.7871 1085.7065 257.4054 778.8792 0L574.9057 243.0382c239.7067 201.0982 270.9524 558.4265 69.7877 798.1162C484.2917 1232.2736 220.1977 1296.0867 0 1210.0493"
            fill="url(#c)"
            transform="translate(696.5754 453.8427)"
          />
          <path
            d="M0 1210.0493c291.3722 139.3517 650.9655 70.3869 868.2085-188.4605 26.6054-31.7004 50.0252-65.0114 70.3132-99.5405 34.3068-213.1877-41.1383-438.5215-218.6098-587.406-45.0903-37.828-93.9016-68.3033-144.975-91.5758 239.6783 201.1024 270.9113 558.4096 69.7565 798.088C484.2917 1232.2734 220.1977 1296.0866 0 1210.0492"
            fill="url(#d)"
            transform="translate(696.5754 453.8427)"
          />
          <path
            d="M1037.9349 70.6196C746.5641-68.7321 386.9709.2313 169.7279 259.0786-87.764 565.8818-47.7702 1023.2635 259.0556 1280.669l203.975-243.0382C223.3224 836.5325 192.0781 479.204 393.2428 239.513 553.6446 48.3938 817.7386-15.4192 1037.935 70.6196"
            fill="url(#e)"
            transform="translate(284.0625 284.0625)"
          />
          <path
            d="M1037.9349 70.6196C746.5641-68.7321 386.9709.2313 169.7279 259.0786c-26.607 31.7019-50.0267 65.0129-70.3133 99.542-34.3082 213.1876 41.1383 438.52 218.6099 587.406 45.0902 37.8266 93.9016 68.3032 144.9736 91.5743-239.677-201.101-270.9114-558.4081-69.7552-798.0879C553.6446 48.3938 817.7386-15.4192 1037.935 70.6196"
            fill="url(#f)"
            transform="translate(284.0625 284.0625)"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default SwishAppIcon;
