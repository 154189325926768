import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import Loading from "../components/Loading";
import { useAuthContext } from "./AuthContext";
import { ME_COMPANY_SUBSCRIPTION } from "../graphql/queries.gql";
import { CompanySubscriptionPlan } from "../graphql/types/global";
import { MeCompanySubscription } from "../graphql/types/MeCompanySubscription";

interface CompanyMetadataContextState {
  subscriptionPlan: CompanySubscriptionPlan;
  companyId: string;
}

const CompanyMetadataContext = React.createContext<
  CompanyMetadataContextState | undefined
>(undefined);

export const CompanyMetadataContextProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const { data } = useQuery<MeCompanySubscription>(ME_COMPANY_SUBSCRIPTION, {
    skip: !isAuthenticated,
  });

  const companyId = data?.me.companyId;
  const subscriptionPlan =
    data?.me.companyConnection?.subscriptionConnection.subscriptionPlan;

  const value = useMemo(() => {
    if (!companyId || !subscriptionPlan) return undefined;

    return {
      subscriptionPlan,
      companyId,
    };
  }, [companyId, subscriptionPlan]);

  return (
    <CompanyMetadataContext.Provider value={value}>
      {isAuthenticated && !value ? <Loading /> : children}
    </CompanyMetadataContext.Provider>
  );
};

export const useCompanyMetadataContext = () => {
  const value = React.useContext(CompanyMetadataContext);
  if (value === undefined)
    throw new Error("Subscription context value not defined");

  return value;
};
