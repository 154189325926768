import React from "react";
import { useParams } from "react-router-dom";
import DemoDetailsHeader from "./DemoDetailsHeader";
import { COMPANY_DEMO_DETAILS } from "../../../graphql/queries.gql";
import {
  companyDemoDetails,
  companyDemoDetailsVariables,
} from "../../../graphql/types/companyDemoDetails";
import { useQuery } from "@apollo/client";
import { DBSortOrder } from "../../../graphql/types/global";
import DemoSurveyBatchesTable from "./DemoSurveyBatchesTable";
import DemoMappedUsersTable from "./DemoMappedUsersTable";
import { noop } from "../../../utils/noop";

const DemoDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { data, refetch, loading } = useQuery<
    companyDemoDetails,
    companyDemoDetailsVariables
  >(COMPANY_DEMO_DETAILS, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: { companyDemoId: id },
  });

  const onRequestRefetch = () => {
    refetch();
  };

  const isLoading = loading;

  return (
    <div>
      <DemoDetailsHeader
        id={id}
        loading={isLoading}
        onRequestRefetch={onRequestRefetch}
        company={data?.companyDemo.companyConnection}
      />
      {data && (
        <>
          <h2>Demo users</h2>
          <DemoMappedUsersTable
            users={data.companyDemo.mappedUsersConnection.users}
            pageInfo={data.companyDemo.mappedUsersConnection.pageInfo}
            onRequestSort={noop}
            sorting={{ sort: DBSortOrder.ASC, sortField: "createdAt" }}
            onRequestRefetch={onRequestRefetch}
            companyDemoId={id}
          />
          <h2>Surveys</h2>
          <DemoSurveyBatchesTable
            batches={data.companyDemo.batchesConnection}
            companyDemoId={id}
            onRequestRefetch={onRequestRefetch}
            onRequestPage={noop}
            onRequestPageSize={noop}
            onRequestSort={noop}
            sorting={{ sort: DBSortOrder.ASC, sortField: "id" }}
          />
        </>
      )}
    </div>
  );
};

export default DemoDetailsPage;
