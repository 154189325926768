import { Button, CssBaseline, makeStyles, withStyles } from "@material-ui/core";
import React, { useLayoutEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import AgainRoundedIcon from "../../components/icons/AgainRoundedIcon";
import AgainTextAndStar, {
  viewBoxRatio,
} from "../../components/icons/AgainTextAndStar";

const logoWidth = 150;
const logoHeight = Math.ceil(logoWidth * (1 / viewBoxRatio));

const useLayoutStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    position: "absolute",
    top: "1rem",
    left: "1rem",
    width: `${logoWidth}px`,
    height: `${logoHeight}px`,
    "& svg": {
      width: `${logoWidth}px`,
      height: `${logoHeight}px`,
    },
    zIndex: 2,
  },
  slogan: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  sloganIcon: {
    width: `90px`,
    height: `90px`,
    flexShrink: 0,
    "& svg": {
      width: `90px`,
      height: `90px`,
    },
    marginRight: "2rem",
  },
  sloganText: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "22px",
    paddingTop: `${logoHeight}px`,
    "& > *": {
      maxWidth: "34rem",
      padding: "2rem 1rem",
    },
    color: theme.palette.common.white,
    zIndex: 2,
  },
}));

export const Layout: React.FC = ({ children }) => {
  const classes = useLayoutStyles();
  useLayoutEffect(() => {
    const bodyClassName = "start-page";
    window.document.body.classList.add(bodyClassName);
    return () => window.document.body.classList.remove(bodyClassName);
  }, []);
  return (
    <>
      <CssBaseline />
      <RouterLink to="/" className={classes.logo}>
        <AgainTextAndStar />
      </RouterLink>
      <div className={classes.root}>
        <div className={classes.slogan}>
          <div className={classes.sloganIcon}>
            <AgainRoundedIcon />
          </div>
          <div className={classes.sloganText}>
            <strong>Again</strong>
            <br />
            The first Open Banking based consumer insights platform.
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export const StyledButton = withStyles((theme) => ({
  root: {
    background: theme.palette.againGreen.main,
    borderRadius: "80px",
    fontSize: "1rem",
    padding: "0.75rem 2rem",

    "&:hover": {
      background: theme.palette.againGreen.dark,
    },
  },
}))(Button);

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  signInLink: {
    color: theme.palette.common.white,
    display: "inline-block",
    fontSize: "0.75em",
    textDecoration: "none",
    padding: "0 1rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const useFormStyles = makeStyles((theme) => ({
  input: {
    border: "0",
    borderRadius: "80px",
    fontSize: "1rem",
    padding: "1rem",
    width: "100%",
    "&:focus": {
      outline: "0",
      boxShadow: `0 0 0 5px ${theme.palette.grey["500"]}`,
    },
  },
  inputError: {
    boxShadow: `0 0 0 5px ${theme.palette.error.main}`,
    "&:focus": {
      boxShadow: `0 0 0 5px ${theme.palette.error.main}`,
    },
  },
  label: {
    display: "block",
    padding: "1rem",
    lineHeight: "1em",
  },
  errorMessage: {
    color: theme.palette.common.white,
    fontSize: "0.75em",
    display: "inline-block",
    padding: "0 1rem",
    margin: "1rem 0",
  },
}));
