import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import WarningIcon from "@material-ui/icons/Warning";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  makeStyles,
  createStyles,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { SystemUser } from "../../../graphql/helperTypes";
import {
  setUserSwishPhoneNumber,
  setUserSwishPhoneNumberVariables,
} from "../../../graphql/types/setUserSwishPhoneNumber";
import {
  ALL_DISBURSEMENTS,
  PHONE_UPDATED_SYSTEM_ACTIVITY_LOGS,
  SET_USER_SWISH_PHONE_NUMBER,
} from "../../../graphql/queries.gql";
import { MobilePhoneSection } from "../Users/Details/MobilePhoneSection";

export const hasMobilePhoneNumber = ({
  mobilePhoneNumber,
}: Pick<SystemUser, "mobilePhoneNumber">) => {
  return (
    typeof mobilePhoneNumber === "string" && mobilePhoneNumber.trim().length > 0
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    iconWarning: {
      marginRight: theme.spacing(0.5),
    },
    iconEdit: {
      marginLeft: theme.spacing(0.5),
    },
    iconHistory: {
      marginLeft: theme.spacing(0.5),
    },
  })
);

export default function UserPhoneDisplay({
  user,
}: {
  user: Pick<SystemUser, "id" | "mobilePhoneNumber">;
}) {
  const styles = useStyles();
  const client = useApolloClient();
  const [showLogs, setShowLogs] = useState(false);
  const [setMobilePhoneMutation, { loading }] = useMutation<
    setUserSwishPhoneNumber,
    setUserSwishPhoneNumberVariables
  >(SET_USER_SWISH_PHONE_NUMBER);

  const onEditPhone = () => {
    const newPhoneNumber = prompt(
      "Set new user swish phone number. NOTE: this will update the mobile phone number on the user account, and retry any failed swish payouts (of the past 3 months).",
      user.mobilePhoneNumber || ""
    );

    if (newPhoneNumber) {
      setMobilePhoneMutation({
        variables: { userId: user.id, swishPhoneNumber: newPhoneNumber },
      })
        .then(() => {
          client.refetchQueries({
            include: [ALL_DISBURSEMENTS, PHONE_UPDATED_SYSTEM_ACTIVITY_LOGS],
          });
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        {hasMobilePhoneNumber(user) ? (
          <span>{user.mobilePhoneNumber}</span>
        ) : (
          <>
            <WarningIcon
              color="error"
              fontSize="small"
              className={styles.iconWarning}
            />
            <Typography variant="caption" color="error">
              Mobile phone number is missing
            </Typography>
          </>
        )}
        <IconButton
          className={styles.iconEdit}
          aria-label="edit phone number"
          title="edit phone number"
          onClick={onEditPhone}
          size="small"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="20px" />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </IconButton>
        <IconButton
          className={styles.iconHistory}
          aria-label="show phone number updated logs"
          title="show phone number updated logs"
          onClick={() => setShowLogs(!showLogs)}
          size="small"
        >
          <HistoryIcon fontSize="small" />
        </IconButton>
      </div>
      {showLogs && (
        <div>
          <MobilePhoneSection user={user} />
        </div>
      )}
    </div>
  );
}
