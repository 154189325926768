import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  InputListSort,
  DBSortOrder,
  EntityDeleteState,
} from "../../../../graphql/types/global";
import {
  CONFIRM_DELETE_ACCOUNT,
  SYSTEM_USERS_DELETES,
} from "../../../../graphql/queries.gql";
import { DeletesSystemUser } from "../../../../graphql/helperTypes";
import {
  SystemUsersDeletes,
  SystemUsersDeletesVariables,
} from "../../../../graphql/types/SystemUsersDeletes";
import UserDeletesFilter from "./UserDeletesFilter";
import UserDeletesTable, { supportedSortFields } from "./UserDeletesTable";
import {
  confirmDeleteAccount,
  confirmDeleteAccountVariables,
} from "../../../../graphql/types/confirmDeleteAccount";

const UserDeletesPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [statuses, setStatuses] = useState<EntityDeleteState[]>([
    EntityDeleteState.requested,
  ]);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "createdAt",
    sort: DBSortOrder.DESC,
  });
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearch(search), 500);
    return () => clearTimeout(handler);
  }, [search]);

  const [confirmDeleteAccountMutation] = useMutation<
    confirmDeleteAccount,
    confirmDeleteAccountVariables
  >(CONFIRM_DELETE_ACCOUNT);

  const { data, refetch, loading } = useQuery<
    SystemUsersDeletes,
    SystemUsersDeletesVariables
  >(SYSTEM_USERS_DELETES, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        filter: { deleteStatus: statuses, search: debouncedSearch },
        paging: { page, size: pageSize },
        sort: sorting,
      },
    },
  });

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const handleRequestSort = useCallback(
    (property: keyof DeletesSystemUser) => {
      if (!supportedSortFields.has(property as any)) {
        return;
      }

      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRequestConfirmDelete = useCallback(
    async (user: DeletesSystemUser) => {
      if (!window.confirm(`Confirm deletion of user ${user.fullName}?`)) {
        return;
      }
      try {
        await confirmDeleteAccountMutation({
          variables: { userId: user.id },
        });
      } catch (err: any) {
        console.error(err);
        alert(err?.message);
      } finally {
        handleRefetch();
      }
    },
    [confirmDeleteAccountMutation, handleRefetch]
  );

  return (
    <div>
      <UserDeletesFilter
        statuses={statuses}
        onRequestSetStatuses={setStatuses}
        onRequestRefetch={handleRefetch}
        onRequestSetSearch={setSearch}
        loading={loading}
        search={search}
      />
      {data ? (
        <UserDeletesTable
          users={data.allSystemUsers.users}
          pageInfo={data.allSystemUsers.pageInfo}
          onRequestRefetch={handleRefetch}
          onRequestSort={handleRequestSort}
          onRequestPage={handleRequestPage}
          onRequestPageSize={handleRequestPageSize}
          onRequestConfirmDelete={handleRequestConfirmDelete}
          sorting={sorting}
        />
      ) : null}
    </div>
  );
};

export default UserDeletesPage;
