import React, { useCallback, useState } from "react";
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { COMPANY_DETAILS } from "../../../graphql/queries.gql";
import {
  CompanyDetails,
  CompanyDetailsVariables,
} from "../../../graphql/types/CompanyDetails";
import MerchantsTable from "./MerchantsTable";
import { DBSortOrder, InputListSort } from "../../../graphql/types/global";
import { MerchantDisplay } from "../../../graphql/helperTypes";
import MerchantsFilter from "./MerchantsFilter";

const CompanyDetailsPage: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [sorting, setSorting] = useState<InputListSort>({
    sortField: "merchantName",
    sort: DBSortOrder.ASC,
  });

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const handleRequestSort = useCallback(
    (property: keyof MerchantDisplay) => {
      const supportedProperties: (keyof MerchantDisplay)[] = ["merchantName"];
      if (!supportedProperties.includes(property)) {
        return;
      }
      const isAsc =
        sorting.sortField === property && sorting.sort === DBSortOrder.ASC;
      setSorting({
        sort: isAsc ? DBSortOrder.DESC : DBSortOrder.ASC,
        sortField: property,
      });
    },
    [sorting, setSorting]
  );

  const { id } = useParams<{ id: string }>();
  const { data, loading, refetch } = useQuery<
    CompanyDetails,
    CompanyDetailsVariables
  >(COMPANY_DETAILS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      companyId: id,
      paging: { page, size: pageSize },
      sort: sorting,
    },
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  if (loading || !data?.company) return <CircularProgress size="24px" />;

  const { company } = data;
  return (
    <div>
      <MerchantsFilter
        company={company}
        loading={loading}
        onRequestRefetch={handleRefetch}
      />
      <MerchantsTable
        merchants={company.merchantConnection.merchants}
        pageInfo={company.merchantConnection.pageInfo}
        onRequestPage={handleRequestPage}
        onRequestPageSize={handleRequestPageSize}
        onRequestRefetch={handleRefetch}
        onRequestSort={handleRequestSort}
        sorting={sorting}
      />
    </div>
  );
};

export default CompanyDetailsPage;
