import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import AddIcon from "@material-ui/icons/Add";
import { SystemUser } from "../../../../graphql/helperTypes";
import { useMutation, useQuery } from "@apollo/client";
import {
  ASSIGN_USER_FEATURE_FLAG,
  UNASSIGN_USER_FEATURE_FLAG,
  USER_FEATURE_FLAGS,
} from "../../../../graphql/queries.gql";
import {
  assignUserFeatureFlag,
  assignUserFeatureFlagVariables,
} from "../../../../graphql/types/assignUserFeatureFlag";
import {
  unassignUserFeatureFlag,
  unassignUserFeatureFlagVariables,
} from "../../../../graphql/types/unassignUserFeatureFlag";
import { FeatureFlagType } from "../../../../graphql/types/global";
import {
  userFeatureFlags,
  userFeatureFlagsVariables,
} from "../../../../graphql/types/userFeatureFlags";

export const FeatureFlagSection: React.FC<{ user: SystemUser }> = ({
  user,
}) => {
  const { data } = useQuery<userFeatureFlags, userFeatureFlagsVariables>(
    USER_FEATURE_FLAGS,
    { variables: { userId: user.id }, nextFetchPolicy: "cache-and-network" }
  );
  const [assignMutation, { loading: isAssigning }] = useMutation<
    assignUserFeatureFlag,
    assignUserFeatureFlagVariables
  >(ASSIGN_USER_FEATURE_FLAG);

  const [unassignMutation, { loading: isUnassigning }] = useMutation<
    unassignUserFeatureFlag,
    unassignUserFeatureFlagVariables
  >(UNASSIGN_USER_FEATURE_FLAG);

  const loading = isAssigning || isUnassigning;

  const onAssign = async (featureFlagType: FeatureFlagType) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Assign feature flag "${featureFlagType}" to "${user.displayName}"?`
      )
    ) {
      await assignMutation({ variables: { featureFlagType, userId: user.id } });
    }
  };

  const onUnassign = async (featureFlagType: FeatureFlagType) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Remove feature flag "${featureFlagType}" from "${user.displayName}"?`
      )
    ) {
      await unassignMutation({
        variables: { featureFlagType, userId: user.id },
      });
    }
  };

  const allFlags = Object.values(FeatureFlagType).sort();

  if (!data) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Feature flag</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {allFlags.map((flag) => {
          const isFlagged = data.userFeatureFlags.some(
            (assigned) => assigned.featureFlagType === flag
          );
          return (
            <TableRow key={flag}>
              <TableCell>{flag}</TableCell>
              <TableCell>
                {isFlagged ? "Flagged" : "Not flagged"}
                {isFlagged ? (
                  <IconButton
                    onClick={() => onUnassign(flag)}
                    disabled={loading}
                  >
                    <BackspaceIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => onAssign(flag)} disabled={loading}>
                    <AddIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
