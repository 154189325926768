import React from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import {
  DBSortOrder,
  InputListSort,
  SupportedLanguageCode,
} from "../../../graphql/types/global";
import { asDirection } from "../../../utils/tableUtils";
import {
  allAppTextDocuments_allAppTextDocuments,
  allAppTextDocuments_allAppTextDocuments_documents,
  allAppTextDocuments_allAppTextDocuments_documents_createdByConnection,
  allAppTextDocuments_allAppTextDocuments_documents_updatedByConnection,
} from "../../../graphql/types/allAppTextDocuments";
import { CompanyThumbnail } from "../Assign/CompanyThumbnail";
import { TimestampDisplay } from "../../../components/TimestampDisplay";
import { truncateText } from "../../../utils/textUtils";
import { documentLanguageCodes } from "../../../utils/getSupportedLanguageCodes";
import WarningIcon from "@material-ui/icons/Warning";
import { Typography } from "@material-ui/core";

type Document = allAppTextDocuments_allAppTextDocuments_documents;

interface HeadCell {
  id: keyof Document;
  label: string;
  type: "string" | "date" | "user" | "campaign";
}

const headCells: HeadCell[] = [
  { id: "kind", type: "string", label: "Kind" },
  { id: "campaignConnection", type: "campaign", label: "Campaign" },
  { id: "createdAt", type: "date", label: "Created" },
];

export type OnRequestSort = (property: keyof Document) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  // onRequestSort: OnRequestSort;
  // sorting: InputListSort;
}

interface TableProps extends allAppTextDocuments_allAppTextDocuments {
  // onRequestSort: OnRequestSort;
  // onRequestPage: OnRequestPage;
  // onRequestPageSize: OnRequestPageSize;
  // sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;
  // const createSortHandler =
  //   (property: keyof SystemActivityLog) => (_: React.MouseEvent<unknown>) => {
  //     onRequestSort(property);
  //   };

  const sorting: InputListSort = {
    sort: DBSortOrder.ASC,
    sortField: "createdAt",
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              // onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {documentLanguageCodes.map((language) => (
          <TableCell key={language} align="left">
            {language}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    campaignLogoWrapper: {
      marginRight: theme.spacing(0.5),
    },
    markdownCell: {
      fontSize: "0.75em",
    },
  })
);

const MarkdownCellContent = ({
  document,
  languageCode,
}: {
  document: allAppTextDocuments_allAppTextDocuments_documents;
  languageCode: SupportedLanguageCode;
}) => {
  const markdown = document.allContentConnection.find(
    (item) => item.languageCode === languageCode
  )?.markdown;
  if (markdown === undefined || markdown === null) {
    return (
      <>
        <WarningIcon color="error" fontSize="small" />
        (no translation)
      </>
    );
  }
  return <>{markdown}</>;
};

const SystemActivityLogTable: React.FC<TableProps> = ({
  // onRequestPage,
  // onRequestPageSize,
  // onRequestSort,
  // pageInfo,
  // sorting,
  documents,
}) => {
  const classes = useStyles();
  // const handleChangePage = (_: any, page: number) => onRequestPage(page);
  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   onRequestPageSize(parseInt(event.target.value, 10));
  // };

  function renderCell(
    row: allAppTextDocuments_allAppTextDocuments_documents,
    cell: HeadCell
  ): React.ReactNode {
    switch (cell.type) {
      case "date":
        return <TimestampDisplay timestamp={row[cell.id] as string} />;
      case "user":
        return (
          row[cell.id] as
            | allAppTextDocuments_allAppTextDocuments_documents_createdByConnection
            | allAppTextDocuments_allAppTextDocuments_documents_updatedByConnection
        ).displayName;
      case "campaign":
        if (!row.campaignConnection) {
          return null;
        }
        const campaign = row.campaignConnection;
        const logo = campaign.companyConnection?.logoThumbnailUrl;
        return (
          <Link to={`/admin/campaign/${campaign.id}`}>
            {logo ? (
              <span className={classes.campaignLogoWrapper}>
                <CompanyThumbnail logoUrl={logo} size={20} />
              </span>
            ) : null}
            {truncateText(campaign.description, 8)}
          </Link>
        );
      default:
        return row[cell.id];
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              // sorting={sorting}
              // onRequestSort={onRequestSort}
            />
            <TableBody>
              {documents.map((document) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={document.id}
                    component={RouterLink}
                    to={`/admin/documents/edit/${document.id}`}
                  >
                    {headCells.map((cell) => (
                      <TableCell
                        key={`${cell.id}-${document.id}`}
                        className={clsx({
                          [classes.noWrapCell]: cell.type === "date",
                        })}
                      >
                        {renderCell(document, cell)}
                      </TableCell>
                    ))}
                    {documentLanguageCodes.map((language) => (
                      <TableCell
                        key={`${document.id}-${language}`}
                        align="left"
                        className={classes.markdownCell}
                      >
                        <MarkdownCellContent
                          document={document}
                          languageCode={language}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          component="div"
          rowsPerPageOptions={[10, 50, 100, 500, 1000]}
          count={pageInfo.totalCount}
          rowsPerPage={pageInfo.size}
          page={pageInfo.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
};

export default SystemActivityLogTable;
