import React, { memo, useCallback, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import SyncIcon from "@material-ui/icons/Sync";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import clsx from "clsx";
import { EnableBankingAccount } from "../../../../graphql/helperTypes";
import { InputListSort, DBSortOrder } from "../../../../graphql/types/global";
import { OmitType } from "../../../../utils/omitType";
import { asDirection } from "../../../../utils/tableUtils";
import { enableBankingSessions_enableBankingSessions_sessions_accountsConnection } from "../../../../graphql/types/enableBankingSessions";
import { Box, Collapse, IconButton } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/client";
import {
  enableBankingAccountFetchLogs,
  enableBankingAccountFetchLogsVariables,
} from "../../../../graphql/types/enableBankingAccountFetchLogs";
import {
  ENABLE_BANKING_ACCOUNT_FETCH_LOGS,
  FETCH_ENABLE_BANKING_ACCOUNT_TRANSACTIONS,
} from "../../../../graphql/queries.gql";
import EnableBankingFetchLogTable from "./EnableBankingFetchLogTable";
import { noop } from "../../../../utils/noop";
import { TruncatedText } from "../../../../components/TruncatedText";
import {
  fetchEnableBankingAccountTransactions,
  fetchEnableBankingAccountTransactionsVariables,
} from "../../../../graphql/types/fetchEnableBankingAccountTransactions";
import { copyToClipboard } from "../../../../utils/clipboard";
import { TimestampDisplay } from "../../../../components/TimestampDisplay";

interface HeadCell {
  id: keyof EnableBankingAccount;
  label: string;
  type: "date" | "string" | "short";
}

const headCells: HeadCell[] = [
  { id: "id", type: "short", label: "Account uid" },
  { id: "details", type: "string", label: "Details" },
  { id: "createdAt", type: "date", label: "Created" },
  { id: "fetchedAt", type: "date", label: "Fetched" },
  { id: "earliestTransactionTimestamp", type: "date", label: "Earliest" },
  { id: "latestTransactionTimestamp", type: "date", label: "Latest" },
];

export type OnRequestSort = (property: keyof EnableBankingAccount) => any;
export type OnRequestPage = (page: number) => any;
export type OnRequestPageSize = (pageSize: number) => any;

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

interface TableProps
  extends OmitType<enableBankingSessions_enableBankingSessions_sessions_accountsConnection> {
  onRequestSort: OnRequestSort;
  sorting: InputListSort;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, sorting, onRequestSort } = props;
  const createSortHandler =
    (property: keyof EnableBankingAccount) =>
    (_: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={cellAlign(headCell)}
            sortDirection={
              sorting.sortField === headCell.id
                ? asDirection(sorting.sort)
                : false
            }
          >
            <TableSortLabel
              active={sorting.sortField === headCell.id}
              direction={
                sorting.sortField === headCell.id
                  ? asDirection(sorting.sort)
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sorting.sortField === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sorting.sort === DBSortOrder.DESC
                    ? "sorted descending"
                    : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const cellAlign = (cell: HeadCell) => "left" as const;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      flex: 1,
      marginBottom: theme.spacing(2),
      maxWidth: "calc(100vw - 34px)",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    noWrapCell: {
      whiteSpace: "nowrap",
    },
    companyRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
    },
    expandButtonCell: {
      width: "44px",
      paddingRight: 0,
      paddingLeft: "8px",
    },
    accountRow: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    disabled: {
      opacity: 0.3,
    },
    pointer: {
      cursor: "pointer",
    },
  })
);

const Row = ({ row }: { row: EnableBankingAccount }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const handleRequestPageSize = useCallback(
    (pageSize: number) => {
      setPageSize(pageSize);
      setPage(1);
    },
    [setPage, setPageSize]
  );

  const handleRequestPage = useCallback(
    (page: number) => setPage(page),
    [setPage]
  );

  const [fetchMutation, { loading: fetchMutationLoading }] = useMutation<
    fetchEnableBankingAccountTransactions,
    fetchEnableBankingAccountTransactionsVariables
  >(FETCH_ENABLE_BANKING_ACCOUNT_TRANSACTIONS);

  const { data } = useQuery<
    enableBankingAccountFetchLogs,
    enableBankingAccountFetchLogsVariables
  >(ENABLE_BANKING_ACCOUNT_FETCH_LOGS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-and-network",
    variables: {
      accountId: row.id,
      paging: { page, size: pageSize },
    },
    pollInterval: 2000,
    skip: !open,
  });

  const handleOnSyncClicked = async () => {
    const defaultFromDate = "default";
    const fromDate =
      prompt(
        `Fetch transactions from (yyyy-MM-dd), or "${defaultFromDate}" for default`,
        defaultFromDate
      ) || undefined;

    if (!fromDate) {
      console.info("Prompt cancelled");
      return;
    }

    try {
      await fetchMutation({
        variables: {
          accountUid: row.id,
          fromDate: fromDate === defaultFromDate ? undefined : fromDate,
        },
      });
    } catch (err: any) {
      console.error(err);
      alert(err?.message);
    }
  };

  const renderCell = (cell: HeadCell, row: EnableBankingAccount) => {
    switch (cell.type) {
      case "date":
        return <TimestampDisplay timestamp={row[cell.id] as string} />;
      case "short":
        return <TruncatedText value={row[cell.id]} />;
      default:
        return row[cell.id];
    }
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        className={clsx(classes.accountRow, {
          [classes.disabled]: row.replacedByAccountUid != null,
        })}
      >
        <TableCell className={classes.expandButtonCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {headCells.map((cell) => (
          <TableCell
            align={cellAlign(cell)}
            key={`${cell.id}-${row.id}`}
            className={clsx({
              [classes.noWrapCell]: cell.type === "date",
              [classes.pointer]: cell.type === "short",
            })}
            onClick={
              cell.type === "short"
                ? () => copyToClipboard(row[cell.id] as any)
                : undefined
            }
          >
            {renderCell(cell, row)}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.expandButtonCell}>
          {open ? (
            <IconButton
              aria-label="Sync transcations"
              onClick={handleOnSyncClicked}
              disabled={fetchMutationLoading}
              size="small"
            >
              <SyncIcon />
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {data && (
                <EnableBankingFetchLogTable
                  onRequestPage={handleRequestPage}
                  onRequestPageSize={handleRequestPageSize}
                  onRequestSort={noop}
                  sorting={{ sortField: "id", sort: DBSortOrder.DESC }}
                  pageInfo={data.enableBankingAccountFetchLogs.pageInfo}
                  transactionLogs={
                    data.enableBankingAccountFetchLogs.transactionLogs
                  }
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EnableBankingAccountsTable = memo<TableProps>(
  ({ onRequestSort, sorting, accounts }) => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              sorting={sorting}
              onRequestSort={onRequestSort}
            />
            <TableBody>
              {accounts.map((row) => (
                <Row row={row} key={row.id} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
);

export default EnableBankingAccountsTable;
