export type StorageKey =
  | "accessToken"
  | "refreshToken"
  | "expiresAt"
  | "codeVerifier"
  | "postLoginRedirect";

export const getLocalStorage = (key: StorageKey) => localStorage.getItem(key);
export const setLocalStorage = (key: StorageKey, value: string) =>
  localStorage.setItem(key, value);

export const removeLocalStorage = (key: StorageKey) =>
  localStorage.removeItem(key);
