import React, { memo } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import { EntityDeleteState } from "../../../../graphql/types/global";

const allStatuses = Object.values(EntityDeleteState).sort();

interface FilterProps {
  statuses: EntityDeleteState[];
  loading: boolean;
  search: string;
  onRequestRefetch: () => any;
  onRequestSetStatuses: (types: EntityDeleteState[]) => any;
  onRequestSetSearch: (search: string) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "block",
    },
    firstRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    textField: {
      margin: 0,
    },
    select: {
      minWidth: 140,
      marginRight: theme.spacing(1),
    },
  })
);

const UserDeletesFilter = memo<FilterProps>(
  ({
    statuses,
    loading,
    search,
    onRequestRefetch,
    onRequestSetStatuses,
    onRequestSetSearch,
  }) => {
    const classes = useStyles();

    return (
      <div className={classes.container}>
        <div className={classes.firstRow}>
          <IconButton
            aria-label="refresh"
            onClick={onRequestRefetch}
            className={classes.icon}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="24px" />
            ) : (
              <Refresh fontSize="default" />
            )}
          </IconButton>

          <FormControl
            variant="outlined"
            size="small"
            margin="dense"
            className={classes.select}
          >
            <InputLabel>Status</InputLabel>
            <Select
              value={statuses[0] ?? ""}
              onChange={(evt) =>
                onRequestSetStatuses(
                  evt.target.value === ""
                    ? []
                    : [evt.target.value as EntityDeleteState]
                )
              }
              label="Status"
            >
              {allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className={classes.textField}>
            <TextField
              label="Search users on name/email"
              value={search}
              onChange={(evt) => onRequestSetSearch(evt.target.value)}
              margin="dense"
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </div>
      </div>
    );
  }
);

export default UserDeletesFilter;
