import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { SystemUser } from "../../../../graphql/helperTypes";
import { useQuery } from "@apollo/client";
import { PHONE_UPDATED_SYSTEM_ACTIVITY_LOGS } from "../../../../graphql/queries.gql";
import {
  PhoneUpdatedSystemActivityLogs,
  PhoneUpdatedSystemActivityLogsVariables,
} from "../../../../graphql/types/PhoneUpdatedSystemActivityLogs";
import { TimestampDisplay } from "../../../../components/TimestampDisplay";

type Props = { user: Pick<SystemUser, "id"> };

export const MobilePhoneSection: React.FC<Props> = ({ user }) => {
  const { data } = useQuery<
    PhoneUpdatedSystemActivityLogs,
    PhoneUpdatedSystemActivityLogsVariables
  >(PHONE_UPDATED_SYSTEM_ACTIVITY_LOGS, {
    variables: { userId: user.id },
    fetchPolicy: "cache-and-network",
  });

  if (!data) return null;

  const { systemActivityLogs } = data.allSystemActivityLogs;

  if (systemActivityLogs.length === 0) {
    return (
      <Typography>There are no logged mobile phone number updates</Typography>
    );
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {systemActivityLogs.map((row, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  <TimestampDisplay timestamp={row.createdAt} />
                </TableCell>
                <TableCell>{row.body}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
