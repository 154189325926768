import React from "react";
import { createStyles, IconButton, makeStyles } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SwishAppIcon from "../../../components/icons/SwishAppIcon";

interface Props {
  disabled?: boolean;
  showAddButton: boolean;
  onClick: () => any;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    swishIcon: {
      width: "0.75em",
      height: "0.75em",
    },
  })
);

export default function DisbursementButton({
  disabled,
  showAddButton,
  onClick,
}: Props) {
  const styles = useStyles();
  return (
    <IconButton aria-label="disbursement" onClick={onClick} disabled={disabled}>
      {showAddButton ? (
        <SwishAppIcon className={styles.swishIcon} />
      ) : (
        <AccountBalanceIcon fontSize="small" />
      )}
    </IconButton>
  );
}
