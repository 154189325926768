import React, { useMemo } from "react";

interface DrawerContextValue {
  isOpen: boolean;
  toggleOpen: () => void;
}

const DrawerContext = React.createContext<DrawerContextValue | undefined>(
  undefined
);

export const DrawerContextProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const value = useMemo<DrawerContextValue>(() => {
    return { isOpen, toggleOpen: () => setIsOpen(!isOpen) };
  }, [isOpen, setIsOpen]);

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  const value = React.useContext(DrawerContext);
  if (value === undefined) throw new Error("Context value not defined");
  return value;
};
