import React from "react";
import { IconButton } from "@material-ui/core";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

export const NotificationIcon = NotificationsNoneIcon;
export function NotificationButton({ onClick }: { onClick: () => any }) {
  return (
    <IconButton size="medium" onClick={onClick}>
      <NotificationIcon style={{ fontSize: "0.75em" }} />
    </IconButton>
  );
}
